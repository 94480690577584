import { Card, CardContent, Grid, Tooltip } from "@mui/material";
import React from "react";
import { style } from "../../../Project/projectDashboard/style";
import commit from "../../../../assets/commit.png";
import msg from "../../../../assets/msg.png";
import sprintCompletionRateIcon from "../../../../assets/sprintcompletionrateicon.jpeg";
import issueCycleTimeIcon from "../../../../assets/issuecycletimeicon.jpeg";
import { config } from "../../../../Config/permission";

const useStyles = style;

const SprintHeader = ({ SprintAllMetrics }) => {
  const classes = useStyles();

  return (
    <div>
      <Grid container spacing={2} style={{ marginBottom: "20px" }}>
        <Grid item xs={2}>
          <Card className={`${classes.card} customCard position-relative`}>
            <CardContent>
              <div className={classes.cardContaner}>
                <div
                  className={classes.icon}
                  style={{ backgroundColor: "#fdeeee" }}
                >
                  <img src={sprintCompletionRateIcon} alt="basket" />
                </div>
                <div className={classes.textContianer}>
                  <h3 style={{ fontSize: "21px" }}>
                    {Math.round(SprintAllMetrics?.SprintCompletionRatio)} %
                  </h3>
                  <h6>Sprint Completion Rate</h6>
                </div>
              </div>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={2}>
          <Card className={`${classes.card} customCard position-relative`}>
            <CardContent>
              <div className={classes.cardContaner}>
                <div
                  className={classes.icon}
                  style={{ backgroundColor: "#fdeeee" }}
                >
                  <img src={issueCycleTimeIcon} alt="basket" />
                </div>
                <div className={classes.textContianer}>
                  <h3 style={{ fontSize: "21px" }}>
                    {SprintAllMetrics?.CycleTime} (Days)
                  </h3>
                  <h6>Issue Cycle Time</h6>
                </div>
              </div>
            </CardContent>
          </Card>
        </Grid>
        {config.sprint.sprintOverViewMetrics && (
          <>
            <Grid item xs={2}>
              <Card className={`${classes.card} customCard position-relative`}>
                <CardContent>
                  <div className={classes.cardContaner}>
                    <div
                      className={classes.icon}
                      style={{ backgroundColor: "#fdeeee" }}
                    >
                      <img src={commit} alt="basket" />
                    </div>
                    <div className={classes.textContianer}>
                      <h3 style={{ fontSize: "21px" }}>
                        {SprintAllMetrics?.CommitWithoutPR} (Count)
                      </h3>
                      <h6>Commit Without Pr</h6>
                    </div>
                  </div>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={2}>
              <Card className={`${classes.card} customCard position-relative`}>
                <CardContent>
                  <div className={classes.cardContaner}>
                    <div
                      className={classes.icon}
                      style={{ backgroundColor: "#fdeeee" }}
                    >
                      <img src={msg} alt="basket" />
                    </div>
                    <div className={classes.textContianer}>
                      <h3 style={{ fontSize: "20px" }}>
                        {SprintAllMetrics?.CommitWithoutTicketReference}
                      </h3>
                      <h6>Commits Without Ticket Reference</h6>
                    </div>
                  </div>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={2}>
              <Card className={`${classes.card} customCard position-relative`}>
                <CardContent>
                  <div className={classes.cardContaner}>
                    <div
                      className={classes.icon}
                      style={{ backgroundColor: "#fdeeee" }}
                    >
                      <img src={commit} alt="basket" />
                    </div>
                    <div className={classes.textContianer}>
                      <h3 style={{ fontSize: "21px" }}>
                        {SprintAllMetrics?.CommitCount}
                      </h3>
                      <h6>Number Of Commits/Week</h6>
                    </div>
                  </div>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={2}>
              <Card className={`${classes.card} customCard position-relative`}>
                <CardContent>
                  <div className={classes.cardContaner}>
                    <div
                      className={classes.icon}
                      style={{ backgroundColor: "#fdeeee" }}
                    >
                      <img src={commit} alt="basket" />
                    </div>
                    <div className={classes.textContianer}>
                      <h3 style={{ fontSize: "21px" }}>
                        {SprintAllMetrics?.IssueCompleted?.toFixed()}
                      </h3>
                      <h6>No Of Story Point Completed</h6>
                    </div>
                  </div>
                </CardContent>
              </Card>
            </Grid>
            {/* <Grid item xs={2}>
              <Card className={`${classes.card} customCard position-relative`}>
                <CardContent>
                  <div className={classes.cardContaner}>
                    <div
                      className={classes.icon}
                      style={{ backgroundColor: "#fdeeee" }}
                    >
                      <img src={commit} alt="basket" />
                    </div>
                    <div className={classes.textContianer}>
                      <h3 style={{ fontSize: "21px" }}>
                        {SprintAllMetrics?.PRreview}
                      </h3>
                      <h6>Pr Reviews/Week</h6>
                    </div>
                  </div>
                </CardContent>
              </Card>
            </Grid> */}
          </>
        )}
      </Grid>
    </div>
  );
};

export default SprintHeader;
