export let iterationList = [
    { lable: "Daily", value: 1 },
    { lable: "Weekly", value: 7 },
    { lable: "Bi-Weekly", value: 14 },
    { lable: "Monthly", value: 30 },
    { lable: "Quarterly", value: 90 },
];



export let rangeDuration = [
    { lable: "Last 30 Days", value: 30 },
    { lable: "Last 60 Days", value: 60 },
    { lable: "Last 90 Days", value: 90 },
    { lable: "Last 180 Days", value: 180 },
];