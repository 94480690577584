import React, { useContext, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Card, CardContent, Typography } from "@material-ui/core";
import axios from "../../utils/axiosHelper";
import TrendChart from "../../components/trendGraph";
import { iterationMap } from "../../utils/textMap";
import { GlobalData } from "../../context/globalData";
import { MODULE_API_URL_GEN } from "../../utils/moduleTabApi";
import { useLocation, useParams, useHistory } from "react-router-dom";
import queryString from "query-string";
import BreadCrumb from "../../components/breadCrumb/BreadCrumb";

const useStyles = makeStyles((theme) => ({
  email: {
    marginBottom: "20px",
  },
  mt5: {
    marginTop: "20px",
  },
  cardContainer: {
    paddingBottom: "16px !important",
  },
  insigthTitle: {
    textAlign: "left",
    font: "normal normal 600 20px/25px Lexend Deca",
    color: "#1E1E1E",
  },
  insideSection: {
    marginTop: "19px",
    paddingTop: "20px",
    borderTop: "1px solid #F2F2F2",
  },
  insideBody: {
    textAlign: "left",
    font: "normal normal normal 16px/20px Lexend Deca",
    color: "#1E1E1E",
    marginTop: "19px",
  },
  sidePadding: {
    paddingLeft: "25px",
  },
}));

function SingleResource() {
  const { iterationValueGlobal, projectRangePickerValue: rangePickerValue } =
    useContext(GlobalData);

  const [graphData, setGraphData] = useState([]);
  const [activeKey, setActiveKey] = useState(
    iterationMap[iterationValueGlobal]
  );

  const classes = useStyles();
  const history = useHistory();
  const { projectId, resourceId } = useParams();
  const { state } = useLocation();

  const trandHandler = (trendUrl) => {
    let url = `/api/v2/resource/trends/${resourceId}/kpi/${state?.matricId}/get?${trendUrl}`;
    axios
      .get(url)
      .then((result) => {
        console.log(result, "result?.data?.trends");
        const data = result?.data?.trends?.map((item) => {
          return {
            Value1: item.Value,
            Time: item.Time,
            Name1: state?.resourceName,
            Value: result?.data?.iteration?.Target,
            // Name: "Target",
          };
        });
        setGraphData(data);
      })
      .catch((err) => {
        console.log("error", err);
      });
  };

  // call trend api
  useEffect(() => {
    let trendUrl = MODULE_API_URL_GEN(rangePickerValue, "", activeKey);
    trandHandler(trendUrl);
  }, [activeKey]);

  return (
    <>
      <BreadCrumb
        backHandler={() => {
          history.goBack();
        }}
        list={[
          "Projects",
          `${state?.projectName}`,
          `${state?.teamName}`,
          "Resources",
        ]}
        active={state?.resourceName}
      />
      <Card className="customCard">
        <CardContent
          className={`${classes.cardContainer} ${classes.sidePadding}`}
        >
          <h4>Resource Name : {state?.resourceName}</h4>
        </CardContent>
      </Card>
      <Card className={`${classes.mt5} customCard`}>
        <CardContent
          className={`${classes.cardContainer} ${classes.sidePadding}`}
        >
          {/* <Typography>
            How Much issue did {value?.name} Take Action on.?
          </Typography> */}

          <TrendChart
            graphData={graphData}
            activeKey={activeKey}
            setActiveKey={setActiveKey}
            matricName={state?.matricName}
          />
          {/* <div className={classes.insideSection}>
            <Typography className={classes.insigthTitle}>Insight</Typography>
            <Typography className={classes.insideBody}>
              No Insight for this Resource
            </Typography>
          </div> */}
        </CardContent>
      </Card>
    </>
  );
}

export default SingleResource;
