import { useContext, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import { Alert, AlertTitle } from "@material-ui/lab";

import axios from "../../../utils/axiosHelper";
import { GlobalData } from "../../../context/globalData";
import { useTranslation } from "react-i18next";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import { DaysMap, MonthMap, StatusValueMap } from "../../../utils/textMap";
import MatricLevelRisks from "./Form/matricLevelRisks";
import MatricsData from "./Form/matricsData";
import MetricGroups from "./Form/metricGroups";
import ConsolidatedRisk from "../../../hoc/layout/risk/projectLevelList/consolidatedRisk";
import AlertFormWorkForm from "../../settingPages/alertFrameWork/alertFormWorkForm";

const Status = ["Active", "Inactive"];
const Days = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];

const Month = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const useStyles = makeStyles((theme) => ({
  head: {
    // margin: theme.spacing(2),

    minWidth: 180,
  },
  root: {
    marginTop: "5px",
  },
  inputWraper: {
    padding: 15,
    display: "flex",
    textAlign: "left",
  },
  errorMsg: {
    color: "#cc0000",
    // marginBottom: "12px",

    marginLeft: 160,

    width: "370px",
    textAlign: "left",
  },
  label: {
    marginRight: "15px",
    fontSize: "16px",
    alignSelf: "center",
    color: "#000000DE",

    fontStyle: "bold",
  },
  min_width: {
    width: "331px",
  },
  input: {
    color: "#20262d",

    height: "15px",
    borderRadius: 0,
  },
  btn: {
    backgroundColor: "#082c49",
    width: "200px",
    fontSize: "14px",
    color: "#fff",
    height: "40px",
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: "#072136",
    },
  },

  btnlabel: { fontSize: 16, color: "#082C49" },
  tableFieldGrid: {
    display: "flex",
    // marginLeft: "42px",

    width: "280px",
  },
  DropdownGrid: {
    marginLeft: "42px",
  },
  DropdownMargin: {
    marginLeft: "-8px",
  },
  star: {
    color: "red",
    fontSize: 5,
  },
  btnWraper: {
    width: "200px",
    marginTop: "20px",
    position: "relative",
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  alert: {
    width: "90%",
    position: "absolute",
    bottom: "0px",
    left: "5%",
    // bottom: "-80%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
  bottomAlert: {
    width: "90%",
    // position: "absolute",

    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}));

export default function Index(props) {
  const { title } = props;
  const classes = useStyles();
  const [t] = useTranslation("common");
  const [groupName, setGroupName] = useState(props.data?.["Metric Group"]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [groupSequance, setgroupSequance] = useState(
    props.data?.["Display Sequence"]
  );
  const { setmetricDetails, metricDetails } = useContext(GlobalData);

  const [fields, setFields] = useState({
    Sequance: props.data?.["Display Sequence"],
    groupName: props.data?.["Metric Group"],
    riskScore: props.data?.["Risk Score"],
  });
  const [errorMessage, setErrorMessage] = useState("");
  const [error2, setError2] = useState(false);
  const [success, setSuccess] = useState(false);

  const [error, setError] = useState({});
  const [dayValue, setDayvalue] = useState("Monday");
  const [daybiweekValue, setDayBiweekvalue] = useState("Monday");
  const [MonthValue, setMonthvalue] = useState("January");
  const [statusValue, setStatusValue] = useState(
    props.data?.isActive ? "Active" : props.form ? "Active" : "Inactive"
  );

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };
  useEffect(() => {});
  const onSubmit = () => {
    if (validateForm()) {
      let id = props?.data?.id || 0;

      let url =
        id === 0 ? "/api/metricgroups/create" : "/api/metricgroups/update";

      const body = {
        DisplayName: groupName,
        isActive: StatusValueMap[statusValue],
        DisplaySeq: groupSequance,
        MetricGroupId: props?.data?.id || undefined,
      };

      axios
        .post(url, body)
        .then((result) => {
          if (result.status === 200) {
            setmetricDetails(metricDetails);
            props.back();
          }
        })
        .catch((error) => {
          setErrorMessage(t(error.response?.data?.error));
          setError2(true);
          setTimeout(function () {
            setError2(false);
          }, 3000);
        });
    }
  };
  const onSubmitSetting = () => {
    let obj = {
      startCalculateFrom: selectedDate,
      weekStart: DaysMap[dayValue],
      biweekStart: DaysMap[daybiweekValue],
      quaterStart: MonthMap[MonthValue],
    };

    axios.post("/api/auth/settings", obj).then((res) => {
      setSuccess(true);
      setTimeout(function () {
        setSuccess(false);
      }, 3000);
    });
  };

  const validateForm = () => {
    let errors = {};

    let formIsValid = true;
    if (!fields["Sequance"]) {
      formIsValid = false;
      errors["Sequance"] = "Required";
    } else if (isNaN(fields["Sequance"])) {
      formIsValid = false;
      errors["Sequance"] = "Please enter a valid number.";
    } else if (0 > fields["Sequance"]) {
      formIsValid = false;
      errors["Sequance"] = "Please enter a positive number.";
    }
    if (!fields["groupName"]) {
      formIsValid = false;
      errors["groupName"] = "Required";
    }

    // if (typeof fields["groupName"] !== "undefined") {
    //   if (!fields["groupName"].match(/^[a-zA-Z ]*$/)) {
    //     formIsValid = false;
    //     errors["groupName"] = "*Please enter characters only.";
    //   }
    // }

    setError(errors);

    return formIsValid;
  };

  return (
    <div className={classes.head}>
      {title === "Metric Level Risks" ? (
        <MatricLevelRisks
          classes={classes}
          fields={fields}
          setFields={setFields}
          back={props.back}
          data={props.data}
        />
      ) : title === "Metric Groups" ? (
        <MetricGroups
          classes={classes}
          error={error}
          fields={fields}
          setFields={setFields}
          back={props.back}
          onSubmit={onSubmit}
          groupSequance={groupSequance}
          setgroupSequance={setgroupSequance}
          groupName={groupName}
          setGroupName={setGroupName}
          Status={Status}
          statusValue={statusValue}
          setStatusValue={setStatusValue}
        />
      ) : title === "Project, Team, Resource, Module Level Risks" ? (
        <ConsolidatedRisk
          classes={classes}
          fields={fields}
          setFields={setFields}
          back={props.back}
          data={props.data}
        />
      ) : title === "Alert Frame Work" ? (
        <AlertFormWorkForm
          fields={fields}
          setFields={setFields}
          classes={classes}
          back={props.back}
          data={props.data}
        />
      )
      //  : title === "Tools Integration" ? (
      //  <ToolsIntegration />
      // ) 
      : (
        <MatricsData
          classes={classes}
          fields={fields}
          setFields={setFields}
          back={props.back}
          data={props.data}
        />
      )}
      {error2 && (
        <div className={classes.alert}>
          <Alert severity="error">
            <AlertTitle>Error</AlertTitle>
            {errorMessage}
          </Alert>
        </div>
      )}
      {success && (
        <div>
          <Alert severity="success">
            <AlertTitle>Success</AlertTitle>
            {errorMessage}
          </Alert>
        </div>
      )}
    </div>
  );
}
