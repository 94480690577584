import { Alert } from "@mui/material";
import { config } from "../../../Config/permission";

export const getLegend = (
  selectedType,
  performanceMedian,
  classes,
  alertState,
  suggestionStatus,
  showTrend,
  teamId
) => {
  const performanceArray = [
    { name: "Under utilized and Under performance", color: "#FF5733" },
    { name: "Good performance Over utilized", color: "purple" },
    { name: "Under utilized and Good performance", color: "yellow" },
    { name: "Under performance and Over utilized", color: "orange" },
    { name: "Over utilized and Normal performance", color: "brown" },
    { name: "Under utilized and Normal performance", color: "gray" },
    { name: "Normal utilized and Normal performance", color: "green" },
    { name: "Normal utilized and Good performance", color: "blue" },
    { name: "Normal utilized and Under performance", color: "velvet" },
  ];

  if (selectedType == "Utilization") {
    return (
      <>
        <div className={classes.graphSuggetions}>
          {alertState && (
            <>
              {suggestionStatus?.UnderUtilized && (
                <Alert
                  sx={{ mb: 3, fontSize: 12, textAlign: "left" }}
                  severity="info"
                >
                  <b>
                    {" "}
                    <span
                      class="legend-color"
                      style={{ background: "#ffb100", width: 13, height: 13 }}
                    ></span>
                    Recommendation for Under Utilized :{" "}
                  </b>
                  You can assign more stories or tasks to this{" "}
                  {teamId ? "engineer" : "team"} to improve utilization and
                  efficiency
                </Alert>
              )}
              {suggestionStatus.OverUtilized && (
                <Alert
                  sx={{ mb: 3, fontSize: 12, textAlign: "left" }}
                  severity="info"
                >
                  <b>
                    {" "}
                    <span
                      class="legend-color"
                      style={{ background: "#FF5733", width: 13, height: 13 }}
                    ></span>
                    Recommendation for Over Utilized :{" "}
                  </b>
                  Reduce the tasks assigned to this{" "}
                  {teamId ? "engineer" : "team"} as consistent high utilization
                  can lead to burn out and attrition.
                </Alert>
              )}
            </>
          )}
        </div>
        {
          // !showTrend &&
          <div class="legend">
            <div class="legend-item">
              <div class="legend-color" style={{ background: "#FF5733" }}></div>
              <div>{` Over Utilized (greater than 100 ${
                config.type === "Task" ? "Hours" : config.type
              }))`}</div>
            </div>
            <div class="legend-item">
              <div class="legend-color" style={{ background: "#ffb100" }}></div>
              <div>{`Under Utilized (less than 50 ${
                config.type === "Task" ? "Hours" : config.type
              }))`}</div>
            </div>
            <div class="legend-item">
              <div class="legend-color" style={{ background: "green" }}></div>
              <div>{`Normal (51 to 100 ${
                config.type === "Task" ? "Hours" : config.type
              }))`}</div>
            </div>
          </div>
        }
      </>
    );
  } else if (selectedType == "Performance") {
    return (
      <>
        <div className={classes.graphSuggetions}>
          {alertState && suggestionStatus.UnderPerformance && (
            <Alert
              sx={{ mb: 3, fontSize: 12, textAlign: "left" }}
              severity="info"
            >
              <b>
                <span
                  class="legend-color"
                  style={{ background: "#FF5733", width: 13, height: 13 }}
                ></span>
                Recommendation for Under performance:{" "}
              </b>
              <br />
              1.Talk to the {teamId ? "engineer" : "team"} to figure out the
              reason for consistent low performance <br />
              2.Assign a mentor to help them understand the feature or domain.{" "}
              <br />
              3.Work with the {teamId ? "engineer" : "team"} to setup a
              performance improvement plan. <br />
            </Alert>
          )}
        </div>
        {
          // !showTrend &&
          <div class="legend">
            <div class="legend-item">
              <div class="legend-color" style={{ background: "green" }}></div>
              <div>
                {`Good Performance (greater than ${Math.abs(
                  performanceMedian + 20
                )} ${config.type === "Task" ? "%" : config.type})`}
              </div>
            </div>
            <div class="legend-item">
              <div class="legend-color" style={{ background: "#FF5733" }}></div>
              <div>
                {`Under Performance (less than ${Math.abs(
                  performanceMedian - 20
                )} ${config.type === "Task" ? "%" : config.type})`}
              </div>
            </div>
            <div class="legend-item">
              <div class="legend-color" style={{ background: "#26a6bb" }}></div>
              <div>
                Normal
                {`(${Math.abs(performanceMedian - 19)} to ${Math.abs(
                  performanceMedian + 19
                )} ${config.type === "Task" ? "%" : config.type})`}
              </div>
            </div>
          </div>
        }
      </>
    );
  } else {
    return (
      <div class="legend">
        {performanceArray.map((e, index) => (
          <div class="legend-item" key={index}>
            <div class="legend-color" style={{ background: e.color }}></div>
            <div>{e.name}</div>
          </div>
        ))}
      </div>
    );
  }
};
