import { format } from "date-fns";
import RiskWrapper, {
  RiskColorWrapper,
} from "../../../components/overview/riskWrapper";
import { config } from "../../../Config/permission";

const getColumnName = (
  teamList,
  status,
  teamStatus,
  res,
  classes,
  setIsTrendOpen,
  setSelectedResourceDetail,
  isHalfSprintCompleted,
  sprintBarGraphData,
  history
) => {
  const data = { ...teamList };

  data.columnsList = [
    {
      field: "Name",
      title: "Name",
      width: "150px",
      render: (rowData) =>
        status == "closed" ? (
          <div
            style={{ cursor: "pointer", color: "#5EBDCC" }}
            onClick={() => {
              history.push({
                pathname: `/project/${rowData?.ProjectId}/single-resource/${rowData?.results[0]?.Id}`,
                state: {
                  projectName: `Sprint`,
                  resourceName: rowData?.Name,
                  teamName: rowData?.results[0]?.TeamName,
                  startDate: format(
                    new Date(sprintBarGraphData?.startDate),
                    "yyyy-MM-d"
                  ),
                  endDate: format(
                    new Date(sprintBarGraphData?.endDate),
                    "yyyy-MM-d"
                  ),
                  date: {
                    startDate: new Date(sprintBarGraphData?.startDate),
                    endDate: new Date(sprintBarGraphData?.endDate),
                    key: "selection",
                    isCustom: true,
                    range: 14,
                  },
                },
              });
            }}
          >
            {rowData.Name}
          </div>
        ) : (
          rowData.Name
        ),
    },
    {
      field: "TeamName",
      title: "Team Name",
      width: "100px",
      render: (rowData) => (
        <div>{rowData.TeamName ? rowData.TeamName : "-"}</div>
      ),
    },
    {
      field: "AvgTaskCompleted",
      title: "Average capacity per Sprint (Story Point)",
      width: "100px",
      render: (rowData) => (
        <div>{rowData.AvgTaskCompleted ? rowData.AvgTaskCompleted : "-"}</div>
      ),
    },
    {
      field: "taskAssigned",
      title: `${config.type} Assigned`,
      width: "100px",
      render: (rowData) => (
        <div>{rowData.taskAssigned ? rowData.taskAssigned.toFixed() : "-"}</div>
      ),
    },
    {
      title: `${config.type} Completed (Overall)`,
      field: "taskCompleted",
      width: "100px",
      render: (rowData) => (
        <div>
          {rowData.taskCompleted ? rowData.taskCompleted.toFixed() : "-"}
        </div>
      ),
    },
  ];
  if (status !== "closed") {
    data.columnsList.push({
      title: `${config.type}  completed Within Time`,
      field: "taskCompletedInTime",
      width: "100px",
      render: (rowData) => (
        <div>
          {rowData.taskCompletedInTime
            ? rowData.taskCompletedInTime.toFixed()
            : "-"}
        </div>
      ),
    });
  }
  if (status == "closed") {
    data.columnsList.push({
      title: `${config.type} Spillover`,
      field: "spSpillover",
      width: "100px",
    });
  }
  if (status !== "closed") {
    data.columnsList.push({
      title: `${config.type} Expected`,
      field: "workForcasted",
      width: "100px",
    });
  }

  data.columnsList.push(
    {
      title: "Utilization(%),based on five sprint avg.",
      field: "utilized",
      width: "100px",
      render: (row) => (row.utilized ? row.utilized : "-"),
    },
    {
      title: "Utilization Status",
      field: "Risk",
      width: "100px",
      render: (rowData) => (
        <RiskColorWrapper riskLevel={rowData?.utilizationStatus}>
          {rowData?.utilizationStatus === "Same"
            ? "unchanged"
            : rowData?.utilizationStatus}
        </RiskColorWrapper>
      ),
    },
    {
      title: "Utilization Trends,based on five sprint avg.",
      field: "utilizationStrength",
      width: "100px",
      render: (row) => (
        <button
          onClick={() => {
            setIsTrendOpen(true);
            setSelectedResourceDetail({ ...row, type: "utilization" });
          }}
          className={classes.ViewMore}
        >
          View More
        </button>
      ),
    },
    {
      title: `${config.type} Completed  (Trends)`,
      field: "storyPoint",
      width: "100px",
      render: (row) => (
        <button
          onClick={() => {
            setIsTrendOpen(true);
            setSelectedResourceDetail({ ...row, type: "story" });
          }}
          className={classes.ViewMore}
        >
          View More
        </button>
      ),
    },
    {
      title: `${config.type} Spillover  (Trends)`,
      field: "spSpillover",
      width: "100px",
      render: (row) => (
        <button
          onClick={() => {
            setIsTrendOpen(true);
            setSelectedResourceDetail({ ...row, type: "Spillover" });
          }}
          className={classes.ViewMore}
        >
          View More
        </button>
      ),
    }
  );
  if (status !== "closed") {
    if (isHalfSprintCompleted) {
      data.columnsList.push({
        title: "Performance Risk",
        field: "Risk",
        width: "100px",
        render: (rowData) => (
          <RiskWrapper>
            {teamStatus((rowData.taskCompleted / rowData.workForcasted) * 100)}
          </RiskWrapper>
        ),
      });
    } else {
      data.columnsList.push({
        title: "Performance Risk",
        field: "Risk",
        width: "100px",
        render: (rowData) => <RiskWrapper>Low</RiskWrapper>,
      });
    }
  }
  if (status === "closed") {
    data.columnsList.push({
      title: "Performance",
      field: "Risk",
      width: "100px",
      render: (rowData) => (
        <RiskColorWrapper riskLevel={rowData.performanceStatus}>
          {rowData.performanceStatus == "Same"
            ? "Unchanged"
            : rowData.performanceStatus}
        </RiskColorWrapper>
      ),
    });
  }
  data.data = res.data.filter((e) => e.Name);
  return data;
};
export { getColumnName };
