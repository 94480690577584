import { useContext, useMemo, useState } from "react";
import Template from "../../Project/pageLayout";
import { GlobalData } from "../../../context/globalData";
import {
  MODULE_API_URL_GEN,
  addLeadingZeroToDateRange,
  datePayload,
} from "../../../utils/moduleTabApi";
import { useEffect } from "react";
import axios from "../../../utils/axiosHelper";
import { Metric, iterationMap } from "../../../utils/textMap";
import PointerWrapper from "../../../components/overview/pointerWrapper";
import { riskMap, riskMap2 } from "../../../utils/levelMap";
import SingleResource from "../SingleResource";
import RiskWrapper from "../../../components/overview/riskWrapper";
import { useHistory } from "react-router-dom";
import queryString from "query-string";
import { useLocation } from "react-router-dom";
import { Box, Card } from "@material-ui/core";
import BreadCrumb from "../../../components/breadCrumb/BreadCrumb";
import { useParams } from "react-router-dom";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { makeStyles } from "@material-ui/core/styles";
import InfoIcon from "@material-ui/icons/Info";
import Header from "../Header";
import { fix } from "../../../utils/changeDecimal";
import GraphCard from "../../Project/projectDashboard/graphCard";
import WorkAlocationChart from "../../Project/projectDashboard/graphs/workAlocationChart";
import { Grid, Paper, Tab, Tabs, Tooltip } from "@mui/material";
import { getMetricRange } from "../../../utils/getMetricRange";
import { config } from "../../../Config/permission";
import { addSpace } from "../../../utils/spaceSeprator";
import PlanningAccuracy from "../../Project/projectDashboard/graphs/PlanningAccuracy";
import CycleTimeBreakDown from "../../Project/projectDashboard/graphs/cycleTimeBreakDown";
import EpicDropDown from "../../../components/epicDropDown";
import NormalSelect from "../../../components/select/normalSelect";
import RangeDatePicker from "../../../components/RangeDatePicker";
import TeamInvestmentProfile from "./teamInvestment";
import EffortAllocation from "./TeamEffort";
import Trend from "../../Project/projectDashboard/trend";
import IssueLevel from "../../Project/projectDashboard/IssueLevel";
import { DateFieldContext } from "react-aria-components";
import TeamDoraMetrics from "../TeamDoraMetrics";
import WIPTrend from "../../Project/projectDashboard/trend/WIPTrend";
import IssueLevelSpeeding from "../../Project/projectDashboard/IssueLevel/IssueLevelSpeeding";

const useStyles = makeStyles((theme) => ({
  anchor: {
    cursor: "pointer",
    color: "#5EBDCC",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  viewMore: {
    color: "#5EBDCC",
    textDecoration: "underline",
    cursor: "pointer",
  },
  // achorLine:{
  //   borderBottom:"2px solid red"
  // }
}));

export default function Resources(props) {
  const { projectId } = useParams();
  const classes = useStyles();
  const [headerData, setHeaderData] = useState({});
  const [resourceData, setResourcesData] = useState();
  const [materialTableColums, setMaterialTableColums] = useState([]);
  const [assigneeList, setAssigneeList] = useState([]);
  const [planningAccuracyData, setPlanningAccuracyData] = useState([]);
  const [cycleData, setCycleData] = useState([]);
  const [workAllocationData, setWorkAllocationDat] = useState([]);
  const [kpiName, setKpiName] = useState();
  const [barData, setBarData] = useState([]);
  const [childViewAll, setChildViewAll] = useState();
  const {
    iterationValueGlobal,
    projectRangePickerValue: rangePickerValue,
    setProjectRangePickerValue: setRangePickerValue,
    isDefinaionPopup,
    setIsDefinaionPopup,
    setProjectTab,
    setDefinationKPI,
  } = useContext(GlobalData);
  const [value, setValue] = useState(0);
  const [issueType, setIssueType] = useState();
  const [statusList, setStatusList] = useState([]);
  const [selectedSubStatus, setSelectedSubStatus] = useState([]);
  const [date, setDate] = useState(rangePickerValue);
  const [iteration, setIteration] = useState(30);
  const [teamProductivity, setTeamProductivity] = useState();
  const [totalOfUnreviewedPr, setTotalOfUnreviewedPr] = useState();
  const [teamProcess, setTeamProcess] = useState();
  const [teamCommunication, setTeamCommunication] = useState();
  const [TeamQuality, setTeamQuality] = useState();
  const [UnreviewedPR, setUnreviewedPR] = useState();
  const [workAllocationStatus, setWorkAllocationStatus] = useState([]);
  const [metricValue, setMetricValue] = useState("Metric Details");
  const [epicData, setEpicData] = useState({ url: "", selectedEpic: [] });
  const [teamAvgAging, setTeamAvgAging] = useState();
  const history = useHistory();
  const { search } = useLocation();
  const { state } = useLocation();
  const values = queryString.parse(search);
  const [singleTeamFlowEfficiency, setSingleTeamFlowEfficiency] = useState();
  const [cycleDataPullRequest, setCycleDataPullRequest] = useState([]);
  const [SpeedingTransitionsRateMetrics, setSpeedingTransitionsRateMetrics] =
    useState();

  console.log(issueType, "issueType");

  const tableOption = {
    sorting: true,
    search: true,
    toolbar: true,
    fixedColumns: {
      left: 2,
      right: 0,
    },
    headerStyle: {
      backgroundColor: "#efefef",
    },
    pageSize: 5,
    paginationType: "stepped",
    searchFieldStyle: {
      padding: "5px 10px 5px 15px",
      borderRadius: "30px",
      disableUnderline: true,
      backgroundColor: "#efefef",
    },
  };
  const teamAndResourceTrend = [
    "Team Commit with pull request",
    "Team Commit Without Ticket Reference",
    // "Team WIP Average Age",
    "Team Unreviewed PR",
  ];
  const [range, setRange] = useState([]);

  const epicDropDownHandler = (url, list) => {
    setEpicData({ url: url.trim(), selectedEpic: list });
  };

  const resourceApiHandler = (url) => {
    axios
      .get(url)
      .then((result) => {
        createData(result?.data);
      })
      .catch((err) => {
        console.log("error", err);
      });
  };

  //create  row data
  const createData = (result) => {
    const data = [];
    if (metricValue === "Metric Details") {
      result.forEach((e) => {
        const columnData = {
          ResourceName: e?.ResourceName,
          TeamName: e?.TeamName,
          Id: e?.Id,
          RiskLevel: e?.RiskLevel,
        };
        // Add metric names to the columnData object
        e.metrics.forEach((item) => {
          const targetFrom = item?.risks?.find((t) => t?.RiskLevel == 1);
          columnData[item.name] = {
            name: item?.Value,
            risk: item?.RiskLevel,
            id: item?.Id,
            from: targetFrom?.From,
            to: targetFrom?.To,
          };
        });
        data.push(columnData);
      });
    } else {
      result.forEach((e) => {
        const columnData = {
          ResourceName: e.ProjectName,
          TeamName: e.TeamName,
          Id: e.pid,
          RiskLevel: e.riskLevel,
        };
        // Add metric names to the columnData object
        e.metrics.forEach((item) => {
          const targetFrom = item?.risks?.find((t) => t?.RiskLevel == 1);
          columnData[item.name] = {
            risk: item?.RiskLevel,
            id: item?.MetricGroupId,
            from: targetFrom?.From,
            to: targetFrom?.To,
          };
        });
        data.push(columnData);
      });
    }
    setResourcesData(data);
  };

  const onColClick = (event, item) => {
    history.push({
      pathname: `/project/${projectId}/resource/${event?.Id}`,
      state: {
        resourceName: event?.ResourceName,
        matricName: item,
        matricId: `${event[item]?.id}`,
        projectName: `${values?.projectName}`,
        teamName: state?.data,
      },
    });
  };

  const resourceGraphPageRedirectHandler = (rowData) => {
    // New Flow -=================
    history.push({
      pathname: `/project/${projectId}/single-resource/${rowData?.Id}`,
      state: {
        projectName: `${values?.projectName}`,
        resourceName: rowData?.ResourceName,
        teamName: state?.data,
      },
    });
  };

  const definationPopupHandler = (value) => {
    setKpiName(value);
    setIsDefinaionPopup(true);
  };

  // call summary and detail api
  useEffect(() => {
    if (!projectId) return;
    let Module_URL = MODULE_API_URL_GEN(
      rangePickerValue,
      projectId,
      iterationMap[iterationValueGlobal]
    );
    let url;
    if (metricValue === "Metric Details") {
      url = `/api/v2/resource/list/kpis?${Module_URL}&teamId=${state?.teamId}`;
    } else {
      url = `/api/project/summary-detail?group_id=3&${Module_URL}`;
    }
    if (epicData?.url) {
      url += `&${epicData?.url.substring(1)}`;
    }

    resourceApiHandler(url);
  }, [
    rangePickerValue,
    projectId,
    iterationValueGlobal,
    metricValue,
    epicData,
  ]);

  // const fetch header Detail
  const fetchHeaderDetail = () => {
    // to fetch planning accuracy
    const dateUrl = datePayload(date);
    let url = `/api/v2/teams/averageTeamMemberData/${projectId}?${dateUrl}&teamId=${state?.teamId}`;
    if (epicData?.url) {
      url += `&${epicData?.url.substring(1)}`;
    }
    axios
      .get(url)
      .then((res) => {
        const result = res.data;
        setHeaderData({
          storyCompleted: result?.AvgCompleted,
          capacityAccuracy: result?.percentageCompleted,
          churn: result?.codechurn,
          commitwoticket: result?.commitwoticket,
          Risk: result?.Risk,
          CommitWithPR: result?.CommitWithPR,
          PercentCommitPR: result?.PercentCommitPR,
          teamUnreviewedPr:
            `${result?.PRWithoutReview}/${result?.commitPr} (${result?.PRPercent})` ||
            0,
          escapedDefectsQA: result?.EscapedDefects || 0,
          testEfficiencyQA: result?.TestEfficiency?.toFixed() || 0,
          meanTimeToRepairQA: result?.MeanTimeToRepair?.toFixed() || 0,
          testExecutionRateQA: result?.TestExecutionRate?.toFixed() || 0,
          totalTestCasesQA: result?.TotalTestCases || 0,
        });
      })
      .catch((err) => {
        console.log("error", err);
      });
  };

  const viewMoreHandler = (name, list, subStatus = []) => {
    setIssueType(name);
    setStatusList(list);
  };

  // useEffect(() => {
  //   fetchHeaderDetail();
  // }, []);
  // create column list

  const getUnits = (name) => {
    switch (name) {
      case "Total Work Delivered (Story, Task, Bug) (Count)":
      case "Stories Completed (Count)":
      case "PR Merge Frequency (Count)":
      case "Commits (Count)":
      case "Defect in Backlog (Count)":
      case "Defect in Progress (Count)":
        return "(Count)";
      case "Avg PR Resolution Time (Days) ":
      case "Defect Lead Time (Days) ":
        return "(Days)";
      case "PR Size (Code changes)":
        return "(Code Changes)";
      case "Commit Size (Line of Code)":
        return "(Line Of Code)";
      default:
        return "(%)";
    }
  };

  useEffect(() => {
    if (!resourceData || resourceData.length === 0) return;
    const columns = [
      {
        field: "ResourceName",
        title: "Contributor Name",
        width: "200px",
        render: (rowData) => (
          <div
            className={classes.anchor}
            onClick={() => resourceGraphPageRedirectHandler(rowData)}
          >
            <span className={classes.achorLine}>{rowData.ResourceName}</span>
          </div>
        ),
      },
      {
        field: "TeamName",
        title: "Team Name",
        width: "200px",
      },
    ];

    console.log("Test", Object.keys(resourceData[0]));
    Object.keys(resourceData[0])?.forEach((item) => {
      if (
        item !== "ResourceName" &&
        item !== "TeamName" &&
        item !== "tableData" &&
        item !== "Id" &&
        item !== "RiskLevel"
      ) {
        if (metricValue === "Metric Details") {
          columns.push({
            title: (
              <HeaderTitle
                lablel={item}
                definationPopupHandler={definationPopupHandler}
              />
            ),
            field: item,
            width: "200px",
            render: (rowData) => (
              <Tooltip
                title={
                  <h6>
                    Target:{" "}
                    {rowData[item]?.from !== null &&
                    rowData[item]?.from !== undefined
                      ? `${rowData[item]?.from}${getUnits(item)}`
                      : " - "}
                    {" to "}
                    {rowData[item]?.to !== null &&
                    rowData[item]?.to !== undefined
                      ? `${rowData[item]?.to}${getUnits(item)}`
                      : " - "}
                  </h6>
                }
              >
                <div
                  onClick={() => onColClick(rowData, item)}
                  className={classes.anchor}
                >
                  {/* <PointerWrapper val={riskMap[rowData[item]?.risk]}> */}
                  {fix(rowData[item]?.name)}&nbsp;
                  <ChevronRightIcon />
                  {/* </PointerWrapper> */}
                </div>
              </Tooltip>
            ),
          });
        } else {
          columns.push({
            title: item,
            field: item,
            width: "200px",

            render: (rowData) => (
              <div
              // onClick={() => onColClick(rowData, item)}
              // style={{ cursor: "pointer" }}
              >
                <RiskWrapper val={riskMap[rowData[item]?.risk]}>
                  {riskMap[rowData[item]?.risk]}
                </RiskWrapper>
              </div>
            ),
          });
        }
      }
    });
    // columns.push({
    //   field: "riskLevel",
    //   title: "Health",
    //   width: "200px",
    //   render: (rowData) => (
    //     <RiskWrapper val={riskMap2[rowData?.RiskLevel]}>
    //       {riskMap2[rowData?.RiskLevel]}
    //     </RiskWrapper>
    //   ),
    // });
    setMaterialTableColums(columns);
  }, [resourceData]);

  // This is graph which print temp use dummy api
  const getWorkAllocation = () => {
    const dateUrl = datePayload(date);
    let url = `/api/v2/projects/resourceAllocation/${projectId}?iteration=${iteration}&${dateUrl}&teamId=${state?.teamId}`;
    if (epicData?.url) {
      url += `&${epicData?.url.substring(1)}`;
    }
    axios
      .get(url)
      .then((res) => {
        if (res.data) {
          const data = Object.keys(res.data).map((e) => {
            return { title: e, value: res.data[e] };
          });
          setWorkAllocationDat(data);
          setAssigneeList(data.map((e) => e.title).filter((e) => e !== "null"));
        }
      })
      .catch((err) => {});
  };
  useEffect(() => {
    let url = `/api/v2/statusNames/${projectId}`;
    if (epicData?.url) {
      url += `&${epicData?.url.substring(1)}`;
    }
    axios
      .get(url)
      .then((response) => {
        setWorkAllocationStatus(response?.data?.StatusNames[0]?.Names);
      })
      .catch((error) => {
        console.log(error, "error");
      });
  }, [projectId, epicData]);

  useEffect(() => {
    if (projectId) {
      getWorkAllocation();
    }
  }, [projectId, date, iteration, epicData]);

  useEffect(() => {
    let url = `/api/risks?ProjectId=${projectId}`;
    if (epicData?.url) {
      url += `&${epicData?.url.substring(1)}`;
    }
    axios
      .get(url)
      .then((result) => {
        setRange(result?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [projectId, epicData]);

  const handleTrend = (data) => {
    setIssueType(data?.data);
    setStatusList(data?.list);
    setSelectedSubStatus(data?.status);
  };
  const capacityNumber = parseFloat(headerData?.capacityAccuracy);
  const codechurnNumber = parseFloat(headerData?.churn);
  const PrReviewTimeNumber = parseFloat(teamCommunication?.prReviewTime);
  const UnreviewedPRNumber = parseFloat(totalOfUnreviewedPr);
  const CommitwithpullrequestNumber = parseFloat(headerData?.PercentCommitPR);

  const capcityAcc = useMemo(
    () => getMetricRange(range, capacityNumber, "Capacity Accuracy"),
    [capacityNumber, range]
  );
  const CodeChurn = useMemo(
    () => getMetricRange(range, codechurnNumber, "Code Churn"),
    [codechurnNumber, range]
  );
  const PrReviewTimeRisk = useMemo(
    () => getMetricRange(range, PrReviewTimeNumber, "PR Review Time (Days)"),
    [PrReviewTimeNumber, range]
  );
  const UnreviwedPRRisk = useMemo(
    () => getMetricRange(range, UnreviewedPRNumber, "Unreviwed PR"),
    [UnreviewedPRNumber, range]
  );
  const CommitWithPRRisk = useMemo(
    () => getMetricRange(range, CommitwithpullrequestNumber, "Commit With PR"),
    [CommitwithpullrequestNumber, range]
  );
  const WorkInProgessAging = useMemo(
    () =>
      getMetricRange(range, teamAvgAging?.Time, "Work In Progess Aging (Days)"),
    [teamAvgAging?.Time, range]
  );
  const FlowEfficiencyRisk = useMemo(
    () =>
      getMetricRange(
        range,
        singleTeamFlowEfficiency,
        "Average Flow Efficiency"
      ),
    [singleTeamFlowEfficiency, range]
  );

  const commitWithoutTicketRef = useMemo(() => {
    if (!headerData?.commitwoticket) return;
    const val = headerData?.commitwoticket.match(/\d+\.\d+/);
    const number = val?.length > 0 ? +val[0] : 0;
    return getMetricRange(
      range,
      number?.toFixed(2),
      "Commit Without Ticket Ref"
    );
  }, [headerData?.commitwoticket, range]);

  const speedyRateRisk = useMemo(
    () =>
      getMetricRange(
        range,
        SpeedingTransitionsRateMetrics,
        "Speeding Transitions Rate"
      ),
    [SpeedingTransitionsRateMetrics, range]
  );

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const fetchTeamProductivity = () => {
    const dateUrl = datePayload(date);
    let url = `/api/v2/teams/teamHealth?projectId=${projectId}&${dateUrl}&teamId=${state?.teamId}`;
    if (epicData?.url) {
      url += `&${epicData?.url.substring(1)}`;
    }
    axios
      .get(url)
      .then((res) => {
        const result = res.data;
        setTeamProductivity(result);
      })
      .catch((err) => {
        console.log("error", err);
      });
  };

  const fetchTeamQuality = () => {
    const dateUrl = datePayload(date);
    let url = `/api/v2/quality/get/resource/overall/${projectId}?teamId=${state?.teamId}&${dateUrl}`;
    if (epicData?.url) {
      url += `&${epicData?.url.substring(1)}`;
    }
    axios
      .get(url)
      .then((res) => {
        const result = res.data[0];
        const total = (result?.PRCount - result?.MergedPR) / result?.PRCount;
        setTotalOfUnreviewedPr(total);
        // setUnreviewedPR(
        //   `${result?.PRCount - result?.MergedPR}/${result?.PRCount} (${
        //     total ? total.toFixed(2) : 0
        //   } %)`
        // );
        setTeamQuality(result);
      })
      .catch((err) => {
        console.log("error", err);
      });
  };
  const fetchTeamProcess = () => {
    const dateUrl = datePayload(date);
    let url = `/api/v2/ph/processhealth/resource/${projectId}?teamId=${state?.teamId}&${dateUrl}`;
    if (epicData?.url) {
      url += `&${epicData?.url.substring(1)}`;
    }
    axios
      .get(url)
      .then((res) => {
        const result = res.data[0];
        setTeamProcess(result);
      })
      .catch((err) => {
        console.log("error", err);
      });
  };
  const fetchTeamCommunication = () => {
    const dateUrl = datePayload(date);
    let url = `/api/v2/cm/communications/teams/${projectId}?${dateUrl}&teamId=${state?.teamId}`;
    if (epicData?.url) {
      url += `&${epicData?.url.substring(1)}`;
    }
    axios
      .get(url)
      .then((res) => {
        const result = res.data[0];

        setTeamCommunication(result);
      })
      .catch((err) => {
        console.log("error", err);
      });
  };

  const avgAging = () => {
    const dateRange = datePayload(date);
    const dateUrl = addLeadingZeroToDateRange(dateRange);
    let url = `/api/v2/teams/avgAging/${projectId}?${dateUrl}&teamId=${state?.teamId}`;
    if (epicData?.url) {
      url += `&${epicData?.url.substring(1)}`;
    }
    axios
      .get(url)
      .then((res) => {
        setTeamAvgAging(res?.data);
      })
      .catch((err) => {
        console.log("error", err);
      });
  };

  useEffect(() => {
    const dateRange = datePayload(date);
    const dateUrl = addLeadingZeroToDateRange(dateRange);
    let url = `/api/v2/teams/flowEfficiency/${projectId}?${dateUrl}&teamId=${state?.teamId}`;
    if (epicData?.url) {
      url += `&${epicData?.url.substring(1)}`;
    }
    axios
      .get(url)
      .then((res) => {
        setSingleTeamFlowEfficiency(res?.data?.Efficiency);
      })
      .catch((err) => {
        console.log("error", err);
      });
  }, [date, epicData]);

  const getCycleTimePullRequest = () => {
    const dateUrl = datePayload(date);
    // let url;
    //  if (epicUrl) {
    //    url = `/api/v2/teams/cycleTimePR/${projectId}?${epicUrl}${dateUrl}`;
    //  } else {
    let url = `/api/v2/teams/cycleTimePR/${projectId}?${dateUrl}&teamId=${state?.teamId}`;
    //  }
    if (epicData?.url) {
      url += `&${epicData?.url.substring(1)}`;
    }
    axios
      .get(url)
      .then(
        (res) => {
          // if (res.data) {
          const response = res?.data;
          // const total = Object.values(response).reduce(
          //   (acc, value) => acc + value,
          //   0
          // );
          // setTotalCycleData({
          //   total: total.toFixed(),
          //   percentValue: res?.data?.percentUnderAvgDuration.toFixed(),
          // });
          // Calculate percentages and format the data
          const formattedData = Object.keys(response)
            .map((key) => ({
              title: key,
              value: response[key], // Format value to two decimal places
              // percentage: ((response[key] / total) * 100).toFixed(), // Calculate and format percentage
            }))
            .filter(
              (e) =>
                e?.title !== "Pickup" &&
                e?.title !== "Coding" &&
                e?.title !== "Review" &&
                e?.title !== "Deploy" &&
                e?.title !== "Idle"
            );

          setCycleDataPullRequest(formattedData);
        }
        // }
      )
      .catch((error) => {
        console.log(error, "error");
      });
  };

  // Speeding Transitions Rate Metrics
  const getSpeedyTime = () => {
    const dateUrl = datePayload(date);
    let url = `/api/v2/projects/speedyTransition?projectId=${projectId}&${dateUrl}&teamName=${state?.data}`;
    if (epicData?.url) {
      url += `&${epicData?.url.substring(1)}`;
    }
    axios
      .get(url)
      .then((response) => {
        setSpeedingTransitionsRateMetrics(response?.data?.percent?.toFixed());
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  useEffect(() => {
    fetchTeamProductivity();
    fetchTeamQuality();
    fetchTeamProcess();
    avgAging();
    fetchHeaderDetail();
  }, [date, iteration, state, epicData]);

  useEffect(() => {
    fetchTeamCommunication();
    getSpeedyTime();
  }, [date, iteration, state, epicData]);

  useEffect(() => {
    getCycleTimePullRequest();
  }, [date, iteration, state, epicData]);

  useEffect(() => {
    if (config.type === "Task") {
      setChildViewAll(true);
    } else {
      setChildViewAll(false);
    }
  }, []);

  const getComponent = (key) => {
    switch (key) {
      case 0:
        return (
          <>
            <Header
              projectId={projectId}
              teamId={state?.teamId}
              setChildViewAll={setChildViewAll}
              capacityAccuracy={headerData?.capacityAccuracy}
              codeChurn={headerData?.churn}
              storyPoint={headerData?.storyCompleted}
              commitReference={headerData?.commitwoticket}
              // commitReferenceRisk={headerData?.Risk}
              riskAndRange={{
                capacityAccuracyData: {
                  risk: capcityAcc?.RiskCategory,
                  from: capcityAcc?.From?.$numberDecimal,
                  to: capcityAcc?.To?.$numberDecimal,
                },
                codeChurnData: {
                  risk: CodeChurn?.RiskCategory,
                  from: CodeChurn?.From?.$numberDecimal,
                  to: CodeChurn?.To?.$numberDecimal,
                },
                commitWithoutTicketRef: {
                  risk: commitWithoutTicketRef?.RiskCategory,
                  from: commitWithoutTicketRef?.From?.$numberDecimal,
                  to: commitWithoutTicketRef?.To?.$numberDecimal,
                },
                PrReviewTimestatus: {
                  risk: PrReviewTimeRisk?.RiskCategory,
                  from: PrReviewTimeRisk?.From?.$numberDecimal,
                  to: PrReviewTimeRisk?.To?.$numberDecimal,
                },
                UnreviwedPRRisk: {
                  risk: UnreviwedPRRisk?.RiskCategory,
                  from: UnreviwedPRRisk?.From?.$numberDecimal,
                  to: UnreviwedPRRisk?.To?.$numberDecimal,
                },
                CommitWithPRRisk: {
                  risk: CommitWithPRRisk?.RiskCategory,
                  from: CommitWithPRRisk?.From?.$numberDecimal,
                  to: CommitWithPRRisk?.To?.$numberDecimal,
                },
                WorkInProgessAging: {
                  risk: WorkInProgessAging?.RiskCategory,
                  from: WorkInProgessAging?.From?.$numberDecimal,
                  to: WorkInProgessAging?.To?.$numberDecimal,
                },
                FlowEfficiencyRisk: {
                  risk: FlowEfficiencyRisk?.RiskCategory,
                  from: FlowEfficiencyRisk?.From?.$numberDecimal,
                  to: FlowEfficiencyRisk?.To?.$numberDecimal,
                },
                speedyRateRisk: {
                  risk: speedyRateRisk?.RiskCategory,
                  from: speedyRateRisk?.From?.$numberDecimal,
                  to: speedyRateRisk?.To?.$numberDecimal,
                },
              }}
              date={date}
              teamQualityHealth={TeamQuality}
              teamProcessHealth={teamProcess}
              teamCommunication={teamCommunication}
              teamProductivity={teamProductivity}
              teamPrReviewTime={teamCommunication?.prReviewTime?.toFixed(2)}
              teamData={true}
              teamAvgAging={teamAvgAging}
              flowEfficiency={singleTeamFlowEfficiency}
              commitWithPr={headerData.CommitWithPR}
              trendItem={(name) => {
                handleTrend(name);
              }}
              CommitWithoutPullRequestCount={headerData?.teamUnreviewedPr}
              isAih={config?.name === "aih" ? false : true}
              TotalTestCasesQAValue={headerData?.totalTestCasesQA}
              escapedDefectsQAValues={headerData?.escapedDefectsQA}
              testEfficiencyQAValues={headerData?.testEfficiencyQA}
              meanTimeToRepairQAValues={headerData?.meanTimeToRepairQA}
              testExecutionRateQAValues={headerData?.testExecutionRateQA}
              SpeedingTransitionsRateMetrics={SpeedingTransitionsRateMetrics}
            />
            {childViewAll && (
              <Grid container spacing={2} my={4} className={classes.graphCard}>
                <Grid item xs={6}>
                  <GraphCard
                    title={`Team Work Allocation`}
                    subTitle={
                      <div
                        onClick={() =>
                          viewMoreHandler(
                            `${state?.data} Team Work Allocation`,
                            workAllocationStatus
                          )
                        }
                        className={classes.viewMore}
                      >
                        View More
                      </div>
                    }
                  >
                    <WorkAlocationChart
                      data={workAllocationData}
                      name="ResourceAllocation"
                    />
                  </GraphCard>
                </Grid>
                <Grid item xs={6}>
                  <GraphCard
                    title={"Cycle Time (Pull Request)"}
                    subTitle={
                      <div className={classes.subTitle}>
                        <div
                          onClick={() =>
                            viewMoreHandler("Team Cycle Time (Pull Request)")
                          }
                          className={classes.viewMore}
                        >
                          View More
                        </div>
                      </div>
                    }
                    // cardItem={totalCycleData}
                  >
                    <div style={{ paddingTop: "60px" }}>
                      <CycleTimeBreakDown data={cycleDataPullRequest} />
                    </div>
                  </GraphCard>
                </Grid>
              </Grid>
            )}
            <Card className="resourceStyling paginationStyling customCard">
              <Template
                rangePickerValue={rangePickerValue}
                setRangePickerValue={setRangePickerValue}
                Metric={Metric}
                metricValue={metricValue}
                setMetricValue={setMetricValue}
                dropdownProjectList={[]}
                setTitleID={""}
                projectId={""}
                moduleDetailData={resourceData}
                materialTableColums={materialTableColums}
                isProject={false}
                tableOption={tableOption}
                kpiName={kpiName}
                title={"Resources"}
                isCompare={true}
                isUtilization={false}
                isMetricValue={false}
              />
            </Card>
          </>
        );
      case 1:
        return (
          <TeamInvestmentProfile
            // epic={epicData?.url.replace(/^\?/, "&")}
            project={projectId}
            teamId={state?.teamId}
            teamName={state?.data}
          />
        );
      case 2:
        return <EffortAllocation />;
      // case 3:
      //   return (
      //     <TeamDoraMetrics
      //       date={date}
      //       projectId={projectId}
      //       teamId={state?.teamId}
      //     />
      //   );
      default:
        break;
    }
  };

  return (
    <>
      <BreadCrumb
        backHandler={() => {
          history.push(
            `/project/${projectId}?projectName=${values.projectName}`,
            { data: 2 }
          );
        }}
        list={["Projects", values.projectName, state?.data]}
        active={"Contributor"}
      />
      <Box mb={4}>
        <Paper>
          <Tabs
            value={value}
            indicatorColor="primary"
            textColor="primary"
            onChange={handleChange}
            aria-label="disabled tabs example"
          >
            <Tab label="Teams" />
            {state?.teamId !== 504 && (
              <Tab
                label={
                  <Box display={"flex"} alignItems={"center"}>
                    Investment Distribution&nbsp;
                    <span
                      onClick={() => {
                        setIsDefinaionPopup(true);
                        setDefinationKPI("Investment profiles");
                      }}
                    >
                      <InfoIcon style={{ color: "gray" }} />
                    </span>
                  </Box>
                }
              />
            )}
            {state?.teamId !== 504 && (
              <Tab
                label={
                  <Box display={"flex"} alignItems={"center"}>
                    Effort allocation&nbsp;
                    <span
                      onClick={() => {
                        setIsDefinaionPopup(true);
                        setDefinationKPI("Allocation");
                      }}
                    >
                      <InfoIcon style={{ color: "gray" }} />
                    </span>
                  </Box>
                }
              />
            )}
            {/* <Tab label="Teams Dora Metrics" /> */}
          </Tabs>
        </Paper>
      </Box>
      <Box display={"flex"} gap={2}>
        {value === 0 && (
          <EpicDropDown epicDropDownHandler={epicDropDownHandler} />
        )}
        {(value === 0 || value === 3) && (
          <>
            {/* month selector tem commented */}
            {/* <div>
              <NormalSelect
                option={[
                  { label: "Monthly", value: 30 },
                  { label: "Biweekly", value: 14 },
                  { label: "Weekly", value: 7 },
                  // { label: "Daily", value: 1 },
                ]}
                value={iteration}
                handleChange={(value) => setIteration(value)}
              />
            </div> */}
            <Box marginLeft={2}>
              <RangeDatePicker
                setDate={setDate}
                date={date}
                isNextDate={false}
              />
            </Box>
          </>
        )}
      </Box>
      <Box paddingX={3}>{getComponent(value)}</Box>
      {issueType && (
        <>
          {[
            ...teamAndResourceTrend,
            `${state?.data} Team Work Allocation`,
            "Team Average Flow Efficiency",
            "Team Pr Review Time",
            "Team Code Churn",
            "Team Cycle Time (Pull Request)",
            "Team Speeding Transitions Rate",
          ].includes(issueType) && (
            <Card style={{ marginTop: 20 }}>
              <Trend
                issueType={issueType}
                projectId={projectId}
                teamId={state?.teamId}
                teamName={state?.data}
              />
            </Card>
          )}
          {["Team WIP Average Age"].includes(issueType) && (
            <Card style={{ marginTop: 40 }}>
              <WIPTrend
                issueType={issueType}
                projectId={projectId}
                teamId={state?.teamId}
              />
            </Card>
          )}
          {[
            ...teamAndResourceTrend,
            `${state?.data} Team Work Allocation`,
            "Team WIP Average Age",
            "Team Cycle Time (Pull Request)",
            "Team Cycle Time (Pull Request)",
            "Escaped Defects",
            "Test Efficiency",
            "Mean Time To Repair",
            "Test Execution Rate",
          ].includes(issueType) && (
            <Card style={{ marginTop: 20 }}>
              <IssueLevel
                issueType={issueType}
                assigneeList={
                  [`${state?.data} Team Work Allocation`].includes(issueType)
                    ? assigneeList
                    : []
                }
                statusList={
                  [
                    `${state?.data} Team Work Allocation`,
                    "Team Unreviewed PR",
                  ].includes(issueType)
                    ? statusList
                    : []
                }
                subStatus={
                  ["Team Unreviewed PR"].includes(issueType)
                    ? selectedSubStatus
                    : []
                }
                projectId={projectId}
                autoScroll={[`${state?.data} Team Work Allocation`].includes(
                  issueType
                )}
                isDate={
                  [
                    ...teamAndResourceTrend,
                    `${state?.data} Team Work Allocation`,
                  ].includes(issueType)
                    ? true
                    : false
                }
                teamId={state?.teamId}
                teamName={state?.data}
              />
            </Card>
          )}
          {["Team Speeding Transitions Rate"].includes(issueType) && (
            <Card style={{ marginTop: 40 }}>
              <IssueLevelSpeeding
                issueType={issueType}
                projectId={projectId}
                epic={epicData?.url}
                teamName={state?.data}
              />
            </Card>
          )}
        </>
      )}
    </>
  );
}

const HeaderTitle = ({ lablel, definationPopupHandler }) => {
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <div>{lablel} </div>
      <div
        style={{ cursor: "pointer" }}
        onClick={() => definationPopupHandler(lablel)}
      >
        <InfoIcon style={{ color: "rgb(203 196 196)" }} fontSize="small" />
      </div>
    </div>
  );
};
