import React from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  roundedTextField: {
    "& .MuiOutlinedInput-root": {
      borderRadius: "30px",
    },
    "& .MuiInputLabel-outlined": {
      marginTop: "0px",
    },
  },
}));

export default function AutoComp(props) {
  const { data, labelName, setValue, value, onChange, className } = props;
  const classes = useStyles();
  return (
    <Autocomplete
      id={"combo-box-demo"}
      disablePortal
      options={data}
      getOptionLabel={(option) => option.name || ""}
      style={{ width: 250 }}
      autoHighlight
      className={className?className:""}
      renderInput={(params) => (
        <TextField
          className={classes.roundedTextField}
          {...params}
          label={labelName}
          variant="outlined"
          id="outlined-basic"
        />
      )}
      onChange={(e, value) => {
        setValue(value);
        onChange && onChange(value);
      }}
      value={{ name: value?.name }}
    />
  );
}
