import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import { Button, Card, TextField } from "@material-ui/core";
import SelectFormFiled from "../../../../components/select/selectFormFiled";
import { useFormik } from "formik";
import * as yup from "yup";
import axios from "../../../../utils/axiosHelper";
import { useHistory, useLocation } from "react-router-dom";
import { Alert, AlertTitle } from "@material-ui/lab";
import BreadCrumb from "../../../../components/breadCrumb/BreadCrumb";

function GroupForm(props) {
  const validationSchema = yup.object({
    DisplaySeq: yup.number("please enter valid number").required("Required"),
    DisplayName: yup.string("").required("Required"),
  });

  const { state } = useLocation();
  const history = useHistory();
  const [error, setError] = useState("");

  const formik = useFormik({
    initialValues: {
      DisplaySeq: "",
      DisplayName: "",
      isActive: "active",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const payload = {
        ...values,
        DisplaySeq: +values.DisplaySeq,
        isActive: values.isActive === "active" ? true : false,
      };
      if (state) {
        updateHandler(payload);
      } else {
        submitHandler(payload);
      }
    },
  });
  const backHandler = () => {
    history.goBack();
  };

  const submitHandler = (payload) => {
    axios
      .post("/api/metricgroups/create", payload)
      .then((res) => {
        backHandler();
      })
      .catch((error) => {
        setError(error.response?.data?.error);
      });
  };
  const updateHandler = (payload) => {
    axios
      .post("/api/metricgroups/update", {
        ...payload,
        MetricGroupId: state.row.MetricGroupId,
      })
      .then((res) => {
        backHandler();
      })
      .catch((error) => {
        setError(error.response?.data?.error);
      });
  };

  useEffect(() => {
    if (state) {
      formik.setFieldValue("DisplaySeq", state.row.DisplaySeq);
      formik.setFieldValue("DisplayName", state.row.DisplayName);
      formik.setFieldValue(
        "isActive",
        state.row.isActive ? "active" : "inactive"
      );
    }
  }, [state]);

  return (
    <div>
      <BreadCrumb
        backHandler={() => {
          history.goBack();
        }}
        list={[]}
        active={"New metric group"}
      />
      <Card className="customCard">
        <form autoComplete="off" className="p-2" onSubmit={formik.handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <div>
                <TextField
                  name="DisplaySeq"
                  className="border-bottom w-100"
                  size="small"
                  type="number"
                  label="Display Sequence"
                  value={formik.values.DisplaySeq}
                  onChange={(e) => {
                    formik.setFieldValue("DisplaySeq", e.target.value);
                  }}
                />
                <div className="errorMsg">
                  {formik.errors.DisplaySeq &&
                    formik.touched.DisplaySeq &&
                    formik.errors.DisplaySeq}
                </div>
              </div>
            </Grid>
            <Grid item xs={4}>
              <div>
                <TextField
                  name="DisplayName"
                  size="small"
                  className="border-bottom w-100"
                  label="Metric Group*"
                  value={formik.values.DisplayName}
                  onChange={(e) => {
                    formik.setFieldValue("DisplayName", e.target.value);
                  }}
                />
                <div className="errorMsg">
                  {formik.errors.DisplayName &&
                    formik.touched.DisplayName &&
                    formik.errors.DisplayName}
                </div>
              </div>
            </Grid>
            <Grid item xs={4}>
              <SelectFormFiled
                render={[
                  { lable: "active", value: "active" },
                  { lable: "Inactive", value: "inactive" },
                ]}
                value={formik.values.isActive}
                setValue={(value) => {
                  formik.setFieldValue("isActive", value);
                }}
                name={"isActive"}
                label="Status"
                withLable={true}
              />
            </Grid>

            <Grid item xs={4}>
              <div
                className="position-relative from-grid"
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <button className={"cancelBtnStyle"} type="button" onClick={backHandler}>
                  <span>Cancel</span>
                </button>
                <button className={"applybtnStyle"} type="submit">
                  <span>Apply</span>
                </button>
              </div>
            </Grid>
          </Grid>

          {error && (
            <div>
              <Alert severity="error">
                <AlertTitle>Error</AlertTitle>
                {error}
              </Alert>
            </div>
          )}
        </form>
      </Card>
    </div>
  );
}

export default GroupForm;
