import { FormControl, InputLabel, Select, makeStyles } from "@material-ui/core";
import { MenuItem } from "@mui/material";
import React, { useContext } from "react";

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: "98%",
    marginTop: "-4px",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },

  dropDown: {
    minWidth: 200,
    "&:focus": {
      backgroundColor: "#fff",
    },
    borderBottom: "1px solid #dee2e6",
  },
}));

function SelectFormFiled(props) {
  const classes = useStyles();
  const { withLable = false, render, value, setValue, label, name = "", multiple = false } = props;

  const [age, setAge] = React.useState("");

  const handleChange = (event) => {
    setValue(event.target.value);
  };
  return (
    <div>
      {withLable ? (
        <FormControl className={classes.formControl}>
          <InputLabel id="demo-simple-select-helper-label">{label}</InputLabel>
          <Select
            labelId="demo-simple-select-helper-label"
            id="demo-simple-select-helper"
            value={value || []}
            onChange={handleChange && handleChange}
            classes={{ root: classes.dropDown }}
            name={name}
            multiple={multiple}
          >
            {render &&
              render.length !== 0 &&
              render.map((val, index) => (
                <MenuItem value={val.value} key={index}>
                  {val.lable}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      ) : (
        <FormControl className={classes.formControl}>
          <InputLabel id="demo-simple-select-helper-label">{label}</InputLabel>
          <Select
            labelId="demo-simple-select-helper-label"
            id="demo-simple-select-helper"
            value={value || []}
            onChange={handleChange && handleChange}
            classes={{ root: classes.dropDown }}
            name={name}
            multiple={multiple}

          >
            {render &&
              render.length !== 0 &&
              render.map((val, index) => (
                <MenuItem value={val} key={val + index}>
                  {val}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      )}
    </div>
  );
}

export default SelectFormFiled;
