import React, { useState } from "react";
import TrendChart from "../../../components/trendGraph";
import { MODULE_API_URL_GEN } from "../../../utils/moduleTabApi";
import { useEffect } from "react";
import axios from "../../../utils/axiosHelper";
import SelectField from "../../../components/select/selectField";
import { Box } from "@material-ui/core";

function Graph({
  state,
  resourceId,
  rangePickerValue,
  iterationValueGlobal,
  allMetrics,
}) {
  const [graphData, setGraphData] = useState([]);
  const [selectedMetric, setSelectedMetric] = useState({});

  const trandHandler = (trendUrl) => {
    let url = `/api/v2/resource/trends/${resourceId}/kpi/${selectedMetric}/get?${trendUrl}`;
    axios
      .get(url)
      .then((result) => {
        const data = result?.data?.trends?.map((item) => {
          return {
            Value1: item.Value,
            Time: item.Time,
            Name1: state?.resourceName,
            Value: result?.data?.iteration?.Target,
            // Name: "Target",
          };
        });
        setGraphData(data);
      })
      .catch((err) => {
        console.log("error", err);
      });
  };

  // call trend api
  useEffect(() => {
    if (!selectedMetric || !resourceId) return;
    let trendUrl = MODULE_API_URL_GEN(
      rangePickerValue,
      "",
      iterationValueGlobal
    );
    trandHandler(trendUrl);
  }, [iterationValueGlobal, rangePickerValue, selectedMetric, state]);

  useEffect(() => {
    if (allMetrics?.length > 0) {
      setSelectedMetric(allMetrics[0].value);
    }
  }, [allMetrics]);

  return (
    <>
      <div style={{ position: "relative" }}>
        <Box style={{ position: "absolute" }}>
          <SelectField
            render={allMetrics}
            withLable={true}
            isValueSelect={true}
            value={selectedMetric}
            setValue={setSelectedMetric}
          />
        </Box>
        {selectedMetric && (
          <TrendChart graphData={graphData} text={selectedMetric} />
        )}
      </div>
    </>
  );
}

export default Graph;
