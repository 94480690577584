import { CircularProgress } from "@material-ui/core";
import React, { useState, useContext } from "react";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Alert, AlertTitle } from "@material-ui/lab";
import axios from "../../../utils/axiosHelper";
import { useHistory } from "react-router-dom";
import { GlobalData } from "../../../context/globalData";
import UpgradePlanMessage from "../../../utils/UpgradePlanMessage";
import UpgradePlanRequest from "../../../utils/UpgradePlanRequest";

const useStyles = makeStyles((theme) => ({
  alert: {
    marginTop: "25px",
  },
}));

function GitHub() {
  const history = useHistory();
  const classes = useStyles();
  const location = useLocation();
  const callBackGitHub = queryString.parse(location.search).code;

  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [error, setError] = useState(false);

  const {
    isPlanUpgradeMessage,
    setIsPlanUpgradeMessage,
    isPlanUpgradeRequest,
    setToolsError,
    toolIntegrationUrl
  } = useContext(GlobalData);

  const githubVerificationHandler = () => {
    let payLoad = { type: "Github", githubCode: callBackGitHub };
    setLoading(true);
    axios
      .post("/api/tool", payLoad)
      .then((result) => {
        if (result.data.success) {
          setLoading(false);
          history.push({
            pathname: toolIntegrationUrl,
            hash: "Tools Integration",
            state: {
              details: "details",
              title: "Tools Integration",
              id: 6,
              isLoad: true,
            },
          });
        }
      })
      .catch((err) => {
        setErrorMessage(err?.response?.data?.error);
        setLoading(false);
        setError(true);
        setTimeout(function () {
          setError(false);
        }, 3000);
        setToolsError(err.response?.data?.error);
        if (err.response?.data?.error === "LIMIT_REACHED") {
          setIsPlanUpgradeMessage(!isPlanUpgradeMessage);
        }
      });
  };

  useEffect(() => {
    githubVerificationHandler();
  }, []);
  return (
    <>
      <h1>GitHub</h1>
      {loading && (
        <div style={{ textAlign: "center" }}>
          <CircularProgress />
        </div>
      )}

      {error && (
        <div className={classes.alert}>
          <Alert severity="error">
            <AlertTitle>Error</AlertTitle>
            {errorMessage}
          </Alert>
        </div>
      )}

      {isPlanUpgradeMessage && <UpgradePlanMessage />}
      {isPlanUpgradeRequest && (
        <UpgradePlanRequest stateCode={callBackGitHub} />
      )}
    </>
  );
}

export default GitHub;
