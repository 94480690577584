import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import { makeStyles } from "@material-ui/core/styles";
import { alpha } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Grid from '@mui/material/Grid';
import EditIcon from '@mui/icons-material/Edit';


const useStyles = makeStyles((theme) => ({
    btnStyle :{
        float:"right",
    background: "#5EBDCC",
    cursor: "pointer",
    font:"normal normal normal 16px/20px Lexend Deca",
    borderRadius:"22px",
    width:'180px',
    padding:"8px 8px",
    margin:"15px 5px"
    },
    btngroup:{
        marginTop:"10px",
    },
    searchnButton:{
        display: "flex"
    }
})
)
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.action.hover,
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    // backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

function createData(displaySequence,metricGroup) {
  return {displaySequence,metricGroup};
}

const rows = [
  createData('1', 'Quality', ),
  createData('2', 'Cost', ),
  createData('3', 'Timeliness', ),
  createData('4', 'Resources', ),
  createData('5', 'Management', ),
];

const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    border:'2px solid black',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '50%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  }));
  
  const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }));
  
  const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create('width'),
      width: '50%',
      [theme.breakpoints.up('md')]: {
        width: '20ch',
      },
    },
  }));


export default function Metrics(props) {
    const [age, setAge] = React.useState('');

    const handleChange = (event) => {
        console.log("event",event);
      setAge(event.target.value);
    };
const classes = useStyles()

  return (

   <>
       
        <div className={classes.btngroup}>
            
        <Toolbar className={classes.searchnButton}>
          <Search  >
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Search…"
              inputProps={{ 'aria-label': 'search' }}
            />
          </Search>
        </Toolbar>

        <FormControl sx={{ m: 1, minWidth: 120,borderRadius:'25px' }} size="small">
      <InputLabel id="demo-select-small-label">Active</InputLabel>
      <Select
        labelId="demo-select-small-label"
        id="demo-select-small"
        value={age}
        label="Age"
        onChange={handleChange}
      >
        <MenuItem value="">
          <em>None</em>
        </MenuItem>
        <MenuItem value={10}>Ten</MenuItem>
        <MenuItem value={20}>Twenty</MenuItem>
        <MenuItem value={30}>Thirty</MenuItem>
      </Select>
    </FormControl>
         <button className={classes.btnStyle}>New Metric Group</button>
         </div>
       
{/* <div className={classes.btngroup}> */}
           {/* <Stack direction="row"> */}
               {/* <Button variant="text">Text</Button> */}
               {/* <Button className={classes.btnStyle}>Contained</Button> */}
               {/* <Button  sx={{backgroundColor:"#5EBDCC 0% 0% no-repeat padding-box"}}>Outlined</Button> */}
           {/* </Stack> */}
       {/* {/* </div> */}
       <TableContainer component={Paper}> 
              <Table sx={{ minWidth: 700 }} aria-label="customized table">
                  <TableHead>
                      <TableRow>
                          <StyledTableCell>Display Sequence</StyledTableCell>
                          <StyledTableCell >Metric Group</StyledTableCell>
                          <StyledTableCell >Update</StyledTableCell>
                     
                      </TableRow>
                  </TableHead>
                  <TableBody>
                      {rows.map((row) => (
                          <StyledTableRow >
                              <StyledTableCell component="th" scope="row">
                                  {row.displaySequence}
                              </StyledTableCell>
                              <StyledTableCell>{row.metricGroup}</StyledTableCell>
                            <StyledTableCell><Grid item xs={4} sx={{ml:3}}>
        <EditIcon /></Grid></StyledTableCell>
                          </StyledTableRow>
                      ))}
                  </TableBody>
              </Table>
          </TableContainer></>
  );
}
