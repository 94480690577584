import * as React from "react";
import Dialog from "@mui/material/Dialog";
import { Autocomplete, Checkbox, TextField } from "@mui/material";
import { useState } from "react";
import { useEffect } from "react";
import { Box, Button, IconButton } from "@material-ui/core";
import CloseIcon from "@mui/icons-material/Close";
import CommonTable from "../../commonTable";

function CompareTable(props) {
  const { open, handleClose, title, list, column } = props;
  const [optionLabel, setOptionLabel] = useState("label");
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [compareList, setCompareList] = useState([]);
  const [error, setError] = useState("");
  useEffect(() => {
    if (!list) return;
    if (title === "Team") {
      setOptionLabel("TeamName");
    } else if (title === "Resources") {
      setOptionLabel("ResourceName");
    } else if (title === "Sprint") {
      setOptionLabel("SprintName");
    }
  }, [list]);

  // handle dropdown Select
  const handleSelect = (event, newValue) => {
    if (newValue.length <= 8) {
      setSelectedOptions(newValue);
    }
  };

  const compareHandler = () => {
    if (selectedOptions.length >= 2) {
      setError("");
      setCompareList(selectedOptions);
    } else {
      setError(`Please select minimum two  ${title}`);
    }
  };

  return (
    <Dialog onClose={handleClose} open={open} fullScreen={true}>
      <div style={{ padding: 30 }}>
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Box display={"flex"} alignItems={"center"}>
            <Autocomplete
              multiple
              options={list}
              disableCloseOnSelect
              limitTags={2}
              getOptionLabel={(option) => option[optionLabel]}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox style={{ marginRight: 8 }} checked={selected} />
                  {option[optionLabel]}
                </li>
              )}
              style={{ width: 500 }}
              value={selectedOptions}
              onChange={handleSelect}
              renderInput={(params) => (
                <TextField
                  error={error ? true : false}
                  helperText={error ? error : ""}
                  {...params}
                  label={`Select ${title}`}
                  placeholder={`Select ${title}`}
                />
              )}
            />
            <Button
              variant="contained"
              style={{
                background: "#5EBDCC",
                color: "#fff",
                marginLeft: "30px",
                height: "47px",
                width: 120,
              }}
              onClick={() => compareHandler()}
            >
              Compare
            </Button>
          </Box>
          <IconButton
            aria-label="delete"
            onClick={handleClose}
            size="small"
            style={{ float: "right", border: "1px solid gray" }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <CommonTable
          columns={column}
          data={compareList}
          options={{
            paging: false,
            search: false,
          }}
        />
      </div>
    </Dialog>
  );
}

export default CompareTable;
