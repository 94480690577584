import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  cardContaner: {
    display: "flex",
    alignItems: "center",
    gap: "14px",
  },
  card: {
    height: "100%",
    display: "flex",
    alignItems: "center",
  },
  icon: {
    backgroundColor: "#eff8fa",
    width: "36px",
    height: "36px",
    "border-radius": "50%",
    justifyContent: "center",
    display: "flex",
    alignItems: "center",
    "& img": {
      maxWidth: "25px",
      maxHeight: "25px",
    },
  },
  textContianer: {
    "& h6": {
      marginBottom: "0px",
    },
  },
  sideCard: {
    display: "flex",
    alignItems: "center",
    gap: "22px",
    cursor: "pointer",
    "& span": {
      font: "normal normal normal 16px/20px Lexend Deca",
      color: "#5EBDCC",
      opacity: 1,
    },
  },
  graphCard: {
    marginTop: "20px",
  },
  subTitle: {
    display: "flex",
    gap: 10,
    alignItems: "baseline",
  },

  subTitleAmt: {
    font: "normal normal normal 18px/23px Lexend Deca",
    letterSpacing: "0px",
    color: "#5EBDCC",
    opacity: 1,
  },
  subTitleText: {
    font: "normal normal normal 12px/15px Lexend Deca",
    letterSpacing: "0px",
    color: "#707070",
  },
  mainhead: {
    display: "flex",
    gap: "100px",
    padding: "18px 0px",
    "& label": {
      fontWeight: "700",
    },
  },
  ViewMore: {
    color: "#5EBDCC",
    textDecoration: "underline",
    padding: '0px',
    background: 'none',
    border: "none",
    cursor: "pointer",
  },
});
