import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles,
} from "@material-ui/core";
const useStyles = makeStyles(() => ({
  dialogText: {
    color: "#000000DE",
  },
}));
function ConfirmationDialog({ title, text, open, handleClose, deleteHandler }) {
  const classes = useStyles();
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="draggable-dialog-title"
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText className={classes.dialogText}>
          {text}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleClose}>
          No
        </Button>
        <Button onClick={deleteHandler}>Yes</Button>
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmationDialog;
