import React, { useEffect, useState } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { useParams } from "react-router-dom";
import moment from "moment";
import { iterationMap } from "../../../utils/textMap";
import GraphDetails from "../../../pages/riskAdvisor/GraphDetails";
export default function StackChart(props) {
  const {
    body,
    isSprint,
    isStories,
    isTask,
    graphData,
    height,
    level,
    precision,
    groupType,
    valueOfIteration,
    groupId,
    matrixName,
  } = props;
  const [barData, setBarData] = useState([
    {
      "Please Wait.....": "",
    },
  ]);
  const routeParams = useParams();
  const [barKeys, setBarKeys] = useState(["Target"]);
  const [barProductId, setBarProductId] = useState([]);
  const [openPop, setOpenPop] = useState(false);

  useEffect(() => {
    if (Array.isArray(graphData) == true) {
      if (level === "SPRINTS") return;
      const result = [
        {
          "Please Wait.....": "",
        },
      ];
      for (let i = 0; i < graphData.length; i++) {
        const obj = {};
        obj.Target = graphData[i].Value;

        obj.Time = moment(graphData[i].Time).format("YYYY-MM-DD");
        for (let j = 1; j <= 20; j++) {
          const name = graphData[i][`Name${j}`];
          const value = graphData[i][`Value${j}`];
          const projectId = graphData[i][`ProjectId${j}`];
          obj[name] = value;
        }
        result.push(obj);
      }

      setBarData(
        result.filter((e) => e !== "Time" && e !== "undefined").reverse()
      );
    }
    if (Array.isArray(graphData) == true) {
      if (level === "SPRINTS") return;
      const resultProduct = [
        {
          "Please Wait.....": "",
        },
      ];
      for (let i = 0; i < graphData.length; i++) {
        const obj = {};
        obj.Target = graphData[i].Value;

        obj.Time = moment(graphData[i].Time).format("YYYY-MM-DD");
        for (let j = 1; j <= 20; j++) {
          const name = graphData[i][`Name${j}`];
          const projectId = graphData[i][`ProjectId${j}`];
          obj[name] = projectId;
        }
        resultProduct.push(obj);
      }

      setBarProductId(
        resultProduct.filter((e) => e !== "Time" && e !== "undefined").reverse()
      );
    }
  }, [body, isSprint, isStories, isTask, graphData, level]);

  useEffect(() => {
    if (
      typeof barData[0] !== "undefined" ||
      barData[0] !== null ||
      barData !== []
    ) {
 
      const keys = Object.keys(barData[0])
        ?.filter((e) => e !== "Time" && e !== "undefined")
        .reverse();
      // Get all keys from the first object

      setBarKeys(keys);
    }
  }, [barData]);

  // const getRandomColor = () => {
  //   const letters = "0123456789ABCDEF";
  //   let color = "#";
  //   for (let i = 0; i < 6; i++) {
  //     color += letters[Math.floor(Math.random() * 16)];
  //   }
  //   return color;
  // };
  function getRandomColor() {
    // Generate a random hue (0-360)
    const hue = Math.floor(Math.random() * 361);

    // Set the saturation to a constant value (80%)
    const saturation = 100;

    // Generate a random lightness (50-90)
    const lightness = Math.floor(Math.random() * 41) + 30;

    // Convert HSL to RGB and return the color as a string
    const color = `hsl(${hue}, ${saturation}%, ${lightness}%)`;
    return color;
  }
  const colors = [
    "#67b7dc",
    "#a367dc",
    "#dc6788",
    "#dcd267",
    "#67dc75",
    "#80d2f5",
    "#FFD700",
    "#CD5C5C",
    "#20B2AA",
    "#800080",
    "#6B8E23",
    "#FF1493",
    "#9932CC",
    "#8FBC8F",
    "#00CED1",
    "#DC143C",
    "#000080",
    "#008B8B",
    "#FF7F50",
    "#00FA9A",
    "#FF69B4",
    "#4B0082",
    "#32CD32",
    "#00BFFF",
    "#FF4500",
    "#FF00FF",
    "#2E8B57",
    "#BA55D3",
    "#8B0000",
    "#9400D3",
    "#D2691E",
    "#FFDAB9",
    "#FFA07A",
    "#9370DB",
    "#6A5ACD",
    "#F08080",
    "#FF8C00",
    "#00FF00",
    "#6B8E23",
    "#8B008B",
    "#ADFF2F",
    "#800000",
    "#FF00FF",
    "#BDB76B",
    "#EEE8AA",
    "#FA8072",
    "#00FF7F",
    "#6B8E23",
    "#DA70D6",
    "#FFDEAD",
    "#8FBC8F",
    "#6495ED",
  ];
  const [clickedData, setClickedData] = useState();

  const handleClick = (data, ind) => {

    setClickedData(
      //{
      {
        matrixName: matrixName,
        value: data.tooltipPayload[0].value,
        name: data.tooltipPayload[0].name,
        // indexs: ind,
        projectId: barProductId[ind][data.tooltipPayload[0].name],
        date: data.Time,
        matrixId: groupType,
        Iteration: iterationMap[valueOfIteration],
        groupId: groupId,
        projectIdParams: routeParams?.projectId,
      }
      // matrixName: "Commits",
      // value: 19,
      // projectId: 1061715,
      // date: "2022-11-20",
      // matrixId: 1560,
      // Iteration: 7,
      // groupId: 1,
      //}
    );
    setOpenPop(true);
  };

  return (
    <>
      <ResponsiveContainer width="100%" height={500}>
        <BarChart width={1000} height={500} data={barData} margin={20}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="Time" />
          <YAxis />
          <Tooltip wrapperStyle={{ textShadow: "5px black" }} />
          <Legend />
          {/* {barKeys &&
          barKeys?.map((key) => (
            <Bar dataKey={key} stackId="a" fill={getRandomColor()} /> //stackId="a"
          ))} */}
          {barKeys?.map((key, index) => (
            <Bar
              key={index}
              dataKey={key}
              // stackId="a"
              fill={colors[index % colors.length]}
              onClick={(data, index) => handleClick(data, index)}
            />
          ))}

          {/* <Bar dataKey="eCommerce" stackId="a" fill="#a367dc" />
      <Bar dataKey="Internet Banking" stackId="a" fill="#dc6788" />
      <Bar dataKey="Portfolio Management" stackId="a" fill="#dcd267" />
      <Bar dataKey="Supply Chain Logistics" stackId="a" fill="#67dc75" />
      <Bar dataKey="Telecom Billing" stackId="a" fill="#80d2f5" /> */}
        </BarChart>
      </ResponsiveContainer>
      {openPop && (
        <GraphDetails
          openPop={openPop}
          setOpenPop={setOpenPop}
          graphData={clickedData}
        />
      )}
    </>
  );
}
