import React, { useState } from "react";
import MaterialTable from "material-table";
import { makeStyles } from "@material-ui/core/styles";
import { tableIcons } from "../../utils/tableIcon";

function CommonTable({ options = {}, columns, data, title = "" }) {
  const tableOption = {
    sorting: false,
    search: true,
    toolbar: true,
    headerStyle: {
      backgroundColor: "#efefef",
    },
    pageSize: 5,
    paginationType: "stepped",
    searchFieldStyle: {
      padding: "5px 10px 5px 15px",
      borderRadius: "30px",
      disableUnderline: true,
      backgroundColor: "#efefef",
    },
  };

  return (
    <>
      <MaterialTable
        style={{ boxShadow: "none" }}
        title={title}
        columns={columns}
        enableRowNumbers
        rowNumberMode="static"
        icons={tableIcons}
        data={data}
        options={{ ...tableOption, ...options }}
        // options={tableOption}
      />
    </>
  );
}

export default CommonTable;
