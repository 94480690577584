import React from "react";
import configImg from "../../../assets/configImg.png";

function JiraScreen({
  GrantAccess,
  res,
  classes,
  index,
  skipHandler,
  configTools,
}) {
  return (
    <>
      <div className={classes.middelContent}>
        <div className={classes.middelContentRight}>
          <div className={`${classes.middleMainTxt} mb-3`}>
            Itechgenic app is requesting access to your atlassian account .
          </div>

          <div className={`${classes.middleMTxtLMs}`}>
            This will allow the app to show you insights for multiple metrics
            such as Cycle time, lead time, work commitment, bugs and many more!
          </div>

          {!configTools[res] ? (
            <div className={classes.btns}>
              <button
                className={classes.grntBtn}
                onClick={() => GrantAccess(res)}
              >
                Grant Access
              </button>
              {/* {value} {mergeResult.length} */}
              <button
                className={`${classes.cnclBtn} ml-3 bg-light border-0`}
                onClick={() => {
                  skipHandler(index);
                }}
              >
                Skip
              </button>
              {/* <butt className={classes.cnclBtn}>Skip</butt> */}
            </div>
          ) : (
            <>
              <p className="mt-5">Your Account has been added Successfully</p>
              <button
                className={`${classes.cnclBtn} ml-3 bg-light border-0`}
                onClick={() => {
                  skipHandler(index);
                }}
              >
                Next
              </button>
            </>
          )}
        </div>
        <div className={classes.middelContentLeft}>
          <img src={configImg} className={classes.configImg} alt="" />
        </div>
      </div>
    </>
  );
}

export default JiraScreen;
