import { format } from "date-fns";
import { useState, useEffect, useContext } from "react";
import Tooltip from "@material-ui/core/Tooltip";
import RiskWrapper from "../components/overview/riskWrapper";
import { riskMap } from "../utils/levelMap";
export const useGraphDetailColumnhook = (tableColumn, matrixName) => {
  const [columnVal, setColumnVal] = useState([]);
  const [data, setData] = useState([]);
  const [column, setColumn] = useState([]);

  useEffect(() => {
    if (!tableColumn) return;
    const init = [];
    tableColumn?.map((coloumnName, index) => {
      init.push({
        field: coloumnName,
        title: coloumnName,
        render: (rowData) => (
          <div>
            {coloumnName === "Commit Id" ? (
              <a href={rowData["Commit Id"].url} target="_blank">
                {rowData["Commit Id"].displayId}
              </a>
            ) : coloumnName === "Comment" ? (
              <Tooltip title={rowData[coloumnName]}>
                <label>
                  {rowData[coloumnName].length > 100
                    ? rowData[coloumnName].substring(0, 100) + "..."
                    : rowData[coloumnName]}
                </label>
              </Tooltip>
            ) 
            :(
              <label>{rowData[coloumnName]}</label>
            )}
          </div>
        ),
        cellStyle: {
          fontSize: "16px",
        },
        tableData: {
          columnOrder: 0,
          groupSort: "asc",
          width: "calc((100% - (0px + 120px)) / 6)",
          id: 0,
        },
      });
    });

    setColumn(init);
  }, [tableColumn]);

  useEffect(() => {
    if (!columnVal) return;

    const matricsData = [];

    columnVal?.data?.map((val) => {
      if (matrixName === "Total Work Delivered (Story, Task, Bug)") {
        matricsData.push({
          Key: val.key,
          // Summary: val.fields?.description?.content[0]?.content[0]?.text,
          Summary: val.fields?.summary,
          "Issue Type": val.fields.issuetype.name,
          Status: val.fields.status.name,
          "Resolution Date": format(
            new Date(val.fields.resolutiondate),
            "dd-MMM-yyyy"
          ),
          Assignee: val.fields.assignee.displayName,
        });
      } else if (matrixName === "Schedule Varient") {
        matricsData.push({
          Key: val.key,
          Summary: val.fields?.description?.content[0]?.content[0]?.text,
          "Issue Type": val.fields.issuetype.name,
          Status: val.fields.status.name,
          "Due Date": format(new Date(val.fields.dueDate), "dd-MMM-yyyy"),
          "Resolution Date": format(
            new Date(val.fields.resolutiondate),
            "dd-MMM-yyyy"
          ),
          Difference: val.timeDiffDays,
        });
      } else if (matrixName === "Commits") {
        val?.commits?.length !== 0 &&
          val.commits.map((commit) =>
            matricsData.push({
              "Commit Id": { url: commit.url, displayId: commit.displayId },
              // "Commit Id": <a href={ commit.url}>{commit.url}</a>,
              Comment: commit.message,
              Author: commit.author.name,
              "Merge Commit": commit.isMerge ? "Yes" : "No",
              Date: format(new Date(commit.timestamp), "dd-MMM-yyyy"),
            })
          );
      } else if (matrixName === "Commits Size") {
        let initialValueAdd = 0;
        let initialValueDelete = 0;
        val?.commits?.length !== 0 &&
          val.commits.map((commit) => {
            commit.files.map((add) => {
              initialValueAdd = add?.linesAdded + initialValueAdd;
              initialValueDelete = add?.linesRemoved + initialValueDelete;
            });

            matricsData.push({
              "Commit Id": { url: commit.url, displayId: commit.displayId },
              Comment: commit.message,
              Author: commit.author.name,
              expandRow: commit.files,
              // "Lines Added": initialValueAdd,
              // "Lines Deleted":initialValueDelete,
              Date: format(new Date(commit.timestamp), "dd-MMM-yyyy"),
              // "Repo":,
            });
          });
      } else if (matrixName === "DashBoard") {
        let object = {};
        tableColumn.map((item) => {
          object[item] = val[item];
        });
        matricsData.push(object);
      }
    });

    setData(matricsData);
  }, [columnVal]);

  return {
    setColumnVal,
    column,
    data,
  };
};
