import React, { useLayoutEffect, useRef } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

am4core.useTheme(am4themes_animated);

const BarChart = ({ data }) => {
  const chartRef = useRef(null);

  useLayoutEffect(() => {
    let chart = am4core.create("chartdiv", am4charts.XYChart);

    // Data
    chart.data = data;

    // Add category axis
    let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "category";
    categoryAxis.title.text = "Metrics";

    // Add value axis
    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.title.text = "";

    // Create series
    const createSeries = (field, name, color) => {
      let series = chart.series.push(new am4charts.ColumnSeries());
      series.dataFields.valueY = field;
      series.dataFields.categoryX = "category";
      series.name = name;
      series.columns.template.tooltipText = "{name}: [bold]{valueY}[/]";
      // series.columns.template.fillOpacity = 0.8;

      let columnTemplate = series.columns.template;
      columnTemplate.strokeWidth = 2;
      // columnTemplate.strokeOpacity = 1;
      columnTemplate.fill = am4core.color(color); // Set the color of the bars
    };

    createSeries("before", "Before", "yellow"); // Change the color to your desired value
    createSeries("after", "After", "lightgreen"); // Change the color to your desired value

    chart.legend = new am4charts.Legend();
    chartRef.current = chart;

    return () => {
      chart.dispose();
    };
  }, [data]);

  return <div id="chartdiv" style={{ width: "100%", height: "500px" }} />;
};

export default BarChart;
