import React, { useState } from "react";
import CloseIcon from "@material-ui/icons/Close";
import MaterialGrid from "../../../components/overview";
import { useEffect } from "react";
import { useGraphDetailColumnhook } from "../../../utils/graphDetailColumnhook";
import { format } from "date-fns";
import ExpandMaterialGrid from "../../../components/overview/expandMaterialGrid";
function ScheduleVarient(props) {
  const {
    responseDataOfMatrix,
    matrixName,
    isLoading,
    openPop,
    setOpenPop,
    isExpant,
  } = props;

  let [header, setHeader] = useState([]);

  useEffect(() => {
    let tableColumn;
    switch (matrixName) {
      case "Total Work Delivered (Story, Task, Bug)":
        tableColumn = [
          "Key",
          "Summary",
          "Issue Type",
          "Status",
          "Resolution Date",
          "Assignee",
        ];
        break;
      case "Schedule Varient":
        tableColumn = [
          "Key",
          "Summary",
          "Issue Type",
          "Status",
          "Due Date",
          "Resolution Date",
          "Difference",
        ];
        break;
      case "Commits":
        tableColumn = [
          "Commit Id",
          "Comment",
          "Author",
          "Merge Commit",
          "Date",
        ];
        break;
      case "Commits Size":
        tableColumn = [
          "Commit Id",
          "Comment",
          "Author",
          // "Lines Added",
          // "Lines Deleted",
          "Date",
          // "Repo",
        ];
        break;
      default:
        <h1>working</h1>;
    }
    setHeader(tableColumn);
  }, [matrixName]);

  let completeTickets = {
    "Total Work Delivered (Story, Task, Bug)": "Tickets",
    Commits: "Commits",
    "Commits Size": "Commits",
    "Schedule Varient": "Tickets",
  };

  const { setColumnVal, column, data } = useGraphDetailColumnhook(
    header,
    matrixName
  );

  useEffect(() => {
    setColumnVal(responseDataOfMatrix);
  }, [responseDataOfMatrix]);
  const backHandler = () => {
    setOpenPop(false);
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          borderBottom: "1px solid  rgba(0, 0, 0, 0.87)",
          paddingBottom: "15px",
        }}
      >
        <label style={{ fontSize: "14px", fontWeight: "700" }}>
          {data?.length} Complete {completeTickets[matrixName]}
        </label>
        <div onClick={backHandler} style={{ cursor: "pointer" }}>
          <CloseIcon />
        </div>
      </div>
      <div
        style={{
          borderBottom: "1px solid #56aee4",
          marginTop: "50px",
          paddingBottom: "15px",
          width: "fit-content",
        }}
      >
        <label
          style={{
            fontSize: "15px",
            fontWeight: "700",
            textTransform: "uppercase",
          }}
        >
          {matrixName} :{" "}
          {responseDataOfMatrix?.date?.start &&
            format(
              new Date(responseDataOfMatrix.date.start),
              "dd-MMM-yyyy"
            )}{" "}
          -{" "}
          {responseDataOfMatrix?.date?.end &&
            format(new Date(responseDataOfMatrix.date.end), "dd-MMM-yyyy")}
        </label>
      </div>
      <div className="materialTable">
        {isExpant ? (
          <ExpandMaterialGrid
            columns={column}
            data={data}
            level={props.level}
          />
        ) : (
          <MaterialGrid
            columns={column}
            data={data}
            level={props.level}
            isLoading={isLoading}
          />
        )}
      </div>
    </>
  );
}

export default ScheduleVarient;
