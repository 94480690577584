import React, { useEffect } from "react";

import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import { getRandomColor } from "../../../../utils/randomColorGenerator";

function TrendPopup({
    data = [],
    yAxisLabel,
    color
}) {


    useEffect(() => {
        // Create chart instance
        const chart = am4core.create('trendChart', am4charts.XYChart);
        // Set data
        chart.data = data;

        // Create axes
        const categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = 'sprintName';
        categoryAxis.title.text = 'Sprint Name';

        const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis.title.text = yAxisLabel;
        valueAxis.min = 0;
        // Create series
        const series = chart.series.push(new am4charts.LineSeries());
        const lineColor = color || getRandomColor();
        series.stroke = am4core.color(lineColor);
        series.fillOpacity = 0.3; // Set the opacity of the fill
        series.fill = am4core.color(lineColor);
        const fillModifier = new am4core.LinearGradientModifier();
        fillModifier.opacities = [1, 0]; // Start with opacity 1 at the line and fade out to 0
        fillModifier.offsets = [0, 1]; // Gradient from the line to the bottom
        fillModifier.gradient.rotation = 90; // Gradient direction
        series.segments.template.fillModifier = fillModifier;


        series.dataFields.valueY = 'val';
        series.dataFields.categoryX = 'sprintName';
        series.name = yAxisLabel;
        series.tensionX = 0.8; // Set the tension for the X-axis (horizontal)
        series.tensionY = 0.8; // Set the tension for the Y-axis (vertical)
        series.tooltipText = '{name}: [bold]{valueY}[/]\nSprint Name: [bold]{categoryX}[/]';
        // Add cursor
        chart.cursor = new am4charts.XYCursor();

        // Add legend
        chart.legend = new am4charts.Legend();
    }, [data, yAxisLabel]);
    return (

        <div id="trendChart" style={{ width: '100%', height: '700px' }} />
    );
}

export default TrendPopup;
