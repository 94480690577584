import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import React, { memo } from "react";
import { useEffect, useState } from "react";
const ClusterColumnGraph = (props) => {
  const {
    data,
    jiraTableData,
    sprintTitleDate,
    value,
  } = props;

  const [dataItem, setDataItem] = useState({});
  useEffect(() => {
    let chart = null;
    chart = am4core.create("Metricdiv", am4charts.XYChart);
    chart.colors.step = 2;

    chart.legend = new am4charts.Legend();
    chart.legend.position = "bottom";
    chart.legend.paddingBottom = 20;
    chart.legend.labels.template.maxWidth = 95;
    chart.legend.labels.template.truncate = false;
    // chart.legend.itemContainers.template.tooltipText = "{SprintName}";
    chart.data = data;
    if (chart.data.length > 10) {
      // Add horizotal scrollbar with preview
      chart.scrollbarX = new am4core.Scrollbar();
      chart.scrollbarX.thumb.mixWidth = 50;
      chart.scrollbarX.thumb.minWidth = 200;
    }

    var xAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    xAxis.dataFields.category = "SprintName";
    xAxis.renderer.cellStartLocation = 0.1;
    xAxis.renderer.cellEndLocation = 0.9;
    xAxis.renderer.grid.template.location = 0;
    xAxis.renderer.line.strokeOpacity = 1;
    xAxis.renderer.line.strokeWidth = 1;
    // xAxis.title.text = "title";
    xAxis.renderer.line.stroke = am4core.color("#000000");
    xAxis.renderer.labels.template.cursorOverStyle =
      am4core.MouseCursorStyle.pointer;
    //for disbled grid of horizontal lines
    // xAxis.renderer.grid.template.disabled = true;
    // xAxis.renderer.baseGrid.disabled = true;
    // xAxis.renderer.grid.template.strokeWidth = 0;

    var yAxis = chart.yAxes.push(new am4charts.ValueAxis());
    yAxis.min = 0;
    yAxis.renderer.line.strokeOpacity = 1;
    yAxis.renderer.line.strokeWidth = 1;
    yAxis.renderer.line.stroke = am4core.color("#000000");
    yAxis.title.text = value?.yaxes;
    //for disbled grid of vertical lines
    // yAxis.renderer.grid.template.disabled = true;
    // yAxis.renderer.baseGrid.disabled = true;
    // yAxis.renderer.grid.template.strokeWidth = 0;

    function highlighColumn(ev, series) {
      let clickValue =
        ev.target.dataItem.categories.categoryX || ev.target.dataItem.category;
      chart.series.each(function (series) {
        series.columns.each(function (column) {
          // console.log(column.dataItem.categoryX, "s", ev.target.dataItem.categories.categoryX, "d");

          if (column.dataItem.categoryX === clickValue) {
            setDataItem(column?.dataItem?._dataContext);
          }
          ev.target.dataItem._isActive = true;
          if (column.dataItem.categoryX == clickValue) {
            column.isActive = true;
            column.strokeOpacity = 1;
            column.stroke = am4core.color("red");
            column.strokeWidth = 4;
          } else {
            column.isActive = false;
            column.strokeOpacity = 0;
          }
        });
      });
    }

    function createSeries(value, name) {
      var series = chart.series.push(new am4charts.ColumnSeries());
      series.dataFields.valueY = value;
      series.dataFields.categoryX = "SprintName";
      series.columns.template.tooltipText = `${value}\n{categoryX} : {valueY}`;
      series.name = name;
      // var activeState = series.columns.template.states.create("active");
      // activeState.properties.fill = am4core.color('red');
      xAxis.renderer.labels.template.events.on("hit", (e) => {
        highlighColumn(e, series);
      });
      series.columns.template.events.on("hit", (e) => {
        highlighColumn(e, series);
      });
      series.columns.template.cursorOverStyle =
        am4core.MouseCursorStyle.pointer;

      var bullet = series.bullets.push(new am4charts.LabelBullet());
      bullet.interactionsEnabled = false;
      bullet.dy = 30;
      bullet.label.text = "{valueY}";
      bullet.label.fill = am4core.color("#ffffff");

      return series;
    }

    if (data[0]) {
      let a = Object.keys(data[0]);
      a.map((e) => {
        if (
          e !== "SprintName" &&
          e !== "Id" &&
          e !== "SprintId" &&
          e !== "MetricId" &&
          e !== "ProjectId" &&
          e !== "BoardId"
        ) {
          createSeries(e, e);
        }
      });
    }
  }, [data]);

  useEffect(() => {
    jiraTableData(dataItem);
    sprintTitleDate(dataItem);
  }, [dataItem]);

  return (
    <>
      <div
        id={"Metricdiv"}
        style={{ width: "100%", height: "490px", margin: "auto" }}
      />
    </>
  );
};
export default memo(ClusterColumnGraph);
