import * as React from "react";
import Grid from "@mui/material/Grid";
import EditIcon from "@mui/icons-material/Edit";
import axios from "../../../utils/axiosHelper";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { useState, useEffect } from "react";
import ConfirmationDialog from "../../../components/confirmationPopup";
// import ConsolidatedRisk from "./consolidatedRisk";
import CommonSettingTable from "../../../components/table/commonSettingTable";
import { useParams, useHistory } from "react-router-dom";
// import AlertFormWork from "../../riskAdvisor/Details/Form/alertFormWork";

export default function AlertFromRisks({ classes }) {
  // const classes = useStyles();
  const [riskValue, setRiskValue] = useState();
  const [isForm, setIsForm] = useState(false);
  const [deleteRisk, setDeleteRisk] = useState();
  const [open, setOpen] = useState(false);
  const { settingTab } = useParams();
  const history = useHistory();

  const columns = [
    // {
    //   field: "Level",
    //   title: "Level",
    //   width: "150px",
    // },
    {
      field: "iteration",
      title: "Iteration",
      width: "150px",
    },
    {
      field: "alertType",
      title: "Alert Type",
      width: "150px",
    },
    {
      field: "days",
      title: "Days",
      width: "150px",
    },
    {
      field: "project",
      title: "Project Name",
      width: "150px",
      render: (row) => {
        return row?.project ? row.project.Name : ""
      }
    },
    {
      field: "notficationType",
      title: "Notfication Type",
      width: "150px",
    },
    {
      field: "email",
      title: "Email",
      width: "150px",
    },
    {
      field: "Actions",
      title: "Actions",
      width: "150px",
      render: (row) => (
        <Grid item xs={4} sx={{ ml: 3 }}>
          <EditIcon
            className={classes?.RiskIcons}
            onClick={() => {
              history.push({
                pathname: `/settings/${settingTab}/form/alertFramework`,
                state: { row },
              });
            }}
            style={{ cursor: "pointer", color: "#5EBDCC" }}
          />
          <DeleteOutlinedIcon
            className={classes?.RiskIcons}
            style={{ cursor: "pointer", color: "#5EBDCC" }}
            xs={6}
            sx={{ ml: 3 }}
            onClick={() => {
              setOpen(true);
              setDeleteRisk(row);
            }}
          />
        </Grid>
      ),
    },
  ];

  const handleClose = () => {
    setOpen(false);
  };

  // get List
  const getList = () => {
    let ClientID = localStorage.getItem("ClientId");
    axios
      .get(`/api/alerts/get?clientId=${ClientID}`)
      .then((result) => {
        setRiskValue(result.data.alerts);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // add page handler
  const AddEditRisks = () => {
    // setIsForm(true);
    // setEditRisk();
    history.push("/settings/${settingTab}/form/alertFramework");
  };
  // delete Handler
  const deleteconRisk = () => {
    axios
      .delete(`/api/alerts/delete?id=${deleteRisk._id}`)
      .then((result) => {
        setOpen(false);
        getList();
      });
  };

  useEffect(() => {
    getList();
  }, []);

  return (
    <>
      {isForm == false ? (
        <>
          <CommonSettingTable
            columns={columns}
            clickHandler={AddEditRisks}
            btnLabel={"New Alert Frame Work"}
            data={riskValue}
            tableTitle={"Alert Frame Work"}
          ></CommonSettingTable>
        </>
      ) : null}
      {isForm == true ? (
        <>
          {/* <AlertFormWork 
          data={editRisk}
          classes={classes}
          getList={getList}
          /> */}
          {/* // <ConsolidatedRisk
        //   editConsolidated={editRisk}
        //   setIsForm={setIsForm}
        // /> */}
        </>
      ) : null}

      <ConfirmationDialog
        open={open}
        handleClose={handleClose}
        title={"Delete"}
        text={"Are you sure you want to delete?"}
        deleteHandler={deleteconRisk}
      />
    </>
  );
}
