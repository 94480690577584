import React from "react";
import BreadCrumb from "../../../components/breadCrumb/BreadCrumb";
import { useHistory } from "react-router-dom";
import { Card, Grid, Typography } from "@material-ui/core";
import LineTrendChart from "../../../components/trendGraph/lineTrendChart";
import CommonTable from "../../../components/commonTable";
import LinearProgress from "@material-ui/core/LinearProgress";

function ResourceBurnOut() {
  const history = useHistory();
  const graphData = [
    {
      Value1: 5.577625347134466,
      Time: "2023-06-04T00:00:00.000Z",
      Name1: "vikas",
      Value: 10,
      Name: "Target",
    },
    {
      Value1: 5.180414571073754,
      Time: "2023-06-11T00:00:00.000Z",
      Name1: "vikas",
      Value: 10,
      Name: "Target",
    },
    {
      Value1: 5.064797698944997,
      Time: "2023-06-18T00:00:00.000Z",
      Name1: "vikas",
      Value: 10,
      Name: "Target",
    },
    {
      Value1: 6.251186345143484,
      Time: "2023-06-25T00:00:00.000Z",
      Name1: "vikas",
      Value: 10,
      Name: "Target",
    },
    {
      Value1: 5.213248002315868,
      Time: "2023-07-02T00:00:00.000Z",
      Name1: "vikas",
      Value: 10,
      Name: "Target",
    },
    {
      Value1: 4.936554703051427,
      Time: "2023-07-09T00:00:00.000Z",
      Name1: "vikas",
      Value: 10,
      Name: "Target",
    },
    {
      Value1: 5.524802740858398,
      Time: "2023-07-16T00:00:00.000Z",
      Name1: "vikas",
      Value: 10,
      Name: "Target",
    },
    {
      Value1: 5.596474511427014,
      Time: "2023-07-23T00:00:00.000Z",
      Name1: "vikas",
      Value: 10,
      Name: "Target",
    },
    {
      Value1: 5.182441493130974,
      Time: "2023-07-30T00:00:00.000Z",
      Name1: "vikas",
      Value: 10,
      Name: "Target",
    },
    {
      Value1: 5.250848732099689,
      Time: "2023-08-06T00:00:00.000Z",
      Name1: "vikas",
      Value: 10,
      Name: "Target",
    },
    {
      Value1: 5.409914681548915,
      Time: "2023-08-13T00:00:00.000Z",
      Name1: "vikas",
      Value: 10,
      Name: "Target",
    },
    {
      Value1: 4.79300125683839,
      Time: "2023-08-20T00:00:00.000Z",
      Name1: "vikas",
      Value: 10,
      Name: "Target",
    },
  ];
  const graphData2 = [
    {
      Value1: 6.577625347134466,
      Time: "2023-06-04T00:00:00.000Z",
      Name1: "vikas",
      Value: 10,
      Name: "Target",
    },
    {
      Value1: 6.180414571073754,
      Time: "2023-06-11T00:00:00.000Z",
      Name1: "vikas",
      Value: 10,
      Name: "Target",
    },
    {
      Value1: 4.064797698944997,
      Time: "2023-06-18T00:00:00.000Z",
      Name1: "vikas",
      Value: 10,
      Name: "Target",
    },
    {
      Value1: 4.251186345143484,
      Time: "2023-06-25T00:00:00.000Z",
      Name1: "vikas",
      Value: 10,
      Name: "Target",
    },
    {
      Value1: 6.213248002315868,
      Time: "2023-07-02T00:00:00.000Z",
      Name1: "vikas",
      Value: 10,
      Name: "Target",
    },
    {
      Value1: 4.936554703051427,
      Time: "2023-07-09T00:00:00.000Z",
      Name1: "vikas",
      Value: 10,
      Name: "Target",
    },
    {
      Value1: 6.524802740858398,
      Time: "2023-07-16T00:00:00.000Z",
      Name1: "vikas",
      Value: 10,
      Name: "Target",
    },
    {
      Value1: 5.596474511427014,
      Time: "2023-07-23T00:00:00.000Z",
      Name1: "vikas",
      Value: 10,
      Name: "Target",
    },
    {
      Value1: 5.182441493130974,
      Time: "2023-07-30T00:00:00.000Z",
      Name1: "vikas",
      Value: 10,
      Name: "Target",
    },
    {
      Value1: 5.250848732099689,
      Time: "2023-08-06T00:00:00.000Z",
      Name1: "vikas",
      Value: 10,
      Name: "Target",
    },
    {
      Value1: 5.409914681548915,
      Time: "2023-08-13T00:00:00.000Z",
      Name1: "vikas",
      Value: 10,
      Name: "Target",
    },
    {
      Value1: 4.79300125683839,
      Time: "2023-08-20T00:00:00.000Z",
      Name1: "vikas",
      Value: 10,
      Name: "Target",
    },
  ];
  const graphData3 = [
    {
      Value1: 6.577625347134466,
      Time: "2023-06-04T00:00:00.000Z",
      Name1: "vikas",
      Value: 10,
      Name: "Target",
    },
    {
      Value1: 6.180414571073754,
      Time: "2023-06-11T00:00:00.000Z",
      Name1: "vikas",
      Value: 10,
      Name: "Target",
    },
    {
      Value1: 4.064797698944997,
      Time: "2023-06-18T00:00:00.000Z",
      Name1: "vikas",
      Value: 10,
      Name: "Target",
    },
    {
      Value1: 4.251186345143484,
      Time: "2023-06-25T00:00:00.000Z",
      Name1: "vikas",
      Value: 10,
      Name: "Target",
    },
    {
      Value1: 6.213248002315868,
      Time: "2023-07-02T00:00:00.000Z",
      Name1: "vikas",
      Value: 10,
      Name: "Target",
    },
    {
      Value1: 4.936554703051427,
      Time: "2023-07-09T00:00:00.000Z",
      Name1: "vikas",
      Value: 10,
      Name: "Target",
    },
    {
      Value1: 6.524802740858398,
      Time: "2023-07-16T00:00:00.000Z",
      Name1: "vikas",
      Value: 10,
      Name: "Target",
    },
    {
      Value1: 5.596474511427014,
      Time: "2023-07-23T00:00:00.000Z",
      Name1: "vikas",
      Value: 10,
      Name: "Target",
    },
    {
      Value1: 3.182441493130974,
      Time: "2023-07-30T00:00:00.000Z",
      Name1: "vikas",
      Value: 10,
      Name: "Target",
    },
    {
      Value1: 3.250848732099689,
      Time: "2023-08-06T00:00:00.000Z",
      Name1: "vikas",
      Value: 10,
      Name: "Target",
    },
    {
      Value1: 3.409914681548915,
      Time: "2023-08-13T00:00:00.000Z",
      Name1: "vikas",
      Value: 10,
      Name: "Target",
    },
    {
      Value1: 4.79300125683839,
      Time: "2023-08-20T00:00:00.000Z",
      Name1: "vikas",
      Value: 10,
      Name: "Target",
    },
  ];
  const columns = [
    {
      field: "name",
      title: "Name",
    },
    {
      field: "workInProgress",
      title: "work In Progress",
    },
    {
      field: "LotsofChurn",
      title: "Churn",
    },
    {
      field: "Highspillover",
      title: "spillover",
    },
    {
      field: "Lowcapacityaccuracyscores",
      title: "capacity accuracy scores",
    },
    // {
    //   field: "level",
    //   title: "Level",
    //   render: (row) => (
    //     <LinearProgress variant="determinate" value={row.level} />
    //   ),
    // },
    // {
    //   field: "email",
    //   title: "Email",
    // },
  ];

  const row = [
    {
      name: "aninda",
      LotsofChurn: "High",
      Lowcapacityaccuracyscores: "High",
      Highspillover: "Low",
      workInProgress: "Low",
      commitId: 3342234,
      level: 30,
      email: "test@123.com",
    },
    {
      name: "ajay",
      LotsofChurn: "Low",
      Lowcapacityaccuracyscores: "High",
      Highspillover: "Low",
      workInProgress: "High",
      commitId: 12342314,
      level: 40,
      email: "test@123.com",
    },
    {
      name: "rushi",
      LotsofChurn: "Low",
      Lowcapacityaccuracyscores: "Low",
      Highspillover: "High",
      workInProgress: "High",
      commitId: 767674576,
      level: 50,
      email: "test@123.com",
    },
    {
      name: "shubham",
      LotsofChurn: "High",
      Lowcapacityaccuracyscores: "High",
      Highspillover: "Low",
      workInProgress: "Low",
      commitId: 3453343,
      level: 20,
      email: "test@123.com",
    },
    {
      name: "vikas",
      LotsofChurn: "High",
      Lowcapacityaccuracyscores: "Low",
      Highspillover: "High",
      workInProgress: "High",
      commitId: 8887788,
      level: 90,
      email: "test@123.com",
    },
  ];
  return (
    <>
      <BreadCrumb
        backHandler={() => {
          history.goBack();
        }}
        active={"Resource BurnOut"}
      />

      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Card className="customCard resourceStyling p-3">
            <Typography color="text.secondary" gutterBottom component={"h3"}>
              High work in progress
            </Typography>
            <LineTrendChart body="graph1" graphData={graphData} />
          </Card>
        </Grid>
        <Grid item xs={6}>
          <Card className="customCard resourceStyling p-3">
            <Typography color="text.secondary" gutterBottom component={"h3"}>
              Lots of Churn
            </Typography>
            <LineTrendChart body="graph2" graphData={graphData2} />
          </Card>
        </Grid>
        <Grid item xs={6}>
          <Card className="customCard resourceStyling p-3">
            <Typography color="text.secondary" gutterBottom component={"h3"}>
              High spillover
            </Typography>
            <LineTrendChart body="graph3" graphData={graphData3} />
          </Card>
        </Grid>
        <Grid item xs={6}>
          <Card className="customCard resourceStyling p-3">
            {/* <Typography color="text.secondary" gutterBottom component={"h3"}>
              Low capacity accuracy scores
            </Typography> */}
            <CommonTable columns={columns} data={row} />
          </Card>
        </Grid>
      </Grid>
    </>
  );
}

export default ResourceBurnOut;
