import React, { useState, useContext } from "react";
import { GlobalData } from "../../context/globalData";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import Button from "@material-ui/core/Button";
import { useFormik } from "formik";
import * as yup from "yup";
import CircularProgress from "@material-ui/core/CircularProgress";
import { green } from "@material-ui/core/colors";
import { Alert, AlertTitle } from "@material-ui/lab";
import axios from "../../utils/axiosHelper";
import Switch from "@material-ui/core/Switch";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { useHistory } from "react-router-dom";
import Logo from "../../assets/itechLogo.png";
import jirIcon from "../../assets/jirIcon.png";
import gitLab from "../../assets/gitlab.png";
import azueDevops from "../../assets/azueDevops.png";
import azureIcon from "../../assets/azureIcon.png";
import bitBucketIcon from "../../assets/bitBucketIcon.png";
import githubIcon from "../../assets/githubIcon.png";
import sonarCube from "../../assets/sonarcube.png";
import slackIcon from "../../assets/slackIcon.png";
import msTeams from "../../assets/msteams.png";
import matterMosticon from "../../assets/mattermosticon.png";
import conFluence from "../../assets/confluence.png";
import notionIcon from "../../assets/notionIcon.png";



const useStyles = makeStyles((theme) => ({
  root: {
    border: "4px solid #092c49",
    borderRadius: "20px",
    padding: "40px",
    margin: "0px",
    boxShadow:"none"
  },
  toggle: {
 width: 40,
  height: 22,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 3,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translate(18px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? 'green' : 'green',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 16,
    height: 16,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
},
  inputWraper: {
    display: "grid",
    gridTemplateColumns: "168px 136px",
    textAlign: "left",
    alignItems: "center",
  },
  label: {
    fontSize: "15px",
    fontWeight: 400,
    color: "#082C49",
    marginBottom: "15px",
    marginTop: "15px",
    marginRight: "30px",
  },
  btn: {
    backgroundColor: "#082c49",
    width: "200px",
    fontSize: "14px",
    color: "#fff",
    height: "40px",
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: "#072136",
    },
  },
  btnWraper: {
    width: "200px",
    marginTop: "20px",
    position: "relative",
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  alert: {
    marginTop: "25px",
  },
  togalebtn: {
    width: "100%",
    display: "flex",
    marginBottom: "30px",
    justifyContent: "space-between",
  },
  dropdownIcons: {
    border: "1px solid lightgray",
    padding: "2px 10px",
    borderRadius: "10px",
  },
  dateBlock: {
    display: "flex",
  },
  description: {
    width: "130%",
    wordWrap: "break-word",
    fontSize: "12px",
    gridColumn: "1 / span 2",
    gridRow: 3,
  },
  desc:{
    width:"468px",
    height:"23px",
    fontSize:"15px",
    fontWeight:"bold"

  },
  mainCtainer:{
    top: "0px",
    left: "0px",
    // width: "1366px",
    height: "768px",
    background: "#FCFCFC 0% 0% no-repeat padding-box",
  },

  headerLogo:{
    top: "30px",
    left: "36px",
    width: "151px",
    height: "30px",
    position: "relative",
  },

  headingUName:{
    top: "30px",
    left: "266px",
    position: "relative",
    textAlign: "left",
    font: "normal normal normal 24px/30px Lexend Deca",
    letterSpacing: "0px",
    color: "#1E1E1E",
    opacity: "1",
    padding:"36px",
  },

  innerBox:{
    top: "60px",
    // left: "36px",
    // width: "1294px",
    // height: "642px",
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    boxShadow: "0px 6px 10px #00000003",
    borderRadius: "14px",
    opacity: "1",
    position:"relative",
    marginRight:"36px",
    marginLeft:"36px",
    padding: "50px",
  },

  innerBoxHeading:{
    // top: "50px",
    // left: "50px",
    textAlign: "left",
    font: "normal normal 600 24px/30px Lexend Deca",
    letterSpacing: "0px",
    color: "#1E1E1E",
    opacity: "1",
    position:"relative",
  },

  innerBoxHtext:{
    top: "5px",
    // left: "50px",
    textAlign: "left",
    font: "normal normal normal 14px/18px Lexend Deca",
    letterSpacing: "0px",
    color: "#7E7E7E",
    opacity: "1",
    position:"relative",
  },

  boxDiv:{
    display:"flex",
    // top: "30px",
    position:"relative",
    marginTop:"30px",
    
  },

  trackBox:{
    // top: "20px",
    // left: "50px",
    // width: "280px",
    // height: "344px",
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    border: "1px solid #E5E5E5",
    borderRadius: "10px",
    opacity: "1",
    position:"relative",
    padding:"20px 18px 60px 20px;",
    marginRight: "15px",
  },

  innerBoxtxt:{
    // top: "20px",
    // left: "20px",
    position:"relative",
    textAlign: "left",
    font: "normal normal 600 20px/25px Lexend Deca",
    letterSpacing: "0px",
    color: "#1E1E1E",
  },

  headingCotainer:{

  },

  subHeading:{
    textAlign: "left",
    font: "normal normal normal 12px/15px Lexend Deca",
    letterSpacing: "0px",
    color: "#7E7E7E",
    marginTop:"2px",
  },

  checkSetting:{
    display:"flex",
    padding: "20px 0px 0px 0px",
  },

  pdlr:{
    padding:"0 20px",
  },

  radioBox:{
    // width: "12px",
    // height: "12px",
    // /* UI Properties */
    // background: "#5EBDCC 0% 0% no-repeat padding-box";
    // borderRadius: "10px",
    // opacity: "1";

    width: "20px",
    height: "20px",
    border: "1px solid #5EBDCC",
    borderRadius: "10px",
    opacity: "1",
  },

  trackImg:{
    width: "20px",
    height: "20px",
    // background: transparent url(img/Mask Group 1.png) 0% 0% no-repeat padding-box;
    opacity: "1",
  },

  ImgName:{
    textAlign: "left",
    font: "normal normal normal 16px/20px Lexend Deca",
    letterSpacing: "0px",
    color: "#1E1E1E",
    opacity: "1",
  },

  submtBtnDiv:{
    textAlign:"center",
    marginTop:"60px",
  },

  submtBtn:{
    // top: 618px;
    // left: 617px;
    width: "132px",
    height: "45px",
    background: "#5EBDCC 0% 0% no-repeat padding-box",
    borderRadius: "30px",
    border:"1px solid #5EBDCC",
    opacity: "1",
    color:"#ffffff",
  },


  /****third row */
  jiraTxt:{
    font: "normal normal 600 20px/25px Lexend Deca",
    letterSpacing: "0px",
    color: "#1E1E1E",
    marginLeft: "10px",
    verticalAlign: "middle",
  },

  innerMainHeading:{
    display:"flex",
  },

  JiraImage:{
    marginLeft:"20px",
  },

  hlines:{
    width: "173px",    
    height: "4px",
    background: "#5EBDCC 0% 0% no-repeat padding-box",
    position: "relative",
    right: "50px",
  },

}));

const SetupForm = () => {
  const classes = useStyles();
  const history = useHistory();
  const { setIsSignUp, setCurrentRender } = useContext(GlobalData);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [error, setError] = useState(false);
  const [week] = useState([
    { id: "1", name: "Monday" },
    { id: "2", name: "Tuesday" },
    { id: "3", name: "Wedensday" },
    { id: "4", name: "Thursday" },
    { id: "5", name: "Friday" },
    { id: "6", name: "Saturday" },
    { id: "7", name: "Sunday" },
  ]);
  const [biWeek] = useState([
    { id: "1", name: "Monday" },
    { id: "2", name: "Tuesday" },
    { id: "3", name: "Wedensday" },
    { id: "4", name: "Thursday" },
    { id: "5", name: "Friday" },
    { id: "6", name: "Saturday" },
    { id: "7", name: "Sunday" },
  ]);
  const [futureDate] = useState([
    { id: "30", name: "30" },
    { id: "60", name: "60" },
    { id: "90", name: "90" },
  ]);
  const [quarterly] = useState([
    { id: "1", name: "January" },
    { id: "2", name: "February" },
    { id: "3", name: "March" },
    { id: "4", name: "April" },
  ]);

  const validationSchema = yup.object({
    settings: yup.object({
      startCalculateFrom: yup
        .date()
        .typeError("Date is mandatory")
        .required("Date is mandatory"),
    }),
  });

  const formik = useFormik({
    initialValues: {
      isSync: false,
      isCalculate: false,
      enableAI: false,
      isDailyCalculate: false,
      isWeeklyCalculate: false,
      isBiWeeklyCalculate: false,
      isMonthlycalculate: false,
      isQuarterlyCalculate: false,
      futureDates: "30",
      settings: {
        startCalculateFrom: "",
        weekStart: "1",
        biweekStart: "1",
        quaterStart: "1",
      },
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setLoading(true);
      axios
        .post("/api/auth/setup/tool", values)
        .then((result) => {
          if (result.data.success) {
            localStorage.setItem("isSetupDone", true);
            setIsSignUp(true);
            setLoading(false);
            // setCurrentRender(0);
            history.push({
              pathname: `/riskadvisor/${5}`,
              hash: "Tools Integration",
              state: {
                details: "details",
                title: "Tools Integration",
                id: 6,
                isLoad: true,
              },
            });
            formik.resetForm();
          }
        })
        .catch((err) => {
          setErrorMessage(err.response.data.error);
          setLoading(false);
          setError(true);
          setTimeout(function () {
            setError(false);
          }, 3000);
        });
    },
  });

  return (
    <>
      <div className={classes.mainCtainer}>
        <div className={classes.headingCotainer}>
          <img src={Logo} className={classes.headerLogo}/>
          <span className={classes.headingUName}>Welcome, Saquib!</span>
        </div>

        <div className={classes.innerBox}>
          <div className={classes.innerBoxHeading}>Let’s start by selecting which tools you would like to connect.</div>
          <div className={classes.innerBoxHtext}>Remember to connect atleast one of the
issue tracking tools to get the insights. </div>
          <form>
          <div className={classes.boxDiv}>
            
            <div className={classes.trackBox}>
              <div className={classes.innerBoxtxt}>Issue tracking system</div>
              <div className={classes.subHeading}>One tool compulsory</div>
              <div className={classes.checkSetting}>
                <div>
                  <input type="radio" name="issueTrack" id="jira" className={classes.radioBox}></input>
                </div>
                <div className={classes.pdlr}>
                  <img src={jirIcon} className={classes.trackImg}/>
                </div>
                
                <div className={classes.ImgName}>
                  Jira
                </div>

              </div>


              <div className={classes.checkSetting}>
                <div>
                  <input type="radio" name="issueTrack" id="gitLab" className={classes.radioBox}></input>
                </div>
                <div className={classes.pdlr}>
                  <img src={gitLab} className={classes.trackImg}/>
                </div>
                
                <div className={classes.ImgName}>
                  Gitlab
                </div>

              </div>


              <div className={classes.checkSetting}>
                <div>
                  <input type="radio" name="issueTrack" id="azureDevops" className={classes.radioBox}></input>
                </div>
                <div className={classes.pdlr}>
                  <img src={azueDevops} className={classes.trackImg}/>
                </div>
                
                <div className={classes.ImgName}>
                  Azure DevOps
                </div>

              </div>


              <div className={classes.checkSetting}>
                <div>
                  <input type="radio" name="issueTrack" id="azure" className={classes.radioBox}></input>
                </div>
                <div className={classes.pdlr}>
                  <img src={azureIcon} className={classes.trackImg}/>
                </div>
                
                <div className={classes.ImgName}>
                  Azure
                </div>

              </div>

            </div>
            <div className={classes.trackBox}>
              <div className={classes.innerBoxtxt}>Source control system</div>
              <div className={classes.subHeading}>No compulsion, But Max one</div>

              <div className={classes.checkSetting}>
                <div>
                  <input type="radio" name="bitbucket" id="bitbucket" className={classes.radioBox}></input>
                </div>
                <div className={classes.pdlr}>
                  <img src={bitBucketIcon} className={classes.trackImg}/>
                </div>
                
                <div className={classes.ImgName}>
                  Bitbucket
                </div>

              </div>


              <div className={classes.checkSetting}>
                <div>
                  <input type="radio" name="github" id="github" className={classes.radioBox}></input>
                </div>
                <div className={classes.pdlr}>
                  <img src={githubIcon} className={classes.trackImg}/>
                </div>
                
                <div className={classes.ImgName}>
                  Github
                </div>

              </div>


              <div className={classes.checkSetting}>
                <div>
                  <input type="radio" name="sonarCube" id="sonarCube" className={classes.radioBox}></input>
                </div>
                <div className={classes.pdlr}>
                  <img src={sonarCube} className={classes.trackImg}/>
                </div>
                
                <div className={classes.ImgName}>
                  Sonarcube
                </div>

              </div>

              <div className={classes.checkSetting}>
                <div>
                  <input type="radio" name="jenkins" id="jenkins" className={classes.radioBox}></input>
                </div>
                <div className={classes.pdlr}>
                  <img src={sonarCube} className={classes.trackImg}/>
                </div>
                
                <div className={classes.ImgName}>
                  Jenkins
                </div>

              </div>

            </div>
            <div className={classes.trackBox}>
              <div className={classes.innerBoxtxt}>Communication & Chat</div>
              <div className={classes.subHeading}>No compulsion, But Max one</div>

              <div className={classes.checkSetting}>
                <div>
                  <input type="radio" name="slack" id="slack" className={classes.radioBox}></input>
                </div>
                <div className={classes.pdlr}>
                  <img src={slackIcon} className={classes.trackImg}/>
                </div>
                
                <div className={classes.ImgName}>
                  Slack
                </div>

              </div>


              <div className={classes.checkSetting}>
                <div>
                  <input type="radio" name="smteams" id="smteams" className={classes.radioBox}></input>
                </div>
                <div className={classes.pdlr}>
                  <img src={msTeams} className={classes.trackImg}/>
                </div>
                
                <div className={classes.ImgName}>
                  Teams
                </div>

              </div>


              <div className={classes.checkSetting}>
                <div>
                  <input type="radio" name="mattermosticon" id="mattermosticon" className={classes.radioBox}></input>
                </div>
                <div className={classes.pdlr}>
                  <img src={matterMosticon} className={classes.trackImg}/>
                </div>
                
                <div className={classes.ImgName}>
                  Matermost
                </div>

              </div>

            </div>
            <div className={classes.trackBox}>
              <div className={classes.innerBoxtxt}>Knowledge Hub/WiKi</div>
              <div className={classes.subHeading}>No compulsion, But Max one</div>

              <div className={classes.checkSetting}>
                <div>
                  <input type="radio" name="confluence" id="confluence" className={classes.radioBox}></input>
                </div>
                <div className={classes.pdlr}>
                  <img src={conFluence} className={classes.trackImg}/>
                </div>
                
                <div className={classes.ImgName}>
                  Confluence
                </div>

              </div>


              <div className={classes.checkSetting}>
                <div>
                  <input type="radio" name="notion" id="notion" className={classes.radioBox}></input>
                </div>
                <div className={classes.pdlr}>
                  <img src={notionIcon} className={classes.trackImg}/>
                </div>
                
                <div className={classes.ImgName}>
                  Notion
                </div>

              </div>

            </div>
          </div>

          <div className={classes.submtBtnDiv}>
            <input type="submit" value="Connect" className={classes.submtBtn}></input>
          </div>
          </form>
        </div>
      </div>


      <div className={classes.mainCtainer}>
        <div className={classes.headingCotainer}>
          <img src={Logo} className={classes.headerLogo}/>
          <span className={classes.headingUName}>Welcome, Saquib!</span>
        </div>

        <div className={classes.innerBox}>
          <div className={classes.innerMainHeading}>
              <div className={classes.JiraIma}>
                <img src={jirIcon} className={classes.trackImg}/>
                <span className={classes.jiraTxt}>Jira</span>
              </div>

              <div className={classes.JiraImage}>
                <img src={githubIcon} className={classes.trackImg}/>
                <span className={classes.jiraTxt}>Github</span>
              </div>
          </div>
          <div className={classes.hlines}></div>
          
        </div>
      </div>





      <center>
        <form
          noValidate
          autoComplete="off"
          className={classes.root}
          onSubmit={formik.handleSubmit}
        >
          <img src={Logo} style={{ width: "220px" }} alt="itechgenic" />
          <p className={classes.desc}>Welcome to your new virtual scrum master !</p>
          <div className={classes.togalebtn}>
            <div className={classes.inputWraper}>
              <InputLabel htmlFor="isSync" className={classes.label}>
                Sync
              </InputLabel>
              <Switch
                checked={formik.values.isSync}
                className={classes.toggle}
                id="isSync"
                name="isSync"
                // color="primary"
                onChange={(e) =>
                  formik.setFieldValue("isSync", e.target.checked)
                }
              />
              <div className={classes.description}>
                (Scheduler should be on or off.)
              </div>
            </div>

            <div className={classes.inputWraper}>
              <InputLabel htmlFor="isCalculate" className={classes.label}>
                Calculate
              </InputLabel>
              <Switch
                checked={formik.values.isCalculate}
                className={classes.toggle}
                id="isCalculate"
                name="isCalculate"
                // color="primary"
                onChange={(e) =>
                  formik.setFieldValue("isCalculate", e.target.checked)
                }
              />
              <div className={classes.description}>
                (Matric calculation should be on or off.)
              </div>
            </div>

            <div className={classes.inputWraper}>
              <InputLabel htmlFor="enableAI" className={classes.label}>
                enableAI
              </InputLabel>
              <Switch
                checked={formik.values.enableAI}
                className={classes.toggle}
                id="enableAI"
                name="enableAI"
                // color="primary"
                onChange={(e) =>
                  formik.setFieldValue("enableAI", e.target.checked)
                }
              />
              <div className={classes.description}>
                (AI prediction should be on or off.)
              </div>
            </div>

            <div className={classes.inputWraper}>
              <InputLabel htmlFor="isDailyCalculate" className={classes.label}>
                Daily calculate
              </InputLabel>
              <Switch
                checked={formik.values.isDailyCalculate}
                className={classes.toggle}
                id="isDailyCalculate"
                name="isDailyCalculate"
                // color="primary"
                onChange={(e) =>
                  formik.setFieldValue("isDailyCalculate", e.target.checked)
                }
              />
              <div className={classes.description}>
                (Daily iteration should be on or off.)
              </div>
            </div>
          </div>
          <div className={classes.togalebtn}>
            <div className={classes.inputWraper}>
              <InputLabel htmlFor="isWeeklyCalculate" className={classes.label}>
                Weekly calculate
              </InputLabel>
              <Switch
                checked={formik.values.isWeeklyCalculate}
                className={classes.toggle}
                id="isWeeklyCalculate"
                name="isWeeklyCalculate"
                // color="primary"
                onChange={(e) =>
                  formik.setFieldValue("isWeeklyCalculate", e.target.checked)
                }
              />
              <div className={classes.description}>
                (Weekly iteration should be on or off.)
              </div>
            </div>

            <div className={classes.inputWraper}>
              <InputLabel
                htmlFor="isBiWeeklyCalculate"
                className={classes.label}
              >
                BiWeekly calculate
              </InputLabel>
              <Switch
                checked={formik.values.isBiWeeklyCalculate}
                className={classes.toggle}
                id="isBiWeeklyCalculate"
                name="isBiWeeklyCalculate"
                // color="primary"
                onChange={(e) =>
                  formik.setFieldValue("isBiWeeklyCalculate", e.target.checked)
                }
              />
              <div className={classes.description}>
                (Biweekly iteration should be on or off.)
              </div>
            </div>

            <div className={classes.inputWraper}>
              <InputLabel
                htmlFor="isMonthlycalculate"
                className={classes.label}
              >
                Monthly calculate
              </InputLabel>
              <Switch
                checked={formik.values.isMonthlycalculate}
                className={classes.toggle}
                id="isMonthlycalculate"
                name="isMonthlycalculate"
                // color="primary"
                onChange={(e) =>
                  formik.setFieldValue("isMonthlycalculate", e.target.checked)
                }
              />
              <div className={classes.description}>
                (Monthly iteration should be on or off.)
              </div>
            </div>

            <div className={classes.inputWraper}>
              <InputLabel
                htmlFor="isQuarterlyCalculate"
                className={classes.label}
              >
                Quarterly calculate
              </InputLabel>
              <Switch
                checked={formik.values.isQuarterlyCalculate}
                className={classes.toggle}
                id="isQuarterlyCalculate"
                name="isQuarterlyCalculate"
                // color="primary"
                onChange={(e) =>
                  formik.setFieldValue("isQuarterlyCalculate", e.target.checked)
                }
              />
              <div className={classes.description}>
                (Quarterly iteration should be on or off.)
              </div>
            </div>
          </div>

          <div className={classes.togalebtn}>
            <div className={classes.inputWraper}>
              <InputLabel htmlFor="futureDates" className={classes.label}>
                Future Date
              </InputLabel>
              <Select
                name="futureDates"
                defaultValue={30}
                onChange={formik.handleChange}
                className={classes.dropdownIcons}
              >
                {futureDate?.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
              <div className={classes.description}>
                (Number of future days for AI prediction.)
              </div>
            </div>

            <div className={classes.inputWraper}>
              <InputLabel
                htmlFor="settings.weekStart"
                className={classes.label}
              >
                Week Start
              </InputLabel>
              <Select
                name="settings.weekStart"
                defaultValue={1}
                onChange={formik.handleChange}
                className={classes.dropdownIcons}
              >
                {week?.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
              <div className={classes.description}>
                (Starting date of week. e.g. Sunday, Monday, Tuesday)
              </div>
            </div>

            <div className={classes.inputWraper}>
              <InputLabel
                htmlFor="settings.biweekStart"
                className={classes.label}
              >
                BiWeek Start
              </InputLabel>
              <Select
                name="settings.biweekStart"
                defaultValue={1}
                onChange={formik.handleChange}
                className={classes.dropdownIcons}
              >
                {biWeek?.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
              <div className={classes.description}>
                (Starting date of biweek. e.g. Sunday, Monday, Tuesday)
              </div>
            </div>

            <div className={classes.inputWraper}>
              <InputLabel
                htmlFor="settings.quaterStart"
                className={classes.label}
              >
                Quarter Start
              </InputLabel>
              <Select
                name="settings.quaterStart"
                defaultValue={1}
                onChange={formik.handleChange}
                className={classes.dropdownIcons}
              >
                {quarterly?.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
              <div className={classes.description}>
                (Starting date of week. e.g. January, February, March)
              </div>
            </div>
          </div>

          <div className={classes.dateBlock}>
            <div className={classes.inputWraper}>
              <InputLabel
                htmlFor="settings.startCalculateFrom"
                className={classes.label}
              >
                Date Start
              </InputLabel>
              <TextField
                name="settings.startCalculateFrom"
                id="date"
                type="date"
                onChange={formik.handleChange}
                className={classes.dropdownIcons}
                error={
                  formik.touched.settings?.startCalculateFrom &&
                  Boolean(formik.errors.settings?.startCalculateFrom)
                }
                helperText={
                  formik.touched.settings?.startCalculateFrom &&
                  formik.errors.settings?.startCalculateFrom
                }
              />
              <div className={classes.description}>
                (Start data calculation from that date.)
              </div>
            </div>
          </div>

          <div className={classes.btnWraper}>
            <Button
              variant="contained"
              className={classes.btn}
              type="submit"
              disabled={loading}
            >
              Submit
            </Button>
            {loading && (
              <CircularProgress size={24} className={classes.buttonProgress} />
            )}
          </div>
        </form>
      </center>

      {error && (
        <div className={classes.alert}>
          <Alert severity="error">
            <AlertTitle>Error</AlertTitle>
            {errorMessage}
          </Alert>
        </div>
      )}
    </>
  );
};

export default SetupForm;
