import { Button, DialogActions, TextField } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import SelectField from "../../../../components/select/selectField";
import { GlobalData } from "../../../../context/globalData";
import axios from "../../../../utils/axiosHelper";
import {
  RiskValueMap,
  RiskCategoryMap,
  RiskCatMap,
  riskLevelMap,
} from "../../../../utils/levelMap";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@mui/material/Grid";
import SelectFormFiled from "../../../../components/select/selectFormFiled";

// const useStyles = makeStyles((theme) => ({
//   btnStyle: {
//     background: "#707070",
//     cursor: "pointer",
//     font: "normal normal normal 16px/20px Lexend Deca",
//     borderRadius: "22px",
//     width: "120px",
//     padding: "8px 15px",
//     margin: "15px 15px",
//   },
//   applybtnStyle: {
//     background: "#5EBDCC",
//     cursor: "pointer",
//     font: "normal normal normal 16px/20px Lexend Deca",
//     borderRadius: "22px",
//     width: "120px",
//     padding: "8px 8px",
//     margin: "15px 5px",
//   },
//   riskText: {
//     font: "normal normal normal 20px/22px Lexend Deca",
//     color: "#B1B1B1",
//     width: "300px",
//     height: "50px",
//     marginBottom: "3%",
//   },
// }));
export default function SprintConsolidatedRisk(props) {
  const { editConsolidated, setIsForm, getList, classes } = props;
  const editConsId = editConsolidated?.SprintConsolidateRiskId;
  const [data] = useState();
  const [t] = useTranslation("common");
  // const classes = useStyles();
  const {
    Risk,
    errorMessage,
    setErrorMessage,
    error2,
    setError2,
    error,
    setError,
  } = useContext(GlobalData);
  const [consolidateRiskValue, setConsolidateRiskValue] = useState(
    RiskCatMap[data?.sprintconsolidatedriskLevel]
  );
  const [consolidatedGreaterThan, setConsolidatedGreaterThan] = useState(
    data?.["ConsolidatedGreater"]?.toString()
  );
  const [consolidatedLessThan, setConsolidatedLessThan] = useState(
    data?.["ConsolidatedThan"]
  );

  const riskSubmitHandler = () => {
    let url;
    if (validateForm()) {
      if (editConsId) {
        const body = {
          From: parseFloat(consolidatedGreaterThan),
          To: parseFloat(consolidatedLessThan),
          RiskLevel: RiskValueMap[consolidateRiskValue],
          RiskCategory: RiskCategoryMap[consolidateRiskValue],
        };
        url = `/api/sprintconsolidatedrisk/update/${editConsId}`;
        axios
          .patch(url, body)
          .then((result) => {
            console.log("result", result);
            if (result.status === 200) {
              // setmetricDetails(metricDetails);
              setIsForm(false);
              getList();
            }
          })
          .catch((error) => {
            setErrorMessage(t(error.response?.data?.error));
            setError2(true);
            setTimeout(function () {
              setError2(false);
            }, 3000);
          });
      } else {
        const body = {
          From: parseFloat(consolidatedGreaterThan),
          To: parseFloat(consolidatedLessThan),
          RiskLevel: RiskValueMap[consolidateRiskValue],
          RiskCategory: RiskCategoryMap[consolidateRiskValue],
        };
        console.log("body", body);
        url = "/api/sprintconsolidatedrisk/create";
        axios
          .post(url, body)
          .then((result) => {
            if (result.status === 200) {
              getList();
              setIsForm(false);
            }
          })
          .catch((error) => {
            setErrorMessage(t(error.response?.data?.error));
            setError2(true);
            setTimeout(function () {
              setError2(false);
            }, 3000);
          });
      }
    }
  };

  const validateForm = () => {
    let errors = {};
    let formIsValid = true;
    if (!consolidateRiskValue) {
      formIsValid = false;
      errors["consolidateRiskValue"] = "Required";
    }
    if (!consolidatedGreaterThan) {
      formIsValid = false;
      errors["consolidatedGreaterThan"] = "Required";
    } else if (isNaN(consolidatedGreaterThan) && consolidatedGreaterThan) {
      formIsValid = false;
      errors["consolidatedGreaterThan"] = "Please enter a valid number.";
    } else if (consolidatedGreaterThan < 0) {
      formIsValid = false;
      errors["consolidatedGreaterThan"] = "Please enter a positive number.";
    }
    if (consolidatedLessThan && isNaN(consolidatedLessThan)) {
      formIsValid = false;
      errors["consolidatedLessThan"] = "Please enter a valid number.";
    } else if (consolidatedLessThan < 0) {
      formIsValid = false;
      errors["consolidatedLessThan"] = "Please enter a positive number.";
    }

    setError(errors);

    return formIsValid;
  };
  useEffect(() => {
    if (editConsolidated) {
      setConsolidatedGreaterThan(editConsolidated?.From);
      setConsolidatedLessThan(editConsolidated?.To);
      setConsolidateRiskValue(riskLevelMap[editConsolidated?.RiskLevel]);
    }
  }, []);
  return (
    <>
      <>
        <div className={classes.riskText}>
          {/* <span style={{ color: "#ff0000" }}>*</span>
          <label style={{ marginTop: "20px" }}>Indicates Required Field</label> */}
        </div>
        <form autoComplete="off"  className="p-2" onSubmit={riskSubmitHandler}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <div>
                {/* <label className={classes.riskText}>
                  Consolidated Risk Score: Greater Than/Equal To
                  <span style={{ color: "#ff0000" }}>*</span>
                </label> */}
                <TextField
                  id="consolidatedGreaterThan"
                  name="consolidatedGreaterThan"
                  // variant="outlined"
                  className="border-bottom w-100"
                  size="small"
                  label="Consolidated Risk Score: Greater Than/Equal To"
                  value={consolidatedGreaterThan}
                  onChange={(e) => {
                    setConsolidatedGreaterThan(e.target.value);
                  }}
                />
                <div className="errorMsg">{error.consolidatedGreaterThan}</div>
              </div>
            </Grid>
            <Grid item xs={4}>
              <div>
                {/* <label className={classes.riskText}>
                  Consolidated Risk Score: Less Than
                  <span style={{ color: "#ff0000" }}>*</span>
                </label> */}

                <TextField
                  id="consolidatedLessThan"
                  name="consolidatedLessThan"
                  size="small"
                  className="border-bottom w-100"
                  label="Consolidated Risk Score: Less Than"
                  value={consolidatedLessThan}
                  onChange={(e) => {
                    setConsolidatedLessThan(e.target.value);
                  }}
                />
                <div className="errorMsg">{error.consolidatedLessThan}</div>
              </div>
            </Grid>
            <Grid item xs={4}>
              {/* <div style={{ marginLeft: "30px" }}> */}
              {/* <label
                  style={{
                    font: "normal normal normal 20px/22px Lexend Deca",
                    color: "#B1B1B1",
                  }}
                >
                  Risk Level
                  <span style={{ color: "#ff0000" }}>*</span>
                </label> */}
              <SelectFormFiled
                // custom={true}
                render={Risk}
                value={consolidateRiskValue}
                setValue={setConsolidateRiskValue}
                label="Risk Level"
              />
              {/* <SelectField
                  custom={true}
                  render={Risk}
                  value={consolidateRiskValue}
                  setValue={setConsolidateRiskValue}
                  label="Risk Level"
                /> */}
              <div className="errorMsg">{error.consolidateRiskValue}</div>
              {/* </div> */}
            </Grid>
            <Grid item xs={4}>
              <div
                className="position-relative from-grid"
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Button
                  className={classes.cancelBtnStyle}
                  onClick={() => setIsForm(false)}
                >
                  <span>Cancel</span>
                </Button>
                <Button
                  className={classes.applybtnStyle}
                  onClick={riskSubmitHandler}
                >
                  {" "}
                  <span>Apply</span>
                </Button>
              </div>
            </Grid>
          </Grid>

          {/* </div> */}

          {/* <DialogActions> */}

          {/* </DialogActions> */}
        </form>
      </>

      {error2 && (
        <div>
          <Alert severity="error">
            <AlertTitle>Error</AlertTitle>
            {errorMessage}
          </Alert>
        </div>
      )}
    </>
  );
}
