import React from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import { useEffect } from "react";

function IssueTypeGraph({
  data,
  legend = true,
  legendPosition = "right",
  height = "250px",
}) {
  useEffect(() => {
    var chart = am4core.create("radiusPaiChart", am4charts.PieChart);
    chart.hiddenState.properties.opacity = 0; // this creates initial fade-in

    chart.data = data;
    chart.padding(0, 20, 0, 0);
    var series = chart.series.push(new am4charts.PieSeries());
    series.dataFields.value = "value";
    // series.dataFields.radiusValue = "value";
    series.dataFields.category = "title";
    series.slices.template.cornerRadius = 0;
    series.colors.step = 3;
    // Create a separate label template for the series
    var labelTemplate = series.labels.template;
    labelTemplate.text = "{value.percent.formatNumber('#.#')}%";
    var customColors = [
      "#5EBDCC",
      "#8DDBE8",
      "#9DE6F2",
      "#2E7E8B",
      "#1B515A",
      "#33FFFF",
    ];
    series.colors.list = customColors.map((color) => am4core.color(color));
    series.labels.template.disabled = false; // pointer show/hide

    // series.hiddenState.properties.endAngle = -10;

    // Create a legend
    if (legend == true) {
      chart.legend = new am4charts.Legend();
      let marker = chart.legend.markers.template.children.getIndex(0);
      marker.cornerRadius(12, 12, 12, 12);
      chart.legend.markers.template.width = 12;
      chart.legend.markers.template.height = 12;
      // chart.legend.maxWidth = 900;
      chart.legend.position = legendPosition; // Align the legend to the right side
      // chart.legend.labels.template.maxWidth = 300; // Adjust the legend label width as needed
      chart.legend.itemContainers.template.paddingTop = 2;
      chart.legend.itemContainers.template.paddingBottom = 2;
      // chart.legend.valueLabels.template.disabled = true;  // %value in legend hide/show
      // chart.legend.labels.template.maxWidth = 450;
      // chart.legend.labels.template.truncate = true;          // for label spacea nd width
      // chart.legend.itemContainers.template.tooltipText = "{category}";  // tooltip
      chart.legend.width = 450;
      chart.legend.labels.template.truncate = false;
      chart.legend.labels.template.wrap = true;
      // chart.innerRadius = am4core.percent(50);   // donut chart
      // chart.tooltip.getFillFromObject = false;   // tooltip removed color
      // chart.tooltip.background.fill = am4core.color("#67b7dc");  // Added tooltip background color

      // Customize the legend labels
      // chart.legend.labels.template.text = "{title}";
    }

    return () => {
      chart.dispose();
    };
  }, [data]);

  return (
    <div style={{ height: height, width: "100%" }} id="radiusPaiChart"></div>
  );
}

export default IssueTypeGraph;
