// import MenuItem from "@material-ui/core/MenuItem";
// import FormControl from "@material-ui/core/FormControl";
// import Select from "@material-ui/core/Select";
// import { makeStyles } from "@material-ui/core/styles";
// import { useContext } from "react";
// import { GlobalData } from "../../context/globalData";

// const useStyles = makeStyles((theme) => ({
//   formControl: {
//     margin: theme.spacing(1),
//     minWidth: 140,
//     "& .MuiOutlinedInput-root": {
//       position: "relative",
//       borderRadius: "40px",
//       margin: "5px 2px",
//       width: "100%",
//       paddingBottom: "3px",
//     },
//   },
//   formControl3: {
//     margin: theme.spacing(1),
//     minWidth: 180,
//     "& .MuiOutlinedInput-root": {
//       position: "relative",
//       borderRadius: "40px",
//       margin: "5px 2px",
//       width: "160px",
//       paddingBottom: "3px",
//     },
//   },
//   formControl2: {
//     marginLeft: theme.spacing(1),
//     // margin: theme.spacing(1),
//     minWidth: 140,
//     "& .MuiOutlinedInput-root": {
//       position: "relative",
//       borderRadius: "40px",
//       margin: "5px 2px",
//       paddingBottom: "3px",
//     },
//   },
//   selectEmpty: {
//     marginTop: theme.spacing(2),
//   },
// }));

// export default function SelectField(props) {
//   const classes = useStyles();
//   const {
//     render,
//     value,
//     setValue,
//     frstGraphClear,
//     secGraphClear,
//     input,
//     custom,
//     RiskAdvisor,
//     setting,
//     withLable = false,
//     isValueSelect = false,
//     isAll = false,
//   } = props;

//   const { setRiskAdvisor } = useContext(GlobalData);
//   const handleChange = (event) => {
//     if (frstGraphClear) frstGraphClear();
//     if (secGraphClear) secGraphClear();
//     if (RiskAdvisor) {
//       setRiskAdvisor(true);
//       setValue(event.target.value);
//     } else {
//       setValue(event.target.value);
//     }
//   };

//   return (
//     <div>
//       {!withLable ? (
//         <FormControl
//           variant="outlined"
//           className={
//             custom
//               ? classes.formControl2
//               : setting
//               ? classes.formControl3
//               : classes.formControl
//           }
//           size="small"
//         >
//           <Select
//             value={value || []}
//             onChange={handleChange && handleChange}
//             input={input}
//           >
//             {isAll === true && <MenuItem value="All">All</MenuItem>}
//             {render &&
//               render?.length !== 0 &&
//               render?.map((val, index) => (
//                 <MenuItem value={val} key={val + index}>
//                   {val?.charAt(0)?.toUpperCase() + val?.slice(1)}
//                 </MenuItem>
//               ))}
//           </Select>
//         </FormControl>
//       ) : !isValueSelect ? (
//         <FormControl
//           variant="outlined"
//           className={
//             custom
//               ? classes.formControl2
//               : setting
//               ? classes.formControl3
//               : classes.formControl
//           }
//           size="small"
//         >
//           <Select
//             value={value || []}
//             onChange={handleChange && handleChange}
//             input={input}
//           >
//             {render &&
//               render.length !== 0 &&
//               render.map((val, index) => (
//                 <MenuItem value={val} key={val + index}>
//                   {val.lable || "No label"}
//                 </MenuItem>
//               ))}
//           </Select>
//         </FormControl>
//       ) : (
//         <FormControl
//           variant="outlined"
//           className={
//             custom
//               ? classes.formControl2
//               : setting
//               ? classes.formControl3
//               : classes.formControl
//           }
//           size="small"
//         >
//           <Select
//             value={value || []}
//             onChange={handleChange && handleChange}
//             input={input}
//             style={{ height: "40px" }}
//             {...props}
//           >
//             {render &&
//               render.length !== 0 &&
//               render.map((val, index) => (
//                 <MenuItem value={val.value} key={val + index}>
//                   {val.lable || "No label"}
//                 </MenuItem>
//               ))}
//           </Select>
//         </FormControl>
//       )}
//     </div>
//   );
// }


import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";
import { useContext } from "react";
import { GlobalData } from "../../context/globalData";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 140,
    "& .MuiOutlinedInput-root": {
      position: "relative",
      borderRadius: "40px",
      margin: "5px 2px",
      width: "100%",
      paddingBottom: "3px",
    },
  },
  formControl3: {
    margin: theme.spacing(1),
    minWidth: 180,
    "& .MuiOutlinedInput-root": {
      position: "relative",
      borderRadius: "40px",
      margin: "5px 2px",
      width: "160px",
      paddingBottom: "3px",
    },
  },
  formControl2: {
    marginLeft: theme.spacing(1),
    // margin: theme.spacing(1),
    minWidth: 140,
    "& .MuiOutlinedInput-root": {
      position: "relative",
      borderRadius: "40px",
      margin: "5px 2px",
      paddingBottom: "3px",
    },
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  placeholder: {
    color: "gray",
    // fontSize: "0.875rem",
  },
}));

export default function SelectField(props) {
  const classes = useStyles();
  const {
    render,
    value,
    setValue,
    frstGraphClear,
    secGraphClear,
    input,
    custom,
    RiskAdvisor,
    setting,
    withLable = false,
    isValueSelect = false,
    isAll = false,
    placeholder="Select an option"
  } = props;

  const { setRiskAdvisor } = useContext(GlobalData);
  const handleChange = (event) => {
    if (frstGraphClear) frstGraphClear();
    if (secGraphClear) secGraphClear();
    if (RiskAdvisor) {
      setRiskAdvisor(true);
      setValue(event.target.value);
    } else {
      setValue(event.target.value);
    }
  };

  return (
    <div>
      {!withLable ? (
        <FormControl
          variant="outlined"
          className={
            custom
              ? classes.formControl2
              : setting
              ? classes.formControl3
              : classes.formControl
          }
          size="small"
        >
          <Select
            value={value || ""}
            onChange={handleChange && handleChange}
            input={input}
            displayEmpty
            renderValue={(selected) => {
              if (!selected) {
                return <span className={classes.placeholder}>{placeholder}</span>;
              }
              return selected;
            }}
            inputProps={{
              style: { color: value ? "black" : "gray" }, // Adjust color based on selection
            }}
          >
            <MenuItem value="" disabled className={classes.placeholder}>
            {placeholder}
          </MenuItem>
            {isAll === true && <MenuItem value="All">All</MenuItem>}
            {render &&
              render?.length !== 0 &&
              render?.map((val, index) => (
                <MenuItem value={val} key={val + index}>
                  {val?.charAt(0)?.toUpperCase() + val?.slice(1)}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      ) : !isValueSelect ? (
        <FormControl
          variant="outlined"
          className={
            custom
              ? classes.formControl2
              : setting
              ? classes.formControl3
              : classes.formControl
          }
          size="small"
        >
          <Select
            value={value || ""}
            onChange={handleChange && handleChange}
            input={input}
            displayEmpty
            renderValue={(selected) => {
              if (!selected) {
                return <span className={classes.placeholder}>{placeholder}</span>;
              }
              return selected;
            }}
            inputProps={{
              style: { color: value ? "black" : "gray" }, // Adjust color based on selection
            }}
          >
            <MenuItem value="" disabled className={classes.placeholder}>
            {placeholder}
          </MenuItem>
            {render &&
              render.length !== 0 &&
              render.map((val, index) => (
                <MenuItem value={val} key={val + index}>
                  {val.lable || "No label"}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      ) : (
        <FormControl
          variant="outlined"
          className={
            custom
              ? classes.formControl2
              : setting
              ? classes.formControl3
              : classes.formControl
          }
          size="small"
        >
          <Select
            value={value || ""}
            onChange={handleChange && handleChange}
            input={input}
            displayEmpty
            renderValue={(selected) => {
              if (!selected) {
                return <span className={classes.placeholder}>{placeholder}</span>;
              }
              return selected;
            }}
            inputProps={{
              style: { color: value ? "black" : "gray" }, // Adjust color based on selection
            }}
            style={{ height: "40px" }}
            {...props}
          >
            <MenuItem value="" disabled className={classes.placeholder}>
            {placeholder}
          </MenuItem>
            {render &&
              render.length !== 0 &&
              render.map((val, index) => (
                <MenuItem value={val.value} key={val + index}>
                  {val.lable || "No label"}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      )}
    </div>
  );
}




