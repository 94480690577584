export const teamGetTableData = (openDrawerHandler, data, unit) => {
  const issues = Object.keys(data[0]?.Teams[0] || {}).filter(
    (key) => key !== "TeamName"
  );
  const teamNames = [
    ...new Set(data.flatMap((item) => item.Teams.map((team) => team.TeamName))),
  ];
  const categories = data.map((item) => item.category);

  // Generate columns for multi-level header
  const columns = [
    {
      title: "Category",
      field: "issue",
      headerStyle: { minWidth: 150 },
    },
    ...categories.flatMap((category) =>
      teamNames.map((teamName) => ({
        title: (
          <div>
            {teamName} <br /> ({category})
          </div>
        ),
        field: `${category}-${teamName}`,
        render: (rowData) => (
          <div
            onClick={() => openDrawerHandler(rowData, category, teamName)}
            style={{ color: "blue", cursor: "pointer" }}
          >
            {unit === "$"
              ? rowData[`${category}-${teamName}`]
                ? `${unit} ${rowData[`${category}-${teamName}`]}`
                : "-"
              : rowData[`${category}-${teamName}`]
              ? `${rowData[`${category}-${teamName}`]} ${unit}`
              : "-"}
          </div>
        ),
      }))
    ),
  ];

  // Generate dynamic table data
  const tableData = issues?.map((issue) => {
    const rowData = { issue };
    data?.forEach((item) => {
      const category = item?.category;
      item.Teams.forEach((team) => {
        rowData[`${category}-${team?.TeamName}`] = team[issue] || "-";
      });
    });
    return rowData;
  });

  return { columns, tableData };
};
