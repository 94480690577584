import React, { useContext, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Card, CardContent, Typography } from "@material-ui/core";
import axios from "../../utils/axiosHelper";
import TrendChart from "../../components/trendGraph";
import { iterationMap } from "../../utils/textMap";
import { GlobalData } from "../../context/globalData";
import { MODULE_API_URL_GEN } from "../../utils/moduleTabApi";
import MaterialTable from "material-table";
import { forwardRef } from "react";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import { useParams } from "react-router-dom";
import { useHistory, useLocation } from "react-router-dom";
import BreadCrumb from "../../components/breadCrumb/BreadCrumb";

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const useStyles = makeStyles((theme) => ({
  email: {
    marginBottom: "20px",
  },
  mt5: {
    marginTop: "20px",
  },
  cardContainer: {
    paddingBottom: "16px !important",
  },
  insigthTitle: {
    textAlign: "left",
    font: "normal normal 600 20px/25px Lexend Deca",
    color: "#1E1E1E",
  },
  insideSection: {
    marginTop: "19px",
    paddingTop: "20px",
    borderTop: "1px solid #F2F2F2",
  },
  insideBody: {
    textAlign: "left",
    font: "normal normal normal 16px/20px Lexend Deca",
    color: "#1E1E1E",
    marginTop: "19px",
  },
  sidePadding: {
    paddingLeft: "25px",
  },
}));

function SingleModule({ }) {
  const { iterationValueGlobal, setModuleKpiGraphFlag } =
    useContext(GlobalData);

  const [graphData, setGraphData] = useState([]);
  const [data, setData] = useState();
  const [activeKey, setActiveKey] = useState(
    iterationMap[iterationValueGlobal]
  );
  const [riskLevelColumn, setRiskLevelColumn] = useState();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  const moduleParams = useParams();
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();

  const selectedModuleInfo = location?.state;
  const {
    rangePickerValue,
    projectId,
    matrixId,
    selectModuleName,
    selectedmoduleKpiName,
  } = selectedModuleInfo;

  // static issues
  const [riskData, setRiskData] = useState({
    "Total Work Delivered (Story, Task, Bug)": [
      {
        Key: 20,
        Summary: "10",
        "Issue Type": 4,
        Status: "H",
        "Resolution Time": "A",
        Assignee: "B",
      },
      {
        Key: 10,
        Summary: "10",
        "Issue Type": 4,
        Status: "B",
        "Resolution Time": "C",
        Assignee: "D",
      },
      {
        Key: 220,
        Summary: "10",
        "Issue Type": 4,
        Status: "E",
        "Resolution Time": "F",
        Assignee: "I",
      },
    ],

    "Commits Size": [
      {
        "Commit Id": "a4728a",
        Comment: "M30-4735 : code refactoring",
        Author: "Chetan Kalode",
        "Merge Commit": "No",
        Date: "21-Nov-2022",
      },
      {
        "Commit Id": "e181da",
        Comment: "M30-4735 : code refactoring",
        Author: "Alpesh Gujarati",
        "Merge Commit": "No",
        Date: "23-Nov-2022",
      },
      {
        "Commit Id": "49e5c2",
        Comment: "M30-4735 : code refactoring",
        Author: "aaditya.doshi",
        "Merge Commit": "No",
        Date: "24-Nov-2022",
      },
      {
        "Commit Id": "4f58ca",
        Comment: "M30-4735 : added code for pancard fetch",
        Author: "Ritik Verma",
        "Merge Commit": "No",
        Date: "25-Nov-2022",
      },
      {
        "Commit Id": "e8413e",
        Comment:
          "feature/M30-5059: avf feedback task done 1. Placing a banner...",
        Author: "Ralph Dias",
        "Merge Commit": "No",
        Date: "26-Nov-2022",
      },
    ],
  });

  const tableOption = {
    sorting: false,
    search: true,
    toolbar: true,
    searchFieldStyle: {
      padding: "5px 10px 5px 15px",
      borderRadius: "30px",
      disableUnderline: true,
      backgroundColor: "#efefef",
    },
  };

  const kpiGraphHandler = (ModuleGraph_URL) => {
    axios
      .get(ModuleGraph_URL)
      .then((result) => {
        setData(result?.data);
        const sortData = result?.data?.trends?.sort(function (a, b) {
          return new Date(a?.Time) - new Date(b?.Time);
        });
        const graphData = sortData?.map((item) => {
          return {
            Value1: item?.Value,
            Time: item?.Time,
            Name1: selectModuleName,
            Value: result?.data?.iteration?.Target,
            // Name: "Target",
          };
        });
        setGraphData(graphData);
      })
      .catch((err) => {
        console.log("error", err);
      });
  };
  useEffect(() => {
    let Module_URL = MODULE_API_URL_GEN(rangePickerValue, projectId, activeKey);
    let url = `api/v2/module/getMetricDetails/${projectId}/kpi/${matrixId}?${Module_URL}`;
    kpiGraphHandler(url);
    // let riskLevel_Url = MODULE_API_URL_GEN(rangePickerValue, projectId, activeKey);
    // let startDate = Module_URL.slice(24,33)
    // let endDate = Module_URL.slice(41,50)
    // setStartDate(startDate)
    // setEndDate(endDate)
  }, [matrixId, activeKey]);

  useEffect(() => {
    return () => {
      setModuleKpiGraphFlag(false);
    };
  }, []);

  // do not delete below line
  // api/project/detail?no_of_days=117&group_id=4&project_id=1061415&startDay=2022-02-1&endDay=2022-05-28&iteration=14

  useEffect(() => {
    const columns = [];
    Object.keys(riskData["Commits Size"][0])?.forEach((item) => {
      if (item !== "tableData") {
        if (item == "Commit Id") {
          columns.push({
            field: item,
            title: item,
            headerStyle: {
              backgroundColor: "#efefef",
            },
            cellStyle: {
              color: "#5EBDCC",
              cursor: "pointer",
            },
          });
        }
        if (item !== "Commit Id") {
          columns.push({
            field: item,
            title: item,
            headerStyle: {
              backgroundColor: "#efefef",
            },
          });
        }
      }
    });
    setRiskLevelColumn(columns);
  }, [riskData]);

  const moduleModalBackHandelr = () => {
    history.push(`/modules`);
  };

  return (
    <>
      <BreadCrumb
        backHandler={() => moduleModalBackHandelr()}
        list={["Modules"]}
        active={selectModuleName}
      />
      <Card className="customCard">
        <CardContent
          className={`${classes.cardContainer} ${classes.sidePadding}`}
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <h6>
            Current Value: <b>{data?.Value?.toFixed(1)}</b>
          </h6>
          <h6>
            Actual Target: <b> {data?.iteration?.Target?.toFixed(1)}</b>
          </h6>
          <h6>
            Deviation: <b> {data?.deviation?.toFixed(1)}</b>
          </h6>
        </CardContent>
      </Card>
      <Card className={`${classes.mt5} customCard`}>
        <CardContent
          className={`${classes.cardContainer} ${classes.sidePadding}`}
        >
          <TrendChart
            graphData={graphData}
            activeKey={activeKey}
            setActiveKey={setActiveKey}
            matricName={selectedmoduleKpiName}
          />
          <div className={classes.insideSection}>
            <Typography className={classes.insigthTitle}>Insight</Typography>
            <Typography className={classes.insideBody}>
              No Insight for this Module
            </Typography>
          </div>
        </CardContent>
      </Card>
      {/* <Card
        className="paginationStyling customCard"
        style={{ marginTop: "25px" }}
      >
        <MaterialTable
          style={{ boxShadow: "none" }}
          title={
            <span
              style={{
                color: "#5EBDCC",
                textTransform: "uppercase",
                fontSize: "16px",
                fontWeight: "400",
              }}
            >{`${selectedmoduleKpiName}${" : 21-NOV-2022 - 27-NOV-2022"}`}</span>
          }
          columns={riskLevelColumn}
          enableRowNumbers
          rowNumberMode="static"
          icons={tableIcons}
          data={riskData["Commits Size"]}
          options={tableOption}
        />
      </Card> */}
    </>
  );
}

export default SingleModule;
