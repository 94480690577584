import React, { useEffect } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";

function MixedBardChart({ graphData, selectedType, performanceMedian, id }) {
  useEffect(() => {
    if (graphData.length === 0) return;
    var chart = am4core.create("chartdiv", am4charts.XYChart);
    chart.colors.step = 2;
    if (selectedType === "Mixed") {
      chart.legend = new am4charts.Legend();
      chart.legend.position = "top";
      chart.legend.paddingBottom = 20;
      chart.legend.labels.template.maxWidth = 95;
    }

    var xAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    xAxis.dataFields.category = "name";
    xAxis.renderer.cellStartLocation = 0.1;
    xAxis.renderer.cellEndLocation = 0.9;
    xAxis.renderer.grid.template.location = 0;
    xAxis.renderer.labels.template.disabled = true;
    var yAxis = chart.yAxes.push(new am4charts.ValueAxis());
    // yAxis.min = -20;

    function createSeries(value, name) {
      var series = chart.series.push(new am4charts.ColumnSeries());
      series.dataFields.valueY = value;
      series.dataFields.categoryX = "name";
      series.name = name;
      // series.tooltip.label.interactionsEnabled = true;
      // series.tooltip.keepTargetHover = true;
      // Add tooltips
      series.columns.template.tooltipText = `{categoryX}: {valueY} %`;

      if (selectedType === "Utilization") {
        // Change bar color based on value
        series.columns.template.adapter.add("fill", function (fill, target) {
          if (target.dataItem && target.dataItem.valueY > 100) {
            return am4core.color("#FF5733");
          } else if (target.dataItem && target.dataItem.valueY < 50) {
            return am4core.color("#ffb100");
          } else {
            return am4core.color("green");
          }
        });
      } else if (selectedType === "Performance") {
        // Change bar color based on value
        series.columns.template.adapter.add("fill", function (fill, target) {
          if (
            target.dataItem &&
            target.dataItem.valueY > performanceMedian + 20
          ) {
            return am4core.color("green");
          } else if (
            target.dataItem &&
            target.dataItem.valueY < performanceMedian - 20
          ) {
            return am4core.color("#FF5733");
          } else {
            return am4core.color("#26a6bb");
          }
        });
      }

      series.events.on("hidden", arrangeColumns);
      series.events.on("shown", arrangeColumns);

      var bullet = series.bullets.push(new am4charts.LabelBullet());
      bullet.interactionsEnabled = false;
      bullet.dy = 30;
      bullet.label.fill = am4core.color("#ffffff");
      return series;
    }

    chart.data = graphData;

    Object.keys(graphData[0])?.forEach((e) => {
      if (e !== "name") createSeries(e, e);
    });

    function arrangeColumns() {
      var series = chart.series.getIndex(0);

      var w =
        1 -
        xAxis.renderer.cellStartLocation -
        (1 - xAxis.renderer.cellEndLocation);
      if (series.dataItems.length > 1) {
        var x0 = xAxis.getX(series.dataItems.getIndex(0), "categoryX");
        var x1 = xAxis.getX(series.dataItems.getIndex(1), "categoryX");
        var delta = ((x1 - x0) / chart.series.length) * w;
        if (am4core.isNumber(delta)) {
          var middle = chart.series.length / 2;

          var newIndex = 0;
          chart.series.each(function (series) {
            if (!series.isHidden && !series.isHiding) {
              series.dummyData = newIndex;
              newIndex++;
            } else {
              series.dummyData = chart.series.indexOf(series);
            }
          });
          var visibleCount = newIndex;
          var newMiddle = visibleCount / 2;

          chart.series.each(function (series) {
            var trueIndex = chart.series.indexOf(series);
            var newIndex = series.dummyData;

            var dx = (newIndex - trueIndex + middle - newMiddle) * delta;

            series.animate(
              { property: "dx", to: dx },
              series.interpolationDuration,
              series.interpolationEasing
            );
            series.bulletsContainer.animate(
              { property: "dx", to: dx },
              series.interpolationDuration,
              series.interpolationEasing
            );
          });
        }
      }
    }

    chart.scrollbarX = new am4core.Scrollbar();
    // chart.scrollbarX.start = 0;
    // chart.scrollbarX.end = 0.4;
  }, [graphData]);
  return <div id="chartdiv" style={{ width: "100%", height: "500px" }} />;
}

export default MixedBardChart;
