import React, { useEffect } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
function BarChart({ data }) {
  useEffect(() => {
    const colors = {
      0: "#5EBDCC",
      1: "#8DDBE8",
      2: "#C2F3FB",
    };

    var chart = am4core.create("chartdiv", am4charts.XYChart);
    chart.padding(0, 20, 0, 0);

    var categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.dataFields.category = "work";
    categoryAxis.renderer.minGridDistance = 1;
    categoryAxis.renderer.inversed = true;
    categoryAxis.renderer.grid.template.disabled = true;

    var valueAxis = chart.xAxes.push(new am4charts.ValueAxis());
    valueAxis.min = 0;

    var series = chart.series.push(new am4charts.ColumnSeries());
    series.dataFields.categoryY = "work";
    series.dataFields.valueX = "value";
    series.tooltipText = "{valueX.value}";
    series.columns.template.strokeOpacity = 0;
    series.columns.template.column.cornerRadiusBottomRight = 20;
    series.columns.template.column.cornerRadiusTopRight = 20;

    // as by default columns of the same series are of the same color, we add adapter which takes colors from chart.colors color set
    series.columns.template.adapter.add("fill", function (fill, target) {
      if (colors[target.dataItem.index]) {
        return am4core.color(colors[target.dataItem.index]);
      }
      return chart.colors.getIndex(target.dataItem.index);
    });

    // to add custom yaxis lable
    var labelTemplate = categoryAxis.renderer.labels.template;
    labelTemplate.adapter.add("html", function (html, target) {
      if (target.dataItem && target.dataItem.dataContext) {
        var dataItem = target.dataItem.dataContext;
        return `<div style="display: flex; align-items: center; color: #000000;">
            <h5 style="text-align: left;
            font: normal normal normal 14px/18px Lexend Deca;
            margin-bottom: 0px; margin-right:15px;
            ">
                ${dataItem.work}
            </h5>
            <span style='font: normal normal 600 16px/20px Lexend Deca'>
                ${dataItem.value}
            </span>
        </div>`;
      }
      return html;
    });

    categoryAxis.sortBySeries = series;
    chart.data = data;
  }, [data]);

  return <div id="chartdiv"></div>;
}

export default BarChart;
