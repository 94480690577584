import { Tooltip, makeStyles } from "@material-ui/core";
import React from "react";
import { color } from "../../../../Config/statusData";

function CycleTimeBreakDown({ data, title }) {
  const useStyles = makeStyles({
    timeLineContainer: {
      display: "flex",
      marginTop: "50px",
    },

    barConatiner: {
      position: "relative",
    },
    barConatinerHover: {
      background: " #fff !important",
      padding: " 10px",
    },
    bar: {
      height: 10,
      width: "100%",
      borderRadius: "0px 10px 10px 0px",
      position: "relative",
      zIndex: 1,
    },
    beforeColor: {
      position: "absolute",
      width: "3px",
      height: "10px",
      left: "-3px",
      top: 0,
    },
    textConatiner: {
      display: "flex",
    },
    text: {
      color: "#000000",
      "& h5": {
        font: "normal normal 600 16px/20px Lexend Deca",
        marginBottom: 6,
        marginTop: 10,
      },
      "& span": {
        font: "normal normal normal 14px/18px Lexend Deca",
        whiteSpace: "nowrap",
      },
    },
  });
  const classes = useStyles();

  return (
    <div className={classes.timeLineContainer}>
      {data.map((e, index) => (
        <div
          key={e.title}
          style={{ width: `${e.percentage}%` }}
          className={classes.hoverView}
        >
          <Tooltip
            // className={classes.barConatinerHover}
            title={
              <div>
                <h5>{e.title}</h5>
                <span>
                  {e.value}{" "}
                  {title === "Cycle Time (Pull Request)" ? "" : "Days"}
                </span>
              </div>
            }
            placement="top-start"
          >
            <div className={classes.barConatiner}>
              <div
                style={{
                  background: `${color[index]}`,
                }}
                className={classes.bar}
              ></div>
              <div
                style={{
                  background: `${color[index]}`,
                }}
                className={classes.beforeColor}
              ></div>
            </div>
          </Tooltip>
          <div
            className={`${classes.barConatiner}`}
            style={{
              marginTop: index % 2 !== 0 ? "-75px" : "0px",
              display: e.percentage < 15 ? "none" : "",
            }}
          >
            <div className={classes.text}>
              <h5>{e.title}</h5>
              <span>
                {e.value} {title === "Cycle Time (Pull Request)" ? "" : "Days"}
              </span>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default CycleTimeBreakDown;
