import {
  forwardRef,
  useEffect,
  useState,
  useRef,
  memo,
  useContext,
} from "react";
import EditIcon from "@material-ui/icons/Edit";
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";
import MaterialTable from "material-table";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import { useHistory, useLocation } from "react-router-dom";
import { riskMap } from "../../utils/levelMap";
import { inverseTextValue } from "../../utils/textMap";
import { GlobalData } from "../../context/globalData";
import { ExcelExport } from "../../utils/excelExport";
// import { makeStyles } from "@material-ui/core";
import "./index.css";
import axios from "../../utils/axiosHelper";
import ConfirmationDialog from "../confirmationPopup";

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

function MaterialGrid(props) {
  const {
    columns,
    data,
    isLink,
    teamLink,
    setTeamValue,
    setRender,
    isLoading,
    level,
    subTab,
    tableID,
    riskType,
    frstGraphClear,
    secGraphClear,
    admin,
    deatils,
    title,
  } = props;
   const location = useLocation();
  const [tableData, setTableData] = useState([]);
  const [tableColumn, setColumn] = useState([]);
  const [rowDataID, setRowDatID] = useState([]);
  const [open, setOpen] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const history = useHistory();
  const tableRef = useRef(null);
  const { isSummary, setmetricDetails ,currentRender,sonarqubeFlag,setMetricConfigurationData,metricConfigurationData,metricConfigurationFlag} = useContext(GlobalData);

  useEffect(() => {
    setColumn(columns);
    setTableData(JSON.parse(JSON.stringify(data)));
    if(level === "DashBoard"){
      tableRef.current.dataManager.changePageSize(5);
    }else{
       tableRef.current.dataManager.changePageSize(50);
    }
   
    let table = tableRef.current.tableContainerDiv.current;

    table.style.borderRadius = "10px";

    table?.offsetParent &&
      (table.offsetParent.children[0].querySelector(
        "[aria-label='Export']"
      ).style.display = "none");
    return () => {
      setColumn([]);
      setTableData([]);
    };
  }, [columns, data]);

  // const downloadCsv = (data, fileName) => {
  //   const finalFileName = fileName.endsWith(".csv")
  //     ? fileName
  //     : `${fileName}.csv`;
  //   const a = document.createElement("a");
  //   a.href = URL.createObjectURL(new Blob([data], { type: "text/csv" }));
  //   a.setAttribute("download", finalFileName);
  //   document.body.appendChild(a);
  //   a.click();
  //   document.body.removeChild(a);
  // };

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
 
useEffect(()=>{
  const isLoad = location?.state?.isLoad;
  let URL = "";
  if (tableID === 2) {
    URL = "/api/metrics/list?isActive=true";
  } else if (tableID === 1) {
    URL = "/api/metricgroups/getall";
  } else if (tableID === 3) {
    URL = "/api/risks/";
  } else if (tableID === 4) {
    URL = "/api/consolidatedrisk/";
  } else if (tableID === 5) {
    URL = `/api/alerts/get?clientId=${15}`;
  } else if (tableID === 6 ) {
    URL = `/api/tool/`;
  } else if (tableID === 7 ) {
    URL = `/api/metrics/config/list`;
  }
  axios
    .get(URL)
    .then((result) => {
        if (tableID === 5) {
          result?.data?.alerts >=0 ?  setmetricDetails(result?.data?.alerts ):setmetricDetails([]);
        } else if (tableID === 6) {
          result?.data?.tools.length >= 0 ? setmetricDetails(result?.data?.tools):setmetricDetails([]);
        } else if (tableID === 7) {
          result?.data?.data.length >=0 ? setmetricDetails(result?.data?.data ):setmetricDetails([]);
        } else {
          result?.data.length >=0 ?setmetricDetails(result?.data ):setmetricDetails([]);
        }
    })
    .catch((err) => {
      console.log(err);
    });
},[tableID,sonarqubeFlag,metricConfigurationFlag])



  const RowClickHandler = (rowData) => {
    setRowDatID(rowData.id);
    // let URL = "";
    // if (rowData.id === 2) {
    //   URL = "/api/metrics/list?isActive=true";
    // } else if (rowData.id === 1) {
    //   URL = "/api/metricgroups/getall";
    // } else if (rowData.id === 3) {
    //   URL = "/api/risks/";
    // } else if (rowData.id === 4) {
    //   URL = "/api/consolidatedrisk/";
    // } else if (rowData.id === 5) {
    //   URL = `/api/alerts/get?clientId=${15}`;
    // } else if (rowData.id === 6) {
    //   URL = `/api/tool/`;
    // }

    // axios
    //   .get(URL)
    //   .then((result) => {
    //     rowData.id === 5 ? setmetricDetails(result.data.alerts)
    //       : rowData.id === 6 ? setmetricDetails(result.data.tools)
    //       : setmetricDetails(result.data);
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
    if (rowData.id === 8) {
      return history.push({
        pathname: `/riskadvisor/setting`,
        // hash: rowData.Type,
        state: {
          details: "addDetails",
          title: rowData.Type,
          AllDetails: rowData,
          id: rowData.id,
        },
      });
    }

    history.push({
      pathname: `/riskadvisor/${currentRender}`,
      hash: rowData.Type,
      // state: "details",
      state: {
        details: "details",
        title: rowData.Type,
        AllDetails: rowData,
        id: rowData.id,
      },
    });
  };
  const deletePopUp = (id, clientid) => {
    setOpen(true);
    if (title === "Metric Level Risks" || title === "Alert Frame Work") {
      setDeleteId(id);
    } 
    else {
      setDeleteId(clientid);
    }
  };
  const deleteHandler = () => {
     let ClientID = localStorage.getItem("ClientId");
    let URL = "";
    let listUrl = "";
    if (title === "Metric Level Risks") {
      URL = `/api/risks/delete/${deleteId}`;
      listUrl = "/api/risks/";
    } 
    if (title === "Alert Frame Work") {
      URL = `/api/alerts/delete?id=${deleteId}`;
      listUrl = `/api/alerts/get?clientId=${ClientID}`;
    } else {
      URL = `/api/consolidatedrisk/delete/${deleteId}`;
      listUrl = "/api/consolidatedrisk/";
    }

    axios
      .delete(URL)
      .then((response) => {
        setOpen(false);
        axios
          .get(listUrl)
          .then((result) => {
            setmetricDetails(
              title === "Alert Frame Work" ? result.data.alerts : result.data
            );
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
      });
    setDeleteId("");
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div
      style={{ height: data?.length === 0 && "50vh" }}
      className={admin ? "setup" : null}
    >
      <MaterialTable
        enableExpanding={true}
        enableExpandAll={false}
        tableRef={tableRef}
        title=""
        icons={tableIcons}
        columns={tableColumn}
        isLoading={isLoading}
        onRowClick={ 
          isLink || teamLink
            ? (_event, rowData) => {
                if (isLink) {
                  if (admin) {
                    return RowClickHandler(rowData);
                  }
                  if (deatils) {
                    if(title === "Metric configuration"){
                      setMetricConfigurationData(rowData)
                    }else{
                      return history.push({
                        pathname: `/riskadvisor/${currentRender}/update/${[
                          Object.values(rowData)[0],
                        ]}`,
                        hash: rowData.Type,
                        state: {
                          details: "addDetails",
                          title: title,
                          AllDetails: rowData,
                        },
                      });
                    }
                  } else {
                    history.push({
                      pathname: `/riskadvisor/project/${rowData.ProjectName.replace(
                        "/",
                        ""
                      )}/${rowData.ProjectId}/0`,
                      hash: riskMap[rowData.risk] || "",
                    });
                  }
                }
                if (teamLink) {
                  frstGraphClear();
                  secGraphClear();
                  setRender(1);
                  setTeamValue(rowData.ProjectName);
                }
              }
            : null
        }
        style={{ boxShadow: "none" }}
        data={tableData}
        actions={
          level === "DashBoard"
            ? []
            : admin
            ? []
            : deatils
            ? [
                (title === "Metric Level Risks" ||
                  title === "Alert Frame Work" ||
                  title === "Project, Team, Resource, Module Level Risks") && {
                  icon: () => (
                    <DeleteOutlineOutlinedIcon style={{ color: "#757575" }} />
                  ),
                  tooltip: "Delete",
                  size: "small",
                  onClick: (_event, rowData) =>
                  {
                    deletePopUp(rowData.RiskId, rowData.ConsolidateRiskId)
                  }
                },
                (title === "Metric Level Risks" ||
                  title === "Project, Team, Resource, Module Level Risks") && {
                  icon: () => <EditIcon style={{ color: "#757575" }} />,
                  tooltip: "Edit",
                  size: "small",
                  onClick: (_event, rowData) => {
                    return history.push({
                      pathname: encodeURI(
                        `/riskadvisor/${currentRender}/update/${[Object.values(rowData)[0]]}`
                      ),
                      hash: rowData.Type,
                      state: {
                        details: "addDetails",
                        title: title,
                        AllDetails: rowData,
                      },
                    });
                  },
                },
              ]
            : [
                {
                  icon: (props, ref) => <SaveAlt {...props} ref={ref} />,
                  tooltip: "Export Spreadsheet",
                  position: "toolbar",
                  isFreeAction: true,
                  onClick: () => {
                    setTimeout(function () {
                      tableRef.current.tableContainerDiv.current.offsetParent.children[0]
                        .querySelector("[aria-label='Export']")
                        .click();
                      tableRef.current.tableContainerDiv.current.ownerDocument.activeElement.style.display =
                        "none";
                      tableRef.current.tableContainerDiv.current.ownerDocument.activeElement.click();
                    }, 100);
                  },
                },
              ]
        }
        options={{
          actionsColumnIndex: -1,
          draggable: false,
          exportAllData: true,
          exportButton: true,
          // search : false ,
          emptyRowsWhenPaging: false,
          //  exportButton: false,
          exportCsv: (columns, data) => {
            const headerRow = columns
              .filter((val) => !(val.title === "Details" || val.title === "Id"))
              .map((col) => col.title);

            const dataRows = data.map((item) => {
              return headerRow.map((val) => {
                if (level === "risk") {
                  if (val === "Risk") {
                    return capitalizeFirstLetter(riskMap[item.riskLevel]);
                  }
                  if (val === "Deviation") {
                    let changeVal = parseFloat(item[val]) / 100;
                    return changeVal;
                  }
                  if (isSummary) {
                    return item[inverseTextValue[val] || val];
                  }
                  return item[val];
                }

                if (level === "RESOURCES") {
                  if (val === "Team") {
                    return item.TeamName;
                  }
                  if (val === "Resource") {
                    return item.ProjectName;
                  }
                } else {
                  if (
                    val === "Project" ||
                    val === "Team" ||
                    val === "Sprint" ||
                    val === "Module"
                  ) {
                    return item.ProjectName;
                  }
                }

                if (val === "Risk") {
                  return capitalizeFirstLetter(riskMap[item.risk]);
                }

                if (isSummary) {
                  if (item[val])
                    return [
                      capitalizeFirstLetter(riskMap[item[val].RiskLevel]),
                    ];
                }
                if (level === "graphDetails") {
                  return item[val];
                }

                return (
                  item[val] && [
                    parseFloat(parseFloat(item[val].Value).toFixed(3)),
                    capitalizeFirstLetter(riskMap[item[val].RiskLevel]),
                  ]
                );
              });
            });

            let newHeaderRow;
            if (level === "graphDetails") {
              newHeaderRow = headerRow;
            } else if (level !== "risk") {
              if (isSummary) {
                newHeaderRow = headerRow;
              } else {
                newHeaderRow = headerRow.reduce((res, current) => {
                  if (current === "Resource") {
                  }
                  if (
                    current === "Project" ||
                    current === "Risk" ||
                    current === "Team" ||
                    current === "Resource" ||
                    current === "Module" ||
                    current === "Sprint"
                  )
                    return res.concat([current]);

                  return res.concat([current, current]);
                }, []);
              }
            } else {
              newHeaderRow = headerRow;
            }

            const { exportDelimiter } = tableRef.current.props.options;

            const delimiter = exportDelimiter ? exportDelimiter : ",";
            const csvContent = [newHeaderRow, ...dataRows]
              .map((e) => e.join(delimiter))
              .join("\n");
            const csvContent2 = [...dataRows]
              .map((e) => e.join(delimiter))
              .join("\n");
            const subLevel = headerRow.find(
              (element) => element === "Resource"
            );

            const csvFileName = level === "risk" ? "Risks" : "Metrics";

            ExcelExport(
              newHeaderRow,
              dataRows,
              csvFileName,
              level,
              isSummary,
              subLevel,
              subTab,
              riskType
            );

            //downloadCsv(csvContent, csvFileName);
          },
          search: level === "DashBoard" ? false : admin ? false : true,
          sorting: admin ? false : true,
          paging: admin ? false : true,
          pageSizeOptions: [],
          doubleHorizontalScroll: true,
          // loadingType: "linear",
          style: {
            top: 0,
            display: "none",
          },
          headerStyle: {
            backgroundColor: "#F9F9F9",  color: "black",
     
            fontSize: "14px",
            fontWeight: "bold",
            position: "sticky",
            top: 0,
          },
          
        }}
      />

      <ConfirmationDialog
        open={open}
        handleClose={handleClose}
        title={"Delete"}
        text={"Are you sure you want to delete?"}
        deleteHandler={deleteHandler}
      />
    </div>
  );
}

export default memo(MaterialGrid);
