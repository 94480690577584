import React, { useEffect, useState } from "react";
import MaterialTable from "material-table";
import { makeStyles } from "@material-ui/core/styles";
import { tableIcons } from "../../utils/tableIcon";
import SelectFormFiled from "../select/selectFormFiled";
import SelectField from "../select/selectField";
import instance from "../../utils/axiosHelper";

function CommonSettingTable({
  children,
  options = {},
  clickHandler,
  columns,
  data,
  btnLabel,
  tableTitle,
  isActiveFilter = false,
  addButton = true,
  filterHandler,
}) {
  const [active, setActive] = useState("active");

  const useStyles = makeStyles((theme) => ({
    Active: {
      top: 227,
      left: 590,
      position: "absolute",
      zIndex: 2,
      width: 400,
      gap: 10
    },
    btnStyle: {
      float: "right",
      background: "#5EBDCC !important",
      cursor: "pointer ",
      font: "normal normal normal 16px/20px Lexend Deca",
      borderRadius: "22px",
      // width: "180px",
      padding: "8px 8px",
      margin: "15px 5px",
      color: "#fff",
      border: "none",
      position: "absolute",
      top: "0px",
      right: 10,
      zIndex: 1,
    },
    headerlabel: {
      margin: "20px 5px",
      font: "normal normal normal 20px/23px Lexend Deca",
    },
    tableStyle: {
      position: "relative",
      "& .MuiTextField-root": {
        position: "absolute",
        left: "10px",
        background: "#f8f8f8",
        borderRadius: 20,
        "& .MuiInput-root": {
          height: 45,
        },
      },
    },
  }));
  const tableOption = {
    sorting: false,
    search: true,
    toolbar: true,
    headerStyle: {
      backgroundColor: "#efefef",
    },
    pageSize: 10,
    paginationType: "stepped",
  };
  const classes = useStyles();
  useEffect(() => {
    if (isActiveFilter && filterHandler) {
      filterHandler(active);
    }
  }, [active]);


  return (
    <>
      <div>
        <div className={classes.headerlabel}>
          <span>{tableTitle}</span>
        </div>
      </div>
      <div className={`d-flex ${classes.Active}`}>
        {isActiveFilter && (
          <div>
            <SelectField
              render={["active", "inactive"]}
              value={active}
              setValue={(value) => {
                setActive(value);
              }}
              name={"isActive"}
              label="Status"
            />
          </div>
        )}
        {children}
      </div>
      <div className={classes.tableStyle}>
        {addButton && (
          <button
            type="button"
            className={classes.btnStyle}
            onClick={clickHandler}
          >
            {btnLabel}
          </button>
        )}

        <MaterialTable
          style={{ boxShadow: "none" }}
          title=""
          columns={columns}
          enableRowNumbers
          rowNumberMode="static"
          icons={tableIcons}
          data={data}
          options={{ ...tableOption, options }}
        />
      </div>
    </>
  );
}

export default CommonSettingTable;
