import React from "react";
import CommonPopup from "../commonPopup";
import { Box, Button, TextField, Typography } from "@material-ui/core";

function DetailPopup({
  isDrawerOpen,
  setIsDrawerOpen,
  popupData,
  selectedRow,
  getData,
  setSelectedRowCost,
  selectedRowCost,
}) {
  return (
    <CommonPopup open={isDrawerOpen} setOpen={setIsDrawerOpen} width={800}>
      <Box padding={2}>
        <Box textAlign={"left"} display={"flex"} gridGap={10} mb={3}>
          <Typography>Issue : {selectedRow?.issue}</Typography>
          <Typography>Date : {selectedRow?.category}</Typography>
          <Typography>Team Name : {selectedRow?.teamName}</Typography>
        </Box>
        <Box textAlign={"left"} display={"flex"} gridGap={10} mb={3}>
          <div>
            <label>Please put your fully loaded engineers cost</label>
            <br />
            <Box display={"flex"} gridGap={10} marginTop={1}>
              <Box display={"flex"} alignItems={"center"} gridGap={2}>
                $
                <TextField
                  value={selectedRowCost}
                  onChange={(e) => {
                    setSelectedRowCost(e.target.value);
                  }}
                  style={{ width: "400px" }}
                  id="outlined-basic"
                  variant="outlined"
                />
              </Box>
              <Button
                disabled={selectedRowCost ? false : true}
                onClick={() => {
                  getData(selectedRow);
                }}
                variant="outlined"
                color="primary"
              >
                {" "}
                Apply
              </Button>
            </Box>
          </div>
        </Box>
        {popupData && (
          <Box
            display={"grid"}
            border={"1px solid #e3d6d6"}
            padding={2}
            sx={{
              gridTemplateColumns: `repeat(${popupData?.length}, 1fr)`,
              justifyContent: "space-between",
            }}
          >
            {popupData.map((e, index) => (
              <Box
                key={index}
                borderRight={
                  index === popupData.length - 1 ? "none" : `1px solid #e3d6d6`
                }
                padding={2}
              >
                {e.value}
              </Box>
            ))}
          </Box>
        )}
      </Box>
    </CommonPopup>
  );
}

export default DetailPopup;
