import React, { useState, useEffect, useContext } from "react";
import ClusterColumnGraph from "./clusterColumnGraph";
import { makeStyles } from "@material-ui/core/styles";
import { CardOfUser } from "./cardOfUser";
import SelectField from "../../components/select/selectField";
import RangeSelector from "../../components/rangeSelector";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { JiraTable } from "./tables/jiraTable";
import Card from "@material-ui/core/Card";
import axios from "../../utils/axiosHelper";
import AutoComp from "./autoComp";
import { format } from "date-fns";
import PaginationTable from "./PaginationTable";
import CircularProgress from "@material-ui/core/CircularProgress";
import { JiraTableSearchBar } from "./jiraTableSearchBar";
import { JiraDynamicTable } from "./tables/jiraDyanamicTable";
import { ExpandJiraDynamicTable } from "./tables/expandJiraDynamicTable";
import { Capitalize } from "../../utils/capitialize";
import Button from "@material-ui/core/Button";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import UpgradePlanRequest from "../../utils/UpgradePlanRequest";
import { GlobalData } from "../../context/globalData";
import SprintPieCharts from "./SprintPieCharts";
import SprintBarCharts from "./SprintBarCharts";
import BreadCrumb from "../breadCrumb/BreadCrumb";
import DateRangeOutlinedIcon from "@material-ui/icons/DateRangeOutlined";
import PageNotFound from "../../pages/pageNotFound/PageNotFound";
import CommonTable from "../commonTable";
import { getSelectedDate } from "../../utils/moduleTabApi";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  fl: {
    display: "flex",
    gap: "10px",
    alignItems: "center",
    backgroundColor: "white",
    borderRadius: "30px",
  },
  tp: {
    margin: "10px",
  },
  formControl: {
    "& .css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root": {
      borderRadius: "30px",
    },
  },
  mainhead: {
    display: "flex",
    gap: "100px",
    padding: "18px 0px",
    "& label": {
      fontWeight: "700",
    },
  },
  timeanddate: {
    // display: "flex",
    gap: "20px",
  },
  searchbar: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "-60px",
    margin: "0px 20px",
    paddingBottom: "20px",
  },
  errMsssg: {
    textAlign: "center",
    padding: " 0px",
  },
  feature: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "20px",
    fontWeight: "bolder",
    marginTop: "20px",
  },
  btn: {
    backgroundColor: "#51a7ed",
    fontSize: "15px",
    padding: "0px 30px",
    color: "#fff",
    height: "35px",
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: "#072136",
    },
  },
  featureBtn: {
    display: "flex",
    justifyContent: "center",
  },
  profile: {
    border: "1px solid black",
    borderRadius: "20px",
  },

  //   input #combo-box-demo :{
  //     textTransform: "capitalize",
  // }
}));
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.action.hover,
    color: theme.palette.common.black,
    fontWeight: "bold",
    fontSize: 16,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    fontWeight: "bold",
  },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "white",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export const Jira = () => {
  const classes = useStyles();
  const [projectList, setProjectList] = useState([]);
  const [sprintIteration, setSprintIteration] = useState([]);
  const [sprintId, setSprintId] = useState();
  const [getMatricId, setMatricId] = useState();
  const [selectedProjectName, setSelectedProjectName] = useState({
    boardList: [],
  });
  const [boardsName, setBoardsName] = useState();
  const [graphIndex, setGraphIndex] = useState(0);
  const [showGraph, setShowGraph] = useState(false);
  const [metricData, setMetricData] = useState();
  const [metricDataList, setMetricDataList] = useState([]);
  const [graphData, setGraphData] = useState([]);
  const [sprintBarGraphData, setSprintBarGraphData] = useState({});
  const [metricBarData, setMetricBarData] = useState([]);
  const [searchJiraIssue, setSearchJiraIssue] = useState([]);
  const [jiraQualityData, setJiraQualityData] = useState([]);
  const [jiraDyanamicData, setJiraDyanamicData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [graphLoader, setgraphLoader] = useState(true);
  const [tableLoader, setTableLoader] = useState(false);
  const [errorMassege, setErrorMassege] = useState("");
  const [keysOfObject, setKeysOfObject] = useState([]);
  const [sprintData, setSprintData] = useState([]);
  const [messageNoData, setMessageNoData] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(4);
  const [sprintID, setSprintID] = useState();
  const lastPostIndex = currentPage * postsPerPage;
  const firstPostIndex = lastPostIndex - postsPerPage;
  const [openRange, setOpenRange] = useState(false);
  const [showSprintLoader, setshowSprintLoader] = useState(false);
  const [dashBoardgraphData, setDashBoardgraphData] = useState([]);
  let clientId = localStorage.getItem("ClientId");
  const [selectedOption, setSelectedOption] = useState(7);
  const [sprintSpilloverList, setSprintSpilloverList] = useState([]);
  const [isSprintSpillover, setIsSprintSpillover] = useState(false);
  const [sprintSpilloverColumns, setSprintSpilloverColumns] = useState([]);
  const [boardList, setBoardList] = useState([]);
  const optionsArray = [
    { name: "Daily", value: 1 },
    { name: "Weekly", value: 7 },
    { name: "Biweekly", value: 14 },
    { name: "Monthly", value: 30 },
    { name: "Quarterly", value: 90 },
  ];
  const handleSelect = (event) => {
    setPostsPerPage(event.target.value);
  };
  const [dataItem, setDataItem] = useState("Work Commitment");

  const {
    isPlanUpgradeRequest,
    setIsPlanUpgradeRequest,
    projectRangePickerValue: rangePickerValue,
    setProjectRangePickerValue: setRangePickerValue,
  } = useContext(GlobalData);
  const dateRangeMap = {
    "Last 180 Days": 180,
    "Last 90 Days": 90,
    "Last 60 Days": 60,
    "Last 30 Days": 30,
    "Last 20 Days": 20,
    "Last 15 Days": 15,
    "Last 7 Days": 7,
    "Next 7 Days": 7,
    "Next 15 Days": 15,
    "Next 30 Days": 30,
    "Next 60 Days": 60,
    "Next 90 Days": 90,
  };
  const dateConvert = (dateObj, isAddDate) => {
    let addDate = dateObj;
    if (isAddDate) {
      addDate = new Date(dateObj);
      addDate.setDate(addDate.getDate() + 1);
    }

    // const month = addDate.getMonth() + 1; //months from 1-12
    const month = ("0" + (addDate?.getMonth() + 1)).slice(-2); //months from 01-12

    const day = addDate?.getDate();
    const year = addDate?.getFullYear();

    return year + "-" + month + "-" + day;
  };

  const riskColor = [
    {
      3: "#E65C5C",
      2: "#ECCB47",
      1: "#209728",
    },
  ];
  const riskName = [
    {
      3: "High",
      2: "Medium",
      1: "Low",
    },
  ];
  useEffect(() => {
    // if (clientId === null) return;
    setLoader(true);
    //project,board name api
    axios
      .get(`/api/sprint/bargraph/getProjects/${clientId}`)
      .then((response) => {
        if (!response.data) return;
        let data = response.data?.map((e) => {
          return {
            id: e["ProjectId"],
            name: e["ProjectName"],
            boardList: e.Boards?.map((data) => {
              return { id: data["board_id"], name: data["board_name"] };
            }),
          };
        });
        setLoader(false);
        setProjectList(data);
        setSelectedProjectName(data[0]);
        setBoardsName(data[0]?.boardList[0]);
        setBoardList(data[0]?.boardList);
      })
      .catch((err) => {
        setLoader(false);
        console.log(err);
      });
    // metric drop down api
    axios
      .get(`/api/sprint/bargraph/getMetric/${clientId}`)
      .then((response) => {
        if (response.data.length === 0) return;
        let data = response["data"].map((e) => {
          //  let cap = Capitalize(e?.Metric);
          return {
            id: e?.Id,
            name: e?.DisplayName,
            yaxes: e?.Unit,
            metricName: e?.Metric,
          };
        });

        setMetricDataList(data);
        setMetricData(data[graphIndex]);
        setLoader(false);
      })
      .catch((err) => {
        console.log(err);
        setLoader(false);
      });
  }, [clientId, graphIndex]);
  useEffect(() => {
    if (!boardsName?.id) return;
    if (graphLoader) {
      setLoader(false);
      setgraphLoader(true);
      setJiraDyanamicData([]);
      setSearchJiraIssue([]);
      setKeysOfObject([]);
      //cluster coloumn graph data http://3.209.189.59

      setErrorMassege("");
      if (rangePickerValue.isCustom) {
        axios
          .get(
            `/api/v2/sprints/project/dashboard/?iteration=${selectedOption}&no_of_days=${
              dateRangeMap[rangePickerValue.range]
            }&startDay=${dateConvert(
              rangePickerValue.startDate
            )}&endDay=${dateConvert(rangePickerValue.endDate)}&project_id=${
              selectedProjectName?.id
            }&board_id=${boardsName?.id}&metric_id=${metricData?.id}`
          )
          .then((response) => {
            const roundValue = (data) => {
              const roundedData = data.map((item) => ({
                ...item,
                metrcis: item.metrcis.map((metric) => ({
                  ...metric,
                  Value:
                    metric.Value >= 0
                      ? parseFloat(metric.Value?.toFixed(3))
                      : "NA",
                })),
              }));
              return roundedData;
            };
            const roundedData = roundValue(response.data);

            setSprintData(roundedData);

            setMetricBarData([]);
            setshowSprintLoader(true);
          })
          .catch((err) => {
            console.log(err);
          });
      } else if (rangePickerValue.range.includes("Next")) {
        const lastDate = new Date();
        lastDate.setDate(
          lastDate.getDate() + (dateRangeMap[rangePickerValue.range] - 1)
        );
        const nextDate = new Date();
        nextDate.setDate(nextDate.getDate());
        axios
          .get(
            `/api/v2/sprints/project/dashboard/?iteration=${selectedOption}&no_of_days=${
              dateRangeMap[rangePickerValue.range]
            }&startDay=${dateConvert(nextDate)}&endDay=${dateConvert(
              rangePickerValue.endDate
            )}&project_id=${selectedProjectName?.id}&board_id=${
              boardsName?.id
            }&metric_id=${metricData?.id}`
          )
          .then((response) => {
            const roundValue = (data) => {
              const roundedData = data.map((item) => ({
                ...item,
                metrcis: item.metrcis.map((metric) => ({
                  ...metric,
                  Value:
                    metric.Value >= 0
                      ? parseFloat(metric.Value?.toFixed(3))
                      : "NA",
                })),
              }));
              return roundedData;
            };
            const roundedData = roundValue(response.data);

            setSprintData(roundedData);

            setMetricBarData([]);
            setshowSprintLoader(true);
          })
          .catch((err) => {
            console.log(err);
          });
      }
      if (!rangePickerValue.isCustom) {
        const lastDate = new Date();
        lastDate.setDate(lastDate.getDate() - 1);

        const nextDate = new Date();
        nextDate.setDate(
          nextDate.getDate() - dateRangeMap[rangePickerValue.range]
        );
        axios
          .get(
            `/api/v2/sprints/project/dashboard/?iteration=${selectedOption}&no_of_days=${
              dateRangeMap[rangePickerValue.range]
            }&startDay=${dateConvert(nextDate)}&endDay=${dateConvert(
              lastDate
            )}&project_id=${selectedProjectName?.id}&board_id=${
              boardsName?.id
            }&metric_id=${metricData?.id}`
          )
          .then((response) => {
            const roundValue = (data) => {
              const roundedData = data.map((item) => ({
                ...item,
                metrcis: item.metrcis.map((metric) => ({
                  ...metric,
                  Value:
                    metric.Value >= 0
                      ? parseFloat(metric.Value?.toFixed(3))
                      : "NA",
                })),
              }));
              return roundedData;
            };
            const roundedData = roundValue(response.data);

            setSprintData(roundedData);
            setMetricBarData([]);
            setshowSprintLoader(true);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
  }, [
    selectedProjectName,
    boardsName,
    metricData,
    graphLoader,
    rangePickerValue,
    selectedOption,
  ]);

  const viewGraph = (rowData) => {
    setSprintID(rowData);
    setShowGraph(true);
    axios
      .get(
        `/api/v2/sprints/project/dashboard/details?board_id=${boardsName?.id}&sprint_id=${rowData.Id}`
      )
      .then((response) => {
        const roundValueToThreeDecimalPlaces = (data) => {
          const roundedData = data.map((metricGroup) => {
            const roundedMetrics = {};
            for (const [metricName, metrics] of Object.entries(metricGroup)) {
              roundedMetrics[metricName] = metrics.map((metric) => ({
                ...metric,
                Value:
                  metric.Value >= 0
                    ? parseFloat(metric.Value?.toFixed(3))
                    : "NA",
              }));
            }
            return roundedMetrics;
          });
          return roundedData;
        };

        const roundedData = roundValueToThreeDecimalPlaces(response.data);
        setDashBoardgraphData(roundedData);
        setshowSprintLoader(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const SprintTitleDate = (urlData) => {
    setLoader(true);
    setMetricBarData([]);
    let url = `/api/sprint/bargraph/getSprint?project_id=${selectedProjectName?.id}&board_id=${boardsName?.id}&sprint_id=${sprintId}`;
    axios
      .get(url)
      .then((response) => {
        setLoader(false);
        setSprintBarGraphData(response?.data);
      })
      .catch((err) => {
        console.log(err);
        setLoader(false);
      });
  };
  const BackToGraph = (urlData) => {
    setLoader(false);
    setgraphLoader(true);
  };

  const JiraTableData = (urlData) => {
    setJiraDyanamicData([]);
    setLoader(true);
    // const currentPosts = coinsData;
    setMetricBarData([]);
    setSearchJiraIssue([]);
    FilterKeys([]);
    let url = `/api/sprint/bargraph/view/details?project_id=${selectedProjectName?.id}&board_id=${boardsName?.id}&sprint_id=${sprintId}&metric_id=${getMatricId}`;
    axios
      .get(url)
      .then((response) => {
        setLoader(false);
        setJiraDyanamicData(response?.data?.data);
      })
      .catch((err) => {
        setErrorMassege(err?.response?.data?.error);
        console.log(err);
        setLoader(false);
      });
    setgraphLoader(false);
  };
  const FilterKeys = (props) => {
    if (!props || !Array.isArray(props) || props.length == 0) return;
    let header = [];
    let key = Object.keys(props[0]);
    if (!key) return;
    key?.map((item) => {
      if (item.includes("_")) {
        let toBeRemove = "_";
        header.push({ title: item.replace(toBeRemove, ""), keyItem: item });
      } else {
        header.push({
          title: item.split(/(?=[A-Z])/).join(" "),
          keyItem: item,
          toggle: false,
        });
      }
    });
    console.log(props[0], "props[0]props[0]props[0]");
    setKeysOfObject(header);
  };

  const [metricName, setMatricName] = useState(metricData?.metricName);
  const customRangePickerHandler = (val) => {
    setRangePickerValue(val);
  };
  useEffect(() => {
    setJiraQualityData([]);
    setMetricBarData([]);
    setSearchJiraIssue([]);
    FilterKeys([]);

    let tempData = jiraDyanamicData;
    if (
      metricData?.metricName === "TICKET_QUALITY" ||
      metricData?.metricName === "WORK_COMMITMENT" ||
      metricData?.metricName === "SPRINT_COMPLETION_RATE"
    ) {
      setMetricBarData(tempData); //search functionality
      setSearchJiraIssue(tempData);
      setJiraQualityData(tempData);
      FilterKeys(tempData);
    } else if (
      metricData?.metricName === "CYCLE_TIME" ||
      metricData?.metricName === "DEFECT_CYCLE_TIME"
    ) {
      let avgLeadTime = tempData?.result2?.map((item) => {
        var date1 = new Date(`${item?.CreatedDate}`);
        var date2 = new Date(`${item?.ResolutionDate}`);
        var diff = (date2 - date1) / 1000;
        diff = Math.abs(Math.floor(diff));
        var days = Math.floor(diff / (24 * 60 * 60));
        return {
          // key: item["Key"],
          IssueType: item?.IssueType?.name || "No Issue Type",
          CreatedDate: item?.CreatedDate || "No Date",
          ResolutionDate: item?.ResolutionDate || "No Date",
          LeadTime: days || "0",
          Status: item?.Status?.name || "No Status",
          EpicName: item?.Epic?.name || "No epic linked",
          EpicKey: item?.Epic?.key || "No epic Linked",
          Expand: item?.ChangeLog,
        };
      });

      setMetricBarData(avgLeadTime); //search functionality
      setJiraQualityData(tempData);
      setSearchJiraIssue(avgLeadTime);
      FilterKeys(avgLeadTime);
    } else {
      setMetricBarData(tempData); //search functionality
      setSearchJiraIssue(tempData);
      setJiraQualityData(tempData);
      FilterKeys(tempData);
    }
    setMatricName(metricData?.metricName);
  }, [jiraDyanamicData, metricData?.metricName, metricName]);

  const metricValue = (value) => {
    if (value?.boardList?.length > 0) {
      setBoardList(value?.boardList);
      setBoardsName(value?.boardList[0]);
    }
  };

  // search functionality
  const [searchInput, setSearchInput] = useState("");
  const handleChangeSearchBar = (e) => {
    e.preventDefault();
    setSearchInput(e.target.value);
    let val = metricBarData.filter((title) => {
      return (
        title?.fields?.summary
          .toLowerCase()
          .includes(e.target.value.toLowerCase()) ||
        title?.key.toLowerCase().includes(e.target.value.toLowerCase())
      );
    });
    if (e?.target?.value?.length > 0) {
      setSearchJiraIssue(val);
    } else {
      setSearchJiraIssue(metricBarData);
    }
  };

  let pricePlan = JSON.parse(localStorage.getItem("pricePlan"));
  let featureList = pricePlan?.pricingPlan?.featureList?.jiraTab;

  const planRequestHandler = () => {
    setIsPlanUpgradeRequest(!isPlanUpgradeRequest);
  };

  const handleChange = (event) => {
    setSelectedOption(event.target.value);
  };
  const user = JSON.parse(localStorage.getItem("user")).name;

  useEffect(() => {
    if (isSprintSpillover === true) {
      axios
        .get(`/api/v2/sprints/issueLevelSpillover/${sprintID.Id}`)
        .then((res) => {
          setSprintSpilloverList(res.data);
          setSprintSpilloverColumns([
            {
              field: "Assignee",
              title: "Assignee",
              width: "150px",
            },
            {
              field: "TaskId",
              title: "TaskId",
              width: "150px",
              render: (rowData) => (
                <a href={rowData.TaskLink}>{rowData.TaskId}</a>
              ),
            },
            {
              field: "TaskDetails",
              title: "Task Details",
              width: "150px",
            },
            {
              field: "TaskStatus",
              title: "Task Status",
              width: "150px",
            },
            {
              field: "CreatedTime",
              title: "Created Time",
              width: "150px",
            },
          ]);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [isSprintSpillover]);

  console.log(searchJiraIssue, "searchJiraIssue");
  return (
    <>
      {featureList === true ? (
        <>
          {!showGraph && (
            <>
              <div className="row w-100 p-0">
                <BreadCrumb list={[]} active={"Sprint"} />
              </div>
              <div className={classes.fl}>
                <div className={classes.tp}>
                  <SelectField
                    input={
                      <Button
                        onClick={() => setOpenRange(true)}
                        style={{
                          border: "1px solid #b7b7b7",
                          height: "230px",
                          borderRadius: "30px",
                          textTransform: "capitalize",
                          padding: "5px",
                          fontSize: "16px",
                          fontWeight: 400,
                          paddingLeft: "14px ",
                          paddingRight: "11px",
                          justifyContent: "space-between",
                        }}
                        endIcon={<DateRangeOutlinedIcon />}
                      >
                        {getSelectedDate(rangePickerValue)}
                      </Button>
                    }
                    onClick={() => setOpenRange(true)}
                  />
                  <RangeSelector
                    open={openRange}
                    setOpen={setOpenRange}
                    pickerHandler={customRangePickerHandler}
                    value={rangePickerValue}
                  />
                </div>
                <div className={classes.tp}>
                  <AutoComp
                    data={projectList}
                    // labelName={"Project Names"}
                    setValue={setSelectedProjectName}
                    value={selectedProjectName}
                    onChange={metricValue}
                  />
                </div>
                <div className={classes.tp}>
                  <AutoComp
                    data={boardList || []}
                    // labelName={"Project Names"}
                    setValue={setBoardsName}
                    value={boardsName}
                    // onChange={metricValue}
                  />
                </div>
                {/* <div className={classes.tp}>
                  <FormControl
                    fullWidth
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <InputLabel id="select-label">Iteration</InputLabel>
                    <Select
                      labelId="select-label"
                      id="select"
                      value={selectedOption}
                      onChange={handleChange}
                      label="Select an option"
                    >
                      {optionsArray.map((option) => (
                        <MenuItem key={option.name} value={option.value}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div> */}
              </div>
            </>
          )}
          <div>
            {boardsName ? (
              <>
                {
                  <>
                    {!showGraph ? (
                      <TableContainer component={Paper}>
                        <Table
                          sx={{ minWidth: 700, borderRadius: "10px" }}
                          aria-label="customized table"
                        >
                          <TableHead>
                            <TableRow>
                              <StyledTableCell align="center">
                                Sprint
                              </StyledTableCell>
                              {sprintData[0]?.metrcis?.map((e) => (
                                <StyledTableCell align="center">
                                  {e.DisplayName}
                                </StyledTableCell>
                              ))}
                              <StyledTableCell align="center">
                                Overall Risk
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                Action
                              </StyledTableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {sprintData?.map((row) => (
                              <StyledTableRow key={row.SprintName}>
                                {row.SprintName && (
                                  <StyledTableCell
                                    component="th"
                                    scope="row"
                                    align="center"
                                  >
                                    {row.SprintName}
                                  </StyledTableCell>
                                )}
                                {row?.metrcis?.map((e) => (
                                  <StyledTableCell align="center">
                                    {e?.Value}
                                  </StyledTableCell>
                                ))}
                                {row.riskLevel && (
                                  <StyledTableCell
                                    component="th"
                                    scope="row"
                                    align="center"
                                    style={{
                                      color: riskColor[0][row?.riskLevel],
                                    }}
                                  >
                                    {riskName[0][row?.riskLevel]}
                                  </StyledTableCell>
                                )}
                                {row.SprintName && (
                                  <StyledTableCell
                                    align="center"
                                    onClick={() => {
                                      viewGraph(row);
                                    }}
                                    style={{
                                      color: "#5EBDCC",
                                      cursor: "pointer",
                                    }}
                                  >
                                    View More
                                  </StyledTableCell>
                                )}
                              </StyledTableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    ) : (
                      <>
                        {showSprintLoader && (
                          <>
                            <p
                              style={{
                                fontSize: "24px",
                              }}
                            >
                              <i
                                className="fa fa-arrow-left"
                                style={{
                                  fontSize: "24px",
                                  cursor: "pointer",
                                }}
                                onClick={() => setShowGraph(false)}
                              >
                                {" "}
                                <span
                                  style={{
                                    fontSize: "24px",
                                    fontFamily: "Lexend Deca",
                                  }}
                                >
                                  Sprint
                                </span>
                              </i>{" "}
                              <i
                                className="fa fa-angle-right"
                                style={{
                                  fontSize: "24px",
                                }}
                              >
                                {" "}
                                <span
                                  style={{
                                    fontSize: "24px",
                                    fontFamily: "Lexend Deca",
                                  }}
                                >
                                  {sprintID.SprintName}
                                </span>
                              </i>
                            </p>
                            <SprintPieCharts
                              dashBoardgraphData={dashBoardgraphData}
                              sprintTitleDate={SprintTitleDate}
                              jiraTableData={JiraTableData}
                              setSprintId={setSprintId}
                              selectedSprintId={sprintID.Id}
                              sprintId={sprintId}
                              setMatricId={setMatricId}
                              setDataItem={setDataItem}
                              dataItem={dataItem}
                              setGraphIndex={setGraphIndex}
                              setIsSprintSpillover={setIsSprintSpillover}
                            />
                          </>
                        )}
                      </>
                    )}
                  </>
                }
              </>
            ) : (
              <div className={classes.errMsssg}>
                {/* <label>NO DATA FOUND</label> */}
                <PageNotFound />
              </div>
            )}
            <div>
              {loader ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-around",
                    height: "100px",
                  }}
                >
                  <CircularProgress />
                </div>
              ) : (
                ""
              )}
            </div>
            {errorMassege?.length !== 0 && (
              <div className={classes.errMsssg}>
                <label>{errorMassege}</label>
              </div>
            )}
            <>
              {isSprintSpillover === true ? (
                <>
                  <CommonTable
                    columns={sprintSpilloverColumns}
                    data={sprintSpilloverList}
                  />
                </>
              ) : (
                <>
                  {metricBarData.length > 0 && showGraph && (
                    <Card>
                      <div style={{ fontSize: "14px", padding: "10px 20px" }}>
                        <h3 style={{ cursor: "pointer" }}>
                          <span
                            style={{
                              background: "rgb(164 234 246)",
                              fontSize: "15px",
                              padding: "15px",
                              borderRadius: "40px",
                            }}
                          >
                            {metricData?.metricName}
                          </span>
                        </h3>

                        <div className={classes.mainhead}>
                          <div>
                            <label>Sprint Name : </label>
                            <span className="mx-2">
                              {sprintBarGraphData?.Name}
                            </span>
                          </div>
                          <div>
                            <label>Project Name : </label>
                            <span className="mx-2">
                              {sprintBarGraphData?.project?.displayName}
                            </span>
                          </div>
                          <div>
                            <label>State : </label>
                            <span className="mx-2">
                              {sprintBarGraphData?.State}
                            </span>
                          </div>
                          <div>
                            <b>Start Date : </b>
                            {sprintBarGraphData?.startDate && (
                              <label>
                                {format(
                                  new Date(sprintBarGraphData?.startDate),
                                  "dd-MMM-yyy"
                                )}
                              </label>
                            )}
                          </div>
                          <div>
                            <b>End Date : </b>{" "}
                            {sprintBarGraphData?.endDate && (
                              <label>
                                {format(
                                  new Date(sprintBarGraphData?.endDate),
                                  "dd-MMM-yyy"
                                )}
                              </label>
                            )}
                          </div>
                        </div>
                      </div>

                      <div>
                        {metricData?.metricName === "WORK_COMMITMENT" ||
                        metricData?.metricName === "SPRINT_COMPLETION_RATE" ? (
                          <JiraTable
                            metricBarData={metricBarData?.slice(
                              firstPostIndex,
                              lastPostIndex
                            )}
                            searchJiraIssue={searchJiraIssue?.slice(
                              firstPostIndex,
                              lastPostIndex
                            )}
                          />
                        ) : metricData?.metricName === "CYCLE_TIME" ||
                          metricData?.metricName === "DEFECT_CYCLE_TIME" ? (
                          <ExpandJiraDynamicTable
                            searchJiraIssue={searchJiraIssue?.slice(
                              firstPostIndex,
                              lastPostIndex
                            )}
                            keysOfObject={keysOfObject}
                          />
                        ) : metricData?.metricName === "TICKET_QUALITY" ? (
                          <JiraDynamicTable
                            searchJiraIssue={jiraQualityData?.slice(
                              firstPostIndex,
                              lastPostIndex
                            )}
                            keysOfObject={keysOfObject}
                            name={"jira"}
                          />
                        ) : (
                          <></>
                        )}
                        <div className="row p-3">
                          <div className="col-3 d-flex justify-content-start align-items-center">
                            {"Show  "}
                            <select
                              value={postsPerPage}
                              onChange={handleSelect}
                              className="mx-2"
                            >
                              {/* <option value="">Select an option</option> */}
                              <option value={4}>4</option>
                              <option value={8}>8</option>
                              <option value={15}>15</option>
                            </select>{" "}
                            {"  per page"}
                          </div>
                          <div className="col-9 d-flex justify-content-end align-items-center">
                            <PaginationTable
                              totalPosts={jiraDyanamicData?.length}
                              postsPerPage={postsPerPage}
                              setCurrentPage={setCurrentPage}
                              currentPage={currentPage}
                            />
                          </div>
                        </div>
                      </div>
                    </Card>
                  )}
                </>
              )}
            </>
          </div>
        </>
      ) : (
        <>
          <div className={`${classes.feature}`}>
            <h4>You dont have access. Please upgrade your plan.</h4>
          </div>
          <div className={`${classes.featureBtn}`}>
            <Button
              variant="contained"
              className={classes.btn}
              type="button"
              onClick={() => {
                planRequestHandler();
              }}
            >
              Upgrade
            </Button>
          </div>
          {isPlanUpgradeRequest && <UpgradePlanRequest />}
        </>
      )}

      {/* <SprintBarCharts dashBoardgraphData={dashBoardgraphData}/> */}
    </>
  );
};
