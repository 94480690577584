import React, { useContext, useState } from "react";
import RiskWrapper, {
  RiskColorWrapperWithIcon,
} from "../../../components/overview/riskWrapper";
import { Box, Tooltip } from "@material-ui/core";
import UtilizationPerformance from "../../../components/utilizationVsPerformance";
import InfoIcon from "@material-ui/icons/Info";
import { GlobalData } from "../../../context/globalData";

function CardDetail({
  classes,
  img,
  value,
  name,
  icon,
  children,
  risk,
  status,
  viewMore,
  teamId,
  define = false,
}) {
  const { setIsDefinaionPopup, setDefinationKPI } = useContext(GlobalData);

  return (
    <div className={classes.cardContaner}>
      <div className={classes.icon}>
        {img && <img src={img} alt="img" />}
        {icon && (
          <i
            className={icon}
            style={{
              fontSize: "20px",
              color: "green",
            }}
          ></i>
        )}
      </div>
      <div className={classes.textContianer}>
        <h3 style={{ fontSize: "24px" }}>{value}</h3>
        <h6>
          {name}
          {define && (
            <span
              onClick={() => {
                setIsDefinaionPopup(true);
                setDefinationKPI(name);
              }}
            >
              <InfoIcon style={{ color: "gray", marginLeft: "8px" }} />
            </span>
          )}
        </h6>
        {risk && (
          <Box display={"flex"}>
            Risk : <RiskWrapper>{risk}</RiskWrapper>
          </Box>
        )}
        {status && (
          <Tooltip
            title={
              <>
                <h6>
                  Status: {status?.risk}{" "}
                  {name === "Capacity Accuracy" ? "" : "Risk"}
                </h6>
                <h6>
                  Target: {status?.from}% to {status?.to}%
                </h6>
              </>
            }
          >
            <div className="metricStatus">
              <RiskColorWrapperWithIcon
                className="metricChips"
                riskLevel={status?.risk}
              ></RiskColorWrapperWithIcon>
            </div>
          </Tooltip>
        )}
      </div>
    </div>
  );
}

export default CardDetail;
