import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import ZoomOutMapIcon from "@material-ui/icons/ZoomOutMap";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  header: {
    backgroundColor: "#F9F9F9",  color: "black",
    borderTopLeftRadius: "10px",
    borderTopRightRadius: "10px",
    padding: "10px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  headerText: {
    color: "black",
    fontWeight: "bold",
    fontSize: "14px",
  },
  icons: {
    color: "black",
  },
}));
export default function PaperHeader(props) {
  const classes = useStyles();
  const { popup, download  } = props;
  return (
    <div className={classes.header}>
      <Typography variant="subtitle1" noWrap className={classes.headerText}>
        {props.name}
      </Typography>
      <div className={classes.iconsGrid}>
        <IconButton size="small" onClick={() => download(props.selectedValue)}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            enableBackground="new 0 0 24 24"
            height="24px"
            viewBox="0 0 24 24"
            width="24px"
            fill="black"
          >
            <g>
              <rect fill="none" height="24" width="24" />
            </g>
            <g>
              <path d="M18,15v3H6v-3H4v3c0,1.1,0.9,2,2,2h12c1.1,0,2-0.9,2-2v-3H18z M17,11l-1.41-1.41L13,12.17V4h-2v8.17L8.41,9.59L7,11l5,5 L17,11z" />
            </g>
          </svg>
        </IconButton>
        <IconButton size="small" onClick={popup}>
          <ZoomOutMapIcon className={classes.icons} />
        </IconButton>
      </div>
    </div>
  );
}
