import React, { useEffect } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import { config } from "../../Config/permission";

const StackedColumnChart = ({
  graphData,
  height = "600px",
  graphType,
  yAxesLabel = "",
  unit = "",
}) => {
  useEffect(() => {
    if (!graphData) return;

    var chart = am4core.create("chartdiv", am4charts.XYChart);
    chart.hiddenState.properties.opacity = 0;
    chart.colors.step = 2;
    chart.padding(30, 30, 10, 30);
    chart.legend = new am4charts.Legend();

    var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "category";
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.title.text = "Date";

    var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.min = 0;
    valueAxis.max = 100;
    valueAxis.strictMinMax = true;
    valueAxis.calculateTotals = true;
    valueAxis.renderer.minWidth = 50;
    valueAxis.title.text = yAxesLabel
      ? yAxesLabel
      : graphType == "workday"
      ? "Time (%)"
      : config.type;

    const createSeries = (e) => {
      var series = chart.series.push(new am4charts.ColumnSeries());
      series.columns.template.width = am4core.percent(80);
      if (graphType == "workday") {
        series.columns.template.tooltipText =
          "{name}: {valueY.totalPercent.formatNumber('#.')}%";
      } else {
        series.columns.template.tooltipText =
          unit === "$" ? `{name}: $ {valueY}` : "{name}: {valueY} " + unit;
      }
      // series.columns.template.tooltipText =
      // "{name}: {valueY.totalPercent.formatNumber('#.00')}%";
      series.name = e;
      series.dataFields.categoryX = "category";
      series.dataFields.valueY = e;
      series.dataFields.valueYShow = "totalPercent";
      series.dataItems.template.locations.categoryX = 0.5;
      series.stacked = true;
      series.tooltip.pointerOrientation = "vertical";

      var bullet1 = series.bullets.push(new am4charts.LabelBullet());
      bullet1.interactionsEnabled = false;
      bullet1.label.text = "{valueY.totalPercent.formatNumber('#.')}%";
      bullet1.label.fontSize = 11;
      bullet1.label.fill = am4core.color("#ffffff");
      bullet1.locationY = 0.5;
    };
    if (graphData.length > 0) {
      Object.keys(graphData[0])?.forEach((e) => {
        if (e !== "category") {
          createSeries(e);
        }
      });
    }

    chart.data = graphData;

    chart.scrollbarX = new am4core.Scrollbar();
    // chart.scrollbarX.start = 0; // Sets the start position to 20%
    // chart.scrollbarX.end = 0.6;   // Sets the end position to 80%
  }, [graphData]);
  return <div id="chartdiv" style={{ width: "100%", height: height }} />;
};

export default StackedColumnChart;
