import { Route, Switch, Redirect } from 'react-router-dom'
import EmailVerification from './EmailVerification';
import Signin from "./signin";
import SignUp from './SignUp';
import ForgetPassword from './ForgetPassword';
import ResetPassword from './ResetPassword';
export default function Auth(props) {
  return (
    <div>
      <Switch>
        <Route path="/login" component={Signin} />
        <Route path="/signUp" component={SignUp} />
        <Route path="/verifyemail/:id" component={EmailVerification} />
        <Route path="/forgetPassword" component={ForgetPassword} />
        <Route path="/resetPassword/:id" component={ResetPassword} />
        <Redirect to="/login"/>
      </Switch>
    </div>
  );
}
