import { Box, Button, ButtonGroup, Card, Grid } from "@mui/material";
import React from "react";
import IssueTypeAllocationTrend from "./IssueTypeAllocationTrend";
import NormalSelect from "../../components/select/normalSelect";
import WorkAllowTrend from "./workAlloTrend";

const WorkAllocationTrend = ({
  data,
  setSelectedIssue,
  selectedIssue,
  selectedIteration,
  setSelectedIteration,
  iteration,
  workAllocationTrendData,
  date,
}) => {
  const normalSelectDrop = {
    width: "200px",
    height: "40px",
  };

  return (
    <div>
      <Grid container columnSpacing={2}>
        <Grid item xs={4}>
          <Card>
            <h5 className="m-3">Allocation By Issue Type</h5>
            <IssueTypeAllocationTrend graphData={data} height={"400px"} />
          </Card>
        </Grid>
        <Grid item xs={8} className="position-relative">
          <Card sx={{ height: "455px", padding: "20px" }}>
            <Box
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <div>
                <NormalSelect
                  option={[
                    { label: "Epic", value: "Epic" },
                    { label: "Project", value: "Project" },
                  ]}
                  value={selectedIssue}
                  handleChange={(value) => setSelectedIssue(value)}
                  style={normalSelectDrop}
                />
              </div>
              <ButtonGroup
                variant="outlined"
                aria-label="Basic button group"
                size="small"
              >
                {iteration?.map((item, index) => (
                  <Button
                    variant={
                      selectedIteration === item?.value
                        ? "contained"
                        : "outlined"
                    }
                    onClick={() => setSelectedIteration(item?.value)}
                    key={index}
                  >
                    {item?.key}
                  </Button>
                ))}
              </ButtonGroup>
            </Box>
            <WorkAllowTrend
              TrendData={workAllocationTrendData}
              yLabel={`${selectedIssue} (%)`}
              selectedIssue={selectedIssue}
              selectedIteration={selectedIteration}
            />
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

export default WorkAllocationTrend;
