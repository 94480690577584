const dateRangeMap = {
  "Last 180 Days": 180,
  "Last 90 Days": 90,
  "Last 60 Days": 60,
  "Last 30 Days": 30,
  "Last 20 Days": 20,
  "Last 15 Days": 15,
  "Last 7 Days": 7,
  "Next 7 Days": 7,
  "Next 15 Days": 15,
  "Next 30 Days": 30,
  "Next 60 Days": 60,
  "Next 90 Days": 90,
};

const RisksMap = {
  "Low Risk": 1,
  "Medium Risk": 2,
  "High Risk": 3,
};

const RiskApiMap = {
  "Metric Details": "risk",
  "Metric Summary": "metric-summary",
};

const ProjectMap = {
  "Metric Details": "detail",
  "Metric Summary": "summary-detail",
};

export const dateConvert = (dateObj, isAddDate) => {
  let addDate = dateObj;
  if (isAddDate) {
    addDate = new Date(dateObj);
    addDate.setDate(addDate.getDate() + 1);
  }

  // const month = addDate.getMonth() + 1; //months from 1-12
  const month = ("0" + (addDate.getMonth() + 1)).slice(-2); //months from 01-12

  const day = addDate.getDate();
  const year = addDate.getFullYear();

  return year + "-" + month + "-" + day;
};

export const API_TREND_GEN = (
  graphTrendApi,
  rangePickerValue,
  group_id,
  projectId,
  teamId,
  iterationValue
) => {
  const project = projectId ? `&project_id=${projectId}` : "";
  const team = teamId ? `&team_id=${teamId}` : "";
  if (rangePickerValue.isCustom) {
    return `/api/project/metric-trend?metrics_id=${graphTrendApi}&no_of_days=${
      rangePickerValue.range + 1
    }&group_id=${group_id}${project}${team}&startDay=${dateConvert(
      rangePickerValue.startDate
    )}&endDay=${dateConvert(
      rangePickerValue.endDate
    )}&iteration=${iterationValue}`;
  } else if (rangePickerValue.range.includes("Next")) {
    const lastDate = new Date();
    lastDate.setDate(
      lastDate.getDate() + (dateRangeMap[rangePickerValue.range] - 1)
    );
    const nextDate = new Date();
    // nextDate.setDate(nextDate.getDate() + 1);
    return `/api/project/metric-trend?metrics_id=${graphTrendApi}&no_of_days=${
      dateRangeMap[rangePickerValue.range]
    }&group_id=${group_id}${project}${team}&startDay=${dateConvert(
      nextDate
    )}&endDay=${dateConvert(lastDate)}&iteration=${iterationValue}`;
  }
  const lastDate = new Date();
  lastDate.setDate(lastDate.getDate() - 1);

  const nextDate = new Date();
  nextDate.setDate(nextDate.getDate() - dateRangeMap[rangePickerValue.range]);

  return `/api/project/metric-trend?metrics_id=${graphTrendApi}&no_of_days=${
    dateRangeMap[rangePickerValue.range]
  }&group_id=${group_id}${project}${team}&startDay=${dateConvert(
    nextDate
  )}&endDay=${dateConvert(lastDate)}&iteration=${iterationValue}`;
};

export const API_GRID_GEN = (
  rangePickerValue,
  riskValue,
  group_id,
  projectId,
  teamId,
  metricValue,
  iterationValue
) => {
  const risk = RisksMap[riskValue] ? `&risk_level=${RisksMap[riskValue]}` : "";
  const project = projectId ? `&project_id=${projectId}` : "";
  const team = teamId ? `&team_id=${teamId}` : "";

  if (rangePickerValue.isCustom) {
    return `/api/project/${
      ProjectMap[metricValue || "Metric Details"]
    }?no_of_days=${
      rangePickerValue.range + 1
    }&group_id=${group_id}${risk}${project}${team}&startDay=${dateConvert(
      rangePickerValue.startDate
    )}&endDay=${dateConvert(
      rangePickerValue.endDate
    )}&iteration=${iterationValue}`;
  } else if (rangePickerValue.range.includes("Next")) {
    const lastDate = new Date();
    lastDate.setDate(
      lastDate.getDate() + (dateRangeMap[rangePickerValue.range] - 1)
    );
    const nextDate = new Date();
    nextDate.setDate(nextDate.getDate());
    return `/api/project/${
      ProjectMap[metricValue || "Metric Details"]
    }?no_of_days=${
      dateRangeMap[rangePickerValue.range]
    }&group_id=${group_id}${risk}${project}${team}&startDay=${dateConvert(
      nextDate
    )}&endDay=${dateConvert(lastDate)}&iteration=${iterationValue}`;
  }
  const lastDate = new Date();
  lastDate.setDate(lastDate.getDate() - 1);

  const nextDate = new Date();
  nextDate.setDate(nextDate.getDate() - dateRangeMap[rangePickerValue.range]);

  return `/api/project/${
    ProjectMap[metricValue || "Metric Details"]
  }?no_of_days=${
    dateRangeMap[rangePickerValue.range]
  }&group_id=${group_id}${risk}${project}${team}&startDay=${dateConvert(
    nextDate
  )}&endDay=${dateConvert(lastDate)}&iteration=${iterationValue}`;
};

export const API_RISK_GRID_GEN = (
  rangePickerValue,
  riskValue,
  group_id,
  projectId,
  metricValue,
  iterationValueGlobal
) => {
  const risk = RisksMap[riskValue] ? `&risk_level=${RisksMap[riskValue]}` : "";
  const project = projectId ? `&project_id=${projectId}` : "";

  if (rangePickerValue.isCustom) {
    return `/api/project/${
      RiskApiMap[metricValue || "Metric Details"]
    }?no_of_days=${
      rangePickerValue.range + 1
    }&group_id=${group_id}${risk}${project}&startDay=${dateConvert(
      rangePickerValue.startDate
    )}&endDay=${dateConvert(
      rangePickerValue.endDate
    )}&iteration=${iterationValueGlobal}`;
  } else if (rangePickerValue.range.includes("Next")) {
    const lastDate = new Date();
    lastDate.setDate(
      lastDate.getDate() + (dateRangeMap[rangePickerValue.range] - 1)
    );
    const nextDate = new Date();
    nextDate.setDate(nextDate.getDate());

    return `/api/project/${
      RiskApiMap[metricValue || "Metric Details"]
    }?no_of_days=${
      dateRangeMap[rangePickerValue.range]
    }&group_id=${group_id}${risk}${project}&startDay=${dateConvert(
      nextDate
    )}&endDay=${dateConvert(lastDate)}&iteration=${iterationValueGlobal}`;
  }
  const lastDate = new Date();
  lastDate.setDate(lastDate.getDate() - 1);

  const nextDate = new Date();
  nextDate.setDate(nextDate.getDate() - dateRangeMap[rangePickerValue.range]);

  return `/api/project/${
    RiskApiMap[metricValue || "Metric Details"]
  }?no_of_days=${
    dateRangeMap[rangePickerValue.range]
  }&group_id=${group_id}${risk}${project}&startDay=${dateConvert(
    nextDate
  )}&endDay=${dateConvert(lastDate)}&iteration=${iterationValueGlobal}`;
};
