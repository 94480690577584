import React from "react";
import "./index.css";
import { Tooltip } from "@mui/material";
import ErrorIcon from "@mui/icons-material/Error";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";

const HealthColorWrapper = ({ status }) => {
  const getStatusIcon = (status) => {
    switch (status) {
      case "GOOD":
        return (
          <Tooltip title={status}>
            <CheckCircleIcon />
          </Tooltip>
        );
      case "BAD":
      case "POOR":
        return (
          <Tooltip title={status}>
            <CancelIcon />
          </Tooltip>
        );
      case "AVERAGE":
        return (
          <Tooltip title={status}>
            <ErrorIcon />
          </Tooltip>
        );
      case "Low":
        return <CheckCircleIcon />;
      case "Medium":
        return <ErrorIcon />;
      case "High":
        return <CancelIcon />;
      default:
        break;
    }
  };
  return <div className={`${status}`}>{getStatusIcon(status)}</div>;
};

export default HealthColorWrapper;
