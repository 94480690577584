import { useState, useEffect, memo, useContext } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
// import { useHistory } from "react-router-dom";
// import { iterationMap } from "../../utils/textMap";
// import { format } from "date-fns";
// import { useParams } from "react-router-dom";
// import GraphDetails from "../../../pages/riskAdvisor/GraphDetails";
import { GlobalData } from "../../context/globalData";

// import { Data } from "./data";

am4core.options.queue = true;
am4core.options.autoDispose = true;

const formatPrecision = (precision, formatType) => {
  // console.log(precision,"precision")
  // let format = "#.";
  // for (let i = 0; i <= precision; i++) {
  //   format += "0";
  // }
  // format = format.slice(0, -1);

  // if (formatType === "normal") {
  return `{name}: {valueY}`;
  // }
  // if (formatType === "sprint1") {
  //   return `{categoryX}: {valueY.formatNumber('${format}')}`;
  // }
};

function LineTrendChart(props) {
  const { body, graphData, height, precision } = props;
  const { settingData } = useContext(GlobalData);

  const valuseYaxis = (name) => {
    switch (name.toString()) {
      case "Defect Lead Time (Days) ":
      case "44015":
      case "44022":
        return "Days";
      default:
        return "";
    }
  };

  const yAxesName = valuseYaxis(props.name);

  useEffect(() => {
    let chart = null;
    chart = am4core.create(body || "Metricdiv", am4charts.XYChart);
    chart.colors.step = 4;
    chart.responsive.enabled = true;
    chart.paddingRight = 30;

    // Add data
    chart.data = graphData;

    // Set input format for the dates
    chart.dateFormatter.inputDateFormat = "yyyy-MM-dd";

    // Create axes
    let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
    dateAxis.renderer.grid.template.disabled = true;

    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.title.text = yAxesName;
    if (graphData && graphData[0]) {
      let counter = 0;
      let skip = false;
      if (!graphData[0].Name) skip = true;
      Object.keys(graphData[0]).map((val) => {
        if (val.includes("Name")) {
          if (skip) {
            counter++;
            skip = false;
          }
          const name = "Name" + (counter === 0 ? "" : counter);
          const value = "Value" + (counter === 0 ? "" : counter);
          const text = graphData[0][name];
          seriesMaker(chart, value, name, counter === 0, text);
          counter++;
        }
        return val;
      });
    }
    chart.legend = new am4charts.Legend();
    chart.legend.labels.template.fill = am4core.color("#595858");
    chart.legend.labels.template.text = "[bold]{name}[/]";

    chart.legend.fontSize = 12;
    chart.legend.itemContainers.template.paddingTop = 1;
    chart.legend.itemContainers.template.paddingBottom = 1;
    chart.cursor = new am4charts.XYCursor();
    chart.cursor.behavior = "panXY";
    chart.cursor.xAxis = dateAxis;

    return () => {
      if (chart) {
        chart.dispose();
      }
    };
  }, [body, graphData, settingData]);

  const seriesMaker = (chart, val, name, isDashed, text) => {
    const series = chart.series.push(new am4charts.LineSeries());
    series.dataFields.valueY = val;
    series.dataFields.dateX = "Time";
    series.dataFields.name = name;

    if (text) series.name = text;
    series.tooltipText = formatPrecision(precision, "normal");

    // using for the click on exsiting series in metric graph , route

    series.segments.template.interactionsEnabled = true;

    // Stroke width  Change
    series.strokeWidth = settingData.stroke;
    if (isDashed) series.strokeDasharray = "3,3";
    series.tensionX = 0.8;
  };

  return (
    <>
      <div
        id={body || "Metricdiv"}
        style={{ width: "100%", height: height || "490px" }}
      />
    </>
  );
}

export default memo(LineTrendChart);
