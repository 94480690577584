import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";
const useStyles = makeStyles((theme) => ({
  Serch: {
    position: "reletive",
  },
  SerchIcon: {
    // position: "absolute",
    // right: "339px",
    fontSize: "26px",
    paddingTop: "4px",
  },
  searchBarontop: {
    minWidth: "300px",
    padding: "6px 0px 6px 36px",
    borderRadius: "4px",
    border: "1px solid #00000042",
  },
}));
export const JiraTableSearchBar = (props) => {
  const { searchInput, metricBarData, handleChangeSearchBar } = props;
  const classes = useStyles();
  return (
    <>
      <div className={classes.Serch}>
        <input
          type="search"
          placeholder="Search here"
          onChange={handleChangeSearchBar}
          value={searchInput}
          className={classes.searchBarontop}
        />
        <SearchIcon className={classes.SerchIcon} />
      </div>
      {metricBarData?.map((title, index) => {
             <>
          <label>{title?.fields?.summary}</label>
        </>;
      })}
    </>
  );
};
