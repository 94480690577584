import { useContext, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { useFormik } from "formik";
import { GlobalData } from "../../context/globalData";
import * as React from "react";
import * as yup from "yup";
import { pricePlan } from "../../utils/PricingPlan";
import axios from "../../utils/axiosHelper";
import screensIcons from "../../assets/itechgenicLogin.png";
import passwordIcons from "../../assets/password-logo.png";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useHistory, useNavigate } from "react-router-dom";
import { Alert, AlertTitle } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  head: {
    marginTop: "5%",
    position: "absolute",
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    padding: "10% 15%",
  },
  input: {
    width: "300px",
    // padding: "10px 15px",
    height: "10px",
    margin: "22px 5px",
    font: "normal normal normal 18px/22px Lexend Deca",
  },
  notchedOutline: {
    // borderWidth: "1px",
    // borderRadius: "32px",
    background: "transparent",
    border: "none",
    margin: "5% 5%",
    // borderBottom: "solid #eeeeee 0.5px",
  },
  btn: {
    display: "flex",
    // alignItems:"center",
    justifyContent: "center",
    margin: "10% 20%",
    width: "190px",
    padding: "25px 2px",
    height: "40px",
    borderRadius: "32px",
    background: "#5EBDCC",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#092C49",
    },
  },
  inputWraper: {
    display: "flex",
    borderBottom: "solid #eeeeee 0.5px",
    // width: "200px",
    // textAlign: "left",
  },
}));
export default function ResetPassword() {
  const classes = useStyles();
  const { id } = useParams();
  const { setIsLogin, setRole, setCurrentRender } = useContext(GlobalData);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [confirmMessage, setConfirmMessage] = useState(false);
  const [error, setError] = useState(false);
  const [checked, setChecked] = React.useState(false);

  const handleChange = () => {
    setChecked(!checked);
  };

  const validationSchema = yup.object({
    newPassword: yup
      .string("Enter your password")
      .min(8, "Password should be of minimum 8 characters length")
      .required("Password is required"),
    confirmPassword: yup
      .string("Confirm password")
      .oneOf([yup.ref("newPassword")], "Password not matched")
      .min(8, "Password should be of minimum 8 characters length")
      .required("Password is required"),
  });
  let history = useHistory();
  const formik = useFormik({
    initialValues: {
      newPassword: "",
      confirmPassword: "",
    },
    validationSchema: validationSchema,

    onSubmit: (values) => {
      console.log("values", values);
      setLoading(true);
      axios
        .put(`${"/api/auth/resetpassword"}/${id}`, {
          password: values.newPassword,
        })
        .then((result) => {
          console.log("result", result);
          if (result.data.success) {
            setConfirmMessage(true);
            history.push("/login");
          }
        })
        .catch((err) => {
          setErrorMessage(err.response?.data?.error);
          setLoading(false);
          setError(true);
          setTimeout(function () {
            setError(false);
          }, 3000);
        });
    },
  });

  return (
    <div
      className="row "
      style={{
        top: "0px",
        right: "0px",
        bottom: "0px",
        left: "0px",
        position: "absolute",
      }}
    >
      <div
        className="col-7 "
        style={{ width: "50%", backgroundColor: "#5EBDCC", height: "100%" }}
      >
        <div className="row">
          <div className="col-12 d-flex justify-content-center">
            {" "}
            <img
              src={screensIcons}
              alt="itechgenic"
              style={{
                width: "489px",
                height: "484px",
                // padding: "47px 49px",
                marginTop: "15%",
                // top: "155px",
                // left: "220px",
                textAlign: "center",
                backgroundColor: "#5EBDCC",
              }}
            />
          </div>

          {/* <h4
            className="col-12 d-flex justify-content-center"
            style={{
              color: "white",
              font: "normal normal normal 24px/22px Lexend Deca",
              letterspacing: "0px",
              color: " #FFFFFF",
              opacity: "1",
            }}
          >
            Connect with many more application
          </h4> */}
          <h4
            className="col-12 d-flex justify-content-center"
            style={{
              color: "white",
              font: "normal normal normal 16px/22px Lexend Deca",
              letterspacing: "0px",
              color: "#FFFFFF",
              opacity: "0.5",
            }}
          >
           Enhance your cycle time and improve code quality.
          </h4>
        </div>
      </div>
      <div className="col-5">
        <div className={classes.head}>
          {setConfirmMessage ? (
            <>
              <form
                noValidate
                autoComplete="off"
                className={classes.root}
                onSubmit={formik.handleSubmit}
              >
                <h4
                  style={{
                    color: "black",
                    font: "normal normal normal 24px/22px Lexend Deca",
                    letterspacing: "0px",
                    color: " #000000",
                    opacity: "1",
                  }}
                >
                  Create New Password
                </h4>
                <div className={classes.inputWraper}>
                  <img
                    src={passwordIcons}
                    alt="password-logo"
                    style={{
                      width: "18px",
                      height: "18px",
                      marginTop: "8%",
                    }}
                  />
                  <TextField
                    id="newPassword"
                    name="newPassword"
                    type="password"
                    variant="outlined"
                    size="small"
                    InputProps={{
                      classes: {
                        notchedOutline: classes.notchedOutline,
                        input: classes.input,
                      },
                    }}
                    value={formik.values.newPassword}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.newPassword &&
                      Boolean(formik.errors.newPassword)
                    }
                    helperText={
                      formik.touched.newPassword && formik.errors.newPassword
                    }
                    placeholder="New Password"
                  />
                </div>
                <div className={classes.inputWraper}>
                  <img
                    src={passwordIcons}
                    alt="password-logo"
                    style={{
                      width: "18px",
                      height: "18px",
                      marginTop: "8%",
                    }}
                  />
                  <TextField
                    id="confirmPassword"
                    name="confirmPassword"
                    type="password"
                    variant="outlined"
                    size="small"
                    InputProps={{
                      classes: {
                        notchedOutline: classes.notchedOutline,
                        input: classes.input,
                      },
                    }}
                    value={formik.values.confirmPassword}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.confirmPassword &&
                      Boolean(formik.errors.confirmPassword)
                    }
                    helperText={
                      formik.touched.confirmPassword &&
                      formik.errors.confirmPassword
                    }
                    placeholder="Confirm Password"
                  />
                </div>
                <div className="d-flex align-items-center">
                  <Button
                    variant="contained"
                    type="submit"
                    className={classes.btn}
                    disabled={loading}
                  >
                    Submit
                  </Button>
                </div>
              </form>
            </>
          ) : (
            <h4>Password has been changed</h4>
          )}
        </div>
      </div>
    </div>
  );
}
