import { useState, useEffect, useContext } from "react";
import IconButton from "@material-ui/core/IconButton";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import EditIcon from "@material-ui/icons/Edit";
// import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";
import RiskWrapper from "../components/overview/riskWrapper";
import PointerWrapper from "../components/overview/pointerWrapper";
import JiraLogo from "../assets/Jira.png";
import GithubLogo from "../assets/githubIcon.png";
import GitlabLogo from "../assets/gitlabIcon.png";
import BitbucketLogo from "../assets/bitbucket.png";
import AzureLogo from "../assets/azure.png";
import SonarqubeLogo from "../assets/sonarcube.png";
import { GlobalData } from "../context/globalData";
import { iterationMap, level, levelMap, riskMap } from "../utils/levelMap";
import { Tooltip } from "@material-ui/core";
import DoneIcon from "@material-ui/icons/Done";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
  btn: {
    backgroundColor: "#51a7ed",
    fontSize: "15px",
    padding: "0px 30px",
    color: "#fff",
    height: "35px",
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: "#072136",
    },
  },
}));

const MaterialButton = () => {
  return (
    <IconButton size="small">
      <ArrowForwardIosIcon />
    </IconButton>
  );
};
const MaterialButton2 = () => {
  return (
    <Tooltip title="Edit">
      <IconButton size="small">
        <EditIcon />
      </IconButton>
    </Tooltip>
  );
};

const data2Val = [
  { Type: "Metric Groups", Details: "Detail Icon", id: 1 },
  { Type: "Metrics", Details: "Detail Icon", id: 2 },
  { Type: "Metric Level Risks", Details: "Detail Icon", id: 3 },
  {
    Type: "Project, Team, Resource, Module Level Risks",
    id: 4,
    Details: "Detail Icon",
  },
  {
    Type: "Alert Frame Work",
    Details: "Detail Icon",
    id: 5,
  },
  {
    Type: "Tools Integration",
    Details: "Detail Icon",
    id: 6,
  },
  {
    Type: "Metric configuration",
    Details: "Detail Icon",
    id: 7,
  },
  // {
  //   Type: "General Setting",
  //   id: 5,
  //   Details: "Detail Icon",
  // },
];

const StatusValueMap = {
  Active: true,
  Inactive: false,
};

export const useColumnHook = (
  title,
  isdetail = true,
  metricValue,
  statusValue,
  newData,
  MatricDetail
) => {
  const [columnVal, setColumnVal] = useState([]);
  const [column, setColumn] = useState([]);
  const [column2, setColumn2] = useState([]);
  const [column3, setColumn3] = useState([]);
  const [column4, setColumn4] = useState([]);
  const [column5, setColumn5] = useState([]);
  const [column6, setColumn6] = useState([]);
  const [column7, setColumn7] = useState([]);
  const [column8, setColumn8] = useState([]);
  const [column9, setColumn9] = useState([]);
  const [data, setData] = useState([]);
  const [data2, setData2] = useState([]);
  const [data3, setData3] = useState([]);
  const [data4, setData4] = useState([]);
  const [data5, setData5] = useState([]);
  const [data6, setData6] = useState([]);
  const [data7, setData7] = useState([]);
  const [data8, setData8] = useState([]);
  const [data9, setData9] = useState([]);
  const [spin, setSpin] = useState(false);

  const {
    allMetricData,
    metricDetails,
    flag,
    setFlag,
    sonarqubeFlag,
    setSonarqubeFlag,
    metricConfigurationFlag,
    setMetricConfigurationFlag,
  } = useContext(GlobalData);

  const classes = useStyles();

  const risk = JSON.parse(localStorage.getItem("pricePlan"));
  const isEnabledRisk = risk?.pricingPlan?.featureList?.risk?.isEnabled;



  let isTeam = false;
  useEffect(() => {
    // if (columnVal && columnVal?.length === 0) return;
    const metricData = [];
    const init2 = [];
    init2.push(
      {
        field: "Type",
        title: "Type",

        cellStyle: {
          backgroundColor: "#fff",

          fontSize: "16px",
          left: 0,
        },

        headerStyle: {
          backgroundColor: "#F9F9F9",
          color: "black",

          left: 0,
          zIndex: 11,
        },
      },
      {
        field: "Details",
        title: "Details",
        render: (value) => <MaterialButton value={value} />,
        cellStyle: {
          backgroundColor: "#fff",
          paddingRight: 50,
          fontSize: "16px",
          textAlign: "right",
        },
        headerStyle: {
          paddingRight: 50,
          backgroundColor: "#F9F9F9",
          color: "black",
          textAlign: "right",
          right: 0,
          zIndex: 11,
        },
      }
    );
    // setAdminMetric(init2);
    setColumn2(init2);
    const init3 = [];
    init3.push(
      {
        field: "Display Sequence",
        title: "Display Sequence",

        cellStyle: {
          backgroundColor: "#fff",
          fontSize: "16px",
          left: 0,
        },

        headerStyle: {
          backgroundColor: "#F9F9F9",
          color: "black",
          left: 0,
          zIndex: 11,
        },
      },
      {
        field: "Metric Group",
        title: "Metric Group",

        cellStyle: {
          backgroundColor: "#fff",
          fontSize: "16px",
          textAlign: "left",
        },
        headerStyle: {
          backgroundColor: "#F9F9F9",
          color: "black",
          textAlign: "left",

          zIndex: 11,
        },
      },
      {
        field: "Update",
        title: "Update",
        render: (value) => <MaterialButton2 value={value} />,
        cellStyle: {
          backgroundColor: "#fff",
          paddingRight: 50,
          fontSize: "16px",
          textAlign: "right",
        },
        headerStyle: {
          backgroundColor: "#F9F9F9",
          color: "black",
          textAlign: "right",
          right: 0,
          zIndex: 11,
        },
      }
    );
    setColumn3(init3);

    const init4 = [];
    init4.push(
      {
        field: "Metric",
        title: "Metric",

        cellStyle: {
          backgroundColor: "#fff",
          fontSize: "16px",
          left: 0,
        },

        headerStyle: {
          backgroundColor: "#F9F9F9",
          color: "black",
          left: 0,
          zIndex: 11,
        },
      },
      {
        field: "DeviationfromTarget",
        title: "Deviation from Target: Greater Than/Equal To",
        render: (rowData) => (
          <div
            style={{
              color: parseInt(rowData.DeviationfromTarget) < 0 && "red",
            }}
          >
            {rowData.DeviationfromTarget}
            {rowData.DeviationfromTarget && "%"}
          </div>
        ),
        cellStyle: {
          backgroundColor: "#fff",
          fontSize: "16px",
          textAlign: "left",
        },
        headerStyle: {
          backgroundColor: "#F9F9F9",
          color: "black",
          textAlign: "left",

          zIndex: 11,
        },
      },
      {
        field: "DeviationTo",
        title: "Deviation from Target: Less Than",
        render: (rowData) => (
          <div style={{ color: parseInt(rowData.DeviationTo) < 0 && "red" }}>
            {rowData.DeviationTo}
            {rowData.DeviationTo && "%"}
          </div>
        ),
        cellStyle: {
          backgroundColor: "#fff",
          fontSize: "16px",
          textAlign: "left",
        },
        headerStyle: {
          backgroundColor: "#F9F9F9",
          color: "black",
          textAlign: "left",

          zIndex: 11,
        },
      },
      {
        field: "Risk Score",
        title: "Risk Score",

        cellStyle: {
          backgroundColor: "#fff",
          fontSize: "16px",
          textAlign: "left",
        },
        headerStyle: {
          backgroundColor: "#F9F9F9",
          color: "black",
          textAlign: "left",

          zIndex: 11,
        },
      },
      {
        field: "RiskLevel",
        title: "Risk Level",
        render: (rowData) => (
          // <center>
          <RiskWrapper>{rowData.RiskLevel}</RiskWrapper>
          // </center>
        ),
        cellStyle: {
          backgroundColor: "#fff",
          fontSize: "16px",
          textAlign: "left",
        },
        headerStyle: {
          backgroundColor: "#F9F9F9",
          color: "black",
          textAlign: "left",

          zIndex: 11,
        },
      }
    );
    setColumn4(init4);

    const init5 = [];
    init5.push(
      {
        field: "ConsolidatedGreater",
        title: "Consolidated Risk Score: Greater Than/Equal To ",

        cellStyle: {
          backgroundColor: "#fff",
          fontSize: "16px",
          left: 0,
        },

        headerStyle: {
          backgroundColor: "#F9F9F9",
          color: "black",
          left: 0,
          zIndex: 11,
        },
      },
      {
        field: "ConsolidatedThan",
        title: "Consolidated Risk Score: Less Than",

        cellStyle: {
          backgroundColor: "#fff",
          fontSize: "16px",
          left: 0,
        },

        headerStyle: {
          backgroundColor: "#F9F9F9",
          color: "black",
          left: 0,
          zIndex: 11,
        },
      },
      {
        field: "ConsolidatedRiskLevel",
        title: "Risk Level ",
        render: (rowData) => (
          // <center>
          <RiskWrapper>{rowData.ConsolidatedRiskLevel}</RiskWrapper>
          // </center>
        ),
        cellStyle: {
          backgroundColor: "#fff",
          fontSize: "16px",
          left: 0,
        },

        headerStyle: {
          backgroundColor: "#F9F9F9",
          color: "black",
          left: 0,
          zIndex: 11,
        },
      }
    );
    setColumn5(init5);

    const init6 = [];

    init6.push(
      {
        field: "Display Sequence",
        title: "Display Sequence",

        cellStyle: {
          backgroundColor: "#fff",
          fontSize: "16px",
          left: 0,
        },

        headerStyle: {
          backgroundColor: "#F9F9F9",
          color: "black",
          left: 0,
          zIndex: 11,
        },
      },
      {
        field: "Metric",
        title: "Metric",

        cellStyle: {
          backgroundColor: "#fff",
          fontSize: "16px",
          left: 0,
        },

        headerStyle: {
          backgroundColor: "#F9F9F9",
          color: "black",
          left: 0,
          zIndex: 11,
        },
      },
      {
        field: "Metric Group",
        title: "Metric Group",

        cellStyle: {
          backgroundColor: "#fff",
          fontSize: "16px",
          left: 0,
        },

        headerStyle: {
          backgroundColor: "#F9F9F9",
          color: "black",
          left: 0,
          zIndex: 11,
        },
      },
      {
        field: "Risk Weight",
        title: "Risk Weight",

        cellStyle: {
          backgroundColor: "#fff",
          fontSize: "16px",
          left: 0,
        },

        headerStyle: {
          backgroundColor: "#F9F9F9",
          color: "black",
          left: 0,
          zIndex: 11,
        },
      },
      {
        field: "Number of Decimals",
        title: "Number of Decimals",

        cellStyle: {
          backgroundColor: "#fff",
          fontSize: "16px",
          left: 0,
        },

        headerStyle: {
          backgroundColor: "#F9F9F9",
          color: "black",
          left: 0,
          zIndex: 11,
        },
      },
      {
        field: "Level",
        title: "Level",

        cellStyle: {
          backgroundColor: "#fff",
          fontSize: "16px",
          left: 0,
        },

        headerStyle: {
          backgroundColor: "#F9F9F9",
          color: "black",
          left: 0,
          zIndex: 11,
        },
      },
      {
        field: "Iteration & Target",
        title: "Iteration & Target",

        cellStyle: {
          backgroundColor: "#fff",
          fontSize: "16px",
          left: 0,
        },

        headerStyle: {
          backgroundColor: "#F9F9F9",
          color: "black",
          left: 0,
          zIndex: 11,
        },
      },
      {
        field: "Update",
        title: "Update",
        render: (value) => <MaterialButton2 value={value} />,
        cellStyle: {
          backgroundColor: "#fff",
          paddingRight: 50,
          fontSize: "16px",
          textAlign: "right",
        },
        headerStyle: {
          backgroundColor: "#F9F9F9",
          color: "black",
          textAlign: "right",
          right: 0,
          zIndex: 11,
        },
      }
    );
    setColumn6(init6);

    const init7 = [];
    init7.push(
      {
        field: "Level",
        title: "Level",

        cellStyle: {
          backgroundColor: "#fff",
          fontSize: "16px",
          left: 0,
        },

        headerStyle: {
          backgroundColor: "#F9F9F9",
          color: "black",
          left: 0,
          zIndex: 11,
        },
      },
      {
        field: "iteration",
        title: "Iteration",

        cellStyle: {
          backgroundColor: "#fff",
          fontSize: "16px",
          left: 0,
        },

        headerStyle: {
          backgroundColor: "#F9F9F9",
          color: "black",
          left: 0,
          zIndex: 11,
        },
      },
      // {
      //   field: "isActive",
      //   title: "Is Active",

      //   cellStyle: {
      //     backgroundColor: "#fff",
      //     fontSize: "16px",
      //     left: 0,
      //   },

      //   headerStyle: {
      //     backgroundColor: "#F9F9F9",  color: "black",
      //     left: 0,
      //     zIndex: 11,
      //   },
      // },

      {
        field: "alertType",
        title: "Alert Type",

        cellStyle: {
          backgroundColor: "#fff",
          fontSize: "16px",
          left: 0,
        },

        headerStyle: {
          backgroundColor: "#F9F9F9",
          color: "black",
          left: 0,
          zIndex: 11,
        },
      },
      {
        field: "day",
        title: "Days",

        cellStyle: {
          backgroundColor: "#fff",
          fontSize: "16px",
          left: 0,
        },

        headerStyle: {
          backgroundColor: "#F9F9F9",
          color: "black",
          left: 0,
          zIndex: 11,
        },
      },
      {
        field: "projectName",
        title: "Project Name",

        cellStyle: {
          backgroundColor: "#fff",
          fontSize: "16px",
          left: 0,
        },

        headerStyle: {
          backgroundColor: "#F9F9F9",
          color: "black",
          left: 0,
          zIndex: 11,
        },
      },
      {
        field: "notficationType",
        title: "Notifiction Type",

        cellStyle: {
          backgroundColor: "#fff",
          fontSize: "16px",
          left: 0,
        },

        headerStyle: {
          backgroundColor: "#F9F9F9",
          color: "black",
          left: 0,
          zIndex: 11,
        },
      },
      {
        field: "email",
        title: "Email",

        cellStyle: {
          backgroundColor: "#fff",
          fontSize: "16px",
          left: 0,
        },

        headerStyle: {
          backgroundColor: "#F9F9F9",
          color: "black",
          left: 0,
          zIndex: 11,
        },
      }
    );
    setColumn7(init7);

    const init8 = [];
    init8.push(
      // {
      //   field: "Configuration",
      //   title: "Configuration",
      //   render: (rowData) => {
      //     if (rowData?.Configuration) {
      //       return <DoneIcon style={{ color: "green" }}>Done</DoneIcon>;
      //     } else if (rowData?.tableData?.id === 4) {
      //       return (
      //         <span
      //           style={{ cursor: "pointer", color: "black" }}
      //           onClick={() => setFlag(!flag)}
      //         >
      //           Configue
      //         </span>
      //       );
      //     } else if (rowData?.tableData?.id === 5) {
      //       return (
      //         <span
      //           style={{ cursor: "pointer", color: "black" }}
      //           onClick={() => setSonarqubeFlag(!sonarqubeFlag)}
      //         >
      //           Configue
      //         </span>
      //       );
      //     } else {
      //       return (
      //         <a
      //           href={rowData?.Url}
      //           style={{ textDecoration: "none", color: "black" }}
      //         >
      //           Configue
      //         </a>
      //       );
      //     }
      //   },
      //   cellStyle: {
      //     backgroundColor: "#fff",
      //     fontSize: "16px",
      //     left: 0,
      //   },

      //   headerStyle: {
      //     backgroundColor: "#F9F9F9",  color: "black",
      //     left: 0,
      //     zIndex: 11,
      //   },
      // },
      {
        field: "Integration Name",
        title: "All Tools",
        render: (rowData) => {
          if (rowData?.["Integration Name"] == "Jira") {
            return (
              <span style={{ color: "black" }} className="row">
                {rowData?.Configuration ? (
                  <span className="col-2 d-flex align-items-center justify-content-end">
                    <i
                      className="fa fa-toggle-on fa-2x"
                      style={{ color: "green" }}
                      aria-hidden="true"
                    ></i>
                  </span>
                ) : (
                  <span className="col-2 d-flex align-items-center justify-content-end">
                    <a
                      href={rowData?.Url}
                      style={{ textDecoration: "none", color: "black" }}
                    >
                      <i
                        className="fa fa-toggle-off fa-2x"
                        style={{ color: "red" }}
                        aria-hidden="true"
                      ></i>
                    </a>
                  </span>
                )}
                <span className="col-1 d-flex align-items-center justify-content-end">
                  <img src={JiraLogo} height={50} width={50} alt=""></img>
                </span>

                <span className="col-8 d-flex align-items-center justify-content-start">
                  <span className="row">
                    <span className="col-12 d-flex align-items-center justify-content-start">
                      <b>Jira</b>
                    </span>
                    <span
                      className="col-12 d-flex align-items-center justify-content-start"
                      style={{ color: "#092c49" }}
                    >
                      A suite of agile work management solutions that powers
                      collaboration across all teams from concept to customer.
                    </span>
                  </span>
                </span>
              </span>
            );
          }
          if (rowData?.["Integration Name"] == "Github") {
            return (
              <span style={{ color: "black" }} className="row">
                {rowData?.Configuration ? (
                  <span className="col-2 d-flex align-items-center justify-content-end">
                    <i
                      className="fa fa-toggle-on fa-2x"
                      style={{ color: "green" }}
                      aria-hidden="true"
                    ></i>
                  </span>
                ) : (
                  <span className="col-2 d-flex align-items-center justify-content-end">
                    <a
                      href={rowData?.Url}
                      style={{ textDecoration: "none", color: "black" }}
                    >
                      <i
                        className="fa fa-toggle-off fa-2x"
                        style={{ color: "red" }}
                        aria-hidden="true"
                      ></i>
                    </a>
                  </span>
                )}
                <span className="col-1 d-flex align-items-center justify-content-end">
                  <img src={GithubLogo} height={50} width={50} alt=""></img>
                </span>

                <span className="col-8 d-flex align-items-center justify-content-start">
                  <span className="row">
                    <span className="col-12 d-flex align-items-center justify-content-start">
                      <b>Github</b>
                    </span>
                    <span className="col-12 d-flex align-items-center justify-content-start">
                      A code hosting platform for version control and
                      collaboration. It lets you and others work together on
                      projects from anywhere.
                    </span>
                  </span>
                </span>
              </span>
            );
          }
          if (rowData?.["Integration Name"] == "Gitlab") {
            return (
              <span style={{ color: "black" }} className="row">
                {rowData?.Configuration ? (
                  <span className="col-2 d-flex align-items-center justify-content-end">
                    <i
                      className="fa fa-toggle-on fa-2x"
                      style={{ color: "green" }}
                      aria-hidden="true"
                    ></i>
                  </span>
                ) : (
                  <span className="col-2 d-flex align-items-center justify-content-end">
                    <a
                      href={rowData?.Url}
                      style={{ textDecoration: "none", color: "black" }}
                    >
                      <i
                        className="fa fa-toggle-off fa-2x"
                        style={{ color: "red" }}
                        aria-hidden="true"
                      ></i>
                    </a>
                  </span>
                )}
                <span className="col-1 d-flex align-items-center justify-content-end">
                  <img src={GitlabLogo} height={50} width={50} alt=""></img>
                </span>

                <span className="col-8 d-flex align-items-center justify-content-start">
                  <span className="row">
                    <span className="col-12 d-flex align-items-center justify-content-start">
                      <b>Gitlab</b>
                    </span>
                    <span className="col-12 d-flex align-items-center justify-content-start">
                      A open source code repository and collaborative software
                      development platform for large DevOps and DevSecOps
                      projects.
                    </span>
                  </span>
                </span>
              </span>
            );
          }
          if (rowData?.["Integration Name"] == "Bitbucket") {
            return (
              <span style={{ color: "black" }} className="row">
                {rowData?.Configuration ? (
                  <span className="col-2 d-flex align-items-center justify-content-end">
                    <i
                      className="fa fa-toggle-on fa-2x"
                      style={{ color: "green" }}
                      aria-hidden="true"
                    ></i>
                  </span>
                ) : (
                  <span className="col-2 d-flex align-items-center justify-content-end">
                    <a
                      href={rowData?.Url}
                      style={{ textDecoration: "none", color: "black" }}
                    >
                      <i
                        className="fa fa-toggle-off fa-2x"
                        style={{ color: "red" }}
                        aria-hidden="true"
                      ></i>
                    </a>
                  </span>
                )}
                <span className="col-1 d-flex align-items-center justify-content-end">
                  <img src={BitbucketLogo} height={50} width={50} alt=""></img>
                </span>

                <span className="col-8 d-flex align-items-center justify-content-start">
                  <span className="row">
                    <span className="col-12 d-flex align-items-center justify-content-start">
                      <b>Bitbucket</b>
                    </span>
                    <span className="col-12 d-flex align-items-center justify-content-start">
                      A Git based code hosting and collaboration tool, built for
                      teams.
                    </span>
                  </span>
                </span>
              </span>
            );
          }
          if (rowData?.["Integration Name"] == "Azure") {
            return (
              <span style={{ color: "black" }} className="row">
                {rowData?.Configuration ? (
                  <span className="col-2 d-flex align-items-center justify-content-end">
                    <i
                      className="fa fa-toggle-on fa-2x"
                      style={{ color: "green" }}
                      aria-hidden="true"
                    ></i>
                  </span>
                ) : (
                  <span
                    className="col-2 d-flex align-items-center justify-content-end"
                    style={{ cursor: "pointer", color: "black" }}
                    onClick={() => setFlag(!flag)}
                  >
                    <i
                      className="fa fa-toggle-off fa-2x"
                      style={{ color: "red" }}
                      aria-hidden="true"
                    ></i>
                  </span>
                )}
                <span className="col-1 d-flex align-items-center justify-content-end">
                  <img src={AzureLogo} height={50} width={50} alt=""></img>
                </span>

                <span className="col-8 d-flex align-items-center justify-content-start">
                  <span className="row">
                    <span className="col-12 d-flex align-items-center justify-content-start">
                      <b>Azure</b>
                    </span>
                    <span className="col-12 d-flex align-items-center justify-content-start">
                      A cloud platform with more than 200 products and cloud
                      services designed to help you bring new solutions to
                      life—to solve today's challenges and create the future.
                    </span>
                  </span>
                </span>
              </span>
            );
          }
          if (rowData?.["Integration Name"] == "Sonarqube") {
            return (
              <span style={{ color: "black" }} className="row">
                {rowData?.Configuration ? (
                  <span className="col-2 d-flex align-items-center justify-content-end">
                    <i
                      className="fa fa-toggle-on fa-2x"
                      style={{ color: "green" }}
                      aria-hidden="true"
                    ></i>
                  </span>
                ) : (
                  <span
                    className="col-2 d-flex align-items-center justify-content-end"
                    style={{ cursor: "pointer", color: "black" }}
                    onClick={() => setSonarqubeFlag(!sonarqubeFlag)}
                  >
                    <i
                      className="fa fa-toggle-off fa-2x"
                      style={{ color: "red" }}
                      aria-hidden="true"
                    ></i>
                  </span>
                )}
                <span className="col-1 d-flex align-items-center justify-content-end">
                  <img
                    src={SonarqubeLogo}
                    height={50}
                    width={50}
                    style={{ padding: "5px" }}
                    alt=""
                  ></img>
                </span>

                <span className="col-8 d-flex align-items-center justify-content-start">
                  <span className="row">
                    <span className="col-12 d-flex align-items-center justify-content-start">
                      <b>Sonarqube</b>
                    </span>
                    <span className="col-12 d-flex align-items-center justify-content-start">
                      A Code Quality Assurance tool that collects and analyzes
                      source code, and provides reports for the code quality of
                      your project.
                    </span>
                  </span>
                </span>
              </span>
            );
          }
        },
        cellStyle: {
          backgroundColor: "#F9F9F9",
          color: "black",
          fontSize: "16px",
          left: 0,
          border: "10px solid white",
          borderRadius: "70px",
        },

        headerStyle: {
          backgroundColor: "white",
          left: 0,
          zIndex: 11,
          color: "black",
          fontWeight: "Bold",
          fontSize: "30px",
          textAlign: "center",
        },
      }
    );
    setColumn8(init8);

    const init9 = [];
    init9.push(
      {
        field: "Metric Name",
        title: "Metric Name",
        cellStyle: {
          backgroundColor: "#fff",
          fontSize: "16px",
          left: 0,
        },
        headerStyle: {
          backgroundColor: "#F9F9F9",
          color: "black",
          left: 0,
          zIndex: 11,
        },
      },
      {
        field: "Data Source",
        title: "Data Source",
        cellStyle: {
          backgroundColor: "#fff",
          fontSize: "16px",
          left: 0,
        },
        headerStyle: {
          backgroundColor: "#F9F9F9",
          color: "black",
          left: 0,
          zIndex: 11,
        },
      },
      {
        field: "Configuration",
        title: "Configuration",
        render: (isAdded, index) => {
          if (isAdded?.isAdded) {
            return <DoneIcon style={{ color: "green" }}>Done</DoneIcon>;
          } else {
            return (
              <Button
                className={classes.btn}
                onClick={() =>
                  setMetricConfigurationFlag(!metricConfigurationFlag)
                }
              >
                Add
              </Button>
            );
          }
        },
        cellStyle: {
          backgroundColor: "#fff",
          fontSize: "16px",
          left: 0,
        },

        headerStyle: {
          backgroundColor: "#F9F9F9",
          color: "black",
          left: 0,
          zIndex: 11,
        },
      }
    );
    setColumn9(init9);

    allMetricData.forEach((val) => {
      if (val[levelMap[title.toUpperCase()]]) {
        const obj = {
          [val.DisplayName]: val.Id,
        };
        metricData.push(obj);
      }
    });

    const init = [
      {
        field: "ProjectId",
        title: "Id",
        hidden: true,
      },
    ];

    if (columnVal && columnVal.some((val) => val.TeamName)) {
      isTeam = true;
      init.push({
        field: "TeamName",
        title: "Team",
        width: "230px",
        cellStyle: {
          backgroundColor: "#fff",
          position: "sticky",
          fontSize: "16px",
          left: 0,
          minWidth: "230px",
        },
        headerStyle: {
          backgroundColor: "#F9F9F9",
          color: "black",
          position: "sticky",
          left: 0,
          zIndex: 11,
          minWidth: "225px",
        },
        customSort: (a, b) => {
          if (a.TeamName.toLowerCase() < b.TeamName.toLowerCase()) {
            return -1;
          }
          if (a.TeamName.toLowerCase() > b.TeamName.toLowerCase()) {
            return 1;
          }
          return 0;
        },
      });
    }

    init.push({
      field: "ProjectName",
      title:
        title.replace(/\S*/g, function (word) {
          return word.charAt(0) + word.slice(1).toLowerCase();
        }) || "",
      width: "225px",
      cellStyle: {
        minWidth: "225px",
        backgroundColor: "#fff",
        position: "sticky",
        fontSize: "16px",
        left: isTeam ? 225 : 0,
        boxShadow: "rgb(125 147 178 / 11%) 14px 3px 14px",
      },
      headerStyle: {
        minWidth: "225px",
        backgroundColor: "#F9F9F9",
        color: "black",
        position: "sticky",
        // fontSize: "16px",
        left: isTeam ? 225 : 0,
        zIndex: 11,
        boxShadow: "rgb(125 147 178 / 11%) 14px 3px 14px",
      },
      customSort: (a, b) => {
        if (a.ProjectName.toLowerCase() < b.ProjectName.toLowerCase()) {
          return -1;
        }
        if (a.ProjectName.toLowerCase() > b.ProjectName.toLowerCase()) {
          return 1;
        }
        return 0;
      },
    });

    const isDetailApi = metricValue === "Metric Details";
    const columnFields = isDetailApi
      ? metricData?.map((val) => Object.keys(val)[0])
      : columnVal
      ? columnVal[0]?.metrics?.map((val) => val?.name)
      : [];

    columnFields?.map((item) => {
      init.push({
        field: item,
        title: item,
        headerStyle: {
          minWidth: "225px",
          textAlign: "center",
          color:"black",
        },
        cellStyle: {
          minWidth: "225px",
        },

        render: (rowData) => {
          if (!isDetailApi) {
            return (
              <center>
                <RiskWrapper>{riskMap[rowData[item].RiskLevel]}</RiskWrapper>
              </center>
            );
          }
          return (
            <div>
              {rowData[item.trim()] && (
                <center>
                  {isEnabledRisk ? (
                    <PointerWrapper val={riskMap[rowData[item].RiskLevel]}>
                      {rowData[item].Value}
                    </PointerWrapper>
                  ) : (
                    <> {rowData[item].Value}</>
                  )}
                </center>
              )}
            </div>
          );
        },
        customSort: (a, b) => {
          if (!isDetailApi) {
            return b[item] && a[item]
              ? a[item].RiskLevel - b[item].RiskLevel
              : 0;
          }
          return b[item] && a[item] ? a[item].Value - b[item].Value : 0;
        },

        customFilterAndSearch: (value, rowData) => {
          return rowData[item]
            ? parseInt(rowData[item].Value) === parseInt(value)
            : false;
        },
      });
      return item;
    });

    init.push({
      field: "risk",
      title: "Risk",
      width: "225px",
      cellStyle: {
        position: "sticky",
        minWidth: "225px",
        right: isdetail ? 113 : 0,
        backgroundColor: "#fff",
        boxShadow: "rgb(125 147 178 / 11%) -14px 0px 15px",
        display: isEnabledRisk === true ? "" : "none",
      },
      headerStyle: {
        position: "sticky",
        minWidth: "225px",
        right: isdetail ? 113 : 0,
        zIndex: 20,
        textAlign: "center",
        backgroundColor: "#F9F9F9",
        color: "black",
        boxShadow: "rgb(125 147 178 / 11%) -14px 0px 15px",
        display: isEnabledRisk === true ? "" : "none",
      },
      render: (rowData) => (
        <center>
          <RiskWrapper>{riskMap[rowData.risk]}</RiskWrapper>
        </center>
      ),
    });

    if (isdetail) {
      init.push({
        field: "details",
        title: "Details",
        // width:'75px',
        minWidth: "115px",
        cellStyle: {
          minWidth: "75px",
          position: "sticky",
          right: 0,
          textAlign: "center",
          backgroundColor: "#fff",
        },
        headerStyle: {
          minWidth: "75px",
          position: "sticky",
          right: 0,
          zIndex: 11,
          textAlign: "center",
          backgroundColor: "#F9F9F9",
          color: "black",
        },
        render: (value) => <MaterialButton value={value} />,
      });
    }
    setColumn(init);
  }, [columnVal, allMetricData, flag, sonarqubeFlag, metricConfigurationFlag]);

  useEffect(() => {
    if (!columnVal) return;
    if (column.length === 0) return;
    if (column2.length === 0) return;
    if (column3.length === 0) return;
    // if(metricDetails.length === 0)return;
    // if(!metricDetails)return;

    const dataVal = columnVal?.map((val) => {
      const obj = {};
      obj.ProjectId = val?.ProjectId;
      obj.ProjectName = val?.ProjectName;
      if (val?.TeamName) obj.TeamName = val?.TeamName;
      obj.risk = val?.riskLevel;
      val?.metrics?.map((item) => {
        obj[item.name] = {
          RiskLevel: item?.RiskLevel,
          Value:
            metricValue === "Metric Details" &&
            item?.Value?.toFixed(item?.Precision),
        };
        return item;
      });
      return obj;
    });

    const MetricDetails = [];
    if (Array.isArray(metricDetails) && metricDetails.length > 0) {
      metricDetails?.map((val) => {
        setSpin(true);
        MetricDetails?.push({
          "Display Sequence": val?.DisplaySeq,
          "Metric Group": val?.DisplayName,
          Update: "Update Icon",
          isActive: val?.isActive,
          id: val?.MetricGroupId,
        });
      });
    }

    const FilterMetricDetails = MetricDetails.filter(
      (item) => item.isActive === StatusValueMap[statusValue]
    );

    // matric risk lavel
    const MatricLevelRisk = [];
    const consolidatedRisk = [];
    const matricsData = [];

    if (Array.isArray(metricDetails)) {
      metricDetails?.map((val) => {
        MatricLevelRisk.push({
          Metric: val?.DisplayName,
          DeviationfromTarget: val?.From?.$numberDecimal,
          DeviationTo: val?.To?.$numberDecimal,
          "Risk Score": val?.RiskScore?.$numberDecimal,
          RiskLevel: val?.RiskCategory,
          RiskId: val?.RiskId,
        });
      });

      // Project, Team, Resource, Module Level Risks

      metricDetails?.map((val) => {
        consolidatedRisk.push({
          ConsolidatedGreater: val?.From,
          ConsolidatedThan: val?.To,
          ConsolidatedRiskLevel: val?.RiskCategory,
          ConsolidateRiskId: val?.ConsolidateRiskId,
        });
      });
      consolidatedRisk.sort(
        (a, b) => a.ConsolidatedGreater - b.ConsolidatedGreater
      );

      // matrics

      metricDetails?.map((val) => {
        matricsData.push({
          "Display Sequence": val?.DisplaySeq,
          Metric: val?.DisplayName,
          "Metric Group": val?.MetricGroupDisplayName,
          "Risk Weight": val?.RiskScoreWeight?.$numberDecimal,
          "Number of Decimals": val?.Precision,
          Level: val?.Level,
          "Iteration & Target": val?.iteration,
          isActive: val?.isActive,
          MetricGroupId: val?.Id,
        });
      });
      // const FilterMetricData = matricsData.filter(
      //   (item) => item.isActive === StatusValueMap[statusValue]
      // );

      // Alert Frame Work
      const AlertData = [];
      metricDetails?.map((val) => {
        AlertData.push({
          Level: level[val?.groupTypeId] || "",
          iteration: iterationMap[val?.iteration] || "",
          alertType: val?.alertType || "",
          notficationType: val?.notficationType || "",
          // isActive: val?.isActive || "",
          projectName: val?.project?.Name || "-",
          email: val?.email || "",
          RiskId: val?._id,
          day: val?.days,
        });
      });

      //Tools Integration
      const ToolsData = [];
      metricDetails?.map((item) => {
        let newEntry = {
          "Integration Name": item?.name || "No Name",
          Configuration: item?.isConfigured,
          Url: item?.url,
        };
        ToolsData.push(newEntry);
      });

      // Metric Configuration
      const MatricConfigurationData = [];
      metricDetails?.map((item, index) => {
        MatricConfigurationData.push({
          "Metric Name": item?.Metric || "No Name",
          "Data Source": item?.DataSource,
          isAdded: item?.isAdded,
          Id: item?.Id,
        });
      });

      setData6(matricsData);
      setData5(consolidatedRisk);
      setData4(MatricLevelRisk);

      setData3(FilterMetricDetails);

      setData7(AlertData);
      setData8(ToolsData);
      setData9(MatricConfigurationData);
    }
    setSpin(false);
    setData2(data2Val);
    setData(dataVal);
  }, [columnVal, column, column2, column3, statusValue, newData, MatricDetail]);
  return {
    MatricDetail,
    setColumnVal,
    spin,
    column,
    data,
    column2,
    column3,
    column4,
    column5,
    column6,
    column7,
    column8,
    column9,
    data2,
    data3,
    data4,
    data5,
    data6,
    data7,
    data8,
    data9,
  };
};
