import { Card, CardHeader, makeStyles } from "@material-ui/core";
import { Box } from "@mui/material";
import React, { useContext } from "react";
import { GlobalData } from "../../../context/globalData";
import InfoIcon from "@material-ui/icons/Info";

const useStyles = makeStyles({
  root: {
    minWidth: 275,
    height: "100%",
  },
  cardBody: {
    padding: "18px",
  },
  cardHeader: {
    borderBottom: "1px solid #F2F2F2",
    padding: "18px",
    display: "flex",
    gap: 15,
    "& h4": {
      font: "normal normal 600 18px/23px Lexend Deca",
      letterSpacing: "0px",
      color: "#1E1E1E",
      marginBottom: "0px",
    },
  },
});
function GraphCard({ title, subTitle, children, cardItem }) {
  const classes = useStyles();
  const { setIsDefinaionPopup, setDefinationKPI } = useContext(GlobalData);
  return (
    <Card className={`${classes.root} customCard`}>
      <div className={classes.cardHeader}>
        <h4>
          {title}{" "}
          <span
            onClick={() => {
              setIsDefinaionPopup(true);
              setDefinationKPI(
                title.includes("Sprint completion rate")
                  ? "Sprint velocity"
                  : title
              );
            }}
          >
            <InfoIcon style={{ color: "gray", marginLeft: "8px" }} />
          </span>
        </h4>
        {subTitle}
      </div>
      {cardItem && (
        <Box display={"flex"} justifyContent={"flex-end"}>
          <div className="border p-2 m-1">
            <div>{cardItem?.total} Day's or Less</div>
            <div>{cardItem?.percentValue} % Work Item</div>
          </div>
        </Box>
      )}
      <div className={classes.cardBody}>{children}</div>
    </Card>
  );
}

export default GraphCard;
