import { useState, useEffect, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import { DateRange } from "react-date-range";
import { addDays, endOfMonth, subMonths } from "date-fns";
import { Grid } from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { GlobalData } from "../../context/globalData";
import { startOfMonth } from "date-fns";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "transparent",
  },
  body: {
    "& .MuiListItem-root": {
      textAlign: "center",
      borderRadius: "4px",
      padding: "11px 23px",
      backgroundColor: "#F9F9F9 ",
      "&:hover": {
        width: "fit-content",
        backgroundColor: "#5EBDCC",
        color: "#ffff ",
      },
      "&.Mui-selected": {
        backgroundColor: "#5EBDCC",
        color: "#ffff ",
        textAlign: "center",
      },
    },
    "& .MuiListItemText-root": {
      "& .MuiTypography-body1": {
        font: "normal normal normal 11px/13px Lexend Deca",
      },
    },
  },
  header: {
    "& .MuiTypography-body1": {
      fontWeight: "900",
    },
    "& .MuiListItem-root": {
      padding: "0px 16px",
      width: "fit-content",
      backgroundColor: "#5EBDCC",
      color: "#ffff !important",
      borderRadius: "4px",
      "&:hover": {
        width: "fit-content",
        backgroundColor: "#5EBDCC",
        color: "#ffff ",
        borderRadius: "4px",
      },
    },
  },
  headers: {
    "& .MuiTypography-body1": {
      fontWeight: "900",
    },
    "& .MuiListItem-root": {
      padding: "0px 16px",
      width: "fit-content",
      "&:hover": {
        width: "fit-content",
        padding: "0px 16px",
        backgroundColor: "#5EBDCC",
        color: "#ffff ",
        borderRadius: "4px",
      },
    },
  },
  dateRangeItems: {
    "& .rdrCalendarWrapper": {
      width: "100%",
      "& .rdrMonth": {
        width: "100%",
      },
    },
    "& .rdrMonthAndYearWrapper": {
      backgroundColor: "#F9F9F9",
      borderRadius: "4px",
      margin: "20px 0px",
      paddingTop: "0px",
    },
  },
}));

const futureRage = {
  90: "Next 90 Days",
  60: "Next 60 Days",
  30: "Next 30 Days",
};

const rangeMap = {
  "Next 30 Days": 30,
  "Next 60 Days": 60,
  "Next 90 Days": 90,
  "Next 15 Days": 15,
  "Next 7 Days": 7,
  "Last 7 Days": -6,
  "Last 15 Days": -14,
  "Last 30 Days": 1,
  "Last 60 Days": 2,
  "Last 90 Days": 3,
  "Last 180 Days": 6,
  "Last 360 Days": 12,
};

const timeDifference = (startDate, endDate) => {
  const diffTime = Math.abs(endDate - startDate);
  return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
};

export default function RangeSelector({
  open,
  setOpen,
  pickerHandler,
  value,
  frstGraphClear,
  secGraphClear,
  isNextDate = false,
}) {
  const classes = useStyles();
  // Temp commented (this logic for next selected dates)
  let Range = isNextDate
    ? [
        "Last 30 Days",
        "Last 60 Days",
        "Last 90 Days",
        "Last 180 Days",
        "Last 360 Days",
        "Next 30 Days",
        "Next 60 Days",
        "Next 90 Days",
      ]
    : [
        "Last 30 Days",
        "Last 60 Days",
        "Last 90 Days",
        "Last 180 Days",
        "Last 360 Days",
      ];
  const [customRange, setCustomRange] = useState(null);
  const [isCustom, setIsCustom] = useState(false);
  const { enableAi, futureDates } = useContext(GlobalData);
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 0),
      key: "selection",
    },
  ]);

  useEffect(() => {
    if (!enableAi) {
      return Range;
    }
    //  start :::cache issue code
    if (futureDates <= 30) {
      if (Range.includes(futureRage[30])) return;
      // Range.unshift(futureRage[7]);
    } else if (futureDates <= 60) {
      if (Range.includes(futureRage[60])) return;
      // Range.unshift(futureRage[15]);
    } else if (futureDates <= 90) {
      if (Range.includes(futureRage[90])) return;
      // Range.unshift(futureRage[30]);
    }
    // end :::
    if (futureDates >= 30) {
      if (Range.includes(futureRage[30])) return;
      Range.unshift(futureRage[30]);
    }
    if (futureDates >= 60) {
      if (Range.includes(futureRage[60])) return;
      Range.unshift(futureRage[60]);
    }
    if (futureDates >= 90) {
      if (Range.includes(futureRage[90])) return;
      Range.unshift(futureRage[90]);
    }
    return Range;
  }, [enableAi, futureDates]);

  // useEffect(() => {
  //   if (!value) return;
  //   if (value.isCustom) {
  //     setState([value]);
  //     setIsCustom(true);
  //     return;
  //   }
  //   setCustomRange(value.range);
  //   setIsCustom(false);

  //   let startDate = addDays(new Date(), rangeMap[value.range]);
  //   let endDate = new Date();
  //   if (value.range.includes("Last")) {
  //     const today = new Date();
  //     today.setDate(today.getDate() - 1);
  //     endDate = today;
  //     startDate = addDays(today, rangeMap[value.range]);
  //   }
  //   if (value.range.includes("Next")) {
  //     const today = new Date();
  //     const edate = new Date();
  //     //  today.setDate(today.getDate() + 1);
  //     edate.setDate(edate.getDate() - 1);
  //     today.setDate(today.getDate());
  //     startDate = today;
  //     endDate = addDays(edate, rangeMap[value.range]);
  //   }

  //   setState([
  //     {
  //       startDate: startDate,
  //       endDate: endDate,
  //       key: "selection",
  //     },
  //   ]);
  // }, [value]);

  useEffect(() => {
    if (!value) return;
    if (value.isCustom) {
      setState([value]);
      setIsCustom(true);
      return;
    }
    setCustomRange(value.range);
    setIsCustom(false);

    let startDate = addDays(new Date(), rangeMap[value.range]);
    let endDate = new Date();

    if (value.range.includes("Last")) {
      const today = new Date();
      const firstDayOfLastMonth = subMonths(
        startOfMonth(today),
        rangeMap[value.range]
      );
      const lastDayOfLastMonth = endOfMonth(subMonths(startOfMonth(today), 1));
      startDate = firstDayOfLastMonth;
      endDate = lastDayOfLastMonth;
    }

    if (value.range.includes("Next")) {
      const today = new Date();
      const edate = new Date();
      edate.setDate(edate.getDate() - 1);
      today.setDate(today.getDate());
      startDate = today;
      endDate = addDays(edate, rangeMap[value.range]);
    }

    setState([
      {
        startDate: startDate,
        endDate: endDate,
        key: "selection",
      },
    ]);
  }, [value]);

  useEffect(() => {
    if (isCustom) {
      setCustomRange("");
    }
  }, [isCustom]);

  const handleClose = () => {
    setOpen(false);

    if (value.isCustom) {
      setState([value]);
      setIsCustom(true);
    } else {
      setCustomRange(value.range);
      setIsCustom(false);
      let startDate = addDays(new Date(), rangeMap[value.range]);
      let endDate = new Date();

      if (value.range.includes("Last")) {
        const today = new Date();
        const firstDayOfLastMonth = subMonths(
          startOfMonth(today),
          rangeMap[value.range]
        );
        const lastDayOfLastMonth = endOfMonth(
          subMonths(startOfMonth(today), 1)
        );
        startDate = firstDayOfLastMonth;
        endDate = lastDayOfLastMonth;
      }

      if (value.range.includes("Next")) {
        const today = new Date();
        const edate = new Date();
        //  today.setDate(today.getDate() + 1);
        edate.setDate(edate.getDate() - 1);
        today.setDate(today.getDate());
        startDate = today;
        endDate = addDays(edate, rangeMap[value.range]);
      }
      setState([
        {
          startDate: startDate,
          endDate: endDate,
          key: "selection",
        },
      ]);
    }
  };

  const preDefinedPickerHandler = (val) => {
    const obj = {
      isCustom: false,
      range: val,
    };
    graphClear();
    pickerHandler(obj);
    setOpen(false);
  };

  const custormPicker = (val) => {
    setState([val.selection]);
    setCustomRange("");
    setIsCustom(true);
  };

  const applyHandler = () => {
    graphClear();
    state[0].isCustom = true;
    state[0].range = timeDifference(state[0].startDate, state[0].endDate);
    pickerHandler(state[0]);
    setOpen(false);
  };

  const graphClear = () => {
    if (frstGraphClear) frstGraphClear();
    if (secGraphClear) secGraphClear();
  };

  return (
    <Dialog
      maxWidth="sm"
      open={open}
      onClose={handleClose}
      BackdropProps={{
        classes: {
          root: classes.root,
        },
      }}
    >
      <DialogContent style={{ overflow: "hidden" }}>
        <Grid container>
          <Grid item xs={12}>
            {isCustom ? (
              <div className={classes.header}>
                <List component="nav" style={{ paddingTop: 0 }}>
                  <ListItem
                    button
                    selected={isCustom}
                    onClick={() => setIsCustom((pre) => !pre)}
                  >
                    <ListItemText fontWeight={900}>Custom</ListItemText>
                  </ListItem>
                </List>
              </div>
            ) : (
              <div className={classes.headers}>
                <List component="nav" style={{ paddingTop: 0 }}>
                  <ListItem
                    button
                    selected={isCustom}
                    onClick={() => setIsCustom((pre) => !pre)}
                  >
                    <ListItemText fontWeight={900}>Custom</ListItemText>
                  </ListItem>
                </List>
              </div>
            )}
            {/* <Divider /> */}
            <div className={classes.body}>
              <List
                component="nav"
                aria-label="main mailbox folders"
                className="d-flex flex-row flex-wrap pt-0 mb-2 px-2"
              >
                {Range.map((val, i) => (
                  <ListItem
                    className="m-1"
                    style={{ margin: "0", width: "fit-content" }}
                    key={i + "list"}
                    button
                    selected={customRange === val}
                    onClick={() => preDefinedPickerHandler(val)}
                  >
                    <ListItemText primary={val} />
                  </ListItem>
                ))}
              </List>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.dateRangeItems}>
              <DateRange
                onChange={(item) => custormPicker(item)}
                showSelectionPreview={true}
                moveRangeOnFirstSelection={false}
                months={2}
                maxDate={addDays(new Date(), enableAi ? futureDates - 1 : 0)}
                ranges={state}
                direction="vertical"
                editableDateInputs={true}
                scroll={{
                  enabled: true,
                }}
                fixedHeight={true}
                rangeColors={["#5EBDCC"]}
              />
            </div>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        {isCustom && (
          <>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={applyHandler} color="primary">
              Apply
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
}
