import React from "react";
import pageNotFound from "../../assets/pageNotFound.svg";

const PageNotFound = () => {
  return (
    <div
      style={{ height: "65vh" }}
      className="d-flex align-items-center justify-content-center"
    >
      <div className="text-center">
        <img
          src={pageNotFound}
          alt="pageNotFound"
          width={200}
          height={200}
          style={{ fill: "white" }}
        ></img>
        <p>
          We will notify you once your data is imported to Itechgenic. It will take
          few hours to import the data from your account.
        </p>
      </div>
    </div>
  );
};

export default PageNotFound;
