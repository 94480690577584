import React, { useContext, useMemo } from "react";
import { style } from "../../Project/projectDashboard/style";
import { Card, CardContent, Grid } from "@material-ui/core";
import teamMemberImg from "../../../assets/users.png";
import codeChurn from "../../../assets/codeChurn.png";
import WIPimg from "../../../assets/WIP.jpeg";
import flowEfficiencyImg from "../../../assets/FlowEfficiency.jpeg";
import utilizationVsPerformanceIcon from "../../../assets/UtilizationvsPerformance.jpeg";
import msg from "../../../assets/msg.png";
import check from "../../../assets/check.png";
import CardDetail from "./cardDetail";
import axios from "../../../utils/axiosHelper";
import { useState } from "react";
import { useEffect } from "react";
import { config } from "../../../Config/permission";
import basket from "../../../assets/basket.png";
import { GlobalData } from "../../../context/globalData";
import pr from "../../../assets/pr.png";
import commit from "../../../assets/commit.png";
import UtilizationPerformance from "../../../components/utilizationVsPerformance";
import {
  addLeadingZeroToDateRange,
  datePayload,
} from "../../../utils/moduleTabApi";
import InfoIcon from "@material-ui/icons/Info";
import { Tooltip } from "@mui/material";
import HealthColorWrapper from "../../../components/overview/healthColorWrapper";
import { getMetricRange } from "../../../utils/getMetricRange";

const useStyles = style;
function Header({
  projectId,
  setChildViewAll,
  date,
  teamTrend,
  teamUrl,
  epic,
}) {
  const dateRange = datePayload(date);
  const dateUrl = addLeadingZeroToDateRange(dateRange);
  const [codeChern, setCodeChern] = useState();
  const [teamMember, setTeamMember] = useState();
  const [storyCompleted, setStoryCompleted] = useState();
  const [commitWithout, setCommitWithout] = useState();
  const [viewAll, setViewAll] = useState(false);
  const [teamAvgAging, setTeamAvgAging] = useState();
  const [range, setRange] = useState([]);
  const [teamFlowEfficiency, setTeamFlowEfficiency] = useState();
  const [isUtilizationOpen, setIsUtilizationOpen] = useState(false);

  useEffect(() => {
    axios
      .get(`/api/risks?ProjectId=${projectId}`)
      .then((result) => {
        setRange(result?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [projectId]);

  const getCodeChern = () => {
    let url = `/api/v2/teams/workBreakdown/${projectId}`;
    if (epic) {
      url += epic;
    }
    axios
      .get(url)
      .then((res) => {
        setCodeChern(res?.data?.graph?.Rework);
      })
      .catch((err) => {
        console.log("error", err);
      });
  };
  const getTeamMember = () => {
    let url = `/api/v2/teams/teamMember/${projectId}`;
    if (epic) {
      url += epic;
    }
    axios
      .get(url)
      .then((res) => {
        setTeamMember(res?.data);
      })
      .catch((err) => {
        console.log("error", err);
      });
  };
  useEffect(() => {
    setChildViewAll(viewAll);
  }, [viewAll]);

  const getStoryPointCompleted = () => {
    let url = `/api/v2/teams/planningAccuracy/${projectId}`;
    if (epic) {
      url += epic;
    }
    axios
      .get(url)
      .then((res) => {
        setStoryCompleted(res?.data?.resData?.Done);
      })
      .catch((err) => {
        console.log("error", err);
      });
  };
  const getCommitWithout = () => {
    let url = `/api/v2/teams/commitwithout/${projectId}`;
    if (epic) {
      url += epic;
    }
    axios
      .get(url)
      .then((res) => {
        setCommitWithout(res?.data);
      })
      .catch((err) => {
        console.log("error", err);
      });
  };
  // Avg Aging api
  const avgAging = () => {
    const multipleTeam = `/api/v2/projects/avgAging/multipleTeam/${projectId}?${dateUrl}${teamUrl}`;
    const mainApi = `/api/v2/projects/avgAging/${projectId}?${dateUrl}`;
    let url = teamUrl ? multipleTeam : mainApi;
    if (epic) {
      url += `&${epic.substring(1)}`;
    }
    axios
      .get(url)
      .then((res) => {
        setTeamAvgAging(res?.data);
      })
      .catch((err) => {
        console.log("error", err);
      });
  };

  // flow Efficiency api
  const flowEfficiency = () => {
    const multipleTeam = `/api/v2/projects/flowEfficiency/multipleTeam/${projectId}?${dateUrl}${teamUrl}`;
    const mainApi = `/api/v2/projects/flowEfficiency/${projectId}?${dateUrl}`;
    let url = teamUrl ? multipleTeam : mainApi;
    if (epic) {
      url += `&${epic.substring(1)}`;
    }
    axios
      .get(url)
      .then((res) => {
        setTeamFlowEfficiency(res?.data?.Efficiency);
      })
      .catch((err) => {
        console.log("error", err);
      });
  };

  useEffect(() => {
    getCodeChern();
    getTeamMember();
    getStoryPointCompleted();
    getCommitWithout();
  }, [epic]);

  useEffect(() => {
    avgAging();
    flowEfficiency();
  }, [teamUrl, date, epic]);
  const { setIsDefinaionPopup, setDefinationKPI } = useContext(GlobalData);

  const classes = useStyles();
  const viewMoreHandler = (name) => {
    teamTrend(name);
  };

  useEffect(() => {
    teamTrend();
  }, [isUtilizationOpen]);

  const WorkInProgressAging = useMemo(
    () =>
      getMetricRange(range, teamAvgAging?.Time, "Work In Progess Aging (Days)"),
    [teamAvgAging?.Time, range]
  );

  const AverageFlowEfficiencyRisk = useMemo(
    () => getMetricRange(range, teamFlowEfficiency, "Average Flow Efficiency"),
    [teamFlowEfficiency, range]
  );

  return (
    <Grid container spacing={2} style={{ marginBottom: "20px" }}>
      {/* <Grid item xs={3}>
        <Card className={`${classes.card} customCard position-relative`}>
          <CardContent>
            <div className={classes.cardContaner}>
              <div className={classes.icon}>
                <img src={basket} alt="basket" />
              </div>
              <div className={classes.textContianer}>
                <h3 style={{ fontSize: "24px" }}>{"00"} %</h3>
                <h6>
                  Capacity Accuracy
                  <span
                    onClick={() => {
                      setIsDefinaionPopup(true);
                      setDefinationKPI("Capacity Accuracy");
                    }}
                  >
                    <InfoIcon style={{ color: "gray", marginLeft: "8px" }} />
                  </span>
                </h6>
                <Tooltip
                  title={
                    <>
                      <h6>Status: {"High"}</h6>
                      <h6>
                        Target: {"10"}% to {"100"}%
                      </h6>
                    </>
                  }
                >
                  <div className="metricStatus">
                    <RiskColorWrapperWithIcon
                      className="metricChips"
                      riskLevel={"High"}
                    ></RiskColorWrapperWithIcon>
                  </div>
                </Tooltip>
              </div>
            </div>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={3}>
        <Card className={`${classes.card} customCard`}>
          <CardContent>
            <CardDetail
              value={"00.0"}
              img={check}
              name={`Work Complete (${config.type})`}
              classes={classes}
              risk={getStatus(codeChern, 15)}
            />
          </CardContent>
        </Card>
      </Grid>
      {config.team.codeChurn && (
        <Grid item xs={3}>
          <Card className={`${classes.card} customCard`}>
            <CardContent>
              <CardDetail
                value={codeChern}
                img={codeChurn}
                name={"Code Churn"}
                classes={classes}
                risk={getStatus(codeChern, 15)}
              />
            </CardContent>
          </Card>
        </Grid>
      )} */}
      {/* <Grid item xs={3}>
        <Card className={`${classes.card} customCard`}>
          <CardContent>
            <CardDetail
              value={codeChern}
              img={utilization}
              name={"Utilization"}
              classes={classes}
            />
          </CardContent>
        </Card>
      </Grid> */}
      <Grid item xs={3}>
        <Card className={`${classes.card} customCard`}>
          <CardContent>
            <CardDetail
              value={teamMember}
              img={teamMemberImg}
              name={"Number Of Team Member"}
              classes={classes}
            />
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={3}>
        {/* <Card className={`${classes.card} customCard`}>
          <CardContent>
            <CardDetail
              img={utilizationVsPerformanceIcon}
              name={"Utilization vs Performance"}
              classes={classes}
              viewMore={true}
            />
          </CardContent>
        </Card> */}
        <Card className={`${classes.card} customCard`}>
          <CardContent>
            <div className={classes.cardContaner}>
              <div
                className={classes.icon}
                style={{ backgroundColor: "#fdeeee" }}
              >
                <img src={utilizationVsPerformanceIcon} alt="basket" />
              </div>
              <div className={classes.textContianer}>
                <>
                  <div
                    style={{
                      fontSize: "18px",
                    }}
                    className="mb-1"
                  >
                    <div>Utilization vs Performance</div>
                  </div>
                </>
                <h6>
                  <span
                    className={classes.viewMore}
                    onClick={() => setIsUtilizationOpen(true)}
                  >
                    View More
                  </span>
                </h6>
              </div>
            </div>
          </CardContent>
        </Card>
      </Grid>
      {isUtilizationOpen && (
        <UtilizationPerformance
          open={isUtilizationOpen}
          handleClose={() => setIsUtilizationOpen(false)}
          classes={classes}
        />
      )}
      <Grid item xs={3}>
        <Card className={`${classes.card} customCard position-relative py-2`}>
          <CardContent>
            <div className={classes.cardContaner}>
              <div
                className={classes.icon}
                style={{ backgroundColor: "#fdeeee" }}
              >
                <img src={WIPimg} alt="basket" />
              </div>
              <div className={classes.textContianer}>
                <>
                  <div
                    style={{
                      fontSize: "18px",
                    }}
                  >
                    <div>
                      Work In Progress - {teamAvgAging?.StoryPoints}{" "}
                      <span
                        onClick={() => {
                          setIsDefinaionPopup(true);
                          setDefinationKPI("Work In Progress");
                        }}
                      >
                        <InfoIcon
                          style={{ color: "gray", marginLeft: "8px" }}
                        />
                      </span>
                    </div>
                  </div>
                  <div
                    style={{
                      fontSize: "18px",
                    }}
                    className="mb-1"
                  >
                    <div> Avg Age - {teamAvgAging?.Time}</div>
                  </div>
                </>
                <h6>
                  <span
                    className={classes.viewMore}
                    onClick={() =>
                      viewMoreHandler(
                        "WIP Average Age"
                        // resourceAllocationStatusListData
                      )
                    }
                  >
                    View More
                  </span>
                </h6>
                <Tooltip
                  title={
                    <>
                      <h6>Status: {WorkInProgressAging?.RiskCategory} Risk</h6>
                      <h6>
                        Target: {WorkInProgressAging?.From?.$numberDecimal}% to{" "}
                        {WorkInProgressAging?.To?.$numberDecimal}%
                      </h6>
                    </>
                  }
                >
                  <div className="metricStatus">
                    <HealthColorWrapper
                      status={WorkInProgressAging?.RiskCategory}
                    ></HealthColorWrapper>
                  </div>
                </Tooltip>
              </div>
            </div>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={3}>
        <Card className={`${classes.card} customCard position-relative py-2`}>
          <CardContent>
            <div className={classes.cardContaner}>
              <div
                className={classes.icon}
                style={{ backgroundColor: "#fdeeee" }}
              >
                <img src={flowEfficiencyImg} alt="basket" />
              </div>
              <div className={classes.textContianer}>
                <h3 style={{ fontSize: "24px" }}>{teamFlowEfficiency} %</h3>
                <h6>
                  Average Flow Efficiency{" "}
                  <span
                    onClick={() => {
                      setIsDefinaionPopup(true);
                      setDefinationKPI("Average Flow Efficiency");
                    }}
                  >
                    <InfoIcon style={{ color: "gray", marginLeft: "8px" }} />
                  </span>
                  <br />
                  <span
                    className={classes.viewMore}
                    onClick={() =>
                      viewMoreHandler(
                        "Average Flow Efficiency"
                        // resourceAllocationStatusListData
                      )
                    }
                  >
                    View More
                  </span>
                </h6>
                <Tooltip
                  title={
                    <>
                      <h6>
                        Status: {AverageFlowEfficiencyRisk?.RiskCategory} Risk
                      </h6>
                      <h6>
                        Target:{" "}
                        {AverageFlowEfficiencyRisk?.From?.$numberDecimal}% to{" "}
                        {AverageFlowEfficiencyRisk?.To?.$numberDecimal}%
                      </h6>
                    </>
                  }
                >
                  <div className="metricStatus">
                    <HealthColorWrapper
                      status={AverageFlowEfficiencyRisk?.RiskCategory}
                    ></HealthColorWrapper>
                  </div>
                </Tooltip>
              </div>
            </div>
          </CardContent>
        </Card>
      </Grid>

      {/* {config.team.commitWithoutTicketRef && (
        <Grid item xs={3}>
          <Card className={`${classes.card} customCard position-relative`}>
            <CardContent>
              <div className={classes.cardContaner}>
                <div
                  className={classes.icon}
                  style={{ backgroundColor: "#fdeeee" }}
                >
                  <img src={msg} alt="basket" />
                </div>
                <div className={classes.textContianer}>
                  <h3 style={{ fontSize: "24px" }}>{"348/1912 (00.00 %)"}</h3>
                  <h6>
                    Commit Without Ticket Reference
                    <span
                      onClick={() => {
                        setIsDefinaionPopup(true);
                        setDefinationKPI("Commit without ticket reference");
                      }}
                    >
                      <InfoIcon style={{ color: "gray", marginLeft: "8px" }} />
                    </span>
                    <br />
                    <span
                      className={classes.viewMore}
                      // onClick={() =>
                      //   viewMoreHandler(
                      //     "Commit Without Ticket Reference"
                      //     // ResourceAllocationStatusList
                      //   )
                      // }
                    >
                      View More
                    </span>
                  </h6>
                  <Tooltip
                    title={
                      <>
                        <h6>Status: {"Low"} Risk</h6>
                        <h6>
                          Target: {"10"}% to {"20"}%
                        </h6>
                      </>
                    }
                  >
                    <div className="metricStatus">
                      <RiskColorWrapperWithIcon
                        className="metricChips"
                        riskLevel={"Low"}
                      ></RiskColorWrapperWithIcon>
                    </div>
                  </Tooltip>
                </div>
              </div>
            </CardContent>
          </Card>
        </Grid>
      )}
      {config.team.unReviewedPR && (
        <Grid item xs={3}>
          <Card className={`${classes.card} customCard position-relative`}>
            <CardContent>
              <div className={classes.cardContaner}>
                <div
                  className={classes.icon}
                  style={{ backgroundColor: "#fdeeee" }}
                >
                  <img src={pr} alt="basket" />
                </div>
                <div className={classes.textContianer}>
                  <h3 style={{ fontSize: "24px" }}>{"0/00 (0.00 %)"}</h3>
                  <h6>
                    Unreviewed PR
                    <span
                      onClick={() => {
                        setIsDefinaionPopup(true);
                        setDefinationKPI("Unreviewed PRs");
                      }}
                    >
                      <InfoIcon style={{ color: "gray", marginLeft: "8px" }} />
                    </span>
                    <br />
                    <span
                      className={classes.viewMore}
                      // onClick={() =>
                      //   viewMoreHandler(
                      //     "Unreviewed PR",
                      //     prReivewStatusList,
                      //     peReivewSubStatus
                      //   )
                      // }
                    >
                      View More
                    </span>
                  </h6>
                  <Tooltip
                    title={
                      <>
                        <h6>Status: {"Medium"} Risk</h6>
                        <h6>
                          Target: {"20"}% to {"40"}%
                        </h6>
                      </>
                    }
                  >
                    <div className="metricStatus">
                      <RiskColorWrapperWithIcon
                        className="metricChips"
                        riskLevel={"Medium"}
                      ></RiskColorWrapperWithIcon>
                    </div>
                  </Tooltip>
                </div>
              </div>
            </CardContent>
          </Card>
        </Grid>
      )} */}
      {/* {config.team.CommitWithPullRequest && (
        <Grid item xs={3}>
          <Card className={`${classes.card} customCard position-relative`}>
            <CardContent>
              <div className={classes.cardContaner}>
                <div
                  className={classes.icon}
                  style={{ backgroundColor: "#fdeeee" }}
                >
                  <img src={commit} alt="basket" />
                </div>
                <div className={classes.textContianer}>
                  <h3 style={{ fontSize: "24px" }}>{"000/000 (00 %)"}</h3>
                  <h6>
                    Commit with pull request
                    <span
                      onClick={() => {
                        setIsDefinaionPopup(true);
                        setDefinationKPI("Commit without pull request");
                      }}
                    >
                      <InfoIcon style={{ color: "gray", marginLeft: "8px" }} />
                    </span>
                    <br />
                    <span
                      className={classes.viewMore}
                      // onClick={() =>
                      //   viewMoreHandler(
                      //     "Commit with pull request"
                      //     // ResourceAllocationStatusList
                      //   )
                      // }
                    >
                      View More
                    </span>
                  </h6>
                  <Tooltip
                    title={
                      <>
                        <h6>Status: {"Low"} Risk</h6>
                        <h6>
                          Target: {24}% to {34}%
                        </h6>
                      </>
                    }
                  >
                    <div className="metricStatus">
                      <RiskColorWrapperWithIcon
                        className="metricChips"
                        riskLevel={"Low"}
                      ></RiskColorWrapperWithIcon>
                    </div>
                  </Tooltip>
                </div>
              </div>
            </CardContent>
          </Card>
        </Grid>
      )} */}

      {/* {viewAll && (
        <>
          {config.team.prComments && (
            <Grid item xs={3}>
              <Card className={`${classes.card} customCard position-relative`}>
                <CardContent>
                  <div className={classes.cardContaner}>
                    <div
                      className={classes.icon}
                      style={{ backgroundColor: "#fdeeee" }}
                    >
                      <img src={commit} alt="basket" />
                    </div>
                    <div className={classes.textContianer}>
                      <h3 style={{ fontSize: "24px" }}>{"00 / 00"}</h3>
                      <span className={classes.subDescription}>
                        (No. of PR/no. of comments)
                      </span>
                      <h6>
                        Pr Comments
                        <span
                          onClick={() => {
                            setIsDefinaionPopup(true);
                            setDefinationKPI("Pr comments");
                          }}
                        >
                          <InfoIcon
                            style={{ color: "gray", marginLeft: "8px" }}
                          />
                        </span>
                      </h6>
                      <Tooltip
                        title={
                          <>
                            <h6>Status: {"Low"} Risk</h6>
                            <h6>
                              Target: {"0"}% to {"5"}%
                            </h6>
                          </>
                        }
                      >
                        <div className="metricStatus">
                          <RiskColorWrapperWithIcon
                            className="metricChips"
                            riskLevel={"Low"}
                          ></RiskColorWrapperWithIcon>
                        </div>
                      </Tooltip>
                    </div>
                  </div>
                </CardContent>
              </Card>
            </Grid>
          )}
          {config.team.prReviewTime && (
            <Grid item xs={3}>
              <Card className={`${classes.card} customCard position-relative`}>
                <CardContent>
                  <div className={classes.cardContaner}>
                    <div
                      className={classes.icon}
                      style={{ backgroundColor: "#fdeeee" }}
                    >
                      <img src={commit} alt="basket" />
                    </div>
                    <div className={classes.textContianer}>
                      <h3 style={{ fontSize: "24px" }}>{"0.00"} (Days)</h3>
                      <h6>
                        Pr Review Time
                        <span
                          onClick={() => {
                            setIsDefinaionPopup(true);
                            setDefinationKPI("Pr review time");
                          }}
                        >
                          <InfoIcon
                            style={{ color: "gray", marginLeft: "8px" }}
                          />
                        </span>
                      </h6>
                      <Tooltip
                        title={
                          <>
                            <h6>Status: {"High"} Risk</h6>
                            <h6>
                              Target:{"15"}% to {"40"}%
                            </h6>
                          </>
                        }
                      >
                        <div className="metricStatus">
                          <RiskColorWrapperWithIcon
                            className="metricChips"
                            riskLevel={"High"}
                          ></RiskColorWrapperWithIcon>
                        </div>
                      </Tooltip>
                    </div>
                  </div>
                </CardContent>
              </Card>
            </Grid>
          )}
          {config.team.firstTimePassIssue && (
            <Grid item xs={3}>
              <Card className={`${classes.card} customCard position-relative`}>
                <CardContent>
                  <div className={classes.cardContaner}>
                    <div
                      className={classes.icon}
                      style={{ backgroundColor: "#fdeeee" }}
                    >
                      <img src={commit} alt="basket" />
                    </div>
                    <div className={classes.textContianer}>
                      <h3 style={{ fontSize: "24px" }}>
                        {"00 / 00 (00.00 %)"}
                      </h3>
                      <h6>
                        First Time Pass Issue
                        <span
                          onClick={() => {
                            setIsDefinaionPopup(true);
                            setDefinationKPI("First Time pass issue");
                          }}
                        >
                          <InfoIcon
                            style={{ color: "gray", marginLeft: "8px" }}
                          />
                        </span>
                      </h6>
                      <Tooltip
                        title={
                          <>
                            <h6>Status: {"Medium"} Risk</h6>
                            <h6>
                              Target: {"50"}% to {"60"}%
                            </h6>
                          </>
                        }
                      >
                        <div className="metricStatus">
                          <RiskColorWrapperWithIcon
                            className="metricChips"
                            riskLevel={"Medium"}
                          ></RiskColorWrapperWithIcon>
                        </div>
                      </Tooltip>
                    </div>
                  </div>
                </CardContent>
              </Card>
            </Grid>
          )}
          {config.team.receptiveness && (
            <Grid item xs={3}>
              <Card className={`${classes.card} customCard position-relative`}>
                <CardContent>
                  <div className={classes.cardContaner}>
                    <div
                      className={classes.icon}
                      style={{ backgroundColor: "#fdeeee" }}
                    >
                      <img src={commit} alt="basket" />
                    </div>
                    <div className={classes.textContianer}>
                       {commentsAddressed?.CommentsAddressed:/commentsAddressed?.PRCount}  
                      <h3 style={{ fontSize: "24px" }}>{"00/00 (00.00 %)"}</h3>
                      <span className={classes.subDescription}>
                        (No of code Revision / No. of Pull Req)
                      </span>
                      <h6>
                        Receptiveness
                        <span
                          onClick={() => {
                            setIsDefinaionPopup(true);
                            setDefinationKPI("Receptiveness");
                          }}
                        >
                          <InfoIcon
                            style={{ color: "gray", marginLeft: "8px" }}
                          />
                        </span>
                      </h6>
                      <Tooltip
                        title={
                          <>
                            <h6>Status: {"Low"} Risk</h6>
                            <h6>
                              Target: {"45"}% to {"80"}%
                            </h6>
                          </>
                        }
                      >
                        <div className="metricStatus">
                          <RiskColorWrapperWithIcon
                            className="metricChips"
                            riskLevel={"Low"}
                          ></RiskColorWrapperWithIcon>
                        </div>
                      </Tooltip>
                    </div>
                  </div>
                </CardContent>
              </Card>
            </Grid>
          )}
          {config.team.commentsAddressed && (
            <Grid item xs={3}>
              <Card className={`${classes.card} customCard position-relative`}>
                <CardContent>
                  <div className={classes.cardContaner}>
                    <div
                      className={classes.icon}
                      style={{ backgroundColor: "#fdeeee" }}
                    >
                      <img src={commit} alt="basket" />
                    </div>
                    <div className={classes.textContianer}>
                      <h3 style={{ fontSize: "24px" }}>54/65 ({((54 / 65) * 100).toFixed(2)} %)</h3> 
                      <h3 style={{ fontSize: "24px" }}>{"00/000 (00.00 %)"}</h3>

                      <span className={classes.subDescription}>
                        (No. of comments responded / No. of Pull Req)
                      </span>
                      <h6>
                        Comments Addressed
                        <span
                          onClick={() => {
                            setIsDefinaionPopup(true);
                            setDefinationKPI("Comments addressed");
                          }}
                        >
                          <InfoIcon
                            style={{ color: "gray", marginLeft: "8px" }}
                          />
                        </span>
                      </h6>
                      <Tooltip
                        title={
                          <>
                            <h6>Status: {"High"} Risk</h6>
                            <h6>
                              Target: {"85"}% to {"100"}%
                            </h6>
                          </>
                        }
                      >
                        <div className="metricStatus">
                          <RiskColorWrapperWithIcon
                            className="metricChips"
                            riskLevel={"High"}
                          ></RiskColorWrapperWithIcon>
                        </div>
                      </Tooltip>
                    </div>
                  </div>
                </CardContent>
              </Card>
            </Grid>
          )}
        </>
      )} */}
    </Grid>
  );
}

export default Header;
