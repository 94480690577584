import React from "react";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { truncateWithEllipsis } from "@amcharts/amcharts4/.internal/core/utils/Utils";

const SelectInvestmentProfile = ({
  handleChange,
  list,
  value,
  iteration,
  isAll,
}) => {
  return (
    <>
      {!iteration === true ? (
        <TextField
          select
          value={value ?? ""}
          onChange={handleChange}
          variant="outlined"
          size="small"
          InputProps={{
            style: {
              borderRadius: "40px",
              marginRight: "20px",
              paddingRight: "10px",
            },
          }}
        >
          {isAll === true && <MenuItem value="All">All</MenuItem>}
          {list?.map((item) => (
            <MenuItem key={item?.Id} value={item?.Id}>
              {item?.Name}
            </MenuItem>
          ))}
        </TextField>
      ) : (
        <TextField
          select
          value={value ?? ""}
          onChange={handleChange}
          variant="outlined"
          size="small"
          InputProps={{
            style: {
              borderRadius: "40px",
              marginRight: "20px",
              paddingRight: "10px",
              // width: "130px",
            },
          }}
        >
          {isAll === true && <MenuItem value="All">All</MenuItem>}
          {list?.map((item) => (
            <MenuItem key={item} value={item}>
              {item}
            </MenuItem>
          ))}
        </TextField>
      )}

      {/* Do not delete below code */}
      {/* <FormControl variant="outlined">
        <Select
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          value={value ?? ""}
          onChange={handleChange}
        >
          {list?.map((item) => (
            <MenuItem key={item?.Id} value={item?.Id}>
              {item?.Name}
            </MenuItem>
          ))}
        </Select>
      </FormControl> */}
    </>
  );
};

export default SelectInvestmentProfile;
