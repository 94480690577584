import { makeStyles } from "@material-ui/core";

export const style = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(1, 2, 2),
    top: "50%",
    left: "50%",
    transform: "translate(-50% ,-50%)",
    textAlign: "center",
    borderRadius: "20px",
    outline: "none",
  },
  boxContainer: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 250px)",
    gridTemplateRows: "repeat(2, 250px)",
    justifyContent: "center",
  },
  mainContainer: {
    display: "flex",
    gap: "20px",
    justifyContent: "center",
    alignItems: "center",
  },
  verticalArrow: {
    writingMode: "vertical-lr",
  },
  block1: {
    background: "#EB5757",
  },
  block3: {
    background: "#5EBDCC",
  },
  block2: {
    background: "#F2994A",
  },
  block4: {
    background: "#219653",
  },

  topArrow: {
    transform: "rotateZ(90deg)",
  },
  bottomArrow: {
    transform: "rotateZ(270deg)",
  },
  rightArrow: {
    transform: "rotateZ(180deg)",
  },
  graphWrapper: {
    position: "relative"
  },
  graphSuggetions: {
    position: 'absolute',
    right: 30,
    top: 63,
    zIndex: 1,
    "& > div": {
      marginBottom: "10px",
      maxWidth: "550px"
    }
  },
}));
