import React, { useState } from "react";
import RiskWrapper from "../../../components/overview/riskWrapper";
import { Box } from "@material-ui/core";
import UtilizationPerformance from "../../../components/utilizationVsPerformance";

function CardDetail({ classes, img, value, name, icon, risk, viewMore }) {
  const [isUtilizationOpen, setIsUtilizationOpen] = useState(false);

  return (
    <>
      <div className={classes.cardContaner}>
        <div className={classes.icon}>
          {img && <img src={img} alt="img" />}
          {icon && (
            <i
              className={icon}
              style={{
                fontSize: "20px",
                color: "green",
              }}
            ></i>
          )}
        </div>
        <div className={classes.textContianer}>
          <h3 style={{ fontSize: "24px" }}>{value}</h3>
          <h6>{name}</h6>
          {risk && (
            <Box display={"flex"}>
              Risk : <RiskWrapper>{risk}</RiskWrapper>
            </Box>
          )}
          {viewMore && (
            <h6
              className="viewMore"
              style={{ textDecoration: "underline" }}
              onClick={() => setIsUtilizationOpen(true)}
            >
              View More
            </h6>
          )}
        </div>
      </div>
      {isUtilizationOpen && (
        <UtilizationPerformance
          open={isUtilizationOpen}
          handleClose={() => setIsUtilizationOpen(false)}
          classes={classes}
        />
      )}
    </>
  );
}

export default CardDetail;
