import ExcelJS from "exceljs";
import saveAs from "file-saver";
const workbook = new ExcelJS.Workbook();

export const ExcelExport = (
  header,
  row,
  fileName,
  level,
  isSummary,
  subLevel,
  subTab,
  riskType
) => {
  let newHeader = [];
  header.map((data, i) => {
    newHeader.push({
      header: data,
      key: data,
      width: 30,
      id: i + 1,
      style:
        data === "Deviation"
          ? {
            numFmt: "0%",
            alignment: { vertical: "middle", horizontal: "center", size: 12 },
          }
          : data === "Metric" ||
            data === "Project" ||
            data === "Team" ||
            data === "Module" ||
            data === "Resource"
            ? { alignment: { vertical: "middle", horizontal: "left", size: 12 } }
            : {
              alignment: { vertical: "middle", horizontal: "center", size: 12 },
            },
    });
  });

  workbook.views = [
    {
      x: 0,
      y: 0,
      width: 10000,
      height: 20000,
      firstSheet: 10,
      activeTab: 3,
      // visibility: "visible",
    },
  ];
  //isSummary?fileName+'-'+"Summary":fileName,
  const sheet = workbook.addWorksheet(fileName, {
    views: [
      {
        state: "frozen",
        ySplit: 1,
        xSplit: level === "risk" ? 0 : level === "RESOURCES" ? 2 : 1,
      },
    ],
  });

  sheet.autoFilter = {
    from: "A1",
    to: "AT1",
  };

  sheet.columns = newHeader;

  row.forEach((val) => {
    val = val.flat();

    sheet.addRow(val);
  });

  const Col2 = sheet.getColumn(2);

  Col2.eachCell(function (cell, rowNumber) {
    cell.alignment = {
      vertical: "middle",
      horizontal: level === "RESOURCES" || level === "risk" ? "left" : "center",
    };
  });

  // Col3.eachCell(function (cell, rowNumber) {
  //   cell.alignment = {
  //     vertical: "middle",
  //     horizontal:
  //       level === "risk" || subLevel === "Resource" ? "left" : "center",
  //     wrapText: true,
  //   };
  // });
  sheet.eachRow({ includeEmpty: true }, function (row, rowNumber) {
    row.eachCell(function (cell, colNumber) {
      cell.font = {
        size: 12,
      };
    });
  });
  sheet.getRow(1).eachCell((cell, colNumber) => {
    sheet.getRow(1).getCell(colNumber).font = { color: { argb: "FFFF0000" } };
    // sheet.getRow(1).getCell(colNumber).alignment = { horizontal: "center" };
    if (
      cell.value === "Metric" ||
      cell.value === "Project" ||
      cell.value === "Team" ||
      cell.value === "Module" ||
      cell.value === "Resource"
    ) {
      cell.alignment = {
        wrapText: true,
        vertical: "middle",
        horizontal: "left",
      };
    } else {
      cell.alignment = {
        wrapText: true,
        vertical: "middle",
        horizontal: "center",
      };
    }
    cell.color = { argb: "#fff" };
    cell.fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "56aee4" },
    };
    cell.font = {
      name: "Calibri",
      color: { argb: "ffffff" },
      family: 2,
      size: 14,
      bold: true,
    };
    cell.border = {
      top: { style: "thin" },
      left: { style: "thin" },
      bottom: { style: "thin" },
      right: { style: "thin" },
    };
  });

  // sheet.getRow(1).getCell(1).alignment = {
  //   vertical: "middle",
  //   horizontal: "left",
  // };

  newHeader.map((data) => {
    const rows = sheet.getRow(data.id + 1);

    rows.eachCell((cell, rowNumber) => {
      // sheet.getRow(data.id + 1).getCell(1).alignment = {
      //   vertical: "middle",
      //   horizontal: "left",

      //   size: 12,
      // };
      if (cell.value === "High") {
        // cell.alignment = {
        //   vertical: "middle",
        //   horizontal: "center",
        // };
        cell.font = { bold: true, color: { argb: "ffffff" }, size: 12 };
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          color: { argb: "ffffff" },
          fgColor: { argb: "eb5757" },
        };
      } else if (cell.value === "Medium") {
        // cell.alignment = {
        //   vertical: "middle",
        //   horizontal: "center",
        // };
        cell.font = { bold: true, color: { argb: "ffffff" }, size: 12 };
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          color: { argb: "ffffff" },
          fgColor: { argb: "f2994a" },
        };
      } else if (cell.value === "Low") {
        // cell.alignment = {
        //   vertical: "middle",
        //   horizontal: "center",
        // };
        cell.font = { bold: true, color: { argb: "ffffff" }, size: 12 };
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "219653" },
        };
      } else {
        cell.font = { size: 12 };
      }
    });
    const lebal = sheet.getColumn(data.id + 2);

    if (data.id > 1) {
      lebal.eachCell((cell, rowNumber) => {
        // cell.alignment = {
        //   vertical: "middle",
        //   horizontal: "center",
        //   wrapText: true,
        //   size: 12,
        // };

        let str = cell.value;

        if (Math.sign(str) === -1) {
          cell.font = { color: { argb: "eb5757" }, size: 12 };
        }

        if (cell.value === "High") {
          // cell.alignment = {
          //   vertical: "middle",
          //   horizontal: "center",
          // };
          cell.font = { bold: true, color: { argb: "ffffff" }, size: 12 };
          cell.fill = {
            type: "pattern",
            pattern: "solid",
            color: { argb: "ffffff" },
            fgColor: { argb: "eb5757" },
          };
        } else if (cell.value === "Medium") {
          // cell.alignment = {
          //   vertical: "middle",
          //   horizontal: "center",
          // };
          cell.font = { bold: true, color: { argb: "ffffff" }, size: 12 };
          cell.fill = {
            type: "pattern",
            pattern: "solid",
            color: { argb: "ffffff" },
            fgColor: { argb: "f2994a" },
          };
        } else if (cell.value === "Low") {
          // cell.alignment = {
          //   vertical: "middle",
          //   horizontal: "center",
          // };
          cell.font = { bold: true, color: { argb: "ffffff" }, size: 12 };
          cell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "219653" },
          };
        }
      });
    }
  });

  workbook.xlsx
    .writeBuffer()
    .then((buffer) => {
      saveAs(
        new Blob([buffer], { type: "application/octet-stream" }),
        `${fileName}.xlsx`
      );
    })
    .catch((err) => {
      console.err(`File: ${fileName} save failed: `, err);
    });
  // eslint-disable-next-line no-unused-expressions
  // level === "risk"
  //   ? riskType === "Team" || riskType === "Module"
  //     ? workbook.removeWorksheet(sheet.id)
  //     : null
  // :
  workbook.removeWorksheet(sheet.id);
};

export const MatrixExport = (header, row, fileName) => {
  let newHeader = [];
  let myHeader = ["Metric", fileName];
  for (let i = 1; i < header.length; i++) {
    myHeader.push("");
  }
  myHeader.map((data, i) => {
    newHeader.push({
      header: data,
      key: data,
      width: 30,
      id: i + 1,
      style:
        data === "Metric"
          ? { alignment: { vertical: "middle", horizontal: "left", size: 12 } }
          : {
            alignment: { vertical: "middle", horizontal: "center", size: 12 },
          },
    });
  });
  workbook.views = [
    {
      x: 0,
      y: 0,
      width: 10000,
      height: 20000,
      firstSheet: 10,
      activeTab: 3,
    },
  ];
  const sheet = workbook.addWorksheet(fileName, {
    views: [
      {
        state: "frozen",
        ySplit: (1, 2, 3),
        xSplit: 1,
      },
    ],
  });
  sheet.autoFilter = {
    from: "A3",
    to: "AT3",
  };
  // ws.mergeCells('A1:C1');
  sheet.columns = newHeader;
  row.unshift(header);
  row.unshift("");
  row.forEach((val) => {
    sheet.addRow(val);
  });

  sheet.eachRow({ includeEmpty: true }, function (row) {
    row.eachCell(function (cell) {
      cell.font = {
        size: 12,
      };
    });
  });

  sheet.getRow(1).eachCell((cell, colNumber) => {
    // console.log(cell._address)
    cell._address === "A1"
      ? (cell.font = {
        size: 14,
        bold: true,
      })
      : (cell.font = {
        size: 14,
      });
    cell.alignment = {
      horizontal: "left",
    };
  });
  sheet.mergeCells('B1:C1');

  sheet.getRow(3).eachCell((cell, colNumber) => {
    // cell.width
    cell.color = { argb: "#fff" };
    cell.fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "56aee4" },
    };
    cell.font = {
      name: "Calibri",
      color: { argb: "ffffff" },
      family: 2,
      size: 14,
      bold: true,
    };
    cell.border = {
      top: { style: "thin" },
      left: { style: "thin" },
      bottom: { style: "thin" },
      right: { style: "thin" },
    };
  });

  workbook.xlsx
    .writeBuffer()
    .then((buffer) => {
      saveAs(
        new Blob([buffer], { type: "application/octet-stream" }),
        `${fileName}.xlsx`
      );
    })
    .catch((err) => {
      console.err(`File: ${fileName} save failed: `, err);
    });

  workbook.removeWorksheet(sheet.id);
};
