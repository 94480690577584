import React, { useContext } from "react";
import CommonTable from "../../../../components/commonTable";
import { useState } from "react";
import SelectField from "../../../../components/select/selectField";
import { useEffect } from "react";
import axios from "../../../../utils/axiosHelper";
import { format } from "date-fns";
import { Box, Button, CardHeader } from "@material-ui/core";
import { addSpace } from "../../../../utils/spaceSeprator";
import DateRangeOutlinedIcon from "@material-ui/icons/DateRangeOutlined";
import { GlobalData } from "../../../../context/globalData";
import RangeSelector from "../../../../components/rangeSelector";
import { datePayload, getSelectedDate } from "../../../../utils/moduleTabApi";
import { useRef } from "react";
import CheckSelect from "../../../../components/select/CheckSelect";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { getSecMinHrs } from "../../../../utils/secMinHrs";

function IssueLevel({
  issueType,
  statusList,
  projectId,
  assigneeList,
  sprintId,
  boardId,
  startDate,
  endDate,
  autoScroll = true,
  isDate = true,
  subStatus = [],
  teamId,
  teamName,
  resourceName,
  isCheckList = false,
  teamUrl,
  epic,
  selectedTeamId,
  job,
}) {
  const { projectRangePickerValue } = useContext(GlobalData);
  const [dataList, setDataList] = useState([]);
  const [columnName, setColumnName] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState(statusList[0]);
  const [selectedSubStatus, setSelectedSubStatus] = useState(subStatus[0]);
  const [selectedAssignee, setSelectedAssignee] = useState("All");
  const [selectedAssigneeList, setSelectedAssigneeList] = useState([]);
  const [selectedAssigneeListUrl, setSelectedAssigneeListUrl] = useState("");
  const [openRange, setOpenRange] = useState(false);
  const [date, setDate] = useState(projectRangePickerValue);
  const [duration, setDuration] = useState(0);
  const myDivRef = useRef(null);
  const myDivRefPagination = useRef(null);
  const customRangePickerHandler = (val) => {
    setDate(val);
  };

  useEffect(() => {
    if (selectedAssigneeList?.length > 0) {
      let url = " ";
      selectedAssigneeList.forEach((e, index) => {
        if (index === 0) {
          url += `&assignee=${e}`;
        } else {
          url += `&assignee=${e}`;
        }
      });
      setSelectedAssigneeListUrl(url.trim(), selectedAssigneeList);
    } else {
      setSelectedAssigneeListUrl("", selectedAssigneeList);
    }
  }, [selectedAssigneeList]);

  useEffect(() => {
    if (!selectedStatus || !date) return;
    const dateUrl = datePayload(date);
    if (issueType === "Planning Accuracy") {
      let url = `/api/v2/projects/issuelevelPlanning/${projectId}?status=${selectedStatus}&${dateUrl}`;
      if (selectedAssignee !== "All") {
        url += `&assignee=${selectedAssignee}`;
      }
      if (epic) {
        url += `&${epic.substring(1)}`;
      }
      api(url);
    } else if (issueType === "Work Allocation") {
      let selectedAssigneeUrl = `/api/v2/projects/issuelevelResourceallocation/${projectId}?status=${selectedStatus}${selectedAssigneeListUrl}&${dateUrl}`;
      if (epic) {
        selectedAssigneeUrl += `&${epic.substring(1)}`;
      }
      let url = selectedAssigneeUrl;
      api(url);
    } else if (issueType === `${teamName} Team Work Allocation`) {
      let url = `/api/v2/projects/issuelevelResourceallocation/${projectId}?status=${selectedStatus}&${dateUrl}&teamId=${teamId}`;
      if (selectedAssignee !== "All") {
        url += `&assignee=${selectedAssignee}`;
      }
      if (epic) {
        url += `&${epic.substring(1)}`;
      }
      api(url);
    } else if (issueType == "Sprint completion rate") {
      if (assigneeList && sprintId && boardId && startDate) {
        let selectedAssigneeUrl = `/api/v2/sprints/sprintcompletion?status=${selectedStatus}&resourceName=${selectedAssignee}&sprintId=${sprintId}&boardId=${boardId}&startDay=${startDate}&endDay=${endDate}`;
        let allAssigneeUrl = `/api/v2/sprints/sprintcompletion?status=${selectedStatus}&sprintId=${sprintId}&boardId=${boardId}&startDay=${startDate}&endDay=${endDate}`;
        let url =
          selectedAssignee !== "All" ? selectedAssigneeUrl : allAssigneeUrl;
        api(url);
      }
    } else if (issueType === "Unreviewed PR") {
      let url = `/api/v2/projects//issuelevel/prmerged?state=${selectedStatus}&status=${selectedSubStatus}&${dateUrl}`;
      if (epic) {
        url += `&${epic.substring(1)}`;
      }
      api(url);
    } else if (issueType === "Team Unreviewed PR") {
      let url = `/api/v2/projects//issuelevel/prmerged?state=${selectedStatus}&status=${selectedSubStatus}&${dateUrl}&teamId=${teamId}&projectId=${projectId}`;
      if (epic) {
        url += `&${epic.substring(1)}`;
      }
      api(url);
    } else if (issueType === "Resource Unreviewed PR") {
      let url = `/api/v2/projects//issuelevel/prmerged?state=${selectedStatus}&status=${selectedSubStatus}&${dateUrl}&resourceName=${resourceName}`;
      if (epic) {
        url += `&${epic.substring(1)}`;
      }
      api(url);
    }
  }, [
    selectedStatus,
    selectedAssignee,
    date,
    selectedSubStatus,
    selectedAssigneeListUrl,
    epic,
  ]);

  useEffect(() => {
    setSelectedStatus(statusList[0]);
    // setDate(projectRangePickerValue);
    const dateUrl = datePayload(date);
    if (issueType === "Commit Without Ticket Reference") {
      api(`/api/v2/projects//issuelevel/commitwotf/${projectId}?${dateUrl}`);
    } else if (issueType === "Work Breakdown") {
      let url = `/api/v2/projects//issuelevel/workbreakdown/${projectId}`;
      if (epic) {
        url += epic;
      }
      api(url);
    } else if (issueType === "Commit with pull request") {
      let url = `/api/v2/projects//issuelevel/commitwpr/${projectId}?${dateUrl}`;
      if (epic) {
        url += `&${epic.substring(1)}`;
      }
      api(url);
    } else if (issueType === "Team Commit with pull request") {
      let url = `/api/v2/projects//issuelevel/commitwpr/${projectId}?${dateUrl}&teamId=${teamId}`;
      if (epic) {
        url += `&${epic.substring(1)}`;
      }
      api(url);
    } else if (issueType === "Resource Commit with pull request") {
      let url = `/api/v2/projects//issuelevel/commitwpr/${projectId}?${dateUrl}&resourceName=${resourceName}`;
      if (epic) {
        url += `&${epic.substring(1)}`;
      }
      api(url);
    } else if (issueType === "Team Commit Without Ticket Reference") {
      let url = `/api/v2/projects//issuelevel/commitwotf/${projectId}?${dateUrl}&teamId=${teamId}`;
      if (epic) {
        url += `&${epic.substring(1)}`;
      }
      api(url);
    } else if (issueType === "Resource Commit Without Ticket Reference") {
      let url = `/api/v2/projects//issuelevel/commitwotf/${projectId}?${dateUrl}&resourceName=${resourceName}`;
      if (epic) {
        url += `&${epic.substring(1)}`;
      }
      api(url);
    } else if (issueType === "WIP Average Age") {
      let url = `/api/v2/teams/issuelevel/avgAging/${projectId}?${dateUrl}`;
      if (teamUrl) {
        url += teamUrl;
      }
      if (epic) {
        url += `&${epic.substring(1)}`;
      }
      api(url);
    } else if (issueType === "Team WIP Average Age") {
      let url = `/api/v2/teams/issuelevel/avgAging/${projectId}?${dateUrl}&teamId=${teamId}`;
      if (epic) {
        url += `&${epic.substring(1)}`;
      }
      api(url);
    } else if (issueType === "Average Flow Efficiency") {
      let url = `/api/v2/projects/issuelevel/flowEfficiency/${projectId}?${dateUrl}`;
      if (epic) {
        url += `&${epic.substring(1)}`;
      }
      api(url);
    } else if (issueType === "Team Average Flow Efficiency") {
      let url = `/api/v2/projects/issuelevel/flowEfficiency/${projectId}?${dateUrl}&teamId=${teamId}`;
      if (epic) {
        url += `&${epic.substring(1)}`;
      }
      api(url);
    } else if (issueType === "Resource Average Flow Efficiency") {
      let url = `/api/v2/projects/issuelevel/flowEfficiency/${projectId}?${dateUrl}&resourceName=${resourceName}`;
      if (epic) {
        url += `&${epic.substring(1)}`;
      }
      api(url);
    } else if (issueType === "Pr Review Time") {
      let url = `/api/v2/projects/issuelevel/flowEfficiency/${projectId}?${dateUrl}`;
      if (epic) {
        url += `&${epic.substring(1)}`;
      }
      api(url);
    } else if (issueType === "Team Pr Review Time") {
      let url = `/api/v2/projects/issuelevel/flowEfficiency/${projectId}?${dateUrl}&teamId=${teamId}`;
      if (epic) {
        url += `&${epic.substring(1)}`;
      }
      api(url);
    } else if (issueType === "Resource Pr Review Time") {
      let url = `/api/v2/projects/issuelevel/flowEfficiency/${projectId}?${dateUrl}&resourceName=${resourceName}`;
      if (epic) {
        url += `&${epic.substring(1)}`;
      }
      api(url);
    } else if (issueType === "Cycle Time (Pull Request)") {
      let url = `/api/v2/teams/cycleTimePR/issuelevel/${projectId}?${dateUrl}`;
      if (epic) {
        url += `&${epic.substring(1)}`;
      }
      api(url);
    } else if (issueType === "Team Cycle Time (Pull Request)") {
      let url = `/api/v2/teams/cycleTimePR/issuelevel/${projectId}?${dateUrl}&teamId=${teamId}`;
      if (epic) {
        url += `&${epic.substring(1)}`;
      }
      api(url);
    } else if (issueType === "Escaped Defects") {
      let url = `/api/v2/teams/issueLevel/escapedDefects/${projectId}?${dateUrl}&teamId=${teamId}`;
      if (epic) {
        url += `&${epic.substring(1)}`;
      }
      api(url);
    } else if (issueType === "Test Efficiency") {
      let url = `/api/v2/teams/issueLevel/testEfficiency/${projectId}?${dateUrl}&teamId=${teamId}`;
      if (epic) {
        url += `&${epic.substring(1)}`;
      }
      api(url);
    } else if (issueType === "Mean Time To Repair") {
      let url = `/api/v2/teams/issueLevel/meanTimeToRepair/${projectId}?${dateUrl}&teamId=${teamId}`;
      if (epic) {
        url += `&${epic.substring(1)}`;
      }
      api(url);
    } else if (issueType === "Test Execution Rate") {
      let url = `/api/v2/teams/issueLevel/testExecutionRate/${projectId}?${dateUrl}&teamId=${teamId}`;
      if (epic) {
        url += `&${epic.substring(1)}`;
      }
      api(url);
    } else if (
      issueType === "Deployment Frequency" ||
      issueType === "Change failure rate" ||
      issueType === "Deployment rollback" ||
      issueType === "Team Deployment Frequency" ||
      issueType === "Team Change failure rate" ||
      issueType === "Team Deployment rollback" ||
      issueType === "Mean Lead Time for Change" ||
      issueType === "Mean Time To Restore"
    ) {
      let url = `/api/v2/projects/issuelevel/doraMatric/${projectId}`;

      let queryParams = [];

      if (job) {
        queryParams.push(`Job=${job}`);
      }
      if (selectedTeamId) {
        queryParams.push(`TeamId=${selectedTeamId}`);
      }

      if (queryParams.length > 0) {
        url += `?${queryParams.join("&")}`;
      }

      api(url);
    }
  }, [
    issueType,
    sprintId,
    boardId,
    startDate,
    date,
    teamUrl,
    epic,
    job,
    selectedTeamId,
  ]);

  const api = (url) => {
    axios(url)
      .then((res) => {
        setDataList(res?.data);
        // dora metrics avg
        if (res?.data) {
          const avgDuration =
            res?.data?.reduce((total, item) => {
              return total + parseInt(item?.["DurationInMin"]);
            }, 0) / res?.data?.length;
          setDuration(avgDuration);
        }

        const data = Object.keys(
          res?.data?.length > 0 ? res?.data[0] : {}
        )?.filter((e) => {
          if (
            e !== "tableData" &&
            e !== "TaskLink" &&
            e !== "CommitLink" &&
            e !== "Link"
          ) {
            return e;
          }
        });
        const columns = data?.map((e) => {
          return {
            field: e,
            title: addSpace(e),
            render: (data) => {
              if (e === "TaskId") {
                return (
                  <a target="_blank" href={data["TaskLink"]} rel="noreferrer">
                    {data.TaskId}
                  </a>
                );
              } else if (e === "CommitId") {
                return (
                  <a target="_blank" href={data["CommitLink"]} rel="noreferrer">
                    {data.CommitId}
                  </a>
                );
              } else if (e === "Id") {
                return (
                  <a target="_blank" href={data["Link"]} rel="noreferrer">
                    {data.Id}
                  </a>
                );
              } else if (e === "id") {
                return (
                  <a target="_blank" href={data["Link"]} rel="noreferrer">
                    {data.id}
                  </a>
                );
              } else if (e === "Assignee") {
                return <>{data[e] ? data[e] : "Unassign"}</>;
              } else if (e === "CreatedTime" || e === "CreatedDate") {
                return data[e] ? format(new Date(data[e]), "dd-MMM-yyy") : "";
              } else if (e === "id") {
                return (
                  <a target="_blank" href={data["url"]} rel="noreferrer">
                    {data.id}
                  </a>
                );
              } else if (e === "Reviewers") {
                return (
                  <div>
                    {data?.Reviewers?.map((name, index) => {
                      return (
                        <h6>
                          <ArrowRightIcon />
                          {name}
                        </h6>
                      );
                    })}
                  </div>
                );
              } else if (e === "MergedDate") {
                return (
                  <>
                    {data?.MergedDate
                      ? format(new Date(data?.MergedDate), "dd-MMM-yyyy")
                      : "--"}
                  </>
                );
              } else {
                return data[e];
              }
            },
          };
        });
        setColumnName(columns);
        scrollToDiv();
        // }
      })
      .catch((error) => {
        setColumnName([]);
        setDataList([]);
        console.log(error);
      });
  };
  const scrollToDiv = () => {
    if (autoScroll === true) {
      myDivRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div>
      {!(
        issueType === "Cycle Time Breakdown" ||
        issueType === "First Time pass issue"
      ) && (
        <div ref={myDivRef}>
          <h4 className="p-3">{issueType}</h4>
          <Box display={"flex"} gap="20px" alignItems={"center"}>
            {statusList.length > 0 && (
              <SelectField
                render={statusList}
                value={selectedStatus}
                setValue={(value) => {
                  setSelectedStatus(value);
                }}
                label="Status"
              />
            )}
            {subStatus?.length > 0 && (
              <SelectField
                render={subStatus}
                value={selectedSubStatus}
                setValue={(value) => {
                  setSelectedSubStatus(value);
                }}
                label="Status"
              />
            )}
            {isCheckList ? null : (
              <>
                {assigneeList?.length > 0 && (
                  <SelectField
                    render={assigneeList}
                    value={selectedAssignee}
                    setValue={(value) => {
                      setSelectedAssignee(value);
                    }}
                    label="Assignee"
                    isAll={true}
                  />
                )}
              </>
            )}
            {isCheckList && (
              <Box>
                <CheckSelect
                  names={assigneeList}
                  value={selectedAssigneeList}
                  setValue={(value) => {
                    setSelectedAssigneeList(value);
                  }}
                />
              </Box>
            )}
            {isDate && (
              <div>
                {/* Date drop down */}
                <SelectField
                  input={
                    <Button
                      onClick={() => setOpenRange(true)}
                      style={{
                        border: "1px solid #b7b7b7",
                        width: "230px",
                        textTransform: "capitalize",
                        padding: "5px",
                        fontSize: "14px",
                        fontWeight: 400,
                        paddingLeft: "14px ",
                        paddingRight: "11px",
                        justifyContent: "space-between",
                        borderRadius: "25px",
                        height: "48px",
                      }}
                      endIcon={<DateRangeOutlinedIcon />}
                    >
                      {/* {date.isCustom ? "Custom" : date.range || ""} */}
                      {getSelectedDate(date)}
                    </Button>
                  }
                  onClick={() => setOpenRange(true)}
                />
                <RangeSelector
                  open={openRange}
                  setOpen={setOpenRange}
                  pickerHandler={customRangePickerHandler}
                  value={date}
                />
              </div>
            )}
            {duration ? (
              <div
                className="border border-secondary mx-2 p-2"
                style={{ borderRadius: "18px" }}
              >
                Deployment Average Time: {duration.toFixed()} Min
              </div>
            ) : null}
          </Box>
          <CommonTable columns={columnName} data={dataList} />
        </div>
      )}
    </div>
  );
}

export default IssueLevel;
