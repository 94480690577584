import React, { useEffect, useState } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import moment from "moment";
export default function BarTrendChart(props) {
  const { body, graphData ,target=true} = props;
  console.log(graphData,"graphData")
  const [barData, setBarData] = useState([
    {
      "Please Wait.....": "",
    },
  ]);
  const [barKeys, setBarKeys] = useState([]);

  useEffect(() => {
    if (Array.isArray(graphData) == true) {
      const result = [
        {
          "Please Wait.....": "",
        },
      ];
      for (let i = 0; i < graphData.length; i++) {
        const obj = {};
        if(target){
          obj.Target = graphData[i].Value;
        }

        obj.Time = moment(graphData[i].Time).format("YYYY-MM-DD");
        for (let j = 1; j <= 20; j++) {
          const name = graphData[i][`Name${j}`];
          const value = graphData[i][`Value${j}`];
          obj[name] = value;
        }
        result.push(obj);
      }

      setBarData(
        result.filter((e) => e !== "Time" && e !== "undefined")
        // .reverse()
      );
    }
  }, [body, graphData]);

  useEffect(() => {
    if (typeof barData[1] !== "undefined") {
      const keys = Object.keys(barData[1])?.filter(
        (e) => e !== "Time" && e !== "undefined"
      );
      // .reverse();
      setBarKeys(keys);
    }
  }, [barData]);

  const colors = [
    "#67b7dc",
    "#a367dc",
    "#dc6788",
    "#dcd267",
    "#67dc75",
    "#80d2f5",
    "#FFD700",
    "#CD5C5C",
    "#20B2AA",
    "#800080",
    "#6B8E23",
    "#FF1493",
    "#9932CC",
    "#8FBC8F",
    "#00CED1",
    "#DC143C",
    "#000080",
    "#008B8B",
    "#FF7F50",
    "#00FA9A",
    "#FF69B4",
    "#4B0082",
    "#32CD32",
    "#00BFFF",
    "#FF4500",
    "#FF00FF",
    "#2E8B57",
    "#BA55D3",
    "#8B0000",
    "#9400D3",
    "#D2691E",
    "#FFDAB9",
    "#FFA07A",
    "#9370DB",
    "#6A5ACD",
    "#F08080",
    "#FF8C00",
    "#00FF00",
    "#6B8E23",
    "#8B008B",
    "#ADFF2F",
    "#800000",
    "#FF00FF",
    "#BDB76B",
    "#EEE8AA",
    "#FA8072",
    "#00FF7F",
    "#6B8E23",
    "#DA70D6",
    "#FFDEAD",
    "#8FBC8F",
    "#6495ED",
  ];

  return (
    <>
      <ResponsiveContainer width="100%" height={500}>
        <BarChart width={1000} height={500} data={barData} margin={20}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="Time" />
          <YAxis />
          <Tooltip wrapperStyle={{ textShadow: "5px black" }} />
          <Legend />
          {barKeys?.map((key, index) => (
            // <Bar key={index} dataKey={key} fill={"#5EBDCC"} />
            <Bar
              key={index}
              dataKey={key}
              fill={colors[index % colors.length]}
            />
          ))}
        </BarChart>
      </ResponsiveContainer>
    </>
  );
}
