import { makeStyles } from "@material-ui/core/styles";
import good from "../../assets/good.png";
import blue from "../../assets/blue.png";
import high from "../../assets/high.png";
import average from "../../assets/average.png";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "6px 12px",
    width: "fit-content",
    // height: "30px",
    borderRadius: "15px",
    textAlign: "center",
    // fontWeight: 700,
    fontSize: "16px",
    color: "#fff",
    textTransform: "capitalize",
    font: "normal normal normal 14px/18px Lexend Deca",
  },
  medium: {
    backgroundColor: "#fdfaec",
    color: "#ECCB47",
  },
  low: {
    backgroundColor: "#f7fcfc",
    color: "#209728",
  },
  high: {
    color: "#E65C5C !important",
    backgroundColor: "#fef7f7 !important",
  },
  average: {
    backgroundColor: "#fdfaec",
    color: "#ECCB47",
  },
  good: {
    backgroundColor: "#f7fcfc",
    color: "#209728",
  },
  bad: {
    color: "#E65C5C",
    backgroundColor: "#fef7f7",
  },
  blue: {
    backgroundColor: "#f7fcfc",
    color: "#0093ff",
  },
}));
export default function RiskWrapper(props) {
  const classes = useStyles();
  const { children, riskWidth, title } = props;
  return (
    <div
      className={`${classes.root} ${
        classes[children ? children?.toLowerCase() : ""]
      }`}
      style={{ width: riskWidth || "" }}
    >
      {children}
    </div>
  );
}

export function RiskColorWrapper({ children, riskLevel, className }) {
  const classes = useStyles();
  const getRiskClass = {
    overutilized: "high",
    underutilized: "average",
    utilizationstatus: "good",
    overperformed: "average",
    underperformed: "high",
    same: "good",
    "ideal range": "good",
    "under commit": "blue",
    "potential over commit": "high",
    "potential under commit": "average",
  };
  const riskClass = getRiskClass[riskLevel?.toLowerCase()];
  return (
    <div
      className={`${classes.root} ${className} ${
        classes[riskClass ? riskClass?.toLowerCase() || riskLevel : ""]
      }`}
    >
      {children}
    </div>
  );
}

export function RiskColorWrapperWithIcon({ children, riskLevel, className }) {
  const useStyles = makeStyles((theme) => ({
    high: {
      background: "#E65C5C",
    },
    average: {
      background: "#ECCB47",
    },
    good: {
      background: "#209728",
    },
    bad: {
      background: "#E65C5C",
    },
    blue: {
      background: "#0093ff",
    },
  }));

  const classes = useStyles();
  const getRiskClass = {
    "ideal range": "good",
    "under commit": "blue",
    "potential over commit": "high",
    "potential under commit": "average",
    good: "good",
    blue: "blue",
    high: "high",
    average: "average",
    low: "good",
    medium: "average",
  };
  const riskClass = getRiskClass[riskLevel?.toLowerCase()];
  // const img = {
  //   good,
  //   blue,
  //   high,
  //   average,
  //   low: good,
  //   medium: average,
  // };
  return (
    <div
      style={{ width: 20, height: 20, borderRadius: "50%" }}
      className={`${classes.root} ${className} ${
        classes[
          riskClass ? riskClass?.toLowerCase() || riskLevel.toLowerCase() : ""
        ]
      }`}
    >
      {children}
      {/* <img
        width={20}
        src={img[riskClass?.toLowerCase()] || img[riskLevel?.toLowerCase()]}
        alt={img[riskClass] || img[riskLevel]}
      /> */}
    </div>
  );
}
