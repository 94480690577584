import React, { useRef, useState, useContext } from "react";
import LineTrendChart from "./lineTrendChart";
import BarTrendChart from "./barTrendChart";
import { IconButton, makeStyles } from "@material-ui/core";
import SelectField from "../select/selectField";
import { iterationDaysMap, iterationMap } from "../../utils/textMap";
import { exportComponentAsPNG } from "react-component-export-image";
import { GlobalData } from "../../context/globalData";
import DefinaionPopup from "../definationPopup/DefinaionPopup";
import InfoIcon from "@material-ui/icons/Info";
import { definations } from "../../utils/defination";
import { useEffect } from "react";

const useStyles = makeStyles((theme) => ({
  chartHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    //   padding: '0px 25px',
    borderBottom: "1px solid #F2F2F2",
    marginBottom: "10px",
  },
  matric: {
    color: "#1E1E1E",
    font: "normal normal 600 15px/20px Lexend Deca",
  },
  itrationButton: {
    width: " 85px",
    height: "30px",
    background: "#f8f8f8",
    borderRadius: "6px",
    //   opacity: "0.2",
    textAlign: "center",
    border: "none",
    color: "#B1B1B1",
  },
  activeItration: {
    background: "#5EBDCC",
    color: "#FFFFFF",
  },
  chartType: {
    display: "flex",
    alignItems: "center",
    "& .MuiOutlinedInput-inputMarginDense": {
      "padding-top": "5.5px",
      "padding-bottom": "5.5px",
    },
  },
  definationContainer: {
    display: "flex",
    gap: "4px",
    cursor: "pointer",
  },
}));

function TrendChart({ graphData, setActiveKey, activeKey, matricName, text }) {
  const { isDefinaionPopup, setIsDefinaionPopup } = useContext(GlobalData);

  const [chartType, setChartType] = useState("Line Chart");
  const [isDefinationIcon, setIsDefinationIcon] = useState(false);

  const classes = useStyles();
  const componentRef = useRef("");

  const downloadGraph = (filename) => {
    exportComponentAsPNG(componentRef, { fileName: filename });
  };

  // const definationPopupHandler = () => {
  //   setIsDefinaionPopup(true);
  // };

  // useEffect(() => {
  //   Object.keys(definations?.["definations"])?.forEach((item) => {
  //     if (item == matricName) {
  //       setIsDefinationIcon(true);
  //     }
  //   });
  // }, []);

  return (
    <>
      <div className={classes.chartHeader}>
        <div className={classes.matric}>{matricName}</div>
        {/* {isDefinationIcon && (
          <div
            className={classes.definationContainer}
            onClick={() => {
              definationPopupHandler();
            }}
          >
            <span>
              <InfoIcon style={{ color: "#707070" }} fontSize="small" />
            </span>
            <span style={{ color: "#707070" }}> Defination</span>
          </div>
        )} */}

        {/* {isDefinaionPopup && <DefinaionPopup matricName={matricName} />} */}
        {activeKey && (
          <div style={{ display: "flex", gap: "10px" }}>
            {iterationDaysMap.map((e) => (
              <button
                key={e}
                onClick={() => setActiveKey(iterationMap[e])}
                className={`${classes.itrationButton} ${
                  activeKey === iterationMap[e] ? classes.activeItration : ""
                }`}
              >
                {e}
              </button>
            ))}
          </div>
        )}
        <div className={classes.chartType}>
          <SelectField
            value={chartType}
            setValue={setChartType}
            render={["Line Chart", "Bar Chart"]}
          />
          <IconButton size="small" onClick={() => downloadGraph(matricName)}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              enableBackground="new 0 0 24 24"
              height="24px"
              viewBox="0 0 24 24"
              width="24px"
              fill="#5EBDCC"
            >
              <g>
                <rect fill="none" height="24" width="24" />
              </g>
              <g>
                <path d="M18,15v3H6v-3H4v3c0,1.1,0.9,2,2,2h12c1.1,0,2-0.9,2-2v-3H18z M17,11l-1.41-1.41L13,12.17V4h-2v8.17L8.41,9.59L7,11l5,5 L17,11z" />
              </g>
            </svg>
          </IconButton>
        </div>
      </div>
      <div ref={componentRef}>
        {chartType === "Line Chart" ? (
          <LineTrendChart
            graphData={graphData}
            name={text ? text : matricName}
          />
        ) : (
          <BarTrendChart graphData={graphData} />
        )}
      </div>
    </>
  );
}

export default TrendChart;
