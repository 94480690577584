import React, { useEffect, useState } from "react";
import { Button, Card, Grid, TextField } from "@material-ui/core";

import { FormikProvider } from "formik";
import { Calendar } from "react-date-range";
import {
  Autocomplete,
  Box,
  Stack,
  styled,
  Tooltip,
  Typography,
  Zoom,
} from "@mui/material";
import * as locales from "react-date-range/dist/locale";
import forbidden from "../../../../../assets/forbidden.png";
import Recommend from "../../../../../assets/favourites.png";
import currentlyAvailable from "../../../../../assets/currentlyAvailable.png";
import CommonTable from "../../../../../components/commonTable";
import { config } from "../../../../../Config/permission";
import "../../style";
import GroupsIcon from "@mui/icons-material/Groups";
import HealthColorWrapper from "../../../../../components/overview/healthColorWrapper";
import SummaryTable from "./SummaryTable";
import instance from "../../../../../utils/axiosHelper";
import { datePayload } from "../../../../../utils/moduleTabApi";
import BestPractices from "./BestPractices";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom";
import CommonTablePopup from "../../../../../components/models/commonTablePopup";
import GroupAddSharpIcon from "@mui/icons-material/GroupAddSharp";
import TechRating from "./TechRating";
import ErrorIcon from "@mui/icons-material/Error";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import PersonPinIcon from "@mui/icons-material/PersonPin";

function Form({
  formik,
  resourceList,
  sprintList,
  epicList,
  resourceAvailabilityHandler,
  calCulateDevAttention,
  resData,
  ExecutionStatusForResource,
  unResolvedData,
  isShowTopFiveResource,
  projectId,
  date,
  devAttentionColumns,
  TechSkillRequestFunc,
  parentState,
}) {
  const [showDevAttention, setShowDevAttention] = useState(false);
  const [onlyResoureNamelist, setOnlyResourceNameList] = useState([]);
  const [isOpen, setSetIsOpen] = useState();
  const [showResourceStatus, setShowResourceStatus] = useState(false);
  const [isScenarioPlanner, setIsScenarioPlanner] = useState(false);
  const [summaryTableData, setSummaryTableData] = useState([]);
  const [summaryTrend, setSummaryTrend] = useState([]);
  const [showBestPractice, setShowBestPractice] = useState(false);
  const [isOpenScope, setIsOpenScope] = useState(false);
  const [openScopeData, setOpenScopeData] = useState([]);
  const [showTechRatingPopup, setShowTechRatingPopup] = useState(false);
  const { epicId } = useParams();
  const param = useParams();
  const history = useHistory();

  console.log(param, "param");

  const issueLevelReamingScope = (row, epic) => {
    setIsOpenScope(true);
    const dateUrl = datePayload(date);
    // const epic = formik?.values?.epic ? formik?.values?.epic[0]?._id : "";
    let url = `/api/v2/ph/issuelevel/remainingScope/${projectId}?${dateUrl}&epic=${epic}&issueType=${row?.Issuetype}`;
    instance
      .get(url)
      .then((response) => {
        console.log(response?.data);
        setOpenScopeData(response?.data);
      })
      .catch((error) => {
        console.log(error, "error");
      });
  };

  const [columns] = useState([
    {
      title: "Name",
      field: "resourceName",
    },
    {
      title: "Team Name",
      field: "teamName",
    },
    {
      title: "Attention Percent (Last 2 weeks)",
      field: "attentionPercentLastWeek",
      render: (row) => <div>{row?.attentionPercentLastWeek} %</div>,
    },
    {
      title: "Competing Priorities (Last 2 weeks)",
      field: "competingPriorities",
      render: (row) => (
        <div
          dangerouslySetInnerHTML={{ __html: row.competingPriorities }}
        ></div>
      ),
    },
  ]);
  const [remainingScopeColumn] = useState([
    {
      title: "epic Name",
      field: "key",
      render: (row) => (
        <a href={row?.url} target="_blank" rel="noreferrer">
          {row?.key}
        </a>
      ),
    },
    {
      title: "Status",
      field: "status",
    },
    {
      title: "Assignee",
      field: "Assignee",
    },
    {
      title: "Issue Type",
      field: "issueType",
    },
    {
      title: "Summery",
      field: "summery",
      render: (row) => (
        <Tooltip title={row?.summery} arrow>
          <div className="text-truncate" style={{ maxWidth: "200px" }}>
            {row?.summery}
          </div>
        </Tooltip>
      ),
    },
  ]);

  // const [columns] = useState([
  //   {
  //     field: "Name",
  //     title: "Name",
  //   },
  //   {
  //     field: "TeamName",
  //     title: "Team Name",
  //   },
  //   {
  //     field: "AttentionPercent",
  //     title: "Attention Percent (Last 1 weeks )",
  //   },
  // ]);

  const [unResolvedColumns] = useState([
    {
      field: "Issuetype",
      title: "Issue Type",
      render: (row) => (
        <div
          onClick={() => issueLevelReamingScope(row, epicId)}
          className="cursorPointer text-primary"
        >
          {row?.Issuetype}
        </div>
      ),
    },
    {
      field: "Count",
      title: "Count",
    },
    {
      title: "Component Name",
      field: "Component",
    },
  ]);

  useEffect(() => {
    setShowResourceStatus(false);
  }, [ExecutionStatusForResource]);

  useEffect(() => {
    isShowTopFiveResource(showResourceStatus);
  }, [showResourceStatus]);

  useEffect(() => {
    const resourceNameList = formik?.values?.resources.map((e) => {
      return e.Name;
    });
    setOnlyResourceNameList(resourceNameList);
  }, [formik?.values?.resources]);

  const showSummary = () => {
    setIsScenarioPlanner(true);
    const dateUrl = datePayload(date);
    const params = new URLSearchParams(dateUrl);
    const parsedObject = {};
    params.forEach((value, key) => {
      parsedObject[key] = value;
    });
    const beforeAfterPayload = {
      epic: formik?.values?.epic ? formik?.values?.epic[0]?._id : "",
    };
    instance
      .post(`/api/v2/sc/scenario/comparePlanner`, beforeAfterPayload)
      .then((res) => {
        setSummaryTableData(res?.data);
        const trendData = res?.data?.trendData?.map((item) => {
          return {
            category: item?.rowTitle,
            after: +item?.after,
            before: +item?.before,
          };
        });
        setSummaryTrend(trendData);
      })
      .catch((error) => {
        console.log(error, "error");
      });
  };

  const showAvailableTechRating = () => {
    setShowTechRatingPopup(true);
  };

  // Overall Rating function
  const getOverallRating = (item) => {
    if (item <= 0.04) {
      return (
        <div className="text-danger">
          <CancelIcon />
        </div>
      );
    } else if (item >= 0.04 && item <= 0.06) {
      return (
        <div className="text-warning">
          <ErrorIcon />
        </div>
      );
    } else if (item > 0.06) {
      return (
        <div className="text-success">
          <CheckCircleIcon />
        </div>
      );
    }
  };

  const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} arrow placement="top" />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: "rgba(0, 0, 0, 0.87)",
      boxShadow: theme.shadows[1],
      fontSize: 14,
      padding: 4,
    },
  }));

  const handleSingleResource = (e, item) => {
    e.stopPropagation();
    history.push({
      pathname: `/project/${projectId}/single-resource/${item?.ResourceId}`,
      state: {
        projectName: item?.ProjectName,
        resourceName: item?.Name,
        teamName: item?.TeamName,
      },
    });
  };

  return (
    <>
      <FormikProvider>
        <form onSubmit={formik.handleSubmit}>
          <div className="d-flex justify-content-between">
            <Box display={"flex"} alignItems={"center"}>
              <Typography variant="h5" mb={1} mt={1} sx={{ color: "#36af31" }}>
                What If Adviser
              </Typography>
              <div>
                {ExecutionStatusForResource === "BAD" ? (
                  <div
                    className="sonar-wrapper"
                    onClick={() => setShowResourceStatus(!showResourceStatus)}
                  >
                    <div className="sonar-emitter">
                      <div className="sonar-wave"></div>
                    </div>
                  </div>
                ) : null}
              </div>
            </Box>
            {resData && (
              <div className="mb-2 text-end">
                <Button
                  variant="outlined"
                  onClick={() => setShowBestPractice(!showBestPractice)}
                >
                  Best Practices
                </Button>
              </div>
            )}
          </div>
          <Grid container spacing={2} style={{ marginBottom: "20px" }}>
            <Grid item xs={6} style={{ minHeight: "200px" }}>
              <Card className="customCard p-3 border text-left h-100">
                <div>
                  <h6>Target Date</h6>
                  <Calendar
                    onChange={(item) => formik.setFieldValue("endDate", item)}
                    months={2}
                    direction="horizontal"
                    minDate={new Date()}
                    locale={locales["English (United States)"]}
                    date={formik.values.endDate}
                  />
                </div>
              </Card>
            </Grid>
            <Grid item xs={6}>
              <Card className="customCard p-3 border text-left h-100">
                {/* <div className="mb-3">
                  <Stack>
                    <h6>Active Sprint</h6>
                    <Autocomplete
                      style={{
                        width: "100%",
                        border: "1px solid rgba(0, 0, 0, 0.23)",
                        padding: `0 10px`,
                        borderRadius: 10,
                      }}
                      id="tags-readOnly"
                      value={formik.values.sprint}
                      onChange={(e, value) =>
                        formik.setFieldValue("sprint", value)
                      }
                      options={sprintList}
                      getOptionLabel={(option) => option?.SprintName || {}}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Select Sprint"
                          style={{ padding: "10px" }}
                          helperText={
                            formik.touched.sprint && formik.errors.sprint
                              ? formik.errors.sprint
                              : ""
                          }
                          error={
                            formik.touched.sprint && formik.errors.sprint
                              ? true
                              : false
                          }
                        />
                      )}
                    />
                  </Stack>
                </div> */}
                <div className="mb-3">
                  <Stack>
                    <h6>{config.type === "Issue" ? "Issue" : "Epic"}</h6>
                    <Autocomplete
                      multiple
                      limitTags={2}
                      style={{
                        width: "100%",
                        border: "1px solid rgba(0, 0, 0, 0.23)",
                        padding: `0 10px`,
                        borderRadius: 10,
                      }}
                      id="tags-readOnly"
                      value={formik.values.epic}
                      onChange={(e, value) =>
                        formik.setFieldValue("epic", value)
                      }
                      options={epicList}
                      getOptionLabel={(option) => option?._id}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder={`Select ${
                            config.type === "Issue" ? "Issue" : "Epic"
                          }`}
                          style={{ padding: "10px" }}
                          helperText={
                            formik.touched.epic && formik.errors.epic
                              ? formik.errors.epic
                              : ""
                          }
                          error={
                            formik.touched.epic && formik.errors.epic
                              ? true
                              : false
                          }
                        />
                      )}
                      disableCloseOnSelect={true}
                    />
                  </Stack>
                </div>
                <div className="mb-3">
                  <Stack>
                    <h6>
                      {showResourceStatus ? (
                        <>
                          Select For Best Resource{" "}
                          <span
                            onClick={() => showAvailableTechRating()}
                            className="cursorPointer"
                          >
                            <GroupAddSharpIcon sx={{ color: "#5EBDCC" }} />
                          </span>
                        </>
                      ) : (
                        "Contributor Allocated"
                      )}
                    </h6>
                    {/* {showResourceStatus ? (
                      <Box display={"flex"} gap={2} mb={2}>
                        <span
                          style={{
                            alignItems: "center",
                            display: "flex",
                            gap: "8px",
                          }}
                        >
                          <label style={{ fontSize: "12px" }} htmlFor="">
                            Currently Available :{" "}
                          </label>
                          <img width={"18px"} src={currentlyAvailable} alt="" />
                        </span>
                        <span
                          style={{
                            alignItems: "center",
                            display: "flex",
                            gap: "8px",
                          }}
                        >
                          <label style={{ fontSize: "12px" }} htmlFor="">
                            Busy :{" "}
                          </label>
                          <img width={"14px"} src={forbidden} alt="" />
                        </span>
                      </Box>
                    ) : null} */}
                    <Autocomplete
                      multiple
                      limitTags={2}
                      style={{
                        width: "100%",
                        border: "1px solid rgba(0, 0, 0, 0.23)",
                        padding: `0 10px`,
                        borderRadius: 10,
                      }}
                      id="tags-readOnly"
                      value={formik.values.resources}
                      onChange={(e, value) => {
                        formik.setFieldValue("resources", value);
                        resourceAvailabilityHandler(value);
                      }}
                      options={resourceList}
                      getOptionLabel={(option) => option?.Name}
                      renderOption={(props, option) => (
                        <li {...props}>
                          <Box
                            display="flex"
                            width="100%"
                            justifyContent="space-between"
                          >
                            {/* Resource Name */}
                            <Box
                              display="flex"
                              gap={2}
                              alignItems="center"
                              width={"30%"}
                            >
                              {showResourceStatus && (
                                <>
                                  {!onlyResoureNamelist.includes(
                                    option?.Name
                                  ) && (
                                    <img
                                      width={16}
                                      src={Recommend}
                                      // src={
                                      //   option?.utilizationStatus ===
                                      //   "RECOMMENDED"
                                      //     ? currentlyAvailable
                                      //     : forbidden
                                      // }
                                      alt={
                                        option.Name === "RECOMMENDED"
                                          ? "Recommend"
                                          : "Forbidden"
                                      }
                                    />
                                  )}
                                </>
                              )}
                              {option.Name}{" "}
                            </Box>
                            {/* Team Name */}
                            <Box
                              display="flex"
                              gap={1}
                              alignItems="center"
                              width={"30%"}
                            >
                              <div>
                                <GroupsIcon />
                              </div>
                              <div>{option?.TeamName}</div>
                            </Box>
                            <Box width={"30%"}>
                              {option?.OverallRating && (
                                <LightTooltip
                                  title={
                                    <div className="p-2">
                                      <h6>Rating : </h6>
                                      <div className="row g-2">
                                        <div className="col-6">
                                          Avg TechRating :
                                          {option?.AvgTechRating?.toFixed(2)}
                                        </div>
                                        <div className="col-6">
                                          Code Quality :
                                          {option?.CodeQuality?.toFixed(2)}
                                        </div>
                                        <div className="col-6">
                                          Code Review :
                                          {option?.CodeReview?.toFixed(2)}
                                        </div>
                                        <div className="col-6">
                                          Domain Expertise:{option?.Expertise}
                                        </div>
                                        <div className="col-6">
                                          Utilization :
                                          {option?.Utilization?.toFixed(2)}
                                        </div>
                                        <div className="col-6">
                                          Velocity :
                                          {option?.AvgVelocity?.toFixed(2)}
                                        </div>
                                        <div className="col-6">
                                          Bug Rate :
                                          {option?.BugRate?.toFixed(2)}
                                        </div>
                                      </div>
                                    </div>
                                  }
                                >
                                  <div className="d-flex m-auto">
                                    <div>Overall Rating: </div>
                                    <div className="mx-2">
                                      {getOverallRating(option?.OverallRating)}
                                    </div>
                                  </div>
                                </LightTooltip>
                              )}
                            </Box>
                            <Box
                              onClick={(e) => handleSingleResource(e, option)}
                              width={"5%"}
                            >
                              {option?.OverallRating && (
                                <Tooltip
                                  title={"View Profile"}
                                  arrow
                                  placement="top"
                                >
                                  <PersonPinIcon sx={{ color: "#5EBDCC" }} />
                                </Tooltip>
                              )}
                            </Box>
                            {/* {config.type === "Task" ? null : (
                              <Box width={"auto"} display={"flex"}>
                                Quality Health :{" "}
                                <HealthColorWrapper
                                  status={option?.averageHealthScore}
                                />
                              </Box>
                            )} */}
                          </Box>
                        </li>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Select Contributor"
                          style={{ padding: "10px" }}
                          helperText={
                            formik.touched.resources && formik.errors.resources
                              ? formik.errors.resources
                              : ""
                          }
                          error={
                            formik.touched.resources && formik.errors.resources
                              ? true
                              : false
                          }
                        />
                      )}
                      disableCloseOnSelect={true}
                    />
                  </Stack>
                </div>
                <div className="mb-3">
                  <Stack>
                    <h6>Contributor count</h6>
                    <TextField
                      disabled
                      variant="outlined"
                      value={formik.values.resourceAllocated}
                      onChange={formik.handleChange}
                      name="resourceAllocated"
                      fullWidth
                      autoComplete="off"
                      inputProps={{ style: { fontSize: 15 } }}
                      InputLabelProps={{
                        style: { fontSize: 15, color: "GrayText" },
                      }}
                      helperText={
                        formik.touched.resourceAllocated &&
                        formik.errors.resourceAllocated
                          ? formik.errors.resourceAllocated
                          : ""
                      }
                      error={
                        formik.touched.resourceAllocated &&
                        formik.errors.resourceAllocated
                          ? true
                          : false
                      }
                    />
                  </Stack>
                </div>
                <div className="mb-3">
                  <Stack>
                    <h6>
                      Remaining Scope ( Issue ) &nbsp;
                      <span
                        onClick={() => {
                          setSetIsOpen(!isOpen);
                          setTimeout(() => {
                            window.scrollTo(0, 1400);
                          }, [500]);
                        }}
                        className="viewMore"
                      >
                        {!isOpen ? "View More" : "View Less"}
                      </span>
                    </h6>

                    <TextField
                      variant="outlined"
                      value={formik?.values?.totalStoryPoints}
                      onChange={formik.handleChange}
                      name="totalStoryPoints"
                      fullWidth
                      autoComplete="off"
                      inputProps={{ style: { fontSize: 15 } }}
                      InputLabelProps={{
                        style: { fontSize: 15, color: "GrayText" },
                      }}
                      helperText={
                        formik.touched.totalStoryPoints &&
                        formik.errors.totalStoryPoints
                          ? formik.errors.totalStoryPoints
                          : ""
                      }
                      error={
                        formik.touched.totalStoryPoints &&
                        formik.errors.totalStoryPoints
                          ? true
                          : false
                      }
                    />
                  </Stack>
                </div>
                {/* <div className="mb-3">
                  <Stack>
                    <h6>Days In Sprint</h6>
                    <TextField
                      variant="outlined"
                      value={formik.values.daysInSprint}
                      onChange={formik.handleChange}
                      name="daysInSprint"
                      fullWidth
                      autoComplete="off"
                      inputProps={{ style: { fontSize: 15 } }}
                      InputLabelProps={{
                        style: { fontSize: 15, color: "GrayText" },
                      }}
                      helperText={
                        formik.touched.daysInSprint &&
                        formik.errors.daysInSprint
                          ? formik.errors.daysInSprint
                          : ""
                      }
                      error={
                        formik.touched.daysInSprint &&
                        formik.errors.daysInSprint
                          ? true
                          : false
                      }
                    />
                  </Stack>
                </div> */}
                <div className="mb-3">
                  {resData?.suggestion !==
                  "Project will be delivered on time" ? (
                    <Stack className="avgVelocity">
                      <Tooltip
                        title={`Recommended Velocity : ${
                          resData?.avgRecommendedVelocity
                            ? (
                                resData?.avgRecommendedVelocity /
                                resData?.resourceAllocated
                              ).toFixed()
                            : ""
                        }`}
                        placement="top-start"
                        TransitionComponent={Zoom}
                        arrow
                      >
                        <>
                          <h6>Avg Velocity</h6>
                          <TextField
                            variant="outlined"
                            value={formik.values.avgVelocity}
                            onChange={formik.handleChange}
                            name="avgVelocity"
                            fullWidth
                            autoComplete="off"
                            inputProps={{ style: { fontSize: 15 } }}
                            InputLabelProps={{
                              style: { fontSize: 15, color: "GrayText" },
                            }}
                            helperText={
                              formik.touched.avgVelocity &&
                              formik.errors.avgVelocity
                                ? formik.errors.avgVelocity
                                : ""
                            }
                            error={
                              formik.touched.avgVelocity &&
                              formik.errors.avgVelocity
                                ? true
                                : false
                            }
                          />
                        </>
                      </Tooltip>
                    </Stack>
                  ) : (
                    <Stack className="avgVelocity">
                      <h6>Avg Velocity</h6>
                      <TextField
                        variant="outlined"
                        value={formik.values.avgVelocity}
                        onChange={formik.handleChange}
                        name="avgVelocity"
                        fullWidth
                        autoComplete="off"
                        inputProps={{ style: { fontSize: 15 } }}
                        InputLabelProps={{
                          style: { fontSize: 15, color: "GrayText" },
                        }}
                        helperText={
                          formik.touched.avgVelocity &&
                          formik.errors.avgVelocity
                            ? formik.errors.avgVelocity
                            : ""
                        }
                        error={
                          formik.touched.avgVelocity &&
                          formik.errors.avgVelocity
                            ? true
                            : false
                        }
                      />
                    </Stack>
                  )}
                </div>
                <div className="mb-3">
                  <Stack>
                    <h6>
                      Dev Attention % ({" "}
                      {parentState.DevAttention[0]?.progressPercentage?.toFixed()}{" "}
                      % Over the last 2 weeks ){" "}
                      <span
                        onClick={() => {
                          setShowDevAttention(!showDevAttention);
                          setTimeout(() => {
                            window.scrollTo(0, 1400);
                          }, [500]);
                        }}
                        className="viewMore"
                      >
                        {!showDevAttention ? "View More" : "View Less"}
                      </span>
                      <br />
                      (On average, how much overall development attention will
                      this deliverable receive)
                    </h6>
                    <TextField
                      variant="outlined"
                      value={formik.values.devAttention}
                      onChange={formik.handleChange}
                      name="devAttention"
                      fullWidth
                      autoComplete="off"
                      inputProps={{ style: { fontSize: 15 } }}
                      InputLabelProps={{
                        style: { fontSize: 15, color: "GrayText" },
                      }}
                      helperText={
                        formik.touched.devAttention &&
                        formik.errors.devAttention
                          ? formik.errors.devAttention
                          : ""
                      }
                      error={
                        formik.touched.devAttention &&
                        formik.errors.devAttention
                          ? true
                          : false
                      }
                    />
                  </Stack>
                </div>
                <div className="mb-3">
                  <Stack>
                    <Button
                      variant="outlined"
                      type="submit"
                      color="primary"
                      style={{
                        border: "1px solid #5EBDCC",
                        color: "#5EBDCC",
                        textTransform: "capitalize",
                      }}
                    >
                      {" "}
                      Calculate Scenario
                    </Button>
                  </Stack>
                </div>
                {resData && (
                  <div className="mb-3 text-end">
                    <Button variant="outlined" onClick={() => showSummary()}>
                      Summary
                    </Button>
                  </div>
                )}
              </Card>
            </Grid>
          </Grid>
        </form>
      </FormikProvider>
      {/* Dev Attention Table */}
      {showDevAttention && (
        <div className="my-2">
          <CommonTable
            columns={columns}
            data={devAttentionColumns}
            options={{
              paging: false,
              search: false,
            }}
            title={`Overall Attention Percent  (Last 2 weeks) : ${calCulateDevAttention(
              devAttentionColumns
            )} %`}
          />
        </div>
      )}
      {/* {showDevAttention && (
        <div className="my-2">
          <CommonTable
            columns={columns}
            data={resourceList}
            options={{
              paging: false,
              search: false,
            }}
            title={`Overall Attention Percent  (Last 1 weeks ) : ${calCulateDevAttention(
              resourceList
            )}`}
          />
        </div>
      )} */}
      {/* Reaming Scope Table */}
      {isOpen && (
        <CommonTable
          columns={unResolvedColumns}
          data={unResolvedData}
          options={{
            paging: false,
            search: false,
          }}
          title="Unresolved Issue"
        />
      )}
      {/* Summary Table */}
      <SummaryTable
        open={isScenarioPlanner}
        onClose={setIsScenarioPlanner}
        columnData={summaryTableData}
        summaryTrend={summaryTrend}
        tableTitle="What If Adviser"
      />
      {/* Best Practice Table */}
      {showBestPractice && (
        <BestPractices open={showBestPractice} onClose={setShowBestPractice} />
      )}
      {/* Remaining Scope Popup */}
      <CommonTablePopup
        title="Unresolved Issue Details"
        open={isOpenScope}
        setOpen={setIsOpenScope}
        columns={remainingScopeColumn}
        data={openScopeData}
        width={1000}
        tableOption={{
          pageSizeOptions: [5],
          pageSize: 5,
        }}
      />
      <TechRating
        open={showTechRatingPopup}
        setShowTechRatingPopup={setShowTechRatingPopup}
        TechnologyBody={(values) => TechSkillRequestFunc(values)}
      />
    </>
  );
}

export default Form;
