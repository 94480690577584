import React, { useEffect } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated"; // Import this theme for animations

am4core.useTheme(am4themes_animated); // Apply the theme

const MultilineChart = ({ TrendUnit, yAxesLabel, data, height = "400px" }) => {
  useEffect(() => {
    if (!data) return;
    // Create chart instance
    var chart = am4core.create("chartdiv", am4charts.XYChart);

    // Set data
    chart.data = data;

    // Create X and Y axes
    var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "category";
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.title.text = "Type";

    var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.title.text = yAxesLabel;

    // Create multiple LineSeries
    function createLineSeries(field, name, type) {
      var series = chart.series.push(new am4charts.LineSeries());
      series.dataFields.valueY = field;
      series.dataFields.categoryX = "category";
      series.strokeWidth = 2;
      series.tooltipText = `${name} \n ${yAxesLabel}: [bold]{valueY}[/] ${TrendUnit} `;
      // series.name = name;
      series.tensionX = 0.8;
      series.showOnInit = true;

      // Add bullets to the series if needed
      var bullet = series.bullets.push(new am4charts.CircleBullet());

      return series;
    }

    const seriesList = data.map((element, index) => {
      return createLineSeries(
        "value" + (index + 1),
        element.name,
        element.category
      );
    });

    chart.legend = new am4charts.Legend();
    chart.legend.data = data.map((element, index) => {
      return {
        name: element.name,
        fill: chart.series.getIndex(index).stroke,
        series: seriesList[index],
      };
    });

    chart.legend.itemContainers.template.events.on("hit", function (event) {
      var series = event.target.dataItem.dataContext.series;
      if (series.isHidden) {
        series.show();
      } else {
        series.hide();
      }
    });

    chart.cursor = new am4charts.XYCursor();
  }, [data]);

  return (
    <div id="chartdiv" style={{ width: "100%", height: height }}>
      {/* The chart will be rendered here */}
    </div>
  );
};

export default MultilineChart;
