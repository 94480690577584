import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignSelf: "center",
    alignItems: "center",
    fontSize: "16px",
    cursor: "pointer",
    // marginLeft: "45px",
    // 'justify-content': 'center'
  },
  section1: {
    textAlign: "left",
    justifySelf: "center",
    paddingLeft: "15px",
    paddingRight: "15px",
    display: "flex",
  },
  section2: {
    alignSelf: "center",
    alignItems: "center",
    justifySelf: "center",
  },
  pointer: {
    borderRadius: "30px",
    width: 10,
    height: 10,
  },

  medium: {
    backgroundColor: "#F2994A",
  },
  low: {
    backgroundColor: "#219653",
  },
  high: {
    backgroundColor: "#EB5757",
  },
  default: {
    backgroundColor: "#F2994A",
  },
  GOOD: {
    backgroundColor: "green",
  },
  BAD: {
    backgroundColor: "red",
  },
  AVERAGE: {
    backgroundColor: "red",
  },
}));
export default function PointerWrapper(props) {
  const classes = useStyles();
  const { children, val } = props;
  return (
    <div className={classes.root}>
      <div className={classes.section2}>
        <div
          className={`${classes.pointer} ${
            classes[val ? val.toLowerCase() : "default"]
          }`}
        ></div>
      </div>
      <div className={classes.section1}>{children}</div>
    </div>
  );
}
