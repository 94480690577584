import { Box, Button, Card, Grid } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import WorkAlocationChart from "../../projectDashboard/graphs/workAlocationChart";
import SelectInvestmentProfile from "../../../../components/select/selectInvestmentProfile";
import SelectField from "../../../../components/select/selectField";
import { datePayload, getSelectedDate } from "../../../../utils/moduleTabApi";
import DateRangeOutlinedIcon from "@material-ui/icons/DateRangeOutlined";
import RangeSelector from "../../../../components/rangeSelector";
import { GlobalData } from "../../../../context/globalData";
import axios from "../../../../utils/axiosHelper";
import IssueLevel from "../IssueLevel";
import CommonTable from "../../../../components/commonTable";
import { format } from "date-fns";

export const EpicDistribution = ({ isSprint = false, projectId, sprintId }) => {
  const [selectedSubStatus, setSelectedSubStatus] = useState();
  const [openRange, setOpenRange] = useState();
  const [statusList, setStatusList] = useState([]);
  const [allEpicList, setAllEpicList] = useState([]);
  const [filteredEpic, setFilteredEpic] = useState([]);
  const [issueLevelEpics, setIssueLevelEpics] = useState([]);
  const { projectRangePickerValue } = useContext(GlobalData);
  const [date, setDate] = useState(projectRangePickerValue);

  console.log(selectedSubStatus, "selectedSubStatus");

  const column = [
    {
      title: "Epic Id",
      field: "TaskId",
      render: (row) => (
        <a href={row?.TaskLink} target="_blank" rel="noreferrer">
          {row?.TaskId}
        </a>
      ),
    },
    {
      title: "Assignee Name",
      field: "Assignee",
    },
    {
      title: "Epic Name",
      field: "Epic",
    },
    {
      title: "Created Time",
      field: "CreatedTime",
      render: (row) => format(new Date(row?.CreatedTime), "dd-MMM-yyyy"),
    },
    {
      title: "Epic Status",
      field: "TaskStatus",
    },
  ];

  //  statusNames
  useEffect(() => {
    const url = `/api/v2/statusNames/${projectId}`;
    axios
      .get(url)
      .then((response) => {
        const status = response?.data?.StatusNames[0]?.Names.map((e) => {
          return { Name: e, Id: e };
        });
        setStatusList(status);
      })
      .catch((error) => {
        console.log(error, "error");
      });
  }, [projectId]);

  //  statusNames
  useEffect(() => {
    const dateUrl = datePayload(date);
    let url = `/api/v2/epic/distribution/${projectId}?`;

    if (isSprint) {
      url += `sprintId=${sprintId}`;
    } else {
      url += `${dateUrl}`;
    }

    if (selectedSubStatus) {
      url += `&status=${selectedSubStatus}`;
    }

    axios
      .get(url)
      .then((response) => {
        setAllEpicList(response?.data?.allEpics);
        setFilteredEpic(response?.data?.filteredEpic);
      })
      .catch((error) => {
        console.log(error, "error");
      });
  }, [isSprint, projectId, date, selectedSubStatus, sprintId]);

  useEffect(() => {
    const dateUrl = datePayload(date);
    let url = `/api/v2/epic/issuelevel/distribution/${projectId}?`;
    if (isSprint) {
      url += `sprintId=${sprintId}`;
    } else {
      url += `${dateUrl}`;
    }

    if (selectedSubStatus) {
      url += `&status=${selectedSubStatus}`;
    }
    axios
      .get(url)
      .then((response) => {
        setIssueLevelEpics(response?.data);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }, [isSprint, projectId, date, selectedSubStatus, sprintId]);

  const handleStatus = (e) => {
    setSelectedSubStatus(e);
  };

  return (
    <div>
      <Box display={"flex"} alignItems={"center"}>
        {!isSprint && (
          <div style={{ marginTop: -8 }}>
            <SelectField
              input={
                <Button
                  onClick={() => setOpenRange(true)}
                  style={{
                    border: "1px solid #b7b7b7",
                    width: "230px",
                    textTransform: "capitalize",
                    padding: "5px",
                    fontSize: "14px",
                    fontWeight: 400,
                    paddingLeft: "14px ",
                    paddingRight: "11px",
                    justifyContent: "space-between",
                    borderRadius: "5px",
                    height: "54px",
                    color: "black",
                  }}
                  endIcon={<DateRangeOutlinedIcon />}
                >
                  {getSelectedDate(date)}
                </Button>
              }
              onClick={() => setOpenRange(true)}
            />
            <RangeSelector
              open={openRange}
              setOpen={setOpenRange}
              pickerHandler={(val) => setDate(val)}
              value={date}
              isNextDate={false}
            />
          </div>
        )}
        <div>
          Select Status :{" "}
          <SelectInvestmentProfile
            handleChange={(e) => {
              handleStatus(e?.target?.value);
            }}
            value={selectedSubStatus}
            list={statusList}
            isAll={true}
            placeholder={"Select Status"}
          />
        </div>
      </Box>

      <Card sx={{ padding: "20px" }}>
        <Grid container mt={2} spacing={2} wrap="wrap">
          <Grid item xs={6}>
            <Card className="p-2">
              <h5 className="pb-4">All Epics</h5>
              <WorkAlocationChart data={allEpicList} name={"project"} />
            </Card>
          </Grid>

          <Grid item xs={6}>
            <Card className="p-2">
              <h5 className="pb-4">Status</h5>
              <WorkAlocationChart data={filteredEpic} name={"team"} />
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card className="p-2">
              <CommonTable columns={column} data={issueLevelEpics} />
            </Card>
          </Grid>
        </Grid>
      </Card>
    </div>
  );
};
