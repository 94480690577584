import React, { useEffect, useState } from "react";
import { useStyles } from "./style";
import { Avatar, Card, CardHeader, LinearProgress } from "@material-ui/core";
import CommonTable from "../commonTable";

function Communication({ reviewer = [], submitter = [] }) {
  const classes = useStyles();
  const [submmiterData, setSubmmiterData] = useState({ top: [], bottom: [] });
  const [reviewerData, setReviewerData] = useState({ top: [], bottom: [] });

  const columns = [
    {
      title: "Rank",
      field: "Rank",
      headerStyle: {
        textAlign: "left",
        backgroundColor: "#efefef",
      },
      render: (rowData) => rowData.tableData.id + 1,
    },
    {
      title: "Assignee",
      field: "Assignee",
      headerStyle: {
        textAlign: "left",
        backgroundColor: "#efefef",
      },
    },
    {
      title: "Value",
      field: "Value",
      headerStyle: {
        textAlign: "left",
        backgroundColor: "#efefef",
      },
    },
  ];

  useEffect(() => {
    const reviewerData = [...reviewer]?.filter((e) => e?.Assignee !== null);
    const submitterData = [...submitter]?.filter((e) => e?.Assignee !== null);

    const sortedReviewer = reviewerData.sort(
      (a, b) => Math.abs(b.Value) - Math.abs(a.Value)
    );
    setReviewerData({
      top: sortedReviewer.slice(0, 5),
      bottom: sortedReviewer.slice(-5),
    });

    const sortedSubmitter = submitterData.sort(
      (a, b) => Math.abs(b.Value) - Math.abs(a.Value)
    );
    setSubmmiterData({
      top: sortedSubmitter.slice(0, 5),
      bottom: sortedSubmitter.slice(-5),
    });
  }, [reviewer, submitter]);

  return (
    <>
      <Card className={`customCard ${classes.scenarionContainer}`}>
        <List
          data={submmiterData.top}
          columns={columns}
          label={"Top 5 PR Submitter  "}
          classes={classes}
        />
        <List
          data={reviewerData.top}
          columns={columns}
          label={"Top 5 PR Reviewer "}
          classes={classes}
        />
      </Card>
      {/* <Card className={`customCard mt-5 ${classes.scenarionContainer}`}>
            <List data={submmiterData.bottom} columns={columns} label={"Bottom 5 PR Submitter  "} classes={classes} />
            <List data={reviewerData.bottom} columns={columns} label={"Bottom 5 PR Reviewer "} classes={classes} />
        </Card> */}
      {/* <Card className={`customCard mt-5 ${classes.scenarionContainer}`}>
        <List
          data={submmiterData.bottom}
          columns={columns}
          label={
            "Top 5 story with maximum complexity (base on Commit & Pull Request)"
          }
          classes={classes}
        />
      </Card> */}
    </>
  );
}

export default Communication;

function List({ data = [], label, columns }) {
  return (
    <>
      {data.length > 0 && (
        <CommonTable
          data={data}
          columns={columns}
          options={{ paging: false, search: false }}
          title={label}
        />
      )}
    </>
  );
}
