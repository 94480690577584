import axios from "../../utils/axiosHelper";
import { useEffect, useState, useContext } from "react";
import SelectField from "../../components/select/selectField";
import AutoComp from "../../components/jira/autoComp";
import { makeStyles } from "@material-ui/core/styles";
import { JiraDynamicTable } from "../../components/jira/tables/jiraDyanamicTable";
import { JiraTableSearchBar } from "../../components/jira/jiraTableSearchBar";
import RangeSelector from "../../components/rangeSelector";
import { Button, CircularProgress } from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { GlobalData } from "../../context/globalData";
import RangeSelectorGitHub from "./rangeSelectorGitHub";
import { format } from "date-fns";
import UpgradePlanRequest from "../../utils/UpgradePlanRequest";
const useStyles = makeStyles((theme) => ({
  searchbar: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "-60px",
    margin: "0px 20px",
    paddingBottom: "20px",
  },
  feature: {
    display:"flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "20px",
    fontWeight: "bolder",
    marginTop: "20px"
  },
  btn: {
    backgroundColor: "#51a7ed",
    fontSize: "15px",
    padding: "0px 30px",
    color: "#fff",
    height: "35px",
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: "#072136",
    },
  },
  featureBtn:{
    display: "flex",
    justifyContent: "center",
  },
}));
export const GitHubBeta = () => {
  const [repositories, setReopositories] = useState([]);
  const [selectedRepositoryName, setSelectedRepositoryName] = useState({});
  const [repositoryName, setRepositoryName] = useState([]);
  const [searchData, setSearchData] = useState([]);
  const [length, setLength] = useState();
  const [openRange, setOpenRange] = useState(false);
  const [loader, setLoader] = useState(true);
  const [rangePickerValue, setRangePickerValue] = useState({
    // startDate: new Date(new Date(new Date().setDate(new Date().getDate() -30))),
    // endDate: new Date(),
    // key: "selection",
  });

  const { isPlanUpgradeRequest, setIsPlanUpgradeRequest } =
  useContext(GlobalData);

  const classes = useStyles();

  const keysOfObject = [
    {
      title: "Commit Id",
      keyItem: "sha",
      toggle: false,
    },
    {
      title: "Author",
      keyItem: "author",
      toggle: false,
    },
    {
      title: "Date",
      keyItem: "date",
      toggle: false,
    },
    {
      title: "New Work",
      keyItem: "newWorkPercentage",
      toggle: false,
    },
    {
      title: "Rework (churn)",
      keyItem: "reWorkPercentage",
      toggle: false,
    },
    {
      title: "Legacy Refactor",
      keyItem: "legacyrefactorPercentage",
      toggle: false,
    },
  ];

  let ClientID = localStorage.getItem("ClientId");

  useEffect(() => {
    let url = `/api/github2/get-repositories?clientId=${ClientID}`;
    axios
      .get(url)
      .then((res) => {
        const list = res?.data?.data.map((entry) => {
          return { name: entry?.name };
        });
        setReopositories(list);
        setSelectedRepositoryName(list[0]);
      })
      .catch((err) => {
        console.log(err);
        setLoader(false);
      });
  }, []);

  useEffect(() => {
    if (!selectedRepositoryName?.name) return;
    let urlTable =
      !rangePickerValue?.startDate && !rangePickerValue?.endDate
        ? `/api/github2/get-calculation?clientId=${ClientID}&repo_name=${selectedRepositoryName?.name}`
        : `/api/github2/get-calculation?clientId=15&repo_name=${
            selectedRepositoryName?.name
          }&start_date=${format(
            new Date(rangePickerValue?.startDate),
            "yyyy-MM-dd"
          )}&end_date=${format(
            new Date(rangePickerValue?.endDate),
            "yyyy-MM-dd"
          )}`;
    axios
      .get(urlTable)
      .then((res) => {
        setLoader(false);
        const list = res?.data?.data.map((entry) => {
          return { name: entry?.name };
        });
        setRepositoryName(res?.data?.data);
        setSearchData(res?.data?.data);
        setLength(list.length);
      })
      .catch((err) => {
        console.log(err);
        setLoader(false);
      });
  }, [selectedRepositoryName, rangePickerValue]);

  // search functionality
  const [searchInput, setSearchInput] = useState("");
  const handleChangeSearchBar = (e) => {
    e.preventDefault();
    setSearchInput(e.target.value);
    let val = searchData.filter((title) => {
      return title?.author
        ?.toLowerCase()
        .includes(e.target.value.toLowerCase());
    });
    if (e?.target?.value?.length > 0) {
      setRepositoryName(val);
    } else {
      setRepositoryName(searchData);
    }
  };
  const customRangePickerHandler = (val) => {
    setRangePickerValue(val);
  };

  let pricePlan= JSON.parse(localStorage.getItem("pricePlan"));
  let featureList =pricePlan?.pricingPlan?.featureList?.githubTab;

  const planRequestHandler=()=>{
    setIsPlanUpgradeRequest(!isPlanUpgradeRequest);
  }

  return (
    <>
      {featureList === true ? (
        <>
          <div style={{ display: "flex", gap: "20px", marginTop: "20px" }}>
            <div style={{ marginTop: "6px" }}>
              <AutoComp
                data={repositories}
                labelName={"Repository Names"}
                setValue={setSelectedRepositoryName}
                value={selectedRepositoryName}
              />
            </div>
            <div>
              <SelectField
                input={
                  <Button
                    onClick={() => setOpenRange(true)}
                    style={{
                      border: "1px solid #b7b7b7",
                      width: "auto",
                      textTransform: "capitalize",
                      padding: "12px",
                      fontSize: "16px",
                      fontWeight: 400,
                      paddingLeft: "14px ",
                      paddingRight: "11px",
                      justifyContent: "space-between",
                    }}
                    endIcon={<ArrowDropDownIcon />}
                  >
                    {rangePickerValue?.startDate && rangePickerValue?.endDate
                      ? ` ${format(
                          new Date(rangePickerValue?.startDate),
                          "dd-MMM-yyyy"
                        )} to
                  ${format(new Date(rangePickerValue?.endDate), "dd-MMM-yyyy")}`
                      : "Select Date"}
                  </Button>
                }
                onClick={() => setOpenRange(true)}
              />
              <RangeSelectorGitHub
                open={openRange}
                setOpen={setOpenRange}
                pickerHandler={customRangePickerHandler}
              />
            </div>
          </div>

          <div>
            {length > 0 ? (
              <>
                <div className={classes.searchbar}>
                  <JiraTableSearchBar
                    searchInput={searchInput}
                    setMetricData={setRepositoryName}
                    metricBarData={repositoryName}
                    handleChangeSearchBar={handleChangeSearchBar}
                  />
                </div>
                <div>
                  {loader ? (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-around",
                        height: "100px",
                      }}
                    >
                      <CircularProgress />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <JiraDynamicTable
                  searchJiraIssue={repositoryName}
                  keysOfObject={keysOfObject}
                  name={"gitHub"}
                />
              </>
            ) : (
              <div style={{ textAlign: "center", marginTop: "30px" }}>
                <h3>NO DATA FIND</h3>
              </div>
            )}
          </div>
        </>
      ) : (
        <>
          <div className={`${classes.feature}`}>
            <h4>You dont have access. Please upgrade your plan.</h4>
          </div>
          <div className={`${classes.featureBtn}`}>
            <Button variant="contained" className={classes.btn} type="button" onClick={()=>{planRequestHandler()}}>
              Upgrade
            </Button>
          </div>
          {isPlanUpgradeRequest && <UpgradePlanRequest />}
        </>
      )}
    </>
  );
};
