import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import { useState } from "react";
// imports for popup use to for legends of graph
import Popover from "@material-ui/core/Popover";
//checkbox
import Checkbox from "@material-ui/core/Checkbox";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import SearchIcon from "@material-ui/icons/Search";
import { withStyles } from "@material-ui/core/styles";
import { useHistory, useLocation, useParams } from "react-router-dom";

const GreenCheckbox = withStyles({
  root: {
    // color: "#56aee4",
    "&$checked": {
      color: "#56aee4",
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);
const useStyles = makeStyles((theme) => ({
  //checkbox
  typography: {
    minWidth: "250px",
    minHeight: "300px",
    padding: "14px 18px",
    backgroundColor: "#ffffff",
  },
  typographySeeMore: {
    minWidth: "250px",
    minHeight: "300px",
    padding: "14px 18px",
    backgroundColor: "#ffffff",
    // display: "flex",
  },
  btnfr: {
    width: "120px",
    height: "40px",
    backgroundColor: "transparent",
    color: "#000000cf",
    border: "1px solid #00000042",
    boxShadow: "none",
    marginTop: "8px",
    padding: "6px 0px",
    fontWeight: "400",
    fontSize: "16px",
    textTransform: "capitalize",
    "&:hover": {
      background: "transparent",
      boxShadow: "none",
      border: "1px solid black",
    },
  },
  searchBarontop: {
    minWidth: "300px",
    padding: "4px 0px 4px 36px",
    borderRadius: "4px",
    border: "1px solid #00000042",
  },
  Serch: {
    position: "reletive",
  },
  SerchIcon: {
    position: "absolute",
    left: "25px",
    fontSize: "22px",
    paddingTop: "4px",
  },
  seemorebtn: {
    padding: "0px",
    backgroundColor: "transparent",
    color: "#56aee4",
    boxShadow: "none",
    textTransform: "capitalize",
    "&:hover": {
      background: "transparent",
      boxShadow: "none",
    },
  },
  projSelect: {
    display: "flex",
    alignItems: "center",
  },
  inputflid: {
    width: "807px",
    display: "grid",
    gridTemplateRows: "auto auto auto auto auto",
    overflow: "auto",
  },
  closenserchicon: {
    display: "flex",
    justifyContent: "space-between",
  },
  closenserchicon2: {
    display: "flex",
    justifyContent: "end",
  },
  srechfild: {
    padding: "8px 14px",
    borderRadius: "6px",
    border: "1px solid #00000042",
  },
  selectnclear: {
    display: "flex",
    justifyContent: "flex-end",
    paddingTop: "20px",
    alignItems: "center",
    "& label": {
      color: "#0e5c8d",
      padding: "0px 20px",
      fontWeight: "bold",
    },
    "& button": {
      padding: "8px 10px",
      border: "1.5px solid #00000042",
      // backgroundColor: "#56aee4",
      borderRadius: "5px",
      marginLeft: "24px",
      // color: "#00000091",
      fontWeight: "600",
      // "&:hover": {
      //    cursor:"pointer"
      // },
    },
 
  },
  btnClear:{
      
    "&:hover": {
      cursor:"pointer"
   },
  },
  btnapply:{
    backgroundColor: "#56aee4",
    color:"#ffffff",
    "&:hover": {
       cursor:"pointer"
    },
  },
  pp: {
    top: "227px",
    left: "488px",
  },
  btnClose:{
    "&:hover": {
      cursor:"pointer"
   },
  }
  // span: {
  //   "& MuiButton-label": {
  //     fontSize: "16px",
  //   },
  // },
}));
export const ModuleProjectList = ({
  allData,
  handle,
  searchBar,
  searchInput,
  clear,
  countOfChecked,
  handleApply,
  setallData,
  setCountOfChecked,
  CountValue,
  allProjectsName,
  dropdowntest,
  searchDisable,
  titleId,
  setTitleID,
  titleName,
  setTitleName,
}) => {
  const classes = useStyles();
  //popup functions for legends of graph
  const [anchorEl, setAnchorEl] = useState(null);
    //apply filter disable method
  const[disableApplyFillter,setDisableApplyFillter]=useState(false);
  const history = useHistory();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  //pop up for see more
  const [anchorElSeeMore, setAnchorElSeeMore] = useState(null);
  const [openSeeMore, setOpenSeeMore] = useState(false);
  const handleClickSeeMore = (event) => {
    setOpenSeeMore(true);
    // setAnchorElSeeMore(event.currentTarget);
    // setAnchorEl(null);
  };
  const handleCloseSeeMore = () => {
    setOpenSeeMore(false);
    setAnchorElSeeMore(null);
  };
  // const idSeeMore = openSeeMore ? "simple-popover" : undefined;
  const handleChange = (index, title) => {
    let allDataTemp = [...allData];
    allDataTemp[index].checked = !allDataTemp[index].checked;
    setallData(allDataTemp);
 
    //count for selected project
    let counted = CountValue(allProjectsName);
    setCountOfChecked(counted);
  };


  //console.log(allData,"all data project");
  const handleModule = (index, titleName, titleId) =>{
    setTitleID(titleId);
    setTitleName(titleName);
    // history.push({
    //   pathname: `/riskadvisor/project/${titleName.replace(
    //     "/",
    //     ""
    //   )}/${titleId}/2`,
    //   hash: "",
    // });

    // let allDataTemp = [...allData];
    // allDataTemp[index].checked = !allDataTemp[index].checked;
    // setallData(allDataTemp);
 
    // //count for selected project
    // let counted = CountValue(allProjectsName);
    // setCountOfChecked(counted);
  };
  return (
    <div>
      <Button
        aria-describedby={id}
        variant="contained"
        color="primary"
        onClick={handleClick}
        className={classes.btnfr}
      >
        {dropdowntest}
        <ArrowDropDownIcon style={{ color: "#00000082", marginLeft: "20px" }} />
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        className={classes.pp}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        {/* checkbox */}
        <div className={classes.typography}>
          <div className={classes.Serch}>
          <input
              type="search"
              placeholder="Search here"
              onChange={searchBar}
              value={searchInput}
              className={classes.searchBarontop}
            />
               <SearchIcon className={classes.SerchIcon} />
        
          </div>
          {allData?.map(
            (title, index) =>
              index < 5 && (
                <div key={index}>
                  {/* <GreenCheckbox
                    checked={title.checked}
                    onChange={() => handle(index, title.projectName)}
                    name="checkedB"
                    color="primary"
                    className="check"
                  /> */}

                  <label onClick={() => handleModule(index, title.ProjectName, title.ProjectId)}>{title.ProjectName}</label>
                </div>
              )
          )}

          <div>
            {allData?.length > 5 && (
              <Button
                // aria-describedby={idSeeMore}
                variant="contained"
                color="primary"
                onClick={handleClickSeeMore}
                className={classes.seemorebtn}
              >
                {allData?.length - 5} more
              </Button>
            )}
          </div>
        </div>
      </Popover>
      {openSeeMore && (
        <Popover
          // id={idSeeMore}
          // open={openSeeMore}
          // anchorEl={anchorElSeeMore}
          // onClose={handleCloseSeeMore}
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={() => {
            handleClose();
            handleCloseSeeMore();
          }}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          {/* checkbox */}
          <div className={classes.typographySeeMore}>
            <div className={classes.closenserchicon}>
                <input
                type="search"
                placeholder="Search here"
                onChange={searchBar}
                value={searchInput}
                className={classes.srechfild}
              />
              <div
              className={classes.btnClose}
                onClick={() => {
                  setOpenSeeMore(false);
                  setAnchorElSeeMore(null);
                  setAnchorEl(null);
                }}
              >
                {/* <CloseIcon /> */}
              </div>
            </div>

            {/* <button onClick={handleApply}>Apply Filter</button> */}
            <div
              className={classes.inputflid}
              style={{
                gridTemplateColumns: `repeat(${Math.ceil(
                  allData?.length / 6
                )}, 200px)`,
              }}
            >
              {allData?.map((title, index) => (
                <div key={index} className={classes.projSelect}>
                  {/* <GreenCheckbox
                    checked={title.checked}
                    onChange={() => handleChange(index, title.projectName)}
                    name="checkedB"
                    color="primary"
                    className="check"
                    disabled={countOfChecked === 10 && title.checked===false ? true : false }
                  /> */}
                  <label onClick={() => handleModule(index, title.ProjectName, title.ProjectId)}>{title.ProjectName}</label>
                </div>
              ))}
            </div>
            <div className={classes.selectnclear}>
            {countOfChecked===10 ? <label style={{color:"red"}}>Select only 10 </label> : ""}
              <label>{countOfChecked}/10 Selected</label>
              <button className={classes.btnClear} onClick={clear}>Clear All</button>
              <button
              className={classes.btnapply}
                onClick={() => {
                  handleApply();
                  handleClose();
                  handleCloseSeeMore();
                }}
                disabled={false}
              >
                Apply Filter
              </button>
              {/* countOfChecked */}
            </div>
          </div>
        </Popover>
      )}
    </div>
  );
};
