import { FormControl, MenuItem, Select } from "@material-ui/core";
import React from "react";

function NormalSelect({ handleChange, value, option, style = {} }, props) {
  return (
    <FormControl fullWidth>
      <Select
        style={{
          border: "1px solid #DCDCDC",
          borderRadius: 4,
          padding: "10px 10px",
          ...style,
        }}
        value={value}
        onChange={(e) => handleChange(e?.target?.value)}
        {...props}
      >
        {option?.map((e) => (
          <MenuItem value={e?.value} key={e?.value}>
            {e?.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default NormalSelect;
