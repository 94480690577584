import { createContext, useState } from "react";

export const GlobalData = createContext();

const GlobalDataProvider = (props) => {
  const [metricData, setMetricData] = useState([]);
  const [metricDataName, setMetricDataName] = useState([]);
  const [currentRender, setCurrentRender] = useState(0);
  const [localChart, setLocalChart] = useState(1);
  const [localChart1, setLocalChart1] = useState(1);
  const [localChart2, setLocalChart2] = useState(1);
  const [localChart3, setLocalChart3] = useState(1);
  const [setting, setSetting] = useState(false);
  const [iteration, setIteration] = useState([]);
  const [iterationValueGlobal, setIterationValueGlobal] = useState("Weekly");
  const [RiskActive, setRiskActive] = useState(false);
  const [riskadvisor, setRiskAdvisor] = useState(false);
  const [titleId, setTitleID] = useState();
  const [titleName, setTitleName] = useState();
  const [metricDetails, setmetricDetails] = useState([]);
  const [settingData, setSettingData] = useState({
    stroke: 3,
    range: -89,
    rangeData: "Last 90 Days",
  });
  const [graphLoading, setGraphLoading] = useState(true);
  const [graphLoading2, setGraphLoading2] = useState(true);
  const [allMetricData, setAllMetricData] = useState([]);
  const [AdminMetric, setAdminMetric] = useState([]);
  const [teamsval, setTeamVal] = useState(["All Teams"]);
  const [openPop, setOpenPop] = useState(false);
  const [trendData, setTrendData] = useState([]);
  const [popupPrecision, setPopupPrecision] = useState(null);
  const [isLogin, setIsLogin] = useState(
    localStorage.getItem("token") ? true : false
  );
  const [role, setRole] = useState(false);
  const [selectedMetricTrend, setSelectedMetricTrend] = useState(null);
  const [isSummary, setIsSummary] = useState(false);
  const [enableAi, setEnableAi] = useState(false);
  const [futureDates, setFutureDates] = useState(0);
  // const [localChart, setLocalChart] = useState(1);
  const [projectLevelData, setProjectLevelData] = useState();
  const [projectLevelData2, setProjectLevelData2] = useState();
  const [selectedTab, setSelectedTab] = useState("");
  const [projectNameData, setProjectNameData] = useState([]);
  const [projectRangePickerValue, setProjectRangePickerValue] = useState({
    isCustom: false,
    range: "Last 180 Days",
  });
  const Risk = ["Green - Low Risk", "Yellow - Medium Risk", "Red - High Risk"];
  const [errorMessage, setErrorMessage] = useState("");
  const [error2, setError2] = useState(false);
  const [error, setError] = useState({});
  const [isSignUp, setIsSignUp] = useState(true);
  const [isVerification, setIsVerification] = useState(false);
  const [flag, setFlag] = useState(false);
  const [sonarqubeFlag, setSonarqubeFlag] = useState(false);
  const [metricConfigurationFlag, setMetricConfigurationFlag] = useState(false);
  const [upgradeMatricFlag, setUpgradeMatricFlag] = useState(false);
  const [isPlanUpgradeMessage, setIsPlanUpgradeMessage] = useState(false);
  const [isPlanUpgradeRequest, setIsPlanUpgradeRequest] = useState(false);
  const [metricConfigurationData, setMetricConfigurationData] = useState({});
  const [moduleKpiFlag, setModuleKpiFlag] = useState(false);
  const [moduleKpiData, setModuleKpiData] = useState([]);
  const [selectedmoduleKpiName, setSelectedmoduleKpiName] = useState();
  const [moduleKpiGraphFlag, setModuleKpiGraphFlag] = useState(false);
  const [projectList, setProjectList] = useState([]);
  const [isDefinaionPopup, setIsDefinaionPopup] = useState(false);
  const [definationKPI, setDefinationKPI] = useState("");
  const [selectedProjectModuleName, setSelectedProjectModuleName] = useState();
  const [toolError, setToolsError] = useState("");
  const [toolIntegrationUrl, setToolIntegrationUrl] =
    useState("/auth/onBoarding");
  const [uniqueuserlistByEmail, setUniqueuserlistByEmail] = useState();
  const [savePageData, setSavePageData] = useState();
  const [selectedprojectId, setSelectedprojectId] = useState();
  const [totalWorkCommit, setTotalWorkCommit] = useState("");
  const [deliveryTracker, setDeliveryTracker] = useState({});

  return (
    <GlobalData.Provider
      value={{
        flag,
        setFlag,
        sonarqubeFlag,
        setSonarqubeFlag,
        metricConfigurationFlag,
        setMetricConfigurationFlag,
        metricConfigurationData,
        setMetricConfigurationData,
        upgradeMatricFlag,
        setUpgradeMatricFlag,
        isPlanUpgradeMessage,
        setIsPlanUpgradeMessage,
        isPlanUpgradeRequest,
        setIsPlanUpgradeRequest,
        projectLevelData,
        setProjectLevelData,
        projectLevelData2,
        setProjectLevelData2,
        projectNameData,
        setProjectNameData,
        graphLoading,
        setGraphLoading,
        graphLoading2,
        setGraphLoading2,
        metricData,
        setMetricData,
        teamsval,
        setTeamVal,
        openPop,
        setOpenPop,
        trendData,
        setTrendData,
        allMetricData,
        setAllMetricData,
        isLogin,
        setIsLogin,
        selectedMetricTrend,
        setSelectedMetricTrend,
        popupPrecision,
        setPopupPrecision,
        isSummary,
        setIsSummary,
        enableAi,
        setEnableAi,
        futureDates,
        setFutureDates,
        projectRangePickerValue,
        setProjectRangePickerValue,
        settingData,
        setSettingData,
        role,
        setRole,
        AdminMetric,
        setAdminMetric,
        metricDetails,
        setmetricDetails,
        iteration,
        setIteration,
        iterationValueGlobal,
        setIterationValueGlobal,
        RiskActive,
        setRiskActive,
        riskadvisor,
        setRiskAdvisor,
        setting,
        setSetting,
        metricDataName,
        setMetricDataName,
        selectedTab,
        setSelectedTab,
        Risk,
        errorMessage,
        setErrorMessage,
        error2,
        setError2,
        error,
        setError,
        currentRender,
        setCurrentRender,
        isSignUp,
        setIsSignUp,
        isVerification,
        setIsVerification,
        localChart,
        setLocalChart,
        localChart1,
        setLocalChart1,
        localChart2,
        setLocalChart2,
        localChart3,
        setLocalChart3,
        titleId,
        setTitleID,
        titleName,
        setTitleName,
        moduleKpiFlag,
        setModuleKpiFlag,
        moduleKpiData,
        setModuleKpiData,
        selectedmoduleKpiName,
        setSelectedmoduleKpiName,
        moduleKpiGraphFlag,
        setModuleKpiGraphFlag,
        projectList,
        setProjectList,
        isDefinaionPopup,
        setIsDefinaionPopup,
        selectedProjectModuleName,
        setSelectedProjectModuleName,
        toolError,
        setToolsError,
        toolIntegrationUrl,
        setToolIntegrationUrl,
        uniqueuserlistByEmail,
        setUniqueuserlistByEmail,
        definationKPI,
        setDefinationKPI,
        savePageData,
        setSavePageData,
        selectedprojectId,
        setSelectedprojectId,
        totalWorkCommit,
        setTotalWorkCommit,
        deliveryTracker,
        setDeliveryTracker,
      }}
    >
      {props.children}
    </GlobalData.Provider>
  );
};
export default GlobalDataProvider;
