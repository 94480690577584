import React from "react";
import { useHistory } from "react-router-dom";
import BreadCrumb from "../../../components/breadCrumb/BreadCrumb";
import { Card, Grid, Typography } from "@material-ui/core";
import CommonTable from "../../../components/commonTable";
// import LineTrendChart from "../../components/trendGraph/lineTrendChart";
import LinearProgress from "@material-ui/core/LinearProgress";

function ResourceUtilized() {
  const history = useHistory();
  const graphData = [
    {
      Value1: 5.577625347134466,
      Time: "2023-06-04T00:00:00.000Z",
      Name1: "Rushikesh",
      Value: 7,
      Name: "Target",
    },
    {
      Value1: 5.180414571073754,
      Time: "2023-06-11T00:00:00.000Z",
      Name1: "Rushikesh",
      Value: 7,
      Name: "Target",
    },
    {
      Value1: 5.064797698944997,
      Time: "2023-06-18T00:00:00.000Z",
      Name1: "Rushikesh",
      Value: 7,
      Name: "Target",
    },
    {
      Value1: 6.251186345143484,
      Time: "2023-06-25T00:00:00.000Z",
      Name1: "Rushikesh",
      Value: 7,
      Name: "Target",
    },
    {
      Value1: 5.213248002315868,
      Time: "2023-07-02T00:00:00.000Z",
      Name1: "Rushikesh",
      Value: 7,
      Name: "Target",
    },
    {
      Value1: 4.936554703051427,
      Time: "2023-07-09T00:00:00.000Z",
      Name1: "Rushikesh",
      Value: 7,
      Name: "Target",
    },
    {
      Value1: 5.524802740858398,
      Time: "2023-07-16T00:00:00.000Z",
      Name1: "Rushikesh",
      Value: 7,
      Name: "Target",
    },
    {
      Value1: 5.596474511427014,
      Time: "2023-07-23T00:00:00.000Z",
      Name1: "Rushikesh",
      Value: 7,
      Name: "Target",
    },
    {
      Value1: 5.182441493130974,
      Time: "2023-07-30T00:00:00.000Z",
      Name1: "Rushikesh",
      Value: 7,
      Name: "Target",
    },
    {
      Value1: 5.250848732099689,
      Time: "2023-08-06T00:00:00.000Z",
      Name1: "Rushikesh",
      Value: 7,
      Name: "Target",
    },
    {
      Value1: 5.409914681548915,
      Time: "2023-08-13T00:00:00.000Z",
      Name1: "Rushikesh",
      Value: 7,
      Name: "Target",
    },
    {
      Value1: 4.79300125683839,
      Time: "2023-08-20T00:00:00.000Z",
      Name1: "Rushikesh",
      Value: 7,
      Name: "Target",
    },
  ];

  const columns = [
    {
      field: "name",
      title: "Name",
    },
    {
      field: "commitId",
      title: "capacity accuracy",
    },
    {
      field: "level",
      title: "Level",
      render: (row) => (
        <LinearProgress variant="determinate" value={row.level} />
      ),
    },
    {
      field: "email",
      title: "Email",
    },
  ];

  const row = [
    { name: "aninda", commitId: "High", level: 30, email: "test@123.com" },
    { name: "ajay", commitId: "High", level: 40, email: "test@123.com" },
    { name: "rushi", commitId: "Medium", level: 50, email: "test@123.com" },
    { name: "shubham", commitId: "Medium", level: 20, email: "test@123.com" },
    { name: "Rushikeh", commitId: "Low", level: 90, email: "test@123.com" },
  ];
  return (
    <>
      <BreadCrumb
        backHandler={() => {
          history.goBack();
        }}
        active={"Resource underutilized /Disengaged"}
      />
      <div>
        <Grid container spacing={2}>
          {/* <Grid item xs={12}>
            <Card className="customCard resourceStyling p-3">
              <LineTrendChart body="graph1" graphData={graphData} />
            </Card>
          </Grid> */}
          <Grid item xs={12}>
            <Card className="customCard resourceStyling p-3">
              <Typography color="text.secondary" gutterBottom component={"h3"}>
                High capacity accuracy score greater than 90%
              </Typography>
              <CommonTable columns={columns} data={row} />
            </Card>
          </Grid>
        </Grid>
      </div>
    </>
  );
}

export default ResourceUtilized;
