import { endOfMonth, startOfMonth, subMonths } from "date-fns";
import { dateConvert } from "./apiGen";

const dateRangeMap = {
  "Last 180 Days": 6,
  "Last 360 Days": 12,
  "Last 90 Days": 3,
  "Last 60 Days": 2,
  "Last 30 Days": 1,
  "Last 20 Days": 20,
  "Last 15 Days": 15,
  "Last 7 Days": 7,
  "Next 7 Days": 7,
  "Next 15 Days": 15,
  "Next 30 Days": 30,
  "Next 60 Days": 60,
  "Next 90 Days": 90,
};
const dateRangeMapForUi = {
  "Last 180 Days": 180,
  "Last 360 Days": 360,
  "Last 90 Days": 90,
  "Last 60 Days": 60,
  "Last 30 Days": 30,
  "Last 20 Days": 20,
  "Last 15 Days": 15,
  "Last 7 Days": 7,
  "Next 7 Days": 7,
  "Next 15 Days": 15,
  "Next 30 Days": 30,
  "Next 60 Days": 60,
  "Next 90 Days": 90,
};

// api for detail
export const MODULE_API_URL_GEN = (
  rangePickerValue,
  projectId,
  // metricValue,
  iterationValue
) => {
  const project_id = projectId ? `&project_id=${projectId}` : "";
  if (rangePickerValue.isCustom) {
    return `no_of_days=${rangePickerValue.range + 1}&startDay=${dateConvert(
      rangePickerValue.startDate
    )}&endDay=${dateConvert(
      rangePickerValue.endDate
    )}&iteration=${iterationValue}${project_id}`;
  } else if (rangePickerValue.range.includes("Next")) {
    const lastDate = new Date();
    lastDate.setDate(
      lastDate.getDate() + (dateRangeMap[rangePickerValue.range] - 1)
    );
    const nextDate = new Date();
    nextDate.setDate(nextDate.getDate());
    return `no_of_days=${
      dateRangeMap[rangePickerValue.range]
    }&startDay=${dateConvert(nextDate)}&endDay=${dateConvert(
      lastDate
    )}&iteration=${iterationValue}${project_id}`;
  } else {
    const lastDate = new Date();
    // lastDate.setDate(lastDate.getDate() - 1);
    const lastDayOfLastMonth = endOfMonth(subMonths(startOfMonth(lastDate), 1));
    const nextDate = new Date();
    // nextDate.setDate(nextDate.getDate() - dateRangeMap[rangePickerValue.range]);
    const firstDayOfLastMonth = subMonths(
      startOfMonth(nextDate),
      dateRangeMap[rangePickerValue.range]
    );
    return `no_of_days=${
      dateRangeMapForUi[rangePickerValue.range]
    }&startDay=${dateConvert(firstDayOfLastMonth)}&endDay=${dateConvert(
      lastDayOfLastMonth
    )}&iteration=${iterationValue}${project_id}`;
  }
};

export const datePayload = (rangePickerValue) => {
  // const project_id = projectId ? `&project_id=${projectId}` : "";
  if (rangePickerValue?.isCustom) {
    return `no_of_days=${rangePickerValue?.range + 1}&startDay=${dateConvert(
      rangePickerValue?.startDate
    )}&endDay=${dateConvert(rangePickerValue?.endDate)}`;
  } else if (rangePickerValue?.range?.includes("Next")) {
    const lastDate = new Date();
    lastDate.setDate(
      lastDate.getDate() + (dateRangeMap[rangePickerValue?.range] - 1)
    );
    const nextDate = new Date();
    nextDate.setDate(nextDate.getDate());
    return `no_of_days=${
      dateRangeMap[rangePickerValue?.range]
    }&startDay=${dateConvert(nextDate)}&endDay=${dateConvert(lastDate)}`;
  } else {
    const lastDate = new Date();
    // lastDate.setDate(lastDate.getDate() - 1);
    const lastDayOfLastMonth = endOfMonth(subMonths(startOfMonth(lastDate), 1));
    const nextDate = new Date();
    // nextDate.setDate(nextDate.getDate() - dateRangeMap[rangePickerValue.range]);
    const firstDayOfLastMonth = subMonths(
      startOfMonth(nextDate),
      dateRangeMap[rangePickerValue?.range]
    );
    return `no_of_days=${
      dateRangeMapForUi[rangePickerValue?.range]
    }&startDay=${dateConvert(firstDayOfLastMonth)}&endDay=${dateConvert(
      lastDayOfLastMonth
    )}`;
  }
};

export const getSelectedDate = (rangePickerValue) => {
  if (rangePickerValue?.isCustom) {
    return `${dateConvert(rangePickerValue.startDate)} to ${dateConvert(
      rangePickerValue.endDate
    )}`;
  } else if (rangePickerValue?.range.includes("Next")) {
    const lastDate = new Date();
    lastDate.setDate(
      lastDate.getDate() + (dateRangeMap[rangePickerValue?.range] - 1)
    );
    const nextDate = new Date();
    nextDate.setDate(nextDate.getDate());
    return `${dateConvert(nextDate)} to ${dateConvert(lastDate)}`;
  } else {
    const lastDate = new Date();
    // lastDate.setDate(lastDate.getDate() - 1);
    const lastDayOfLastMonth = endOfMonth(subMonths(startOfMonth(lastDate), 1));
    const nextDate = new Date();
    // nextDate.setDate(
    //   nextDate.getDate() - dateRangeMap[rangePickerValue?.range]
    // );
    const firstDayOfLastMonth = subMonths(
      startOfMonth(nextDate),
      dateRangeMap[rangePickerValue.range]
    );
    return `${dateConvert(firstDayOfLastMonth)} to ${dateConvert(
      lastDayOfLastMonth
    )}`;
  }
};

// this for format date string
export const addLeadingZeroToDateRange = (dateUrl) => {
  // Split the dateUrl string by '&' to separate parameters
  const params = dateUrl.split("&");

  // Iterate through each parameter
  for (let i = 0; i < params.length; i++) {
    // Split the parameter by '=' to separate key and value
    const keyValue = params[i].split("=");

    // Check if the key is 'startDay' or 'endDay'
    if (keyValue[0] === "startDay" || keyValue[0] === "endDay") {
      // Split the date value by '-' to separate year, month, and day
      const dateParts = keyValue[1].split("-");

      // Check if the day part needs a leading zero
      if (parseInt(dateParts[2]) >= 1 && parseInt(dateParts[2]) <= 10) {
        // Add leading zero if the day is between 1 and 10
        dateParts[2] = dateParts[2].padStart(2, "0");
      }

      // Join the modified date parts back together
      const modifiedDate = dateParts.join("-");

      // Replace the original date value with the modified one
      params[i] = `${keyValue[0]}=${modifiedDate}`;
    }
  }

  // Join the modified parameters back together with '&'
  const modifiedDateUrl = params.join("&");

  return modifiedDateUrl;
};
