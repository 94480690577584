import { useState, useEffect, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import IconButton from "@material-ui/core/IconButton";
import FormControl from "@material-ui/core/FormControl";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import Logo from "../../assets/itechLogo.png";
// import ProjectDisplay from "../Project";
import IntegrationTool from "../../assets/IntegrationTool.png";
import NavTabs from "../../components/tabs/tabs";
import SelectField from "../../components/select/selectField";
import Details from "./Details";
import MaterialGrid from "../../components/overview";
import TeamMaterialGrid from "../../components/overview/teamMaterialGrid";
import MetricGraph from "../../components/metricGraph/metricGraph";
import axios from "../../utils/axiosHelper";
import { useColumnHook } from "../../utils/columnhook";
import { useMetricHook } from "../../utils/metricHook";
import { idMap } from "../../utils/idsMap";
import { API_TREND_GEN, API_GRID_GEN } from "../../utils/apiGen";
import Riskpage from "../risks";
import RangeSelector from "../../components/rangeSelector";
import { Button } from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { GlobalData } from "../../context/globalData";
import ScrollToTop from "../../components/scroll/scrollToTop";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { iterationMap } from "../../utils/textMap";
import { setUpMap } from "../../utils/setUpMap";
import { Dashboard } from "../../pages/dashboard";
import Navbar from "../../hoc/layout/navbar";
import Setting from "../../hoc/layout/setting";
import ProjectDisplay from "../../pages/Project";

import {
  CheckboxDataGenrate,
  CountValue,
  MetricGraphDataSorted,
} from "../../utils/riskAdvisorReuseFn";
import { ProjectSelect } from "../../components/projectSelect/projectSelect";
import { TeamProjectList } from "../../components/projectSelect/teamProjectList";
import { ResourceProjectList } from "../../components/projectSelect/resourceProjectList";
import { ModuleProjectList } from "../../components/projectSelect/moduleProjectList";
import { SettingsCellOutlined } from "@material-ui/icons";
import { useStaticState } from "@material-ui/pickers";
import ClusterColumnGraph from "../../components/jira/clusterColumnGraph";
import { Jira } from "../../components/jira";
import { GitHubBeta } from "../../pages/github(beta)";
import SetupForm from "../auth/SetupForm";
import AzureModal from "../../screens/authentication/azure/AzureModal";
import SonarqubeModal from "../../screens/authentication/sonarqube/SonarqubeModal";
import MatricModal from "../../screens/matric/MatricModal";
import UpgradePlanMessage from "../../utils/UpgradePlanMessage";
import UpgradePlanRequest from "../../utils/UpgradePlanRequest";
import ConfigureForm from "../../pages/auth/onBoardingScreens/ConfigureForm";
import Resources from "../Resource/ResourceList/resources";
import ModulesPage from "../Project/modules";
import ProjectDashboard from "../Project/projectDashboard/projectDashboard";
import ProjectPage from "../Project/project";
import Teams from "../Teams/team";

const level = "Project";
const graphLevel = "Projects";
const group_id = idMap.PROJECTS;
const Tabs = [
  "PROJECTS",
  "MODULES",
  "SPRINT",
  "RISK ANYLISIS",
  "NOTIFICATIONS",
  "SETUP",
  "SETTINGS",
  "LOGOUT",
];

// const Admin_Tabs = [
//   "PROJECTS",
//   "MODULES",
//   "SPRINT",
//   "NOTIFICATIONS",
//   "SETTINGS",
//   "LOGOUT",
// ];
const Risks = ["All Risks", "High Risk", "Medium Risk", "Low Risk"];

const Metric = ["Metric Details", "Metric Summary"];
const Status = ["Active", "Inactive"];

const useStyles = makeStyles((theme) => ({
  fieldGrid: {
    display: "flex",
    marginTop: "25px",
    marginBottom: "10px",
    paddingRight: "11px",
    width: "260px",
    justifyContent: "space-between",
  },
  charts: {
    display: "flex",
    // flexWrap: "wrap",
    // justifyContent: "space-between",
    marginBottom: "30px",
    marginTop: "16px",
  },
  tableFieldGrid: {
    position: "absolute",
    top: "-10px",
    zIndex: 1000,
    display: "flex",
    marginTop: "16px",
    // width: "260px",
    justifyContent: "space-between",
  },
  table: {
    position: "relative",
  },
  table2: {
    position: "relative",
    marginTop: "15px",
  },
  header: {
    alignSelf: "center",
    alignContent: "center",
    alignItems: "center",
    marginTop: "8px",
    // marginBottom: "15px",
    display: "flex",
    justifyContent: "space-between",
  },
  text: {
    fontSize: "20px",
    fontWeight: "bold",
    color: "#000000DE",
  },
  button: {
    backgroundColor: " #082C49",
    fontSize: "14px",
    fontWeight: "bold",
    height: "40px",
    borderRadius: "5px",
    color: "#fff",
    Padding: "3px",
    cursor: "pointer",
    // padding: 5,
  },
  //checkbox
  typography: {
    width: "200px",
    height: "300px",
  },
  typographySeeMore: {
    width: "200px",
    height: "300px",
  },
  feature: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "20px",
    fontWeight: "bolder",
    marginTop: "20px",
  },
  btn: {
    backgroundColor: "#51a7ed",
    fontSize: "15px",
    padding: "0px 30px",
    color: "#fff",
    height: "35px",
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: "#072136",
    },
  },
  featureBtn: {
    display: "flex",
    justifyContent: "center",
  },
  check: {},
  mainTitle: {
    marginTop: "-70px",
    marginLeft: "-50px",
    fontSize: "24px",
    fontWeight: "600",
  },
}));

export default function RiskAdvisor(props) {
  const rowData = props.location?.state?.id;
  const title = props.location?.state?.title;
  const check = props.location?.state?.details;
  const allDetails = props.location?.state?.AllDetails;
  const { id: param } = useParams();
  const classes = useStyles();
  const [render, setRender] = useState(1);
  const [setRowId] = useState(rowData);
  const [newData, setnewData] = useState([]);
  const [form, setFrom] = useState(false);
  const [riskValue, setRiskValue] = useState("All Risks");
  const [metricValue, setMetricValue] = useState("Metric Details");
  const [statusValue, setStatusValue] = useState("Active");
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));
  const [graphTrendApi, setGraphTrendApi] = useState();
  const [graphTrendApi2, setGraphTrendApi2] = useState();
  const [graphTrendApi3, setGraphTrendApi3] = useState();
  const [graphTrendApi4, setGraphTrendApi4] = useState();
  const [graphTrendApi5, setGraphTrendApi5] = useState();
  // allprojectname,allmetricgraphdata,selectedprojectnamedata names for metric graph
  const [allProjectsName, setAllProjectNames] = useState([]);
  const [allMetricGraphDataTwo, setAllMetricGraphDataTwo] = useState({});
  const [allMetricGraphDataFirst, setAllMetricGraphDataFirst] = useState({});
  const [allData, setAllData] = useState([]);
  //count of selected project from list as per checkbox
  const [countOfChecked, setCountOfChecked] = useState(0);
  const [isLogout, setLogout] = useState(false);
  const [projectList, setProjectList] = useState();
  const [isOpenSetting, setOpenSetting] = useState(false);

  const {
    setIsSummary,
    projectRangePickerValue: rangePickerValue,
    setProjectRangePickerValue: setRangePickerValue,
    role,
    metricDetails,
    setmetricDetails,
    iteration,
    iterationValueGlobal,
    setIterationValueGlobal,
    graphLoading,
    setGraphLoading,
    graphLoading2,
    graphLoading3,
    graphLoading4,
    graphLoading5,
    setGraphLoading2,
    setGraphLoading3,
    setGraphLoading4,
    setGraphLoading5,
    projectLevelData,
    setProjectLevelData,
    projectLevelData2,
    projectLevelData3,
    projectLevelData4,
    projectLevelData5,
    setProjectLevelData2,
    setProjectLevelData3,
    setProjectLevelData4,
    setProjectLevelData5,
    setProjectNameData,
    currentRender,
    setCurrentRender,
    flag,
    setFlag,
    sonarqubeFlag,
    metricConfigurationFlag,
    upgradeMatricFlag,
    isPlanUpgradeMessage,
    setIsPlanUpgradeMessage,
    isPlanUpgradeRequest,
    setIsPlanUpgradeRequest,
    setOpen,
    titleId,
    setTitleID,
    titleName,
    setTitleName,
    graphLoadingData,
    localChart,
    setLocalChart,
    localChart1,
    setLocalChart1,
    localChart2,
    setLocalChart2,
    localChart3,
    setLocalChart3,
    moduleKpiGraphFlag,
  } = useContext(GlobalData);

  const {
    setColumnVal,
    column,
    data,
    column2,
    data2,
    column3,
    column4,
    column5,
    column6,
    column7,
    column8,
    column9,
    data3,
    data4,
    data5,
    data6,
    data7,
    data8,
    data9,
    spin,
  } = useColumnHook(
    level,
    true,
    metricValue,
    statusValue,
    newData,
    metricDetails,
    title
  );

  const rowHeaderMap = {
    1: column3,
    2: column6,
    3: column4,
    4: column5,
    5: column7,
    6: column8,
    7: column9,
  };
  const rowDataMap = {
    1: data3,
    2: data6,
    3: data4,
    4: data5,
    5: data7,
    6: data8,
    7: data9,
  };
  // metric graph hook=====>
  const { metricGraphData, setMetricGraph, setGridData, flush } =
    useMetricHook();
  const {
    metricGraphData: metricGraphData2,
    setMetricGraph: setMetricGraph2,
    setGridData: setGridData2,
    flush: flush2,
  } = useMetricHook();
  const {
    metricGraphData: metricGraphData3,
    setMetricGraph: setMetricGraph3,
    setGridData: setGridData3,
    flush: flush3,
  } = useMetricHook();
  const {
    metricGraphData: metricGraphData4,
    setMetricGraph: setMetricGraph4,
    setGridData: setGridData4,
    flush: flush4,
  } = useMetricHook();
  const {
    metricGraphData: metricGraphData5,
    setMetricGraph: setMetricGraph5,
    setGridData: setGridData5,
    flush: flush5,
  } = useMetricHook();

  const [isLoading, setLoading] = useState(false);
  const [gridResponse, setGridResponse] = useState(null);
  const [openRange, setOpenRange] = useState(false);
  const [firstTimeGraphLoad, setFirstTimeGraphLoad] = useState(false);
  const history = useHistory();
  const location = useLocation();

  const backHandler = () => {
    setFrom(false);
    // setmetricDetails([]);
    const isLoad = location?.state?.isLoad;
    if (isLoad) {
      history.push(`/riskadvisor/${5}`);
    } else {
      history.goBack();
    }
    // history.push("/riskadvisor");
  };

  useEffect(() => {
    if (render === 9) {
      setLogout(true);
    }
  }, [render]);

  useEffect(() => {
    setStatusValue("Active");
  }, [rowData]);
  useEffect(() => {
    if (param) {
      setCurrentRender(+param);
      setRender(+param);
    }
  }, [param]);

  useEffect(() => {
    if (rowData === 2) {
      let url;
      statusValue === "Active"
        ? (url = "/api/metrics/list?isActive=true")
        : (url = "/api/metrics/list?isActive=false");
      axios
        .get(url)
        .then((result) => {
          // console.log("result1", result);
          setnewData(result.data);
          result.data.length >= 0
            ? setmetricDetails(result.data)
            : setmetricDetails([]);
          // setAllMetricData(result.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [statusValue]);

  const backSubmit = () => {
    let ClientID = localStorage.getItem("ClientId");
    setFrom(false);
    history.goBack();
    let url = "";
    title === "Metric Level Risks"
      ? (url = "/api/risks/")
      : title === "Metric Groups"
        ? (url = "/api/metricgroups/getall")
        : title === "Metrics"
          ? (url = "/api/metrics/list?isActive=true")
          : title === "Project, Team, Resource, Module Level Risks"
            ? (url = "/api/consolidatedrisk/")
            : (url = `/api/alerts/get?clientId=${ClientID}`);
    axios
      .get(url)
      .then((result) => {
        // console.log("result2", result);
        setnewData(result.data);
        if (result?.data?.alerts?.length >= 0 || result?.data >= 0) {
          title === "Alert Frame Work"
            ? setmetricDetails(result?.data?.alerts)
            : setmetricDetails(result?.data);
          // setAllMetricData(result.data);
        } else {
          title === "Alert Frame Work"
            ? setmetricDetails([])
            : setmetricDetails([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    //reset
    setRiskValue("All Risks");
    setMetricValue("Metric Details");
    setStatusValue("Active");
    setFirstTimeGraphLoad(false);
  }, [render]);
  // setGridData2 UseEffect =====>
  useEffect(() => {
    let unmounted = false;
    setLoading(true);
    setGridResponse(null);
    // if (!iterationMap[iterationValueGlobal]) return;
    const source = axios.CancelToken.source();

    setIsSummary(metricValue === "Metric Summary");
    axios
      .get(
        API_GRID_GEN(
          rangePickerValue,
          riskValue,
          group_id,
          null,
          null,
          metricValue,
          iterationMap[iterationValueGlobal]
        ),
        {
          cancelToken: source.token,
        }
      )
      .then((result) => {
        // console.log("result3", result);
        setLoading(false);
        const nameList = result.data.map((val) => val.ProjectName);
        setGridResponse(result.data);
        setColumnVal(result.data);
        setGridData(nameList);
        setGridData2(nameList);
        setGridData3(nameList);
        setGridData4(nameList);
        setGridData5(nameList);
        setProjectNameData(nameList);
      })
      .catch((err) => {
        setLoading(false);
        if (unmounted) setLoading(true);
      });

    return () => {
      //source.cancel();
      unmounted = true;
    };
  }, [
    setColumnVal,
    setGridData,
    setGridData2,
    setGridData3,
    setGridData4,
    setGridData5,
    setGridResponse,
    riskValue,
    render,
    metricValue,
    rangePickerValue,
    iterationValueGlobal,
  ]);
  // set matric graph ========>
  // useEffect(() => {
  //   if (!graphTrendApi) return;
  //   if (!gridResponse) return;
  //   const source = axios.CancelToken.source();
  //   const API = API_TREND_GEN(
  //     graphTrendApi,
  //     rangePickerValue,
  //     group_id,
  //     null,
  //     null,
  //     iterationMap[iterationValueGlobal]
  //   );
  //   axios
  //     .get(API, {
  //       cancelToken: source.token,
  //     })
  //     .then((res) => {
  //       setProjectLevelData(res.data.trends[0]);
  //       setFirstTimeGraphLoad(true);
  //       setGraphLoading(false);

  //       let genratedDataOfCheckBox =
  //         allProjectsName.length > 0
  //           ? allProjectsName
  //           : CheckboxDataGenrate(res);
  //       setAllProjectNames(genratedDataOfCheckBox);
  //       //count for selected project
  //       let counted = CountValue(genratedDataOfCheckBox);
  //       setCountOfChecked(counted);

  //       let dataOfTenSelectedProjectName = createDataForMetricGraphFirst(
  //         genratedDataOfCheckBox,
  //         res.data
  //       );
  //       setMetricGraph(dataOfTenSelectedProjectName);
  //       setAllMetricGraphDataFirst(res.data);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });

  //   return () => {
  //     source.cancel();
  //   };
  // }, [
  //   graphTrendApi,
  //   setMetricGraph,
  //   rangePickerValue,
  //   gridResponse,
  //   // iterationValueGlobal,
  // ]);
  // setMetricGraph2=====>

  useEffect(() => {
    if (!graphTrendApi2) return;
    if (!gridResponse) return;
    // if (!firstTimeGraphLoad) return;
    const source = axios.CancelToken.source();

    const API = API_TREND_GEN(
      graphTrendApi2,
      rangePickerValue,
      group_id,
      null,
      null,
      iterationMap[iterationValueGlobal]
    );

    axios
      .get(API, {
        cancelToken: source.token,
      })
      .then((res) => {
        // console.log("res1", res);
        let genratedDataOFCheckBox =
          allProjectsName.length > 0
            ? allProjectsName
            : CheckboxDataGenrate(res);
        setAllProjectNames(genratedDataOFCheckBox);
        setAllData(genratedDataOFCheckBox);
        // console.log("genratedDataOFCheckBox", genratedDataOFCheckBox);
        setAllMetricGraphDataTwo(res.data);
        // console.log("res.data", res.data);
        //reuseble function for fillter out particular data of project name
        let dataOfTenSelectedProjectName = createDataForMetricGraphTwo(
          genratedDataOFCheckBox,
          res.data
        );
        // console.log(
        //   "dataOfTenSelectedProjectName",
        //   dataOfTenSelectedProjectName
        // );
        setMetricGraph2(dataOfTenSelectedProjectName);
        // setMetricGraph3(dataOfTenSelectedProjectName);
        setProjectLevelData2(res.data.trends[0]);
        // setProjectLevelData3(res.data.trends[0]);
        setGraphLoading2(false);
        // setGraphLoading3(false);
      })
      .catch((err) => {
        console.log(err);
      });

    return () => {
      //source.cancel();
    };
  }, [
    graphTrendApi2,
    setMetricGraph2,
    rangePickerValue,
    gridResponse,
    firstTimeGraphLoad,
    // iterationValueGlobal,
  ]);

  useEffect(() => {
    if (!graphTrendApi3) return;
    if (!gridResponse) return;
    // if (!firstTimeGraphLoad) return;
    const source = axios.CancelToken.source();

    const API = API_TREND_GEN(
      graphTrendApi3,
      rangePickerValue,
      group_id,
      null,
      null,
      iterationMap[iterationValueGlobal]
    );

    axios
      .get(API, {
        cancelToken: source.token,
      })
      .then((res) => {
        // console.log("res2", res);
        let genratedDataOFCheckBox =
          allProjectsName.length > 0
            ? allProjectsName
            : CheckboxDataGenrate(res);
        // console.log("genratedDataOFCheckBox", genratedDataOFCheckBox);
        setAllProjectNames(genratedDataOFCheckBox);
        setAllData(genratedDataOFCheckBox);
        setAllMetricGraphDataTwo(res.data);

        //reuseble function for fillter out particular data of project name
        let dataOfTenSelectedProjectName = createDataForMetricGraphTwo(
          genratedDataOFCheckBox,
          res.data
        );
        // console.log(
        //   "dataOfTenSelectedProjectName",
        //   dataOfTenSelectedProjectName
        // );
        setMetricGraph3(dataOfTenSelectedProjectName);
        setProjectLevelData3(res.data.trends[0]);
        setGraphLoading3(false);
      })
      .catch((err) => {
        console.log(err);
      });

    return () => {
      //source.cancel();
    };
  }, [
    graphTrendApi3,
    setMetricGraph3,
    rangePickerValue,
    gridResponse,
    firstTimeGraphLoad,
    // iterationValueGlobal,
  ]);

  useEffect(() => {
    if (!graphTrendApi4) return;
    if (!gridResponse) return;
    // if (!firstTimeGraphLoad) return;
    const source = axios.CancelToken.source();

    const API = API_TREND_GEN(
      graphTrendApi4,
      rangePickerValue,
      group_id,
      null,
      null,
      iterationMap[iterationValueGlobal]
    );

    axios
      .get(API, {
        cancelToken: source.token,
      })
      .then((res) => {
        // console.log("res2", res);
        let genratedDataOFCheckBox =
          allProjectsName.length > 0
            ? allProjectsName
            : CheckboxDataGenrate(res);
        // console.log("genratedDataOFCheckBox", genratedDataOFCheckBox);
        setAllProjectNames(genratedDataOFCheckBox);
        setAllData(genratedDataOFCheckBox);
        setAllMetricGraphDataTwo(res.data);

        //reuseble function for fillter out particular data of project name
        let dataOfTenSelectedProjectName = createDataForMetricGraphTwo(
          genratedDataOFCheckBox,
          res.data
        );
        // console.log(
        //   "dataOfTenSelectedProjectName",
        //   dataOfTenSelectedProjectName
        // );
        setMetricGraph4(dataOfTenSelectedProjectName);
        setProjectLevelData4(res.data.trends[0]);
        setGraphLoading4(false);
      })
      .catch((err) => {
        console.log(err);
      });

    return () => {
      //source.cancel();
    };
  }, [
    graphTrendApi4,
    setMetricGraph4,
    rangePickerValue,
    gridResponse,
    firstTimeGraphLoad,
    // iterationValueGlobal,
  ]);

  useEffect(() => {
    if (!graphTrendApi5) return;
    if (!gridResponse) return;
    // if (!firstTimeGraphLoad) return;
    const source = axios.CancelToken.source();

    const API = API_TREND_GEN(
      graphTrendApi5,
      rangePickerValue,
      group_id,
      null,
      null,
      iterationMap[iterationValueGlobal]
    );

    axios
      .get(API, {
        cancelToken: source.token,
      })
      .then((res) => {
        // console.log("res2", res);
        let genratedDataOFCheckBox =
          allProjectsName.length > 0
            ? allProjectsName
            : CheckboxDataGenrate(res);
        // console.log("genratedDataOFCheckBox", genratedDataOFCheckBox);
        setAllProjectNames(genratedDataOFCheckBox);
        setAllData(genratedDataOFCheckBox);
        setAllMetricGraphDataTwo(res.data);

        //reuseble function for fillter out particular data of project name
        let dataOfTenSelectedProjectName = createDataForMetricGraphTwo(
          genratedDataOFCheckBox,
          res.data
        );
        // console.log(
        //   "dataOfTenSelectedProjectName",
        //   dataOfTenSelectedProjectName
        // );
        setMetricGraph5(dataOfTenSelectedProjectName);
        setProjectLevelData5(res.data.trends[0]);
        setGraphLoading5(false);
      })
      .catch((err) => {
        console.log(err);
      });

    return () => {
      //source.cancel();
    };
  }, [
    graphTrendApi5,
    setMetricGraph5,
    rangePickerValue,
    gridResponse,
    firstTimeGraphLoad,
    // iterationValueGlobal,
  ]);

  const customRangePickerHandler = (val) => {
    setRangePickerValue(val);
  };

  function ScrollFun() {
    setTimeout(() => {
      document.getElementById("mainRiskAdvisor").style.minHeight = "auto";
    }, 1);
  }
  // sorting object using projects name . function build for metric graph
  let createDataForMetricGraphTwo = (projectName, allData) => {
    let mainObject = MetricGraphDataSorted(projectName, allData);
    return mainObject;
  };
  // let createDataForMetricGraphFirst = (projectName, allData) => {
  //   let mainObject = MetricGraphDataSorted(projectName, allData);
  //     return mainObject;
  // };

  //checkbox
  const handleChange = (index, title) => {
    let allDataTemp = [...allData];
    allDataTemp[index].checked = !allDataTemp[index].checked;
    setAllData(allDataTemp);

    let allProjectsNameTemp = [...allProjectsName];
    allProjectsNameTemp.map((properties) => {
      if (properties.projectName === title) {
        allProjectsNameTemp.checked = !allProjectsNameTemp.checked;
      }
    });
    // console.log("allProjectsNameTemp", allProjectsNameTemp);
    setAllProjectNames(allProjectsNameTemp);

    //count for selected project
    let counted = CountValue(allProjectsNameTemp);
    setCountOfChecked(counted);
    // console.log("counted", counted);
    let dataOfTenSelectedProjectName = createDataForMetricGraphTwo(
      allProjectsNameTemp,
      { ...allMetricGraphDataTwo }
    );
    // console.log("dataOfTenSelectedProjectName", dataOfTenSelectedProjectName);
    setMetricGraph2(dataOfTenSelectedProjectName);
    setMetricGraph3(dataOfTenSelectedProjectName);
    setMetricGraph4(dataOfTenSelectedProjectName);
    setMetricGraph5(dataOfTenSelectedProjectName);

    // let dataOfTenSelectedProjectNameFirst = createDataForMetricGraphFirst(
    //   allProjectsNameTemp,
    //   { ...allMetricGraphDataFirst }
    // );
    // setMetricGraph(dataOfTenSelectedProjectNameFirst);//changed name to First
  };

  //clear apply
  const clearAllApply = () => {
    let ProjectName = [...allProjectsName];
    ProjectName.map((title, index) => {
      if (index > 5) title.checked = false;
    });
    let dataOfTenSelectedProjectNameTwo = createDataForMetricGraphTwo(
      ProjectName,
      { ...allMetricGraphDataTwo }
    );
    // setMetricGraph2(dataOfTenSelectedProjectNameTwo);
    // let dataOfTenSelectedProjectNameFirst = createDataForMetricGraphFirst(
    //   ProjectName,
    //   { ...allMetricGraphDataTwo }
    // );
    // setMetricGraph(dataOfTenSelectedProjectNameFirst);
    setCountOfChecked(0);
  };

  //handle apply
  // const handleApply = () => {
  //   let allProjectName = [...allProjectsName];
  //   let dataOfTenSelectedProjectName = createDataForMetricGraphTwo(
  //     allProjectName,
  //     { ...allMetricGraphDataTwo }
  //   );
  //   setMetricGraph2(dataOfTenSelectedProjectName);
  //   setMetricGraph(dataOfTenSelectedProjectName);
  // };
  const handleChartType = (event) => {
    setLocalChart(event.target.value);
    // setOpen(false);
  };
  const handleApply = () => {
    let allProjectName = [...allProjectsName];
    // let dataOfTenSelectedProjectNameFirst = createDataForMetricGraphFirst(
    //   allProjectName,
    //   { ...allMetricGraphDataFirst }//changed
    // );
    let dataOfTenSelectedProjectNameTwo = createDataForMetricGraphTwo(
      allProjectName,
      { ...allMetricGraphDataTwo } //changed
    );
    setMetricGraph2(dataOfTenSelectedProjectNameTwo);
    setMetricGraph3(dataOfTenSelectedProjectNameTwo);
    setMetricGraph4(dataOfTenSelectedProjectNameTwo);
    setMetricGraph5(dataOfTenSelectedProjectNameTwo);
    // setMetricGraph(dataOfTenSelectedProjectNameFirst);
  };

  //searchBar functionality
  const [searchInput, setSearchInput] = useState("");
  const handleChangeSearchBar = (e) => {
    e.preventDefault();

    setSearchInput(e.target.value);
    let val = allProjectsName.filter((title) => {
      return title.projectName
        .toLowerCase()
        .includes(e.target.value.toLowerCase());
    });
    setAllData(val);
  };

  //popup functions for legends of graph
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  //pop up for see more
  const [anchorElSeeMore, setAnchorElSeeMore] = useState(null);
  const handleClickSeeMore = (event) => {
    setAnchorElSeeMore(event.currentTarget);
    setAnchorEl(null);
  };
  const handleCloseSeeMore = () => {
    setAnchorElSeeMore(null);
  };
  const openSeeMore = Boolean(anchorElSeeMore);
  const idSeeMore = openSeeMore ? "simple-popover" : undefined;

  const changeTabHandler = (render) => {
    history.push(`/riskadvisor/${render}`);
  };

  let isSetupDone = JSON?.parse(localStorage?.getItem("isSetupDone"));
  let pricePlan = JSON?.parse(localStorage?.getItem("pricePlan"));
  let featureList = pricePlan?.pricingPlan?.featureList?.projectLevel;

  const planRequestHandler = () => {
    setIsPlanUpgradeRequest(!isPlanUpgradeRequest);
  };

  // console.log(data, "project data");
  const handleChartTypeChart = (event) => {
    // console.log("event", event);
    setLocalChart(event.target.value);
    // setOpen(false);
  };
  const handleChartTypeChart1 = (event) => {
    // console.log("event", event);
    setLocalChart1(event.target.value);
    // setOpen(false);
  };
  const handleChartTypeChart2 = (event) => {
    // console.log("event", event);
    setLocalChart2(event.target.value);
    // setOpen(false);
  };
  const handleChartTypeChart3 = (event) => {
    // console.log("event", event);
    setLocalChart3(event.target.value);
    // setOpen(false);
  };

  // To set default project
  useEffect(() => {
    if (projectList?.length > 0 && !titleId) {
      setTitleID(projectList[0]?.Id);
    }
  }, [data]);

  // To show project list for dropdown
  const projectListHandler = () => {
    axios
      .get("/api/v2/projects/list")
      .then((result) => {
        setProjectList(result?.data);
      })
      .catch((err) => {
        console.log("error", err);
      });
  };

  useEffect(() => {
    projectListHandler();
  }, [render]);

  useEffect(() => {
    projectListHandler();
  }, []);

  return (
    <>
      {isSetupDone === false ? (
        <ConfigureForm />
      ) : (
        // <SetupForm />

        <div className="row m-0 p-0">
          <div
            className="col-12 d-flex justify-content-center align-items-center"
            style={{
              position: "fixed",
              zIndex: "1000000",
              // background: "#FCFCFC",
            }}
          >
            <Navbar frstGraphClear={flush} secGraphClear={flush2} />
          </div>
          <div className="row">
            <div
              className="col-2 p-2"
              style={{
                position: "fixed",
                left: 0,

                background: "#FFFFFF 0% 0% no-repeat padding-box",
                boxShadow: "0px 16px 20px #0000000F",
                opacity: 1,
              }}
            >
              <span className="col-12 p-3 d-flex justify-content-center align-items-center">
                <img
                  src={Logo}
                  style={{ width: "164px", height: "33px" }}
                  alt="itechgenic"
                />
              </span>

              <span className="col-12 d-flex justify-content-center align-items-center">
                <NavTabs
                  value={render}
                  setValue={(render) => changeTabHandler(render)}
                  render={Tabs}
                  fontsize="14px"
                  isroot
                  clearFrstGraph={flush}
                  clearSecGraph={flush2}
                  clearAdmin={() => history.push(`/riskadvisor/${render}`)}
                />{" "}
              </span>
            </div>
            <div
              className="col-11 p-2"
              style={{
                position: "absolute",
                right: "38px",
                marginTop: "40px",
              }}
            >
              {render === 0 &&
                (featureList === true ? (
                  <div id="mainRiskAdvisor" style={{ marginLeft: "220px" }}>
                    <ProjectPage
                      projectId={titleId}
                      projectName={titleName}
                      dropdownProjectList={data}
                      setTitleID={setTitleID}
                      Metric={Metric}
                    />
                    {/* <ProjectDashboard /> */}
                  </div>
                ) : (
                  <>
                    <div className={`${classes.feature}`}>
                      <h4>You dont have access. Please upgrade your plan.</h4>
                    </div>
                    <div className={`${classes.featureBtn}`}>
                      <Button
                        variant="contained"
                        className={classes.btn}
                        type="button"
                        onClick={() => {
                          planRequestHandler();
                        }}
                      >
                        Upgrade
                      </Button>
                    </div>
                    {isPlanUpgradeRequest && <UpgradePlanRequest />}
                  </>
                ))}
            </div>
          </div>

          {/***teams */}

          <div className="row">
            <div
              className="col-2 p-2"
              style={{
                position: "fixed",
                left: 0,

                background: "#FFFFFF 0% 0% no-repeat padding-box",
                boxShadow: "0px 16px 20px #0000000F",
                opacity: 1,
              }}
            >
              <span className="col-12 p-3 d-flex justify-content-center align-items-center">
                <img
                  src={Logo}
                  style={{ width: "164px", height: "33px" }}
                  alt="itechgenic"
                />
              </span>

              <span className="col-12 d-flex justify-content-center align-items-center">
                <NavTabs
                  value={render}
                  setValue={(render) => changeTabHandler(render)}
                  render={Tabs}
                  fontsize="14px"
                  isroot
                  clearFrstGraph={flush}
                  clearSecGraph={flush2}
                  clearAdmin={() => history.push(`/riskadvisor/${render}`)}
                />{" "}
              </span>
            </div>
            <div
              className="col-11 p-2"
              style={{
                position: "absolute",
                right: "38px",
                marginTop: "50px",
              }}
            >
              {render === 1 &&
                (featureList === true ? (
                  <div id="mainRiskAdvisor" style={{ marginLeft: "220px" }}>
                    <div className={classes.fieldGrid}>
                      <ScrollToTop />
                    </div>
                    <Teams
                      projectId={titleId}
                      projectName={titleName}
                      dropdownProjectList={projectList}
                      setTitleID={setTitleID}
                      Metric={Metric}
                    />
                  </div>
                ) : (
                  <>
                    <div className={`${classes.feature}`}>
                      <h4>You dont have access. Please upgrade your plan.</h4>
                    </div>
                    <div className={`${classes.featureBtn}`}>
                      <Button
                        variant="contained"
                        className={classes.btn}
                        type="button"
                        onClick={() => {
                          planRequestHandler();
                        }}
                      >
                        Upgrade
                      </Button>
                    </div>
                    {isPlanUpgradeRequest && <UpgradePlanRequest />}
                  </>
                ))}
            </div>
          </div>

          {render === 9 && (
            <Navbar
              frstGraphClear={flush}
              secGraphClear={flush2}
              isLogout={isLogout}
            />
          )}

          {render === 8 && (
            <Navbar frstGraphClear={flush} secGraphClear={flush2} />
          )}

          <div className="row" style={{ marginTop: "40px" }}>
            {render === 5 && (
              <>
                <div className="col-2 p-2"></div>
                <div className="col-10 p-2">
                  <Riskpage />
                </div>
              </>
            )}
          </div>

          <div
            className="row"
            style={{ marginTop: "70px", position: "absolute", right: "0" }}
          >
            {render === 4 && (
              <>
                <div className="col-2 p-2"></div>
                <div className="col-10 p-2">
                  <Jira />
                </div>
              </>
            )}
          </div>

          <div
            className="col-11 p-2"
            style={{ position: "absolute", right: "38px" }}
          >
            {render === 2 &&
              (featureList === true ? (
                <div id="mainRiskAdvisor" style={{ marginLeft: "220px" }}>
                  <div className={classes.fieldGrid}>
                    <ScrollToTop />
                  </div>

                  <Resources
                    projectId={titleId}
                    projectName={titleName}
                    dropdownProjectList={projectList}
                    setTitleID={setTitleID}
                    Metric={Metric}
                  />
                </div>
              ) : (
                <>
                  <div className={`${classes.feature}`}>
                    <h4>You dont have access. Please upgrade your plan.</h4>
                  </div>
                  <div className={`${classes.featureBtn}`}>
                    <Button
                      variant="contained"
                      className={classes.btn}
                      type="button"
                      onClick={() => {
                        planRequestHandler();
                      }}
                    >
                      Upgrade
                    </Button>
                  </div>
                  {isPlanUpgradeRequest && <UpgradePlanRequest />}
                </>
              ))}
          </div>
          <div
            className="col-11 p-2"
            style={{ position: "absolute", right: "38px", marginTop: "50px" }}
          >
            {render === 3 &&
              (featureList === true ? (
                <div id="mainRiskAdvisor" style={{ marginLeft: "220px" }}>
                  <div className={classes.fieldGrid}>
                    <ScrollToTop />
                  </div>

                  <div
                    style={{
                      // border: "2px solid black",
                      boxShadow: "0px 6px 10px #0000008",
                      borderRadius: "15px",
                    }}
                  >
                    <ModulesPage
                      projectId={titleId}
                      projectName={titleName}
                      dropdownProjectList={projectList}
                      setTitleID={setTitleID}
                      Metric={Metric}
                    />
                  </div>
                </div>
              ) : (
                <>
                  <div className={`${classes.feature}`}>
                    <h4>You dont have access. Please upgrade your plan.</h4>
                  </div>
                  <div className={`${classes.featureBtn}`}>
                    <Button
                      variant="contained"
                      className={classes.btn}
                      type="button"
                      onClick={() => {
                        planRequestHandler();
                      }}
                    >
                      Upgrade
                    </Button>
                  </div>
                  {isPlanUpgradeRequest && <UpgradePlanRequest />}
                </>
              ))}
          </div>

          <div className="row" style={{ marginTop: "40px" }}>
            {render === 7 ? (
              check === "addDetails" || form ? (
                <>
                  <div className="col-2 p-2"></div>
                  <div className="col-10 p-2">
                    <div className={classes.header}>
                      <div className={classes.text}>
                        {" "}
                        <IconButton size="small" onClick={backHandler}>
                          <ArrowBackIosIcon />
                        </IconButton>
                        {title}
                      </div>
                    </div>
                    <hr color="#cacaca" />
                    <Details
                      data={allDetails}
                      form={form}
                      back={backSubmit}
                      id={rowData}
                      title={title}
                    />
                  </div>
                </>
              ) : check !== "details" ? (
                <>
                  <div className="col-2 p-2"></div>
                  <div className="col-10 p-2">
                    <div className={classes.table}>
                      <MaterialGrid
                        admin={true}
                        columns={column2}
                        data={data2}
                        isLink={data2}
                        isLoading={isLoading}
                        level={render == 5 ? "setup" : graphLevel}
                        setId={setRowId}
                      />
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="col-2 p-2"></div>
                  <div className="col-10 p-2">
                    <div id="mainRiskAdvisor">
                      <div className={classes.header}>
                        <div className={classes.text}>
                          {" "}
                          <span className="row">
                            <IconButton
                              size="small"
                              onClick={() => {
                                backHandler();
                                setmetricDetails([]);
                              }}
                              className="col-1 d-flex align-items-center"
                            >
                              <ArrowBackIosIcon />
                            </IconButton>
                            {title == "Tools Integration" && (
                              <span className="col-1 mx-4 d-flex align-items-center justify-content-center p-0 m-0">
                                <img
                                  src={IntegrationTool}
                                  height={70}
                                  width={90}
                                  alt=""
                                ></img>
                              </span>
                            )}
                            <span className="col-8 d-flex align-items-center">
                              <span className="row">
                                <span
                                  className="col-12 d-flex align-items-center"
                                  style={{
                                    fontSize: "24px",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {title}
                                </span>

                                {title == "Tools Integration" && (
                                  <span
                                    className="col-12 d-flex align-items-center"
                                    style={{
                                      fontSize: "16px",
                                      fontWeight: "10px",
                                    }}
                                  >
                                    Streamline your processes and link the tools
                                    you use regularly
                                  </span>
                                )}
                              </span>
                            </span>
                          </span>
                        </div>
                        {title != "Metrics" &&
                          rowData !== 6 &&
                          rowData !== 7 && (
                            <button
                              style={{
                                fontFamily: "Lexend Deca",
                              }}
                              className={classes.button}
                              onClick={() => {
                                setFrom(true);

                                //  history.push(`/riskadvisor/add/${rowData}`);
                                history.push({
                                  pathname: `/riskadvisor/${currentRender}/create/${title}}`,

                                  state: {
                                    details: "addDetails",
                                    title: title,
                                  },
                                });
                              }}
                            >
                              New {setUpMap[title]}
                            </button>
                          )}
                      </div>
                      <hr color="#cacaca" />
                      <div className={classes.table2}>
                        {rowData === 1 && (
                          <div className={classes.tableFieldGrid}>
                            <SelectField
                              ScrollFun={(val) => ScrollFun(val)}
                              render={Status}
                              value={statusValue}
                              setValue={setStatusValue}
                              frstGraphClear={flush}
                              secGraphClear={flush2}
                            />
                          </div>
                        )}
                        {rowData === 2 && (
                          <div className={classes.tableFieldGrid}>
                            <SelectField
                              ScrollFun={(val) => ScrollFun(val)}
                              render={Status}
                              value={statusValue}
                              setValue={setStatusValue}
                              frstGraphClear={flush}
                              secGraphClear={flush2}
                            />
                          </div>
                        )}

                        <div className="materialTable">
                          <MaterialGrid
                            deatils={true}
                            key={metricValue}
                            columns={rowHeaderMap[rowData]}
                            data={rowDataMap[rowData]}
                            isLink={
                              rowData === 4 || rowData === 3 || rowData === 6
                                ? false
                                : true
                            }
                            isLoading={isLoading}
                            tableID={rowData}
                            title={title}
                            metricConfig={true}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )
            ) : null}
          </div>
          {rowData === 6 && flag && <AzureModal data={data8 || ""} />}
          {rowData === 6 && sonarqubeFlag && <SonarqubeModal />}
          {rowData === 7 && metricConfigurationFlag && <MatricModal />}

          {rowData === 6 && isPlanUpgradeMessage && (
            <UpgradePlanMessage dataID={rowData} />
          )}
          {rowData === 7 && isPlanUpgradeMessage && (
            <UpgradePlanMessage dataID={rowData} />
          )}

          {rowData === 6 && isPlanUpgradeRequest && (
            <UpgradePlanRequest dataID={rowData} />
          )}
          {rowData === 7 && isPlanUpgradeRequest && (
            <UpgradePlanRequest dataID={rowData} />
          )}
        </div>
      )}
    </>
  );
}
