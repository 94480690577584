import { useEffect, useContext } from "react";
import { GlobalData } from "../context/globalData";
import { MatrixExport } from "./excelExport";

const Headertitle = { TEAMS: "Team", RESOURCES: "Resource", MODULES: "Module" };

export const useExcelDownloadHook = (
  graphName,
  companyName,
  selectedValue,
  precision
) => {
  const { selectedTab } = useContext(GlobalData);
  const downloadCsv = () => {
    if (graphName) {
      let HeaderDate = graphName.map((dates) => new Date(dates.Time));
      HeaderDate.sort((a, b) => a.getTime() - b.getTime());
      let allDate = HeaderDate.map((item) => {
        const month = item.toLocaleString("default", { month: "long" });
        let dates = item.getDate();
        return ` ${month}  ${dates}`;
      });
      let NewDate = new Set(allDate);
      const headerCols = [
        selectedTab ? Headertitle[selectedTab] : "Project",
        ...NewDate,
      ];
      let rowDatas = [];
      let newArray = [];
      let i = 0;
      while (i < graphName.length) {
        let tempName = graphName[i].Name;
        let catArr = [];
        catArr.push(tempName);
        while (i < graphName.length && graphName[i].Name === tempName) {
          let value = graphName[i].Value;
          value = Math.round(value * `1e${precision}`) / `1e${precision}`;
          catArr.push(value);
          i++;
        }
        rowDatas.push(catArr);
      }
      let filterArray = [];
      companyName.map((curCompany) => {
        rowDatas.filter((item) => {
          if (curCompany === item[0]) {
            filterArray.push(item);
          }
        });
      });

      let allvalue = filterArray.filter((item) => {
        return item[0] !== "Not Assigned";
      });
      let notAssignValue = filterArray.filter((item) => {
        return item[0] === "Not Assigned";
      });
      newArray = [...allvalue, ...notAssignValue];
      MatrixExport(headerCols, newArray, selectedValue, selectedTab);
    }
  };
  return {
    downloadCsv,
  };
};
