import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
// import Button from "@mui/material/Button";
import { makeStyles } from "@material-ui/core/styles";
import { alpha } from "@mui/material/styles";
import { useContext, useState } from "react";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Grid from "@mui/material/Grid";
import EditIcon from "@mui/icons-material/Edit";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { useHistory, useLocation, useParams } from "react-router-dom";
import AddEditUser from "./AddEditUser";
import { Button } from "@material-ui/core";


const useStyles = makeStyles((theme) => ({
  btnStyle: {
    float: "right",
    background: "rgb(94, 189, 204)",
    cursor: "pointer",
    font: "normal normal normal 16px/20px Lexend Deca",
    borderRadius: "22px",
    width: "180px",
    padding: "8px 8px",
    margin: "25px 5px",
  },
  btngroup: {
    marginTop: "10px",
  },
  searchnButton: {
    display: "flex",
  },
  headerlabel: {
    margin: "20px 0",
  },
}));
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.action.hover,
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    // backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function createData(
  Srno,
  profile,
  name,
  role,
  email,
  invitationDate,
  activationDate
) {
  return {
    Srno,
    profile,
    name,
    role,
    email,
    invitationDate,
    activationDate,
  };
}

const rows = [
  createData(
    "1",
    "",
    "Abhishek Purane",
    "UI UX Designer",
    "abhi@gmail.com",
    "12 June 2023",
    "13 June 2023"
  ),
  createData(
    "2",
    "",
    "Manjiri Rane",
    "UX Designer",
    "manjiri.rane@gmail.com",
    "12 June 2023",
    "13 June 2023"
  ),
  createData(
    "3",
    "",
    "Pradnya Adhav",
    "Executive support",
    "pradnya.a@gmail.com",
    "12 June 2023",
    "13 June 2023"
  ),
  createData(
    "4",
    "",
    "Amit Salunke",
    "Backend Developer",
    "amit.s@gmail.com",
    "12 June 2023",
    "13 June 2023"
  ),
  createData(
    "5",
    "",
    "Akshay Jagalpure",
    "Frontend Developer",
    "akshay.j@gmail.com",
    "12 June 2023",
    "13 June 2023"
  ),
  createData(
    "6",
    "",
    "Vaibhav Shinde",
    "Backend Designer",
    "vaibhav.s@gmail.com",
    "12 June 2023",
    "13 June 2023"
  ),
  createData(
    "7",
    "",
    "Aninda Mukharjee",
    "Manager",
    "aninda.m@gmail.com",
    "12 June 2023",
    "13 June 2023"
  ),
  createData(
    "8",
    "",
    "Akshay Sagvekar",
    "UI UX Designer",
    "akshay.s@gmail.com",
    "12 June 2023",
    "13 June 2023"
  ),
  createData(
    "9",
    "",
    "Pranav Telavane",
    "UI UX Designer",
    "pranav.t@gmail.com",
    "12 June 2023",
    "13 June 2023"
  ),
];

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  border: "2px solid black",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "50%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIconsetAddEdiadd
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "50%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

export default function User() {
  const history = useHistory();
  const [age, setAge] = useState("");
  const [addEdit, setAddEdit] = useState(false);

  const handleChange = (event) => {
    console.log("event", event);
    setAge(event.target.value);
  };

  const AddEditUsers = () => {
    setAddEdit(true);
  };
  console.log("addedit", addEdit);
  const classes = useStyles();

  return (
    <>
          {addEdit==false ? (
      
          <><div className={classes.headerlabel}>
          <span onClick={AddEditUsers}>Manage Users</span>
          <button
            className={classes.btnStyle}
            onClick={AddEditUsers}
          >
            Add New User
          </button>


        </div><>
            <TableContainer component={Paper}>

              <Table sx={{ minWidth: 700 }} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Sr.no</StyledTableCell>
                    <StyledTableCell>Profile</StyledTableCell>
                    <StyledTableCell>Name</StyledTableCell>
                    <StyledTableCell>Role</StyledTableCell>
                    <StyledTableCell>Email</StyledTableCell>
                    <StyledTableCell>Invitation Date</StyledTableCell>
                    <StyledTableCell>Activation Date</StyledTableCell>
                    <StyledTableCell>Actions</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row) => (
                    <StyledTableRow>
                      <StyledTableCell component="th" scope="row">
                        {row.Srno}
                      </StyledTableCell>
                      <StyledTableCell>{row.profile}</StyledTableCell>
                      <StyledTableCell>{row.name}</StyledTableCell>
                      <StyledTableCell>{row.role}</StyledTableCell>
                      <StyledTableCell>{row.email}</StyledTableCell>
                      <StyledTableCell>{row.invitationDate}</StyledTableCell>
                      <StyledTableCell>{row.activationDate}</StyledTableCell>
                      <StyledTableCell>
                        <Grid item xs={4} sx={{ ml: 3 }}>
                          <EditIcon />
                          <DeleteOutlinedIcon xs={6} sx={{ ml: 3 }} />
                        </Grid>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </></>
      ) : ( addEdit == true ?  <AddEditUser />: null
      )}
    </>
  );
}
