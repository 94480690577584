import React, { useEffect, useState } from "react";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";
import ListItemText from "@material-ui/core/ListItemText";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200,
  },
}));

const MenuProps = {
  PaperProps: {
    style: {
      width: 250,
    },
  },
};

export default function CheckSelectId({
  names,
  setValue,
  value,
  defaultValue,
}) {
  const classes = useStyles();

  useEffect(() => {
    if (names.length > 0 && value.length === 0 && defaultValue) {
      setValue([defaultValue]);
    }
  }, [names, setValue, value?.length, defaultValue]);

  const isAllSelected = names?.length > 0 && value.length === names.length;

  const handleChange = (event) => {
    const { value: newValue } = event.target;
    if (newValue[newValue.length - 1] === "all") {
      setValue(isAllSelected ? [] : names?.map((item) => item?.id));
      return;
    }
    setValue(typeof newValue === "string" ? newValue.split(",") : newValue);
  };

  return (
    <FormControl style={{ width: "200px" }}>
      <Select
        labelId="demo-multiple-checkbox-label"
        id="demo-multiple-checkbox"
        multiple
        value={value}
        onChange={handleChange}
        renderValue={(selected) =>
          names
            .filter((item) => selected.includes(item?.id))
            .map((item) => item?.name)
            .join(", ")
        }
        MenuProps={MenuProps}
        style={{
          height: "45px",
          borderRadius: "40px",
          border: "1px solid gray",
          padding: "2px 6px",
        }}
      >
        <MenuItem value="all">
          <Checkbox
            checked={isAllSelected}
            indeterminate={value.length > 0 && value.length < names.length}
          />
          <ListItemText primary="All" />
        </MenuItem>
        {names?.map((item, index) => (
          <MenuItem key={index} value={item?.id}>
            <Checkbox checked={value.indexOf(item?.id) > -1} />
            <ListItemText primary={item?.name} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
