import React from "react";
import {
  makeStyles,
  createStyles,
  withStyles,
  Theme,
} from "@material-ui/core/styles";
import userImage from "../../assets/id_img.jpg";
import LinearProgress from "@material-ui/core/LinearProgress";
import { EmojiTransportationRounded, FullscreenExit } from "@material-ui/icons";
import InfoIcon from '@material-ui/icons/Info';
import { orange, yellow } from "@material-ui/core/colors";
const useStyles = makeStyles({
  u1: {
    height: "80px",
    width: "150px",
  },
  userstyle: {
    height: "70px",
    width: "70px",
    padding: "10px",
  },
  userinform: {
    display: "flex",
    gap: "5px",
  },
  wip: {
    color: "black",
    fontWeight: "bold",
    margin: "4px 0px",
  },
  branch:{
    fontSize: "12px",
    fontWeight:"600",
    "text-transform": "capitalize",
    margin:"0px" 
  },

  userdetail: {
    paddingLeft: "10px",
    textAlign: "left",
    "& h6": {
      display: "flex",
      margin: "2px 0px",
      "text-transform": "capitalize",
      gap: "10px",
      "font-size": "14px",
    },
  },
});

export const UserInformation = (props) => {
  const classes = useStyles();
  const BorderLinearProgress = withStyles((theme) =>
    createStyles({
      root: {
        height: 10,
        borderRadius: 5,
      },
      colorPrimary: {
        backgroundColor: "transparent",
        // theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
      },
      bar: {
        borderRadius: 5,
        backgroundColor: "#05135f",
      },
    })
  )(LinearProgress);

  return (
    <>
      <div className={classes.u1}>
        <div className={classes.userinform}>
          <img src={userImage} alt="" className={classes.userstyle}></img>
          <div className={classes.userdetail}>
            <h6>
              <span>{"Rahul"}</span>
              <InfoIcon style={{ color: orange[500] }}/>
            </h6>

            <BorderLinearProgress variant="determinate" value={40} />
            <h5 className={classes.wip}>4 wip</h5>
            <h5 className={classes.branch}>12 branches</h5>
          </div>
        </div>
      </div>
    </>
  );
};
