import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import { GlobalData } from "../../src/context/globalData";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 4),
    top: "50%",
    left: "50%",
    transform: "translate(-50% ,-50%)",
    textAlign: "center",
  },
  feature: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "20px",
    fontWeight: "bolder",
    marginTop: "20px",
  },
  btn2: {
    backgroundColor: "#51a7ed",
    fontSize: "15px",
    padding: "0px 30px",
    color: "#fff",
    height: "35px",
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: "#072136",
    },
  },
  featureBtn: {
    display: "flex",
    justifyContent: "center",
  },
  btnWraper: {
    display: "flex",
    justifyContent: "space-around",
  },
}));

const UpgradePlanMessage = ({dataID}) => {
  const classes = useStyles();
  const history = useHistory();

  const { isPlanUpgradeMessage, setIsPlanUpgradeMessage ,isPlanUpgradeRequest, setIsPlanUpgradeRequest} =
    useContext(GlobalData);

  const cancelHandler = () => {
    setIsPlanUpgradeMessage(!isPlanUpgradeMessage);
    if (dataID === 7) {
      history.push({
        pathname: `/riskadvisor/${5}`,
        hash: "Metric configuration",
        state: {
          details: "details",
          title: "Metric configuration",
          id: 7,
          isLoad: true,
        },
      });
    } else {
      history.push({
        pathname: `/riskadvisor/${5}`,
        hash: "Tools Integration",
        state: {
          details: "details",
          title: "Tools Integration",
          id: 6,
          isLoad: true,
        },
      });
    }
  };


  const upgradeHandler = () => {
    setIsPlanUpgradeMessage(!isPlanUpgradeMessage);
    setIsPlanUpgradeRequest(!isPlanUpgradeRequest);
  };

  return (
    <>
      <Modal
        open={isPlanUpgradeMessage}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className={classes.paper}>
          <div className={`${classes.feature}`}>
            <h4>You dont have access. Please upgrade your plan.</h4>
          </div>
          <div className={classes.btnWraper}>
            <Button
              variant="contained"
              className={classes.btn2}
              type="button"
              onClick={() => cancelHandler()}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              className={classes.btn2}
              type="button"
              onClick={() => {
                upgradeHandler();
              }}
            >
              Upgrade
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default UpgradePlanMessage;
